import { Button, Divider, Form, Switch } from 'antd';
import { HiOutlineCalendar } from 'react-icons/hi';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import DatePicker from '../../../components/DatePicker';
import { Trash } from '../../../components/icons';
import { sectionCardFormItemLayout } from '../../../components/SectionCard';
import TimePicker from '../../../components/TimePicker';
import genericMessages from '../../../i18n/genericMessages';
import placeMessages from '../../../i18n/placeMessages';

const PlaceClosedDaysFields = () => {
    const { formatMessage } = useIntl();
    const form = Form.useFormInstance();

    return (
        <>
            <Form.Item
                label={formatMessage(placeMessages.closedDaysLabel)}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <DatePicker
                    onChange={(value) => {
                        if (value) {
                            form.setFieldsValue({
                                closedDays: [
                                    ...(form.getFieldValue('closedDays') || []),
                                    {
                                        fullDay: false,
                                        fromDate: value?.startOf('day')?.hour(6),
                                        toDate: value?.startOf('day')?.hour(20),
                                    },
                                ],
                            });
                        }
                    }}
                    suffixIcon={<HiOutlineCalendar />}
                    format="DD/MM/YYYY"
                />
            </Form.Item>
            <Divider />
            <Form.Item shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['closedDays'])?.length > 0 ? (
                        <Form.List name={['closedDays']}>
                            {(fields, { remove }, { errors }) => (
                                <>
                                    {fields.map(({ key, name: subName }) => (
                                        <Form.Item
                                            key={key}
                                            label={
                                                <FormattedDate
                                                    value={form
                                                        .getFieldValue(['closedDays', subName, 'fromDate'])
                                                        ?.toDate()}
                                                />
                                            }
                                            {...sectionCardFormItemLayout}
                                            wrapperCol={{ span: 16 }}
                                        >
                                            <div className="flex items-center space-x-3">
                                                <Form.Item
                                                    name={[subName, 'isFullDay']}
                                                    valuePropName="checked"
                                                    noStyle
                                                >
                                                    <Switch />
                                                </Form.Item>
                                                <Form.Item dependencies={['closedDays', subName, 'isFullDay']} noStyle>
                                                    {({ getFieldValue }) =>
                                                        getFieldValue(['closedDays', subName, 'isFullDay']) ? (
                                                            <span>{formatMessage(genericMessages.fullDay)}</span>
                                                        ) : (
                                                            <FormattedMessage
                                                                tagName="span"
                                                                {...placeMessages.closedFromTo}
                                                                values={{
                                                                    from: (
                                                                        <Form.Item
                                                                            name={[subName, 'fromDate']}
                                                                            dependencies={[
                                                                                ['closedDays', subName, 'toDate'],
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <TimePicker
                                                                                format="HH:mm"
                                                                                size="small"
                                                                                minuteStep={5}
                                                                                style={{ margin: '0 4px' }}
                                                                            />
                                                                        </Form.Item>
                                                                    ),
                                                                    to: (
                                                                        <Form.Item
                                                                            name={[subName, 'toDate']}
                                                                            dependencies={[
                                                                                ['closedDays', subName, 'fromDate'],
                                                                            ]}
                                                                            rules={[
                                                                                ({ getFieldValue }) => ({
                                                                                    async validator(_, value) {
                                                                                        if (
                                                                                            !value ||
                                                                                            getFieldValue([
                                                                                                'closedDays',
                                                                                                subName,
                                                                                                'fromDate',
                                                                                            ]) < value
                                                                                        ) {
                                                                                            return await Promise.resolve();
                                                                                        }
                                                                                        return await Promise.reject(
                                                                                            new Error(
                                                                                                formatMessage(
                                                                                                    placeMessages.openHoursErrorFromMustBeBeforeTo
                                                                                                )
                                                                                            )
                                                                                        );
                                                                                    },
                                                                                }),
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <TimePicker
                                                                                format="HH:mm"
                                                                                size="small"
                                                                                minuteStep={5}
                                                                                style={{ margin: '0 4px' }}
                                                                            />
                                                                        </Form.Item>
                                                                    ),
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </Form.Item>
                                                <Button type="text" icon={<Trash />} onClick={() => remove(subName)} />
                                            </div>
                                        </Form.Item>
                                    ))}
                                    <div>
                                        <Form.ErrorList errors={errors} />
                                    </div>
                                </>
                            )}
                        </Form.List>
                    ) : (
                        formatMessage(placeMessages.closedDaysEmpty)
                    )
                }
            </Form.Item>
        </>
    );
};

export default PlaceClosedDaysFields;
