import { FC, useCallback, useEffect } from 'react';
import { Button, Layout, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Breakpoint } from 'react-socks';
import { FormattedMessage } from 'react-intl';

import useQueryParams from '../../hooks/queryParams';
import { AuthorizeType, useAuthorizeMobilic } from '../../queries/mobilic';
import { getRoute, RoutePathName } from '../../routes';
import Logo from '../../components/Logo';
import background from '../../assets/images/login-background.jpg';
import genericMessages from '../../i18n/genericMessages';
import SvgCheckCircleGreen from '../../components/icons/CheckCircleGreen';
import { useOperatorMobilicTokenUpdate } from '../../queries/operators';
import { ExclamationCircle } from '../../components/icons';

const OperatorMobilicCallback: FC = () => {
    const [queryParams] = useQueryParams('code');
    const code = queryParams.get('code');
    const { mutate: mutateAuthorizeMobilic, isSuccess, isLoading, isError } = useAuthorizeMobilic();
    const {
        mutate: mutateOperatorMobilicTokenUpdate,
        isSuccess: isMobilicTokenUpdateSuccess,
        isLoading: isMobilicTokenUpdateLoading,
        isError: isMobilicTokenUpdateError,
    } = useOperatorMobilicTokenUpdate();

    const authorizeMobilic = useCallback(() => {
        if (code) {
            mutateAuthorizeMobilic(
                { code, type: AuthorizeType.operator },
                {
                    onSuccess: ({ access_token }) => {
                        mutateOperatorMobilicTokenUpdate({
                            mobilicToken: access_token,
                        });
                    },
                }
            );
        }
    }, [code, mutateAuthorizeMobilic, mutateOperatorMobilicTokenUpdate]);

    useEffect(() => {
        if (
            !isLoading &&
            !isSuccess &&
            !isError &&
            !isMobilicTokenUpdateLoading &&
            !isMobilicTokenUpdateSuccess &&
            !isMobilicTokenUpdateError
        ) {
            authorizeMobilic();
        }
    });
    return (
        <Layout id="login-layout">
            <div id="login-section">
                <div id="login-section-inner" className="px-12">
                    {isLoading || isMobilicTokenUpdateLoading ? (
                        <>
                            <Spin spinning={true} className="mb-6 operator-callback-icon" />
                            <Typography.Title level={2} className="text-primary uppercase mb-4 font-bold">
                                <FormattedMessage {...genericMessages.wait} />
                            </Typography.Title>
                            <p className="text-xl">
                                <FormattedMessage {...genericMessages.save} />
                            </p>
                        </>
                    ) : (
                        <>
                            {isError || isMobilicTokenUpdateError || !code ? (
                                <>
                                    <ExclamationCircle className="mb-6 operator-callback-icon operator-callback-icon-warning" />
                                    <Typography.Title level={2} className="text-primary uppercase mb-4 font-bold">
                                        <FormattedMessage {...genericMessages.mobilicError} />
                                    </Typography.Title>
                                    <p className="text-xl">
                                        <FormattedMessage {...genericMessages.mobilicErrorDetail} />
                                    </p>
                                    <p className="text-xl">
                                        <FormattedMessage {...genericMessages.mobilicErrorDetailRequestDispacher} />
                                    </p>
                                </>
                            ) : (
                                <>
                                    <SvgCheckCircleGreen className="mb-6 operator-callback-icon" />
                                    <Typography.Title level={3} className="text-primary uppercase mb-4 font-bold">
                                        <FormattedMessage {...genericMessages.mobilicSuccess} />
                                    </Typography.Title>
                                    <p className="text-xl">
                                        <FormattedMessage {...genericMessages.mobilicSuccessDetail} />
                                    </p>
                                </>
                            )}
                            <Link to={getRoute(RoutePathName.home)}>
                                <Button type="primary" size="large" className="mt-5" block>
                                    <FormattedMessage {...genericMessages.closePage} />
                                </Button>
                            </Link>
                        </>
                    )}
                </div>
            </div>
            <Breakpoint className="login-image" small down>
                <Logo color="red" />
                <img src={background} alt="Camions ID Logistics" />
            </Breakpoint>
        </Layout>
    );
};

export default OperatorMobilicCallback;
