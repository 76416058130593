import { useMemo, VFC } from 'react';
import { Space } from 'antd';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import Seo from '../../../components/Seo';
import genericMessages from '../../../i18n/genericMessages';
import { getRoute, RoutePathName } from '../../../routes';
import { Transporter } from '../../../queries/api/types';
import { useTransporterDetails, useTransporterUpdate } from '../../../queries/transporters';
import DetailsFormCard from '../../../components/form/DetailsFormCard';
import ApiResult from '../../../components/ApiResult';
import { TransporterUpdatePayload } from '../../../queries/api/transporters';
import DetailsFormEditableTitle from '../../../components/form/DetailsFormEditableTitle';
import { formatTransporterInitialValue, formatTransporterUpdatePayload, getFormSections, TransporterForm } from '.';
import StatusSwitch from '../../../components/form/StatusSwitch';

const TransporterDetails: VFC = () => {
    const { transporterId } = useParams<{ transporterId: Transporter['id'] }>();
    const { formatMessage } = useIntl();
    const {
        data: transporterDetails,
        isError,
        error,
    } = useTransporterDetails(transporterId, {
        enabled: !!transporterId,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });
    const pageTitle = transporterDetails?.reference || formatMessage(genericMessages.editing);
    const sections = useMemo(() => getFormSections(formatMessage), [formatMessage]);
    const sectionCards = useMemo(
        () =>
            sections.map((section) => (
                <DetailsFormCard<Transporter, TransporterForm, TransporterUpdatePayload>
                    {...section}
                    key={section.title}
                    detailsQueryHandler={useTransporterDetails}
                    updateQueryHandler={useTransporterUpdate}
                    initialValueFormatter={formatTransporterInitialValue}
                    formatPayload={formatTransporterUpdatePayload()}
                    id={transporterId}
                />
            )),
        [transporterId, sections]
    );

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                <DetailsFormEditableTitle
                    backRoute={getRoute(RoutePathName.transporters)}
                    detailsQueryHandler={useTransporterDetails}
                    updateQueryHandler={useTransporterUpdate}
                    placeholder={formatMessage(genericMessages.name)}
                    id={transporterId}
                />
                <StatusSwitch
                    detailsQueryHandler={useTransporterDetails}
                    updateQueryHandler={useTransporterUpdate}
                    id={transporterId}
                />
            </div>
            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    {sectionCards}
                </Space>
            )}
        </>
    );
};

export default TransporterDetails;
