import { ReactNode, useState, VFC } from 'react';
import { Form, FormItemProps, Select, SelectProps } from 'antd';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { list, OrganizationListPayload, OrganizationListResponse } from '../../queries/api/organizations';
import { errorMessage } from '../../helpers/message';
import { defaultErrorMessage } from '../../i18n';
import { useDebounce } from '../../hooks';
import { Organization } from '../../queries/api/types';

interface OrganizationsFormItemProps extends FormItemProps {
    readOnly?: boolean;
    selectProps?: SelectProps<Organization['id']>;
    queryPayload?: OrganizationListPayload;
    optionLabelRender?: (record: Organization) => ReactNode;
    defaultOrganizations?: Organization[];
}

const OrganizationsFormItem: VFC<OrganizationsFormItemProps> = ({
    readOnly,
    selectProps,
    queryPayload,
    optionLabelRender = (record) => record.name,
    defaultOrganizations = [],
    ...props
}) => {
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);
    const { data: organizations, isLoading } = useQuery<OrganizationListResponse, AxiosError>(
        ['providers-field', debouncedSearch, queryPayload],
        async () =>
            await list({
                ...(debouncedSearch ? { search: debouncedSearch } : {}),
                ...queryPayload,
            }),
        {
            enabled: !readOnly,
            onError: () => {
                errorMessage({ content: defaultErrorMessage });
            },
        }
    );

    return (
        <>
            <Form.Item label="Client" {...props}>
                <Select
                    {...selectProps}
                    placeholder={selectProps?.placeholder ?? 'Choisir un client'}
                    filterOption={false}
                    onSearch={setSearch}
                    loading={isLoading}
                    options={defaultOrganizations
                        .concat(
                            organizations?.items?.filter(
                                (org) => !defaultOrganizations.find((def) => def.id === org.id)
                            ) ?? []
                        )
                        .map((org) => ({
                            label: optionLabelRender(org),
                            value: org.id,
                        }))}
                    showSearch
                />
            </Form.Item>
        </>
    );
};

export default OrganizationsFormItem;
