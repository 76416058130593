import { VFC } from 'react';
import { useIntl } from 'react-intl';

import { taskGroupCaptionMessagesMap } from '../../../i18n/taskGroupMessages';
import { TaskGroupStatus } from '../../../queries/api/types';

const CalendarTaskGroupCaption: VFC = () => {
    const { formatMessage } = useIntl();

    return (
        <ul
            className="flex justify-between items-center list-style-none gap-4 mb-0 px-2 py-1 rounded"
            style={{ backgroundColor: '#F2F5F7', height: 32 }}
        >
            {Object.values(TaskGroupStatus).map((key) => (
                <li key={key} className="flex justify-between items-center gap-2">
                    <div className={`caption caption-bg-${key}`} />
                    <span className="block">
                        {formatMessage(taskGroupCaptionMessagesMap.get(key)!, { unitDisplay: '' })}
                    </span>
                </li>
            ))}
        </ul>
    );
};

export default CalendarTaskGroupCaption;
