import { FC, useEffect, useState } from 'react';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Button, Alert, Typography, Spin, message } from 'antd';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { ResetPasswordPayload } from '../../queries/api/auth';
import PasswordInput from '../../components/PasswordInput';
import LoginLayout from '../../components/LoginLayout';
import ButtonLink from '../../components/ButtonLink';
import { checkIfTokenExpired } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import useQueryParams from '../../hooks/queryParams';
import validatePasswordRules from '../../helpers/passwords';
import { useAuth } from '../../context/AuthContext';
import formMessages from '../../i18n/formMessages';
import genericMessages from '../../i18n/genericMessages';
import { getRequiredRule } from '../../i18n';

const ResetPassword: FC = () => {
    const { formatMessage } = useIntl();
    const { resetPassword, resendInvitation } = useAuth();
    const {
        mutate: sendResetPassword,
        error: resetPasswordError,
        isLoading: isResettingPassword,
        isSuccess: isResetPasswordSuccess,
    } = resetPassword;
    const {
        mutate: sendresendInvitation,
        error: resendInvitationError,
        isLoading: isResendingInvitation,
        isSuccess: isResendInvitationSuccess,
    } = resendInvitation;
    const [isTokenExpired, setIsTokenExpired] = useState<boolean | null>(null);
    const [queryParams] = useQueryParams('reset-password');
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const [validate, setValidator] = useState<string | undefined>('onSubmit');
    const token = queryParams.get('token');
    const onFormValidSubmit: FormProps['onFinish'] = (values: ResetPasswordPayload) => {
        if (token) {
            sendResetPassword({
                ...values,
                token,
            });
        }
    };
    const onClickSendNewInvitation = () => {
        if (token) {
            sendresendInvitation({ token });
        }
    };
    const onSubmit = () => {
        setValidator(undefined);
    };

    let errorMessage =
        resetPasswordError ?? resendInvitationError ? <FormattedMessage {...genericMessages.defaultError} /> : null;

    if (resetPasswordError) {
        if (resetPasswordError?.response?.status === 404) {
            errorMessage = (
                <FormattedMessage
                    id="reset_password.error.404.email"
                    defaultMessage="Adresse e-mail introuvable"
                    description="reset password email not found"
                />
            );
        } else if (resetPasswordError?.response?.status === 400) {
            errorMessage = <FormattedMessage {...formMessages.invalidEmail} />;
        } else {
            errorMessage = <FormattedMessage {...genericMessages.defaultError} />;
        }
    }

    const pageTitle = formatMessage({
        id: 'reset_password.title.reset_password',
        defaultMessage: 'Reinitialisez votre mot de passe',
        description: 'Reset password page title',
    });

    const resetPasswordForm = isResetPasswordSuccess ? (
        <>
            <Typography.Paragraph>
                <FormattedMessage
                    id="reset_password.paragraph.success_edit"
                    defaultMessage="Votre mot de passe a été modifié avec succès"
                    description="change password success message"
                    tagName="p"
                />
            </Typography.Paragraph>
            <ButtonLink to={getRoute(RoutePathName.home)} type="primary">
                <FormattedMessage
                    id="reset_password.button_link.access_site"
                    defaultMessage="Acceder au site"
                    description="go-to-site button after create/change password success"
                />
            </ButtonLink>
        </>
    ) : (
        <>
            <Typography.Paragraph>
                <FormattedMessage
                    id="reset_password.paragraph.change_password"
                    defaultMessage="Merci de saisir un nouveau mot de passe ci-dessous"
                    description="Change password information text"
                    tagName="p"
                />
            </Typography.Paragraph>
            <Form.Item
                label={formatMessage({
                    id: 'reset_password.form.item.label.new_password',
                    defaultMessage: 'Nouveau mot de passe',
                    description: 'Change-password password field label',
                })}
                rules={[
                    getRequiredRule(formatMessage),
                    {
                        validator: async (_, value) => {
                            if (!validatePasswordRules(value)) {
                                return await Promise.resolve();
                            }
                            return await Promise.reject(formatMessage(formMessages.invalidPassword));
                        },
                    },
                ]}
                name="password"
                validateTrigger={validate}
            >
                <PasswordInput
                    placeholder={formatMessage({
                        id: 'reset_password.form.item.input.password',
                        defaultMessage: 'Saisissez votre mot de passe',
                        description: 'reset password email placeholder',
                    })}
                />
            </Form.Item>
            {errorMessage && <Alert type="error" message={errorMessage} className="mb-6" showIcon />}
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isResettingPassword}
                        onClick={onSubmit}
                        size="large"
                        disabled={
                            !form.isFieldsTouched(true) ||
                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                        block
                    >
                        <FormattedMessage
                            id="reset_password.form.item.button.reset"
                            defaultMessage="Réinitialiser votre mot de passe"
                            description="Reset password submit button"
                        />
                    </Button>
                )}
            </Form.Item>
        </>
    );

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    useEffect(() => {
        if (token) {
            setIsTokenExpired(token === null ? true : checkIfTokenExpired(token));
        }
    }, [setIsTokenExpired, token]);

    if (!token) {
        message.error('Lien non valide');
        return <Redirect to={getRoute(RoutePathName.login)} />;
    }

    return (
        <LoginLayout title={pageTitle} seoTitle={pageTitle}>
            <Form
                form={form}
                className="login-form"
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
            >
                <Spin spinning={isTokenExpired === null} />

                {isTokenExpired ? (
                    <>
                        {isResendInvitationSuccess ? (
                            <Typography.Paragraph>
                                <FormattedMessage
                                    id="reset_password.form.item.paragraph.resend_invitation_success"
                                    defaultMessage="Une nouvelle invitation vous a été envoyée par e-mail"
                                    description="New invitation success message"
                                    tagName="p"
                                />
                            </Typography.Paragraph>
                        ) : (
                            <>
                                <Alert
                                    type="error"
                                    message={
                                        errorMessage ?? (
                                            <FormattedMessage
                                                id="reset_password.form.item.paragraph.error.invitation"
                                                defaultMessage="L'invitation a expiré."
                                                description="Error message"
                                            />
                                        )
                                    }
                                    className="mb-6"
                                />
                                <Button
                                    onClick={onClickSendNewInvitation}
                                    loading={isResendingInvitation}
                                    type="primary"
                                    size="large"
                                >
                                    <FormattedMessage
                                        id="reset_password.form.item.button.get_new_invitation_email"
                                        defaultMessage="Recevoir une nouvelle invitation par email"
                                        description="Button to get a new invitation by email"
                                    />
                                </Button>
                            </>
                        )}
                    </>
                ) : (
                    resetPasswordForm
                )}
            </Form>
        </LoginLayout>
    );
};

export default ResetPassword;
