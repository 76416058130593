import { defineMessages } from 'react-intl';

export default defineMessages({
    requiredField: {
        id: 'form_message.required_field',
        defaultMessage: 'Champ requis',
        description: 'Form required field',
    },
    allFieldsRequired: {
        id: 'form_message.all_fields_required',
        defaultMessage: 'Tous les champs sont requis',
        description: 'Form required fields',
    },
    invalidEmail: {
        id: 'form_message.invalid_email',
        defaultMessage: 'Veuillez entrer une adresse email valide',
        description: 'Form invalid email error',
    },
    invalidZipCode: {
        id: 'form_message.invalid_zipCode',
        defaultMessage: 'Veuillez entrer un code postal valide',
        description: 'Form invalid zipCode error',
    },
    invalidPassword: {
        id: 'form_message.invalid_password',
        defaultMessage: 'Veuillez entrer un mot de passe valide',
        description: 'Form invalid password error',
    },
    confirmEmailNotIdentical: {
        id: 'form_message.confirm_email_not_identical',
        defaultMessage: 'Les deux emails doivent être identiques',
        description: 'Form invalid email error',
    },
    passwordStrengthLength: {
        id: 'form_message.password_strength_length',
        defaultMessage: '12 caractères minimum',
        description: 'Form password strength',
    },
    passwordStrengthDigit: {
        id: 'form_message.password_strength_digit',
        defaultMessage: 'Au moins un chiffre',
        description: 'Form password strength',
    },
    passwordStrengthUppercase: {
        id: 'form_message.password_strength_uppercase',
        defaultMessage: 'Au moins une lettre en majuscule',
        description: 'Form password strength',
    },
    passwordStrengthSymbol: {
        id: 'form_message.password_strength_symbol',
        defaultMessage: 'Au moins un caractère spécial',
        description: 'Form password strength',
    },
    selectPlaceholder: {
        id: 'form_message.select_placeholder',
        defaultMessage: 'Choisir une valeur',
        description: 'Form select placeholder',
    },
    submit: {
        id: 'form_message.submit',
        defaultMessage: 'Sauvegarder',
        description: 'Form submit button text',
    },
    validate: {
        id: 'form_message.validate',
        defaultMessage: 'Valider',
        description: 'Form validate button text',
    },
    cancel: {
        id: 'form_message.cancel',
        defaultMessage: 'Annuler',
        description: 'Form cancel button text',
    },
    delete: {
        id: 'form_message.delete',
        defaultMessage: 'Supprimer',
        description: 'Form delete button text',
    },
    deleteSelection: {
        id: 'delete_selection',
        defaultMessage: 'Supprimer la saisie',
    },
    deleteConfirmTitle: {
        id: 'delete_confirm_title',
        defaultMessage: 'Confirmation de suppression',
    },
    deleteConfirmMessage: {
        id: 'delete_confirm_message',
        defaultMessage: 'Êtes-vous sur de vouloir supprimer cette saisie ?',
    },
    deleteSuccess: {
        id: 'delete_success',
        defaultMessage: 'Saisie supprimée avec succès',
    },
    add: {
        id: 'form_message.add',
        defaultMessage: 'Ajouter',
        description: 'Form add button text',
    },
    valueListDeleteConfirm: {
        id: 'form_message.value_list.delete_confirm',
        defaultMessage: 'Êtes-vous sûr de vouloir supprimer cette valeur ?',
        description: 'Form value list delete confirm text',
    },
    valueListInputPlaceholder: {
        id: 'form_message.value_list_add_card.input.placeholder',
        defaultMessage: 'Saisir une valeur',
    },
    saveData: {
        id: 'form_message.submit.save_data',
        defaultMessage: 'Sauvegarder les informations',
        description: 'Form submit',
    },
    inputPlaceholder: {
        id: 'form_messages.input.placeholder',
        defaultMessage: 'Saisir',
        description: 'Form input generic placeholder',
    },
    previousStepButton: {
        id: 'form_messages.steps.previous_step_button',
        defaultMessage: "Retour à l'écran précédent",
        description: 'Form step back to previous step button',
    },
    saveAndContinueButton: {
        id: 'form_messages.steps.save_and_continue',
        defaultMessage: 'Sauvegarder et continuer',
        description: 'Form step save and continue submit button',
    },
    saveAndCloseButton: {
        id: 'form_messages.steps.save_and_close',
        defaultMessage: 'Sauvegarder et fermer',
        description: 'Form step save and close submit button',
    },
    conflictError: {
        id: 'form_messages.error.conflict',
        defaultMessage: 'Valeur déjà utilisée, veuillez en saisir une autre',
        description: 'Form conflict error (field value already used in database, 409 http error code)',
    },
    genericConflictError: {
        id: 'form_messages.error.conflict_generic',
        defaultMessage:
            'Une des valeurs saisie est déjà utilisée par une autre ressource, veuillez en saisir une autre',
        description: 'Form conflict error (field value already used in database, 409 http error code)',
    },
});
