/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.248.351a1.2 1.2 0 0 1 0 1.698L3.297 6l3.951 3.951a1.2 1.2 0 1 1-1.697 1.697l-4.8-4.8a1.2 1.2 0 0 1 0-1.697l4.8-4.8a1.2 1.2 0 0 1 1.697 0Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgChevronLeft: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-chevron-left', props.className)} />
));
export default SvgChevronLeft;
