import { FC } from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import OperatorsListComponent from '../../../components/lists/OperatorsList';
import Seo from '../../../components/Seo';
import { Ban, DotsHorizontal, Download, PencilAlt, Plus } from '../../../components/icons';
import genericMessages from '../../../i18n/genericMessages';
import { getRoute, RoutePathName } from '../../../routes';
import operatorMessages from '../../../i18n/operatorMessages';
import { useExportOperators } from '../../../queries/operators';
import { errorMessage } from '../../../helpers/message';

const OperatorsList: FC = () => {
    const { formatMessage } = useIntl();
    const isDisabledOperatorsRoute = !!useRouteMatch({
        path: getRoute(RoutePathName.operatorsDisabled),
        exact: true,
    });

    const { refetch: exportOperators } = useExportOperators({
        enabled: false,
        onError: () => {
            errorMessage({ content: formatMessage(genericMessages.defaultExportError) });
        },
    });

    const onClickExport = () => {
        exportOperators();
    };

    return (
        <OperatorsListComponent
            queryParamsKey="operators-list"
            queryPayload={{
                isActive: !isDisabledOperatorsRoute,
            }}
            title={
                <>
                    <Route path={getRoute(RoutePathName.operators)} exact>
                        <Seo title={formatMessage(operatorMessages.listPageTitle)} />
                        <FormattedMessage {...operatorMessages.listPageTitle} />
                    </Route>
                    <Route path={getRoute(RoutePathName.operatorsDisabled)} exact>
                        <Seo title={formatMessage(operatorMessages.inactiveListPageTitle)} />
                        <FormattedMessage {...operatorMessages.inactiveListPageTitle} />
                    </Route>
                </>
            }
            backRoute={isDisabledOperatorsRoute ? getRoute(RoutePathName.operators) : undefined}
            listItemLink={(item) => getRoute(RoutePathName.operatorDetails, { operatorId: item.id })}
            scrollOffset={317}
        >
            <Route path={getRoute(RoutePathName.operators)} exact>
                <div className="flex items-center space-x-2">
                    <Link to={getRoute(RoutePathName.operatorDetails)}>
                        <Button icon={<Plus className="text-base" />} type="primary">
                            <FormattedMessage {...genericMessages.add} tagName="span" />
                        </Button>
                    </Link>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item key="disabled" icon={<Ban className="text-faded-blue text-base" />}>
                                    <Link to={getRoute(RoutePathName.operatorsDisabled)}>
                                        <FormattedMessage
                                            id="operators_list.header.menu.disabled_operators"
                                            defaultMessage="Chauffeurs inactifs"
                                            description="Header list menu item"
                                        />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="export"
                                    icon={<Download className="text-faded-blue text-base" />}
                                    onClick={onClickExport}
                                >
                                    <FormattedMessage {...genericMessages.exportData} />
                                </Menu.Item>
                                <Menu.Item key="valuesLists" icon={<PencilAlt className="text-faded-blue text-base" />}>
                                    <Link to={getRoute(RoutePathName.operatorsValueLists)}>
                                        <FormattedMessage
                                            id="operators_list.header.menu.values_lists"
                                            defaultMessage="Données sociales"
                                            description="Header list menu item"
                                        />
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                        placement="bottomRight"
                    >
                        <Button icon={<DotsHorizontal className="text-lg" />} />
                    </Dropdown>
                </div>
            </Route>
        </OperatorsListComponent>
    );
};

export default OperatorsList;
