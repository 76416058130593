import { FC } from 'react';
import { useIntl } from 'react-intl';

import Seo from '../../../components/Seo';
import VehiclesListComponent from '../../../components/lists/VehiclesList';
import { getRoute, RoutePathName } from '../../../routes';
import { ManagementVehiclesDetailsTabs } from '.';

const ManagementVehiclesList: FC = () => {
    const { formatMessage } = useIntl();
    const pageTitle = formatMessage({
        id: 'management.vehicles.title',
        defaultMessage: 'Suivi du Parc',
        description: 'Page title',
    });

    return (
        <>
            <Seo title={pageTitle} />
            <VehiclesListComponent
                queryParamsKey="management-vehicles-list"
                title={pageTitle}
                listItemLink={(item) =>
                    getRoute(RoutePathName.managementVehiclesDetails, {
                        vehicleId: item.id,
                        tab: ManagementVehiclesDetailsTabs.history,
                    })
                }
                scrollOffset={278}
            />
        </>
    );
};

export default ManagementVehiclesList;
