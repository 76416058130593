import { VFC } from 'react';

import VehicleBrandsAndModelsValueListAddCard from './VehicleBrandsAndModelsValueListAddCard';
import VehicleBrandsAndModelsValueListTable from './VehicleBrandsAndModelsValueListTable';

const VehicleValueListBrandsAndModels: VFC = () => (
    <div className="flex space-x-8">
        <div style={{ width: 375, maxWidth: '100%' }}>
            <VehicleBrandsAndModelsValueListAddCard />
        </div>
        <div className="flex-1">
            <VehicleBrandsAndModelsValueListTable />
        </div>
    </div>
);

export default VehicleValueListBrandsAndModels;
