import { VFC } from 'react';
import { Form, InputNumber } from 'antd';
import { useIntl } from 'react-intl';
import { HiOutlineCalendar } from 'react-icons/hi';

import { ValueListSlug } from '../../../queries/api/types';
import customerMessages from '../../../i18n/customerMessages';
import genericMessages from '../../../i18n/genericMessages';
import { getRequiredRule } from '../../../i18n';
import DatePicker from '../../../components/DatePicker';
import { sectionCardFormItemLayout } from '../../../components/SectionCard';
import ValueListItemSelect from '../../../components/selects/ValueListItemSelect';

const CustomerInvoicingUnitService: VFC = () => {
    const { formatMessage } = useIntl();

    return (
        <>
            <Form.Item
                {...sectionCardFormItemLayout}
                name={['invoicingPricesService', 'serviceType']}
                label={formatMessage(customerMessages.invoicingUnitService)}
                rules={[getRequiredRule(formatMessage)]}
                required
            >
                <ValueListItemSelect valueListSlug={ValueListSlug.customerServices} />
            </Form.Item>
            <Form.Item
                {...sectionCardFormItemLayout}
                name={['invoicingPricesService', 'standard']}
                label={formatMessage(genericMessages.cost)}
                rules={[getRequiredRule(formatMessage)]}
                required
            >
                <InputNumber addonAfter="€" min={0} placeholder={formatMessage(genericMessages.amountSelect)} />
            </Form.Item>
            <Form.Item
                {...sectionCardFormItemLayout}
                name={['invoicingPricesService', 'dates']}
                label={formatMessage(customerMessages.invoicingUnitPrices)}
                rules={[getRequiredRule(formatMessage)]}
                required
            >
                <DatePicker.RangePicker format="DD/MM/YYYY" suffixIcon={<HiOutlineCalendar />} />
            </Form.Item>
        </>
    );
};

export default CustomerInvoicingUnitService;
