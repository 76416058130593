import { VFC } from 'react';
import { Descriptions } from 'antd';
import { useIntl } from 'react-intl';

import { Customer, InvoicingUnit } from '../../../queries/api/types';
import { formatDate } from '../../../i18n';
import customerMessages from '../../../i18n/customerMessages';
import genericMessages from '../../../i18n/genericMessages';
import CustomerInvoicingUnitVehicle from './CustomerInvoicingUnitVehicle';

interface CustomerInvoicingUnitDetailsProps {
    record?: Customer;
}

const CustomerInvoicingUnitDetails: VFC<CustomerInvoicingUnitDetailsProps> = ({ record }) => {
    const { formatMessage } = useIntl();

    if (!record?.invoicingUnit || !record?.invoicingPrices || record.invoicingPrices?.length === 0) return null;

    const invoicingPrices = record.invoicingPrices[0];
    const isVehicle = invoicingPrices.invoicingUnit === InvoicingUnit.vehicle;
    const isService = invoicingPrices.invoicingUnit === InvoicingUnit.service;

    return (
        <>
            {isVehicle && (
                <Descriptions
                    column={1}
                    colon={false}
                    labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                >
                    <Descriptions.Item label={formatMessage(customerMessages.invoicingUnitPrices)}>
                        <span className="ant-descriptions-item-content">
                            {formatMessage(genericMessages.dateRange, {
                                fromDate: formatDate(invoicingPrices.fromDate),
                                toDate: formatDate(invoicingPrices.toDate),
                            })}
                        </span>
                    </Descriptions.Item>

                    <Descriptions.Item>
                        <div style={{ width: '100%' }}>
                            <CustomerInvoicingUnitVehicle isEditMode={false} record={record} />
                        </div>
                    </Descriptions.Item>
                </Descriptions>
            )}

            {isService && (
                <Descriptions
                    column={1}
                    colon={false}
                    labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                >
                    <Descriptions.Item label={formatMessage(customerMessages.invoicingUnitService)}>
                        <span className="ant-descriptions-item-content">
                            {invoicingPrices.serviceType?.fields.customerServices}
                        </span>
                    </Descriptions.Item>

                    <Descriptions.Item label={formatMessage(genericMessages.cost)}>
                        <span className="ant-descriptions-item-content">{invoicingPrices.standard} €</span>
                    </Descriptions.Item>

                    <Descriptions.Item label={formatMessage(customerMessages.invoicingUnitPrices)}>
                        <span className="ant-descriptions-item-content">
                            {formatMessage(genericMessages.dateRange, {
                                fromDate: formatDate(invoicingPrices.fromDate),
                                toDate: formatDate(invoicingPrices.toDate),
                            })}
                        </span>
                    </Descriptions.Item>
                </Descriptions>
            )}
        </>
    );
};

export default CustomerInvoicingUnitDetails;
