import { FC } from 'react';
import { useIntl } from 'react-intl';

import Seo from '../../../components/Seo';
import OperatorsListComponent from '../../../components/lists/OperatorsList';
import { getRoute, RoutePathName } from '../../../routes';
import { ManagementOperatorsDetailsTabs } from '.';

const ManagementOperatorsList: FC = () => {
    const { formatMessage } = useIntl();
    const pageTitle = formatMessage({
        id: 'management.operators.title',
        defaultMessage: 'Suivi des Chauffeurs',
        description: 'Page title',
    });

    return (
        <>
            <Seo title={pageTitle} />
            <OperatorsListComponent
                queryParamsKey="management-operators-list"
                title={pageTitle}
                listItemLink={(item) =>
                    getRoute(RoutePathName.managementOperatorsDetails, {
                        operatorId: item.id,
                        tab: ManagementOperatorsDetailsTabs.history,
                    })
                }
                scrollOffset={306}
            />
        </>
    );
};

export default ManagementOperatorsList;
