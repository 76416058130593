import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, ModalProps, Row } from 'antd';
import { useIntl } from 'react-intl';
import { ReactNode } from 'react';
import { HiX } from 'react-icons/hi';

import genericMessages from '../../i18n/genericMessages';
import CustomModal from '../CustomModal';

interface SelectVehiculeModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    title?: ReactNode;
    message?: ReactNode;
    cancelText?: string;
}
const ErrorModal = NiceModal.create<SelectVehiculeModalProps>(({ title, message, cancelText, ...props }) => {
    const { formatMessage } = useIntl();
    const errorModal = useModal();

    return (
        <CustomModal
            {...props}
            {...antdModal(errorModal)}
            afterClose={errorModal.remove}
            title={title}
            closable={true}
            footer={false}
            centered
            closeIcon={<HiX className="text-blue text-xl" />}
        >
            <Row>
                <Col span={24}>{message}</Col>
            </Row>
            <Row>
                <Col span={24} className="flex justify-end gap-2">
                    <Button key="cancelBtn" className="text-blue" onClick={errorModal.hide}>
                        {cancelText || formatMessage(genericMessages.cancel)}
                    </Button>
                </Col>
            </Row>
        </CustomModal>
    );
});
export default ErrorModal;
