import { Form, Tag } from 'antd';
import { IntlShape } from 'react-intl';
import NiceModal from '@ebay/nice-modal-react';

import { DetailsFormCardProps } from '../../../components/form/DetailsFormCard';
import { sectionCardFormItemLayout } from '../../../components/SectionCard';
import PlaceSelect from '../../../components/selects/PlaceSelect';
import genericMessages from '../../../i18n/genericMessages';
import userMessages, { userRoleMessagesMap } from '../../../i18n/userMessages';
import { Place, Role, User } from '../../../queries/api/types';
import { UserUpdatePayload } from '../../../queries/api/users';
import DispatcherAlertModal from './DispatcherAlertModal';

export { default as UsersList } from './UsersList';
export { default as UserDetails } from './UserDetails';

export interface UserForm extends Omit<User, 'id' | 'firstName' | 'lastName' | 'email' | 'role' | 'places'> {
    id: User['id'];
    firstName: User['firstName'];
    lastName: User['lastName'];
    email: User['email'];
    role: Role['id'];
    places?: Array<Place['id']>;
}

export const formatUserInitialValue = (value: User | undefined) =>
    ({
        ...value,
        role: value?.role?.id,
        places: value?.places?.map((place) => place.id),
    } as UserForm);

export const formatUserCreatePayload = (values: UserForm) => values;

export const formatUserUpdatePayload = (values: UserForm, user?: User, dispatcherIds?: string[]): UserUpdatePayload => {
    return {
        ...values,
        firstName: values.firstName || user?.firstName,
        lastName: values.lastName || user?.lastName,
        email: values.email || user?.email,
        role: values.role || user!.role!.id,
        places: dispatcherIds?.includes(values.role || user!.role!.id)
            ? values.places || user?.places?.map((place) => place.id)
            : [],
    };
};

export const getUserFormSections = (
    formatMessage: IntlShape['formatMessage'],
    userDetails?: User,
    dispatcherIds?: string[]
) => {
    const sections: Array<
        Pick<
            DetailsFormCardProps<User, UserForm, UserUpdatePayload>,
            'title' | 'editButtonText' | 'fields' | 'renderEdit' | 'renderDetails' | 'beforeUpdate' | 'onUpdateError'
        >
    > = [
        {
            title: formatMessage(genericMessages.identity),
            editButtonText: formatMessage(genericMessages.editIdentity),
            fields: [
                {
                    name: 'lastName',
                    type: 'string',
                    label: formatMessage(genericMessages.lastName),
                    fieldComponentProps: {
                        placeholder: formatMessage(genericMessages.lastNamePlaceholder),
                    },
                    required: true,
                },
                {
                    name: 'firstName',
                    type: 'string',
                    label: formatMessage(genericMessages.firstName),
                    fieldComponentProps: {
                        placeholder: formatMessage(genericMessages.firstNamePlaceholder),
                    },
                    required: true,
                },
            ],
        },
        {
            title: formatMessage(genericMessages.contact, { count: 0 }),
            editButtonText: formatMessage(genericMessages.editContact),
            fields: [
                {
                    name: 'email',
                    type: 'email',
                    label: formatMessage(genericMessages.email),
                    required: true,
                },
            ],
        },
        {
            title: formatMessage(userMessages.roleAndPlaces),
            editButtonText: formatMessage(userMessages.editRole),
            onUpdateError: async (error, update, payload, setIsForm) => {
                if (error?.response?.status === 409) {
                    const places = error.response.data?.fields.places;
                    NiceModal.show(DispatcherAlertModal, { places }).then(() => {
                        update({ ...payload, checkDispatcher: false });
                        NiceModal.hide(DispatcherAlertModal);
                        setIsForm(false);
                    });
                }
            },
            fields: [
                {
                    name: 'role',
                    type: 'RoleSelect',
                    label: formatMessage(userMessages.role),
                    required: true,
                    renderDetails: (field, record) => (
                        <>{record?.role ? formatMessage(userRoleMessagesMap.get(record.role.slug)!) : ''}</>
                    ),
                },
                {
                    name: 'places',
                    type: 'PlaceSelect',
                    label: formatMessage(userMessages.groupLinkLabel),
                    fieldComponentProps: {
                        placeholder: formatMessage(genericMessages.select),
                        mode: 'multiple',
                        initialValue: userDetails?.places,
                    },
                    formItemProps: {
                        dependencies: ['role'],
                    },
                    shouldRenderEdit: (form) => dispatcherIds?.includes(form.getFieldValue('role')) || false,
                    renderEdit: (field, record) => (
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                                getFieldValue(['role']) && (
                                    <Form.Item
                                        label={formatMessage({
                                            id: 'user.place.label',
                                            defaultMessage: 'Entrepôts de rattachement',
                                        })}
                                        name="places"
                                        className="mb-0"
                                        {...sectionCardFormItemLayout}
                                        required
                                    >
                                        <PlaceSelect mode="multiple" size="small" initialValue={record?.places} />
                                    </Form.Item>
                                )
                            }
                        </Form.Item>
                    ),
                    shouldRenderDetails: (field, record) => Boolean(record?.places && record?.places?.length > 0),
                    renderDetails: (field, record) => (
                        <>
                            {record?.places?.map((place) => (
                                <Tag key={place.id} color="cyan">
                                    {place.name}
                                </Tag>
                            ))}
                        </>
                    ),
                    required: true,
                },
            ],
        },
    ];

    return sections;
};
