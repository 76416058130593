import { VFC } from 'react';
import { Card } from 'antd';

import { Issue, Vehicle } from '../../../../queries/api/types';
import IssueCardBody from './IssueCardBody';
import IssueCardFooter from './IssueCardFooter';
import IssueCardHeader from './IssueCardHeader';

interface IssueCardProps {
    issue: Issue;
    vehicleId: Vehicle['id'];
}

const IssueCard: VFC<IssueCardProps> = ({ issue, vehicleId }) => (
    <Card>
        <div className="flex flex-col gap-6">
            <IssueCardHeader issue={issue} vehicleId={vehicleId} />
            <IssueCardBody issue={issue} />
            <IssueCardFooter issue={issue} vehicleId={vehicleId} />
        </div>
    </Card>
);

export default IssueCard;
