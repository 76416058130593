import { VFC } from 'react';

import '../assets/styles/components/flag.less';

import { classNames } from '../helpers';
import { SupportedLanguage } from '../queries/api/types';

interface FlagProps {
    language: SupportedLanguage;
}

const Flag: VFC<FlagProps> = ({ language }) => <span className={classNames('custom-flag', 'flag', language)} />;

export default Flag;
