import { ChangeEvent, useState } from 'react';
import { Alert, Button, Drawer, DrawerProps, Form, Input, message, Spin, Table } from 'antd';
import { FormProps } from 'antd/lib/form';
import { ColumnsType } from 'antd/lib/table';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';

import { useRoleCreate, useRoleDetails, useRoleListPermissions, useRoleUpdate } from '../../../queries/roles';
import { Permission, PermissionRight, Role } from '../../../queries/api/types';
import RoleListCheckbox from './RoleListCheckbox';

export interface RoleFormDrawerProps extends Omit<DrawerProps, 'visible' | 'onClose'> {
    roleId?: Role['id'];
}

type RoleListItem = {
    permissionName: Permission | string;
};

type PermissionPayload = {
    [key in Permission | 'inputField']?: PermissionRight;
};

// use this function to transform the Role permissions object to match a defined <Form> API
// from ->
// {
//     admin: 'w',
//     roles: 'disabled'
// }
// to ->
// {
//     admin: {
//         r: false, w: true, disabled: false
//     },
//     roles: {
//         r: false, w: false, disabled: true
//     }
// }
const transformPermissionsToFormObject = (permissions: Role['permissions']) => {
    const result =
        permissions &&
        Object.keys(permissions).reduce((prev, permissionKey) => {
            const key = permissionKey as keyof typeof Permission;
            const permissionValue = permissions ? permissions[key] : undefined;

            const isRead = permissionValue === PermissionRight.read;
            const isWrite = permissionValue === PermissionRight.write;
            const isDisabled = permissionValue === PermissionRight.disabled;

            return {
                ...prev,
                [permissionKey]: {
                    [PermissionRight.read]: isRead,
                    [PermissionRight.write]: isWrite,
                    [PermissionRight.disabled]: isDisabled,
                },
            };
        }, {});

    return result;
};

const RoleFormDrawer = NiceModal.create(({ roleId, ...props }: RoleFormDrawerProps) => {
    const modal = useModal();
    const isEditing = roleId !== undefined;
    const [isInputFieldCheckboxDisabled, setIsInputFieldCheckboxDisabled] = useState(true);
    const [form] = Form.useForm();
    const { isLoading: isLoadingDetails, data: roleDetails } = useRoleDetails(roleId, {
        enabled: modal.visible && isEditing,
        onSuccess: (data) => {
            const newPermissions = transformPermissionsToFormObject(data.permissions);

            form.setFieldsValue({
                ...data,
                name: data?.name,
                permissions: {
                    ...newPermissions,
                },
            });
        },
    });
    const roleCreate = useRoleCreate({
        onError: () => {
            message.error('Une erreur est survenue pendant la création du rôle');
        },
        onSuccess: () => {
            modal.hide();
            message.success('Le rôle a été créé');
        },
    });
    const roleUpdate = useRoleUpdate({
        onError: () => {
            message.error('Une erreur est survenue pendant la mise à jour du rôle');
        },
        onSuccess: () => {
            modal.hide();
            message.success('Le rôle a été mis à jour');
        },
    });

    const onSubmit: FormProps['onFinish'] = (values) => {
        const permissionsPayload: PermissionPayload = Object.keys(values.permissions).reduce((prev, permissionKey) => {
            const permissionRight = Object.keys(values.permissions[permissionKey]).filter(
                (permissionValue) => values.permissions[permissionKey][permissionValue]
            )[0];

            return {
                ...prev,
                [permissionKey]: permissionRight,
                ...(values.inputField && { [values.inputField]: permissionRight }), // skip if inputField value is undefined
            };
        }, {});

        delete permissionsPayload.inputField;

        if (isEditing) {
            roleUpdate.mutate({ id: roleId, ...values, permissions: permissionsPayload });
        } else {
            roleCreate.mutate({
                ...values,
                permissions: permissionsPayload,
            });
        }
    };

    const onInputFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) setIsInputFieldCheckboxDisabled(true);
        setIsInputFieldCheckboxDisabled(false);
    };

    const onInputFieldBlur = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) {
            setIsInputFieldCheckboxDisabled(true);
        }
    };

    const { data: globalPermissions = [], isLoading: isLoadingPermissions } = useRoleListPermissions();

    const filterRolePermissions = roleDetails?.permissions
        ? Object.keys(roleDetails?.permissions).filter((rolePerm: any) => !globalPermissions?.includes(rolePerm))
        : [];

    const mergedPermissions = [...globalPermissions, ...filterRolePermissions];

    const dataSource = mergedPermissions.map((permissionName) => ({
        permissionName,
    }));

    const columns: ColumnsType<RoleListItem> = [
        {
            title: 'Nom de la permission',
            dataIndex: 'permissionName',
            render: (value) => {
                return value === 'inputField' ? (
                    <Form.Item
                        className="mb-0"
                        name="inputField"
                        validateTrigger="onBlur"
                        rules={[
                            {
                                validator: async (_, value: string) => {
                                    if (mergedPermissions.includes(value as Permission)) {
                                        return await Promise.reject(new Error(`La permission "${value}" existe déjà`));
                                    }
                                    return await Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input
                            onBlur={onInputFieldBlur}
                            onChange={onInputFieldChange}
                            placeholder="Ajouter une permission"
                        />
                    </Form.Item>
                ) : (
                    value
                );
            },
        },
        {
            title: 'read',
            render: (permission) => (
                <RoleListCheckbox
                    disabled={isInputFieldCheckboxDisabled}
                    permissionRight={PermissionRight.read}
                    {...permission}
                />
            ),
        },
        {
            title: 'write',
            render: (permission) => (
                <RoleListCheckbox
                    disabled={isInputFieldCheckboxDisabled}
                    permissionRight={PermissionRight.write}
                    {...permission}
                />
            ),
        },
        {
            title: 'disabled',
            render: (permission) => (
                <RoleListCheckbox
                    disabled={isInputFieldCheckboxDisabled}
                    permissionRight={PermissionRight.disabled}
                    {...permission}
                />
            ),
        },
    ];

    const permissionsInitialValues = transformPermissionsToFormObject(roleDetails?.permissions);
    return (
        <>
            <Drawer
                title={isEditing ? 'Modifier le rôle' : 'Créer un rôle'}
                width={720}
                {...props}
                {...antdDrawer(modal)}
            >
                <Spin spinning={isLoadingDetails}>
                    <Form
                        form={form}
                        onFinish={onSubmit}
                        layout="vertical"
                        initialValues={{
                            permissions: permissionsInitialValues,
                        }}
                    >
                        <Form.Item
                            label="Nom du rôle"
                            name="name"
                            rules={[{ required: true, message: 'Veuillez rentrer le nom du rôle !' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Table<RoleListItem>
                            rowKey="permissionName"
                            columns={columns}
                            loading={isLoadingDetails || isLoadingPermissions}
                            dataSource={[...dataSource, { permissionName: 'inputField' }]}
                            pagination={false}
                        />

                        <Form.Item>
                            {(roleCreate.isError && roleCreate.error) || (roleUpdate.isError && roleUpdate.error) ? (
                                <div className="mb-2">
                                    <Alert
                                        type="error"
                                        message={roleCreate.error?.message ?? roleUpdate.error?.message}
                                        showIcon
                                    />
                                </div>
                            ) : null}

                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={isEditing ? roleUpdate.isLoading : roleCreate.isLoading}
                                block
                            >
                                {isEditing ? 'Modifier le rôle' : 'Créer le rôle'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </>
    );
});

export default RoleFormDrawer;
