/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgCurrencyEuro: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-currency-euro', props.className)} />
));
export default SvgCurrencyEuro;
