import {
    Button,
    Form,
    FormInstance,
    FormItemProps,
    Input,
    InputNumber,
    InputNumberProps,
    InputProps,
    Segmented,
    SegmentedProps,
    SelectProps,
    Typography,
} from 'antd';
import { ReactNode } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { HiOutlineCalendar } from 'react-icons/hi';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RuleType } from 'rc-field-form/lib/interface';
import { Dayjs } from 'dayjs';
import { PickerDateProps, RangePickerDateProps } from 'antd/es/date-picker/generatePicker';
import { PhoneInputProps } from 'react-phone-input-2';

import { classNames, debug } from '../../helpers';
import { getRequiredRule } from '../../i18n';
import placeMessages from '../../i18n/placeMessages';
import { InvoicingUnit, PlaceType, Transporter } from '../../queries/api/types';
import DatePicker from '../DatePicker';
import { Plus, Trash } from '../icons';
import { sectionCardFormItemLayout } from '../SectionCard';
import CountrySelect, { CountrySelectProps } from '../selects/CountrySelect';
import CustomerSelect, { CustomerSelectProps } from '../selects/CustomerSelect';
import PlaceSelect, { PlaceSelectProps } from '../selects/PlaceSelect';
import ValueListItemButtons, { ValueListItemButtonsProps } from '../selects/ValueListItemButtons';
import ValueListItemSelect, { ValueListItemSelectProps } from '../selects/ValueListItemSelect';
import TimePicker, { TimePickerProps } from '../TimePicker';
import { getDataFromFieldName } from './DetailsFormCard';
import OpenHoursFormFields, { OpenHoursFormFieldsProps } from './OpenHoursFormFields';
import PhoneInput from '../PhoneInput';
import formMessages from '../../i18n/formMessages';
import ApiSelect from '../selects/ApiSelect';
import { useTransporterList } from '../../queries/transporters';
import { TransporterListPayload } from '../../queries/api/transporters';
import InvoicingUnitSelect from '../selects/InvoicingUnitSelect';
import ValueListItemSegmented, { ValueListItemSegmentedProps } from '../segmenteds/ValueListItemSegmented';
import RoleSelect, { RoleSelectProps } from '../selects/RoleSelect';

interface BaseFormFieldProps<TData> {
    record?: TData;
    label?: string;
    detailsLabel?: string | ((record?: TData) => string | number | ReactNode);
    formItemProps?: FormItemProps;
    /**
     * If FormField contains fields, it acts as a fieldset with label being displayed as the fieldset title
     */
    fields?: Array<FormFieldProps<TData>>;
    required?: boolean;
    /**
     * Removes bottom margin if true
     */
    isLast?: boolean;
    /**
     * Bypasses edit renderer
     */
    renderEdit?: (
        field: FormFieldProps<TData> | undefined,
        record: TData | undefined,
        listItemName?: number
    ) => JSX.Element;
    /**
     * Does not render edit form for this field if the function returns false
     */
    shouldRenderEdit?: (
        form: FormInstance,
        field: FormFieldProps<TData> | undefined,
        record: TData | undefined
    ) => boolean;
    /**
     * Bypasses details renderer, if not used it defaults to displaying the value of the field in a Descriptions
     */
    renderDetails?: (
        field: FormFieldProps<TData> | undefined,
        record: TData | undefined,
        dataGetter: typeof getDataFromFieldName
    ) => ReactNode;
    /**
     * Does not render a Descriptions for this field if the function returns false
     */
    shouldRenderDetails?: (field: FormFieldProps<TData> | undefined, record: TData | undefined) => boolean;
}

export type FormFieldName<TData> = Extract<keyof TData, string> | Array<string | number>;

export type FormFieldProps<TData> = BaseFormFieldProps<TData> &
    (
        | {
              type?: 'string' | 'email';
              name?: FormFieldName<TData>;
              fieldComponentProps?: InputProps;
          }
        | {
              type?: 'number';
              name?: FormFieldName<TData>;
              fieldComponentProps?: InputNumberProps;
          }
        | {
              type?: 'list';
              name: FormFieldName<TData>;
              listItemLabel?: MessageDescriptor;
              listFields?: Array<FormFieldProps<Record<string, any>>>;
              listAddButtonLabel?: string;
              /**
               * Form values passed when adding a new list item
               */
              listAddValues?: Record<string, any>;
              /**
               * Show a remove button next to the field starting at this index
               */
              listMinimumIndexToRemove?: number;
          }
        | {
              type?: 'TimePicker';
              name?: FormFieldName<TData>;
              fieldComponentProps?: TimePickerProps;
          }
        | {
              type?: 'DatePicker';
              name?: FormFieldName<TData>;
              fieldComponentProps?: PickerDateProps<Dayjs>;
          }
        | {
              type?: 'DateRangePicker';
              name?: FormFieldName<TData>;
              fieldComponentProps?: RangePickerDateProps<Dayjs>;
          }
        | {
              type?: 'PlaceTypeSegmented';
              name?: FormFieldName<TData>;
              fieldComponentProps?: SegmentedProps;
          }
        | {
              type?: 'CountrySelect';
              name?: FormFieldName<TData>;
              fieldComponentProps?: CountrySelectProps;
          }
        | {
              type?: 'PhoneInput';
              name?: FormFieldName<TData>;
              fieldComponentProps?: PhoneInputProps;
          }
        | {
              type?: 'ValueListItemSelect';
              name?: FormFieldName<TData>;
              fieldComponentProps: ValueListItemSelectProps;
          }
        | {
              type?: 'ValueListItemButtons';
              name?: FormFieldName<TData>;
              fieldComponentProps: ValueListItemButtonsProps;
          }
        | {
              type?: 'ValueListItemSegmented';
              name?: FormFieldName<TData>;
              fieldComponentProps: ValueListItemSegmentedProps;
          }
        | {
              type?: 'OpenHoursFormFields';
              name?: FormFieldName<TData>;
              fieldComponentProps: OpenHoursFormFieldsProps<TData>;
          }
        | {
              type?: 'ValueListItemSegmented';
              name?: FormFieldName<TData>;
              fieldComponentProps: ValueListItemSegmentedProps;
          }
        | {
              type?: 'CustomerSelect';
              name?: FormFieldName<TData>;
              fieldComponentProps?: CustomerSelectProps;
          }
        | {
              type?: 'InvoicingUnitSelect';
              name?: FormFieldName<TData>;
              fieldComponentProps?: SelectProps<InvoicingUnit>;
          }
        | {
              type?: 'PlaceSelect';
              name?: FormFieldName<TData>;
              fieldComponentProps?: PlaceSelectProps;
          }
        | {
              type?: 'ApiSelect';
              name?: FormFieldName<TData>;
              apiSelectType?: 'transporter'; // | 'place' | 'customer' | 'organization';
          }
        | {
              type?: 'RoleSelect';
              name?: FormFieldName<TData>;
              fieldComponentProps?: RoleSelectProps;
          }
    );

const FormField = <TData extends Record<string, any>>(props: FormFieldProps<TData>) => {
    const { record, label, name, required, type, formItemProps, renderEdit, shouldRenderEdit, fields, isLast } = props;
    const { formatMessage } = useIntl();
    const form = Form.useFormInstance();
    const dependency = Form.useWatch(formItemProps?.dependencies?.[0] || '', form);
    let field;

    if (shouldRenderEdit && !shouldRenderEdit(form, props, record)) {
        return null;
    }

    switch (type) {
        case 'PlaceTypeSegmented':
            field = (
                <Segmented
                    options={[
                        { label: formatMessage(placeMessages.warehouse), value: PlaceType.warehouse },
                        { label: formatMessage(placeMessages.shop), value: PlaceType.shop },
                    ]}
                />
            );
            break;

        case 'DatePicker':
            field = <DatePicker {...props.fieldComponentProps} />;
            break;

        case 'CountrySelect':
            field = <CountrySelect {...props.fieldComponentProps} />;
            break;

        case 'PhoneInput':
            field = <PhoneInput {...props.fieldComponentProps} />;
            break;

        case 'ValueListItemSelect':
            field = <ValueListItemSelect {...props.fieldComponentProps} />;
            break;

        case 'ValueListItemButtons':
            field = <ValueListItemButtons {...props.fieldComponentProps} />;
            break;

        case 'ValueListItemSegmented':
            field = <ValueListItemSegmented {...props.fieldComponentProps} />;
            break;

        case 'CustomerSelect':
            field = <CustomerSelect {...props.fieldComponentProps} />;
            break;

        case 'InvoicingUnitSelect':
            field = <InvoicingUnitSelect {...props.fieldComponentProps} />;
            break;

        case 'PlaceSelect':
            field = <PlaceSelect {...props.fieldComponentProps} />;
            break;

        case 'ApiSelect':
            if (!props.apiSelectType) {
                debug.error('[FormField] Missing apiSelectType prop for ApiSelect type');
                field = null;
            } else {
                switch (props.apiSelectType) {
                    case 'transporter':
                        field = (
                            <ApiSelect<Transporter, Transporter['id'], TransporterListPayload>
                                listQueryHandler={useTransporterList}
                                valueProp="id"
                                labelProp="name"
                            />
                        );
                        break;

                    default:
                        break;
                }
            }
            break;

        case 'TimePicker':
            field = <TimePicker format="HH:mm" style={{ width: '100%' }} {...props.fieldComponentProps} />;
            break;

        case 'DateRangePicker':
            field = (
                <DatePicker.RangePicker
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                    suffixIcon={<HiOutlineCalendar />}
                    {...props.fieldComponentProps}
                />
            );
            break;

        case 'OpenHoursFormFields':
            field = <OpenHoursFormFields {...props.fieldComponentProps} field={props} />;
            break;

        case 'number':
            field = (
                <InputNumber
                    {...props.fieldComponentProps}
                    placeholder={props.fieldComponentProps?.placeholder || formatMessage(formMessages.inputPlaceholder)}
                />
            );
            break;

        case 'string':
        case 'email':
            field = (
                <Input
                    {...props.fieldComponentProps}
                    placeholder={props.fieldComponentProps?.placeholder || formatMessage(formMessages.inputPlaceholder)}
                />
            );
            break;

        case 'list': {
            const {
                listMinimumIndexToRemove = 0,
                listItemLabel,
                listFields,
                listAddValues,
                listAddButtonLabel,
            } = props;
            return (
                <>
                    {label && <label className="block mb-6 text-base font-medium">{label}</label>}
                    <Form.List name={name} key={Array.isArray(name) ? name.join('') : name}>
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                <div className="space-y-6 mb-6">
                                    {fields.map(({ key, name: fieldListItemName }, index) => (
                                        <div className="gap-8 p-6 border" key={key}>
                                            <div className="flex justify-between items-center">
                                                {listItemLabel && (
                                                    <Typography.Paragraph className="text-dark-blue font-bold text-base">
                                                        {formatMessage(listItemLabel, {
                                                            index: index + 1,
                                                        })}
                                                    </Typography.Paragraph>
                                                )}
                                                {index >= listMinimumIndexToRemove ? (
                                                    <Button
                                                        type="text"
                                                        icon={<Trash />}
                                                        onClick={() => remove(fieldListItemName)}
                                                    />
                                                ) : null}
                                            </div>
                                            {listFields?.map((listField, listFieldIndex) => {
                                                const isList = listField.type === 'list';
                                                const arrayName = listField.name
                                                    ? Array.isArray(listField.name)
                                                        ? [fieldListItemName, ...listField.name]
                                                        : [fieldListItemName, listField.name]
                                                    : undefined;
                                                const listItemSubFieldName = listField.name
                                                    ? arrayName
                                                    : isList
                                                    ? arrayName
                                                    : undefined;
                                                return listField.renderEdit ? (
                                                    listField.renderEdit(listField, undefined, fieldListItemName)
                                                ) : (
                                                    <FormField
                                                        {...listField}
                                                        key={
                                                            listField.name
                                                                ? Array.isArray(listField.name)
                                                                    ? listField.name.join('')
                                                                    : listField.name
                                                                : listFieldIndex
                                                        }
                                                        {...(listItemSubFieldName
                                                            ? { name: listItemSubFieldName }
                                                            : {})}
                                                        record={record}
                                                        isLast={listFields?.length === listFieldIndex}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <Button icon={<Plus />} type="primary" onClick={() => add(listAddValues)} ghost>
                                        {listAddButtonLabel}
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </div>
                            </>
                        )}
                    </Form.List>
                </>
            );
        }

        case 'RoleSelect':
            field = <RoleSelect {...props.fieldComponentProps} />;
            break;

        default:
            field = null;
    }

    return renderEdit ? (
        renderEdit(props, record)
    ) : fields ? (
        <div className={classNames('p-6 border', !isLast && 'mb-6')}>
            {label && (
                <Typography.Paragraph className="text-dark-blue font-bold text-base mb-2">{label}</Typography.Paragraph>
            )}
            {fields?.map((field, fieldIndex) => (
                <FormField<TData>
                    {...field}
                    record={record}
                    key={Array.isArray(field.name) ? field.name.join('') : field.name}
                    isLast={fields?.length === fieldIndex + 1}
                />
            ))}
        </div>
    ) : (
        <Form.Item
            name={name}
            label={label}
            rules={[
                ...(required ? [getRequiredRule(formatMessage)] : []),
                ...(type === 'email'
                    ? [{ type: 'email' as RuleType, message: formatMessage(formMessages.invalidEmail) }]
                    : []),
            ]}
            required={required}
            {...sectionCardFormItemLayout}
            wrapperCol={type === 'OpenHoursFormFields' ? { span: 18 } : sectionCardFormItemLayout.wrapperCol}
            {...formItemProps}
            className={classNames(formItemProps?.className, isLast && 'mb-0')}
            key={dependency}
        >
            {field}
        </Form.Item>
    );
};

export default FormField;
