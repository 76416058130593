import api from './apiClient';
import { DatesQuery, downloadResponseAsBlob, SearchPaginationQuery } from '.';
import {
    ListResponse,
    Customer,
    Transporter,
    TaskGroup,
    TaskGroupPreBillingStatus,
    PreBillingCustomer,
    PreBillingTransporter,
    TaskGroupTransferStatus,
} from './types';

// Controller Interface

export type PreBillingCustomersListPayload = SearchPaginationQuery &
    DatesQuery & {
        customer?: Customer['id'] | Array<Customer['id']>;
    };
export type PreBillingCustomersListResponse = ListResponse<PreBillingCustomer>;

export type PreBillingTransportersListPayload = SearchPaginationQuery &
    DatesQuery & {
        transporter?: Transporter['id'] | Array<Transporter['id']>;
    };
export type PreBillingTransportersListResponse = ListResponse<PreBillingTransporter>;

export type PreBillingCustomerDetailsPayload = DatesQuery & {
    customerId?: Customer['id'];
};

export type PreBillingTransporterDetailsPayload = DatesQuery & {
    transporterId?: Transporter['id'];
};

export type PreBillingCustomerTaskGroupsListPayload = SearchPaginationQuery &
    DatesQuery & {
        customerId?: Customer['id'];
        preBillingStatus?: TaskGroupPreBillingStatus | TaskGroupPreBillingStatus[];
        transferStatus?: TaskGroupTransferStatus;
    };

export type PreBillingTransporterTaskGroupsListPayload = SearchPaginationQuery &
    DatesQuery & {
        transporterId?: Transporter['id'];
        preBillingStatus?: TaskGroupPreBillingStatus | TaskGroupPreBillingStatus[];
    };

export type PreBillingListTaskGroupResponse = ListResponse<TaskGroup>;

// Routes
export const listCustomers = async (payload?: PreBillingCustomersListPayload) => {
    return await api
        .get<PreBillingCustomersListResponse>(`/preBilling/customers`, { params: payload })
        .then((response) => response?.data);
};
export const listTransporters = async (payload?: PreBillingTransportersListPayload) => {
    return await api
        .get<PreBillingTransportersListResponse>(`/preBilling/transporters`, { params: payload })
        .then((response) => response?.data);
};

export const detailsCustomer = async ({ customerId, ...payload }: PreBillingCustomerDetailsPayload) => {
    if (!customerId) {
        throw new Error('missing id');
    }
    return await api
        .get<PreBillingCustomer>(`/preBilling/customers/${customerId}`, { params: payload })
        .then((response) => response?.data);
};

export const detailsTransporter = async ({ transporterId, ...payload }: PreBillingTransporterDetailsPayload) => {
    if (!transporterId) {
        throw new Error('missing id');
    }
    return await api
        .get<PreBillingTransporter>(`/preBilling/transporters/${transporterId}`, { params: payload })
        .then((response) => response?.data);
};

export const listCustomerTaskGroups = async ({ customerId, ...payload }: PreBillingCustomerTaskGroupsListPayload) => {
    if (!customerId) {
        throw new Error('missing id');
    }
    return await api
        .get<PreBillingListTaskGroupResponse>(`/preBilling/customers/${customerId}/taskGroups`, { params: payload })
        .then((response) => response?.data);
};

export const listTransporterTaskGroups = async ({
    transporterId,
    ...payload
}: PreBillingTransporterTaskGroupsListPayload) => {
    if (!transporterId) {
        throw new Error('missing id');
    }
    return await api
        .get<PreBillingListTaskGroupResponse>(`/preBilling/transporters/${transporterId}/taskGroups`, {
            params: payload,
        })
        .then((response) => response?.data);
};
export interface PreBillingCustomersExportPayload {
    fromDate?: string;
    toDate?: string;
}
export const exportPreBillingCustomers = async (payload: PreBillingCustomersExportPayload) => {
    return await api
        .get<Blob>('/prebilling/customers/list/export', {
            params: payload,
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);

            return undefined;
        });
};

export interface PreBillingExportCustomerDetailsPayload {
    customerId?: Customer['id'];
    transferStatus?: TaskGroupTransferStatus;
    fromDate?: string;
    toDate?: string;
}
export const exportPreBillingCustomerDetails = async ({
    customerId,
    ...payload
}: PreBillingExportCustomerDetailsPayload) => {
    if (!customerId) {
        throw new Error('missing customerId');
    }
    return await api
        .get<Blob>(`/prebilling/customers/${customerId}/details/export`, {
            params: payload,
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);

            return undefined;
        });
};

export interface PreBillingExportCustomerDetailsClientPayload {
    customerId?: Customer['id'];
    fromDate?: string;
    toDate?: string;
}
export const exportPreBillingCustomerDetailsClient = async ({
    customerId,
    ...payload
}: PreBillingExportCustomerDetailsClientPayload) => {
    if (!customerId) {
        throw new Error('missing customerId');
    }
    return await api
        .get<Blob>(`/prebilling/customers/${customerId}/taskGroups/export/pdf`, {
            params: payload,
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);

            return undefined;
        });
};
export interface PreBillingTransportersExportPayload {
    fromDate?: string;
    toDate?: string;
}
export const exportPreBillingTransporters = async (payload: PreBillingTransportersExportPayload) => {
    return await api
        .get<Blob>('/prebilling/transporters/list/export', {
            params: payload,
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);

            return undefined;
        });
};

export interface PreBillingExportTransporterDetailsPayload {
    transporterId?: Transporter['id'];
    transferStatus?: TaskGroupTransferStatus;
    fromDate?: string;
    toDate?: string;
}
export const exportPreBillingTransporterDetails = async ({
    transporterId,
    ...payload
}: PreBillingExportTransporterDetailsPayload) => {
    if (!transporterId) {
        throw new Error('missing transporterId');
    }
    return await api
        .get<Blob>(`/prebilling/transporters/${transporterId}/details/export`, {
            params: payload,
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);

            return undefined;
        });
};

export interface PreBillingExportTaskGroupPayload {
    taskGroupId?: TaskGroup['id'];
    serviceType: 'customer' | 'transporter';
}
export const exportPreBillingTaskGroup = async ({ taskGroupId, ...payload }: PreBillingExportTaskGroupPayload) => {
    if (!taskGroupId) {
        throw new Error('missing taskGroupId');
    }
    return await api
        .get<Blob>(`/prebilling/taskGroup/${taskGroupId}/export/pdf`, {
            params: payload,
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);

            return undefined;
        });
};
