import { createContext, useState, FC, useMemo, useContext, Dispatch, SetStateAction } from 'react';

interface LayoutContextTypes {
    isSidebarCollapsed: boolean;
    setIsSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
}

export const LayoutContext = createContext<LayoutContextTypes | null>(null);
LayoutContext.displayName = 'LayoutContext';

export const LayoutProvider: FC = ({ children }) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const layoutContext = useMemo(
        () => ({
            isSidebarCollapsed,
            setIsSidebarCollapsed,
        }),
        [isSidebarCollapsed, setIsSidebarCollapsed]
    );

    return <LayoutContext.Provider value={layoutContext}>{children}</LayoutContext.Provider>;
};

export const useLayout = () => {
    const context = useContext(LayoutContext);

    if (!context) {
        throw new Error('useLayout must be used within an LayoutProvider');
    }

    return context;
};
