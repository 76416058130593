import axios, { AxiosError } from 'axios';

import constants from '../../config/constants';
import { AuthorizeType } from '../mobilic';

const { BASE_URL_TOKEN, OPERATOR_REDIRECT_URI, PLACE_REDIRECT_URI, CLIENT_ID, CLIENT_SECRET } = constants.API_MOBILIC;

const api = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        throw error;
    }
);

export interface MobilicAuthorizeResponse {
    token_type: 'Bearer';
    access_token: string;
}

export const authorizeMobilic = async (code: string, type: AuthorizeType) => {
    const params = new URLSearchParams();
    params.append('client_id', CLIENT_ID);
    params.append('client_secret', CLIENT_SECRET);
    params.append('grant_type', 'authorization_code');
    params.append('code', code);
    if (type === AuthorizeType.operator) {
        params.append('redirect_uri', OPERATOR_REDIRECT_URI);
    }
    if (type === AuthorizeType.place) {
        params.append('redirect_uri', PLACE_REDIRECT_URI);
    }

    return await api.post(BASE_URL_TOKEN, params).then<MobilicAuthorizeResponse>((response) => response?.data);
};

export default api;
