import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime, useIntl } from 'react-intl';
import {
    Input,
    Table,
    Space,
    TableProps,
    InputProps,
    Tooltip,
    Button,
    Row,
    Col,
    Card,
    Typography,
    Divider,
    Spin,
    Dropdown,
    Menu,
    Switch,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
    HiCheck,
    HiOutlineCalendar,
    HiOutlineCheckCircle,
    HiOutlineDocumentAdd,
    HiOutlineDocumentText,
    HiOutlineFilter,
} from 'react-icons/hi';
import dayjs from 'dayjs';
import { show } from '@ebay/nice-modal-react';
import { DownOutlined } from '@ant-design/icons';

import Seo from '../../components/Seo';
import ListTitle from '../../components/ListTitle';
import ApiResult from '../../components/ApiResult';
import useQueryParams from '../../hooks/queryParams';
import { getFullName, getPreBillingStatusColor, sortOrderConverter } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import { Download, Search } from '../../components/icons';
import genericMessages from '../../i18n/genericMessages';
import preBillingMessages, { preBillingStatusMessageMap } from '../../i18n/preBillingMessages';
import DatePicker from '../../components/DatePicker';
import PreBillingStatusSelect from '../../components/selects/PreBillingStatusSelect';
import {
    usePreBillingCustomerDetails,
    usePreBillingCustomerDetailsClientExport,
    usePreBillingCustomerDetailsExport,
    usePreBillingCustomerTaskGroupsList,
    usePreBillingTaskGroupExport,
} from '../../queries/preBilling';
import {
    Customer,
    InvoicingUnit,
    TaskGroup,
    TaskGroupPreBillingStatus,
    TaskGroupServiceType,
    TaskGroupTransferStatus,
} from '../../queries/api/types';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import TagWithDot from '../../components/TagWithDot';
import SetPreBillingStatusModal from './SetPreBillingStatusModal';
import TaskGroupServiceFormModal from './TaskGroupServiceFormModal';
import { errorMessage } from '../../helpers/message';

let searchTimeout: number;

const PreBillingCustomerDetails: FC = () => {
    const { customerId } = useParams<{ customerId: Customer['id'] }>();
    const { formatMessage } = useIntl();
    const [isExportClientEnabled, setIsExportClientEnabled] = useState(false);
    const [exportAllType, setExportAllType] = useState<'interGroup' | 'all'>();
    const [currentTaskGroupExporting, setCurrentTaskGroupExporting] = useState<TaskGroup['id']>();
    const [queryParams, setQueryParams] = useQueryParams('PreBillingCustomerDetails');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const interGroup = queryParams.get('interGroup') ?? undefined;
    const preBillingStatus =
        (queryParams.get('preBillingStatus') as TaskGroupPreBillingStatus | undefined) ?? undefined;
    const [fromDate, toDate] =
        queryParams.get('fromDate') && queryParams.get('toDate')
            ? [queryParams.get('fromDate')!, queryParams.get('toDate')!]
            : [];

    const [selectedTaskGroupIds, setSelectedTaskGroupIds] = useState<Array<TaskGroup['id']>>();
    const { data: customer } = usePreBillingCustomerDetails({
        customerId,
        fromDate,
        toDate,
    });
    const {
        data: taskGroups,
        isLoading,
        isFetching,
        isError,
        error,
    } = usePreBillingCustomerTaskGroupsList({
        customerId,
        page,
        search,
        sort,
        sortOrder,
        preBillingStatus,
        transferStatus: interGroup ? TaskGroupTransferStatus.interGroup : undefined,
        fromDate,
        toDate,
    });
    usePreBillingCustomerDetailsExport(
        {
            customerId,
            ...(exportAllType === 'interGroup' ? { transferStatus: TaskGroupTransferStatus.interGroup } : {}),
            fromDate,
            toDate,
        },
        {
            enabled: Boolean(exportAllType),
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
                });
            },
            onSettled: () => {
                setExportAllType(undefined);
            },
        }
    );
    usePreBillingTaskGroupExport(
        {
            taskGroupId: currentTaskGroupExporting,
            serviceType: 'customer',
        },
        {
            enabled: Boolean(currentTaskGroupExporting),
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
                });
            },
            onSettled: () => {
                setCurrentTaskGroupExporting(undefined);
            },
        }
    );
    usePreBillingCustomerDetailsClientExport(
        {
            customerId,
            fromDate,
            toDate,
        },
        {
            enabled: isExportClientEnabled,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
                });
            },
            onSettled: () => {
                setIsExportClientEnabled(false);
            },
        }
    );
    const onTableChange: TableProps<TaskGroup>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
            });
        },
        [setQueryParams]
    );
    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                    sort: undefined,
                    sortOrder: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );
    const onMarkAsPreBilled = (ids: Array<TaskGroup['id']>) => () => {
        show(SetPreBillingStatusModal, { taskGroupIds: ids, serviceType: TaskGroupServiceType.customer }).then(() => {
            setSelectedTaskGroupIds(undefined);
        });
    };
    const columns: ColumnsType<TaskGroup> = useMemo(
        () => [
            {
                key: 'reference',
                dataIndex: 'reference',
                title: formatMessage(genericMessages.taskGroup, { count: 1 }),
                sorter: true,
                fixed: 'left',
                width: 150,
            },
            {
                key: 'preBillingStatus',
                title: formatMessage(genericMessages.status),
                render: (_, item) =>
                    item.preBillingStatus ? (
                        <TagWithDot color={getPreBillingStatusColor(item.preBillingStatus)} size="small">
                            <span className="font-bold">
                                <FormattedMessage {...preBillingStatusMessageMap.get(item.preBillingStatus)} />
                            </span>
                        </TagWithDot>
                    ) : (
                        '-'
                    ),
                fixed: 'left',
                width: 150,
            },
            {
                key: 'date',
                title: formatMessage(genericMessages.date),
                render: (_, item) => <FormattedDate value={item.date} />,
                sorter: true,
                fixed: 'left',
                width: 124,
            },
            {
                key: 'week',
                title: formatMessage(genericMessages.week),
                render: (_, item) => <FormattedNumber value={dayjs(item.date).week()} />,
                sorter: true,
                width: 120,
            },
            {
                key: 'prebillingNumber',
                title: formatMessage(preBillingMessages.preBillingReference),
                render: (_, item) => item.computedProperties?.prebillingNumber || '-',
                sorter: true,
                width: 200,
            },
            {
                key: 'operator',
                title: formatMessage(genericMessages.operator),
                render: (_, item) => getFullName(item.operator) || '-',
                width: 185,
            },
            {
                key: 'computedProperties.soldAmount',
                title: formatMessage(preBillingMessages.soldAmount),
                render: (_, item) =>
                    item.computedProperties?.soldAmount ? (
                        <Tooltip
                            title={
                                <>
                                    {formatMessage(genericMessages.taskGroup, { count: 2 })} :{' '}
                                    <FormattedNumber
                                        style="currency"
                                        currency="EUR"
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={0}
                                        value={item.computedProperties.soldAmount}
                                    />
                                    <br />
                                    {formatMessage(preBillingMessages.extraServices)} :{' '}
                                    <FormattedNumber
                                        style="currency"
                                        currency="EUR"
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={0}
                                        value={item.computedProperties.transporterAdditionalServicesAmount}
                                    />
                                </>
                            }
                        >
                            <FormattedNumber
                                style="currency"
                                currency="EUR"
                                maximumFractionDigits={2}
                                minimumFractionDigits={0}
                                value={item.computedProperties.soldAmount}
                            />
                        </Tooltip>
                    ) : (
                        '-'
                    ),
                sorter: true,
                width: 130,
            },
            {
                key: 'computedProperties.purchasedAmount',
                title: formatMessage(preBillingMessages.boughtAmount),
                render: (_, item) =>
                    item.computedProperties?.purchasedAmount ? (
                        <FormattedNumber
                            style="currency"
                            currency="EUR"
                            maximumFractionDigits={2}
                            minimumFractionDigits={0}
                            value={item.computedProperties.purchasedAmount}
                        />
                    ) : (
                        '-'
                    ),
                sorter: true,
                width: 105,
            },
            {
                key: 'computedProperties.marginAmount',
                title: formatMessage(preBillingMessages.marginAmount),
                render: (_, item) =>
                    item.computedProperties?.marginAmount ? (
                        <FormattedNumber
                            style="currency"
                            currency="EUR"
                            maximumFractionDigits={2}
                            minimumFractionDigits={0}
                            value={item.computedProperties.marginAmount}
                        />
                    ) : (
                        '-'
                    ),
                sorter: true,
                width: 105,
            },
            {
                key: 'computedProperties.transporterAdditionalServicesAmount',
                title: formatMessage(genericMessages.taskGroupServices, { count: 2 }),
                render: (_, item) =>
                    item.computedProperties?.customerAdditionalServiceCount ? (
                        <>
                            <FormattedNumber value={item.computedProperties.customerAdditionalServiceCount} /> (
                            <FormattedNumber
                                style="currency"
                                currency="EUR"
                                maximumFractionDigits={2}
                                minimumFractionDigits={0}
                                value={item.computedProperties.customerAdditionalServicesAmount}
                            />
                            )
                        </>
                    ) : (
                        '-'
                    ),
                width: 155,
            },
            {
                key: 'invoicingUnit',
                title: formatMessage(genericMessages.billingUnit),
                render: (_, item) =>
                    item.customer.invoicingUnit ? (
                        <>
                            {item.customer.invoicingUnit === InvoicingUnit.service ? (
                                <Tooltip
                                    title={item.customer.invoicingPrices?.[0]?.serviceType?.fields?.customerServices}
                                >
                                    <FormattedMessage {...genericMessages.services} values={{ count: 1 }} />
                                </Tooltip>
                            ) : (
                                <FormattedMessage {...genericMessages.vehicles} values={{ count: 1 }} />
                            )}
                        </>
                    ) : (
                        '-'
                    ),
                width: 200,
            },
            {
                key: 'transporter',
                title: formatMessage(genericMessages.partner),
                render: (_) => '-',
                width: 210,
            },
            {
                key: 'vehicle.reference',
                title: formatMessage(genericMessages.vehicles, { count: 1 }),
                render: (_, item) => item.vehicle?.reference ?? '-',
                sorter: true,
                width: 210,
            },
            {
                key: 'transferPlace.name',
                title: formatMessage(preBillingMessages.interGroup),
                render: (_, item) => item.transferPlace?.name ?? '-',
                sorter: true,
                width: 210,
            },
            {
                key: 'startHour',
                title: formatMessage(genericMessages.start),
                render: (_, item) => <FormattedTime value={item.startDate} />,
                width: 100,
            },
            {
                key: 'endHour',
                title: formatMessage(genericMessages.end),
                render: (_, item) => <FormattedTime value={item.endDate} />,
                width: 100,
            },
            {
                key: 'computedProperties.handlingUnit',
                title: formatMessage(preBillingMessages.handlingUnitShort),
                render: (_, item) => item.computedProperties?.handlingUnit ?? '-',
                width: 100,
            },
            {
                key: 'computedProperties.tasksCount',
                title: formatMessage(preBillingMessages.taskCountShort),
                render: (_, item) => item.computedProperties?.taskCount ?? '-',
                width: 100,
            },
            {
                key: 'computedProperties.vehicleStartingMileage',
                title: formatMessage(preBillingMessages.startingMileage),
                render: (_, item) => (
                    <FormattedNumber
                        value={item?.computedProperties?.vehicleStartingMileage ?? 0}
                        style="unit"
                        unit="kilometer"
                    />
                ),
                sorter: true,
                width: 150,
            },
            {
                key: 'computedProperties.vehicleEndingMileage',
                title: formatMessage(preBillingMessages.endingMileage),
                render: (_, item) => (
                    <FormattedNumber
                        value={item?.computedProperties?.vehicleEndingMileage ?? 0}
                        style="unit"
                        unit="kilometer"
                    />
                ),
                sorter: true,
                width: 150,
            },
            {
                key: 'computedProperties.mileage',
                title: formatMessage(preBillingMessages.mileage),
                render: (_, item) => (
                    <FormattedNumber value={item?.computedProperties?.mileage ?? 0} style="unit" unit="kilometer" />
                ),
                sorter: true,
                width: 140,
            },
            {
                key: 'actions',
                title: formatMessage(genericMessages.actions),
                render: (_, item) => {
                    const isPreBilled = item.preBillingStatus === TaskGroupPreBillingStatus.preBilled;
                    const isToPreBill = item.preBillingStatus === TaskGroupPreBillingStatus.toPreBill;
                    const hasServices = !!item.computedProperties?.customerAdditionalServiceCount;
                    const taskGroupServicesRoute = {
                        pathname: getRoute(RoutePathName.preBillingCustomersTaskGroupServices, {
                            customerId,
                            taskGroupId: item.id,
                        }),
                        search: `?taskGroupReference=${item.reference}`,
                    };

                    return (
                        <div className="flex items-center space-x-2">
                            {/* first button */}
                            {isToPreBill && hasServices && (
                                <Tooltip title={formatMessage(preBillingMessages.seeAdditionalServicesTooltip)}>
                                    <Link to={taskGroupServicesRoute}>
                                        <Button
                                            size="small"
                                            type="text"
                                            icon={<HiOutlineDocumentText className="text-xl text-faded-blue" />}
                                        />
                                    </Link>
                                </Tooltip>
                            )}
                            {isToPreBill && !hasServices && (
                                <Tooltip title={formatMessage(preBillingMessages.addAdditionalServicesTooltip)}>
                                    <Button
                                        size="small"
                                        type="text"
                                        icon={<HiOutlineDocumentAdd className="text-xl text-faded-blue" />}
                                        onClick={() => {
                                            show(TaskGroupServiceFormModal, {
                                                taskGroupId: item.id,
                                                taskGroupServiceType: TaskGroupServiceType.customer,
                                            });
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {isPreBilled && (
                                <Button
                                    size="small"
                                    type="text"
                                    icon={<Download className="text-xl text-faded-blue" />}
                                    onClick={() => setCurrentTaskGroupExporting(item.id)}
                                    loading={currentTaskGroupExporting === item.id}
                                />
                            )}

                            {/* second button */}
                            {isToPreBill && (
                                <Tooltip title={formatMessage(preBillingMessages.markAsPreBilledTooltip)}>
                                    <Button
                                        size="small"
                                        type="text"
                                        icon={<HiOutlineCheckCircle className="text-xl text-faded-blue" />}
                                        onClick={onMarkAsPreBilled([item.id])}
                                    />
                                </Tooltip>
                            )}
                            {isPreBilled && hasServices && (
                                <Tooltip title={formatMessage(preBillingMessages.seeAdditionalServicesTooltip)}>
                                    <Link to={taskGroupServicesRoute}>
                                        <Button
                                            size="small"
                                            type="text"
                                            icon={<HiOutlineDocumentText className="text-xl text-faded-blue" />}
                                        />
                                    </Link>
                                </Tooltip>
                            )}
                        </div>
                    );
                },
                width: 104,
                fixed: 'right',
            },
        ],
        [currentTaskGroupExporting, customerId, formatMessage]
    );
    const pageTitle = formatMessage({
        id: 'pre_billing.customers.details.title',
        defaultMessage: 'Pré-facturation Clients',
        description: 'Customer pre billing list title',
    });
    const location = useLocation();
    const getPreBillingCustomersListLink = useMemo(() => {
        return {
            pathname: getRoute(RoutePathName.preBillingCustomersList),
            search: location.search,
        };
    }, [location.search]);

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                <ListTitle
                    className="mb-0 uppercase"
                    count={taskGroups?.totalCount}
                    backRoute={getPreBillingCustomersListLink}
                >
                    {customer?.customerName}
                </ListTitle>
                <div className="flex items-center gap-4">
                    <DatePicker.RangePicker
                        defaultValue={fromDate && toDate ? [dayjs(fromDate), dayjs(toDate)] : undefined}
                        onChange={(values) =>
                            setQueryParams({
                                fromDate: values?.[0]?.toISOString(),
                                toDate: values?.[1]?.toISOString(),
                            })
                        }
                        style={{ width: 284 }}
                        format="DD/MM/YYYY"
                        suffixIcon={<HiOutlineCalendar />}
                        size="small"
                    />
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item onClick={() => setExportAllType('all')}>
                                    <FormattedMessage {...preBillingMessages.exportAll} tagName="span" />
                                </Menu.Item>
                                <Menu.Item onClick={() => setExportAllType('interGroup')}>
                                    <FormattedMessage
                                        {...preBillingMessages.exportInterGroup}
                                        values={{
                                            b: (chunks) => <span className="uppercase">{chunks}</span>,
                                        }}
                                        tagName="span"
                                    />
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type="primary" loading={Boolean(exportAllType)}>
                            <FormattedMessage {...preBillingMessages.exportIDL} tagName="span" />
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                    <Button
                        type="primary"
                        icon={<Download className="text-base" />}
                        onClick={() => setIsExportClientEnabled(true)}
                        loading={isExportClientEnabled}
                    >
                        <FormattedMessage {...preBillingMessages.exportCustomer} tagName="span" />
                    </Button>
                </div>
            </div>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={6}>
                    <Card className="h-full">
                        <Spin spinning={isLoading}>
                            <Typography.Title level={2} className="text-base font-medium text-faded-blue">
                                <LabelWithTooltipIcon tooltip={'à définir'}>
                                    <FormattedMessage
                                        id="pre_billing.customers.details.stats.total_to_bill"
                                        defaultMessage="Total à facturer"
                                        description="Card title"
                                    />
                                </LabelWithTooltipIcon>
                            </Typography.Title>
                            <span className="flex text-2xl font-semibold gap-4">
                                <FormattedNumber
                                    style="currency"
                                    currency="EUR"
                                    maximumFractionDigits={2}
                                    minimumFractionDigits={0}
                                    value={customer?.sold ?? 0}
                                />
                                {customer?.margin !== undefined && (
                                    <span className="text-duck-blue">
                                        (
                                        <FormattedNumber
                                            style="currency"
                                            currency="EUR"
                                            maximumFractionDigits={2}
                                            minimumFractionDigits={0}
                                            value={customer.margin}
                                        />
                                        )
                                    </span>
                                )}
                            </span>
                        </Spin>
                    </Card>
                </Col>
                <Col xs={24} lg={6}>
                    <Card className="h-full">
                        <Spin spinning={isLoading}>
                            <Typography.Title level={2} className="text-base font-medium text-faded-blue">
                                <LabelWithTooltipIcon tooltip={'à définir'}>
                                    <FormattedMessage
                                        id="pre_billing.customers.details.stats.um"
                                        defaultMessage="Unité de manutention"
                                        description="Card title"
                                    />
                                </LabelWithTooltipIcon>
                            </Typography.Title>
                            <span className="flex text-2xl font-semibold gap-4">
                                <FormattedNumber value={customer?.handlingUnit ?? 0} />
                            </span>
                        </Spin>
                    </Card>
                </Col>
                <Col xs={24} lg={6}>
                    <Card className="h-full">
                        <Spin spinning={isLoading}>
                            <Typography.Title level={2} className="text-base font-medium text-faded-blue">
                                <LabelWithTooltipIcon tooltip={'à définir'}>
                                    <FormattedMessage {...preBillingMessages.taskCount} />
                                </LabelWithTooltipIcon>
                            </Typography.Title>
                            <span className="flex text-2xl font-semibold gap-4">
                                <FormattedNumber value={customer?.taskCount ?? 0} />
                            </span>
                        </Spin>
                    </Card>
                </Col>
                <Col xs={24} lg={6}>
                    <Card className="h-full">
                        <Spin spinning={isLoading}>
                            <Typography.Title level={2} className="text-base font-medium text-faded-blue">
                                <LabelWithTooltipIcon tooltip={'à définir'}>
                                    <FormattedMessage {...preBillingMessages.mileage} />
                                </LabelWithTooltipIcon>
                            </Typography.Title>
                            <span className="flex text-2xl font-semibold gap-4">
                                <FormattedNumber value={customer?.mileage ?? 0} style="unit" unit="kilometer" />
                            </span>
                        </Spin>
                    </Card>
                </Col>
            </Row>
            <Divider />
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <div className="flex items-center gap-4">
                    <PreBillingStatusSelect
                        onChange={(value) => setQueryParams({ preBillingStatus: value })}
                        style={{ width: 240 }}
                        defaultValue={preBillingStatus}
                        maxTagCount="responsive"
                        size="small"
                        allowClear
                    />
                    <Input
                        placeholder={formatMessage(preBillingMessages.searchInputPlaceholder)}
                        onChange={onSearch}
                        defaultValue={search}
                        prefix={<Search className="text-primary text-base leading-4" />}
                        size="small"
                        className="flex-1"
                        allowClear
                    />
                    <Dropdown
                        overlay={
                            <Menu
                                items={[
                                    {
                                        key: 'interGroup',
                                        label: (
                                            <label
                                                htmlFor="interGroup"
                                                className="flex space-x-2 items-center py-2 font-bold cursor-pointer"
                                            >
                                                <Switch
                                                    id="interGroup"
                                                    onChange={(value) =>
                                                        setQueryParams({ interGroup: value || undefined })
                                                    }
                                                    defaultChecked={!!interGroup}
                                                />
                                                <FormattedMessage
                                                    id="pre_billing.customers.filter.inter_group"
                                                    defaultMessage="Intergroupe uniquement"
                                                    tagName="span"
                                                />
                                            </label>
                                        ),
                                    },
                                ]}
                            />
                        }
                    >
                        <Button
                            type="primary"
                            icon={
                                <span>
                                    <HiOutlineFilter className="text-xl text-faded-blue" />
                                </span>
                            }
                            disabled
                            ghost
                        />
                    </Dropdown>
                </div>
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Table<TaskGroup>
                        rowKey="id"
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={taskGroups?.items}
                        pagination={{
                            total: taskGroups?.totalCount,
                            current: page + 1,
                            pageSize: taskGroups?.pageSize,
                            position: ['bottomCenter'],
                            hideOnSinglePage: true,
                        }}
                        scroll={{
                            x: 1440,
                            y: `calc(100vh - 252px - ${selectedTaskGroupIds?.length ? '72px' : '0px'})`,
                            scrollToFirstRowOnChange: true,
                        }}
                        onChange={onTableChange}
                        size="small"
                        rowSelection={{
                            preserveSelectedRowKeys: true,
                            onChange: (selectedRowKeys) => {
                                setSelectedTaskGroupIds(selectedRowKeys as Array<TaskGroup['id']>);
                            },
                            selectedRowKeys: selectedTaskGroupIds || [],
                            getCheckboxProps: (record) => ({
                                disabled: record.preBillingStatus === TaskGroupPreBillingStatus.preBilled,
                            }),
                        }}
                        footer={
                            selectedTaskGroupIds?.length
                                ? () => (
                                      <div className="flex justify-between items-center">
                                          <Typography.Text className="text-base text-blue font-semibold">
                                              <FormattedMessage
                                                  id="pre_billing.customers.details.task_group_selection_bar.selected_count"
                                                  defaultMessage="{count, plural, one {{count, number} tournée sélectionnée} other {{count, number} tournées sélectionnées}}"
                                                  values={{ count: selectedTaskGroupIds?.length }}
                                              />
                                          </Typography.Text>
                                          <Button
                                              type="primary"
                                              onClick={onMarkAsPreBilled(selectedTaskGroupIds)}
                                              icon={<HiCheck className="text-base" />}
                                          >
                                              <FormattedMessage
                                                  id="pre_billing.customers.details.task_group_selection_bar.mark_as_pre_billed_button"
                                                  defaultMessage="Marquer comme pré-facturée"
                                                  tagName="span"
                                              />
                                          </Button>
                                      </div>
                                  )
                                : undefined
                        }
                    />
                )}
            </Space>
        </>
    );
};

export default PreBillingCustomerDetails;
