import api from './apiClient';
import { Application, ApplicationClient } from './types';

export type ApplicationClientCreatePayload = Omit<ApplicationClient, 'id' | 'application' | 'apiKey'> & {
    application: Application['id'];
};
export const create = async (payload: ApplicationClientCreatePayload) => {
    return await api
        .post<ApplicationClient>(`/applications/${payload.application}/clients`, payload)
        .then((response) => response?.data);
};

export type ApplicationClientUpdatePayload = Omit<ApplicationClient, 'application' | 'apiKey'> & {
    application: Application['id'];
};
export const update = async ({ id, ...payload }: ApplicationClientUpdatePayload) => {
    return await api
        .put<ApplicationClient>(`/applications/${payload.application}/clients/${id}`, payload)
        .then((response) => response?.data);
};

export interface ApplicationClientIdPayload {
    id: ApplicationClient['id'];
    applicationId: Application['id'];
}
export const details = async ({ id, applicationId }: ApplicationClientIdPayload) => {
    return await api
        .get<ApplicationClient>(`/applications/${applicationId}/clients/${id}`)
        .then((response) => response?.data);
};

export const remove = async ({ id, applicationId }: ApplicationClientIdPayload) => {
    return await api
        .delete<undefined>(`/applications/${applicationId}/clients/${id}`)
        .then((response) => response?.data);
};
