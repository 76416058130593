import { IntlShape } from 'react-intl';
import { Form, Select, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { CustomerCreatePayload, CustomerUpdatePayload } from '../../../queries/api/customers';
import {
    Address,
    Contact,
    Customer,
    CustomerType,
    InvoicingPrice,
    InvoicingUnit,
    PauseDurationUnit,
    Place,
    ValueListItem,
    ValueListSlug,
} from '../../../queries/api/types';
import { getRequiredRule } from '../../../i18n';
import customerMessages, {
    customerInvoicingUnitMessagesMap,
    customerTypeMessagesMap,
} from '../../../i18n/customerMessages';
import genericMessages from '../../../i18n/genericMessages';
import { filterDuplicates } from '../../../helpers';
import { DetailsFormCardProps } from '../../../components/form/DetailsFormCard';
import { sectionCardFormItemLayout } from '../../../components/SectionCard';
import { ChevronDown } from '../../../components/icons';
import CustomerInvoicingUnitService from './CustomerInvoicingUnitService';
import CustomerInvoicingUnitVehicle from './CustomerInvoicingUnitVehicle';
import CustomerInvoicingUnitDetails from './CustomerInvoicingUnitDetails';
import CustomersList from './CustomersList';
import { CustomerCalendar } from './forecasts';

export enum CustomerDetailsTabs {
    forecast = 'forecast',
    informations = 'informations',
}

export enum CustomerFormSection {
    address = 'address',
    contacts = 'contacts',
    contract = 'contract',
    places = 'places',
    pauses = 'pauses',
    billing = 'billing',
    forecast = 'forecast',
}

export interface CustomerForm
    extends Omit<
        Customer,
        | 'id'
        | 'address'
        | 'administrativeContact'
        | 'primaryContact'
        | 'contractType'
        | 'pauses'
        | 'invoicingPrices'
        | 'places'
    > {
    address: Address;
    administrativeContact: Contact;
    primaryContact: Contact;
    contractType: ValueListItem['id'];
    invoicingUnit: InvoicingUnit;
    invoicingPricesVehicle: Array<
        Omit<InvoicingPrice, 'vehicleType' | 'fromDate' | 'toDate'> & {
            vehicleType: ValueListItem;
            fromDate: Dayjs;
            toDate: Dayjs;
        }
    >;
    invoicingPricesService: Omit<InvoicingPrice, 'serviceType'> & {
        serviceType: ValueListItem['id'];
        dates: Dayjs[] | [];
    };
    dates: Dayjs[] | [];
    places: Array<ValueListItem['id']>;
}

export const formatCustomerInitialValues = (record?: Customer) => {
    const dates = [dayjs(record?.invoicingPrices[0]?.fromDate), dayjs(record?.invoicingPrices[0]?.toDate)];
    const places = record?.places?.map((place) => place.group?.id) || [];
    const invoicingPricesService = record?.invoicingPrices?.reduce(
        (acc, invoicingPrice) => ({
            ...acc,
            serviceType: invoicingPrice.serviceType?.id,
            standard: invoicingPrice.standard,
            dates: [dayjs(invoicingPrice.fromDate), dayjs(invoicingPrice.toDate)],
        }),
        {}
    );

    const isService = record?.invoicingUnit === InvoicingUnit.service;
    const isVehicle = record?.invoicingUnit === InvoicingUnit.vehicle;

    const initialValues = {
        ...record,
        dates,
        pauseDuration: record?.pauseDuration || {
            unit: PauseDurationUnit.min,
            value: 30,
        },
        places,
        invoicingPricesService: isService ? invoicingPricesService : {},
        invoicingPricesVehicle: isVehicle ? record.invoicingPrices : [],
    };

    return initialValues;
};

export const formatCustomerCreatePayload = (values: CustomerForm): CustomerCreatePayload => ({
    ...values,
});

export const formatCustomerUpdatePayload =
    (step?: number) =>
    (values: CustomerForm, customer?: Customer): CustomerUpdatePayload => {
        const { dates = [], invoicingPricesService, invoicingPricesVehicle, ...restValues } = values;

        if (!customer?.id) {
            throw new Error('missing id');
        }

        const formattedInvoicingPricesService = [
            {
                invoicingUnit: values.invoicingUnit,
                serviceType: invoicingPricesService?.serviceType,
                standard: invoicingPricesService?.standard,
                weekend: 0,
                holiday: 0,
                reuse: 0,
                fromDate: invoicingPricesService?.dates[0],
                toDate: invoicingPricesService?.dates[1],
            },
        ];

        const formattedInvoicingPricesVehicle = invoicingPricesVehicle?.map((invoicingPrice) => ({
            invoicingUnit: values.invoicingUnit,
            vehicleType: invoicingPrice.vehicleType?.id,
            fromDate: dates[0],
            toDate: dates[1],
            standard: invoicingPrice.standard,
            ...(invoicingPrice.weekend && {
                weekend: invoicingPrice.weekend,
            }),
            ...(invoicingPrice.holiday && {
                holiday: invoicingPrice.holiday,
            }),
            ...(invoicingPrice.reuse && {
                reuse: invoicingPrice.reuse,
            }),
        }));

        return {
            ...customer,
            ...restValues,
            ...(invoicingPricesService && {
                invoicingPrices: formattedInvoicingPricesService,
            }),
            ...(invoicingPricesVehicle && {
                invoicingPrices: formattedInvoicingPricesVehicle,
            }),
            meta: {
                creationStep: (step ?? 0) > (customer?.meta?.creationStep ?? 0) ? step : customer?.meta?.creationStep,
            },
        };
    };

export const getFormSections = (formatMessage: IntlShape['formatMessage'], select?: CustomerFormSection[]) => {
    const sections: {
        [value in CustomerFormSection]: Pick<
            DetailsFormCardProps<Customer, CustomerForm, CustomerUpdatePayload>,
            'title' | 'editButtonText' | 'fields' | 'renderEdit' | 'renderDetails' | 'sectionCardProps'
        >;
    } = {
        [CustomerFormSection.address]: {
            title: formatMessage(customerMessages.addressTitle),
            editButtonText: formatMessage(genericMessages.editAddress),
            fields: [
                {
                    name: ['address', 'street'],
                    type: 'string',
                    label: formatMessage(customerMessages.address),
                    fieldComponentProps: {
                        placeholder: formatMessage(genericMessages.addressPlaceholder),
                    },
                    required: true,
                },
                {
                    name: ['address', 'zipCode'],
                    type: 'string',
                    label: formatMessage(genericMessages.zipCode),
                    fieldComponentProps: {
                        placeholder: formatMessage(genericMessages.zipCodePlaceholder),
                    },
                    required: true,
                },
                {
                    name: ['address', 'city'],
                    type: 'string',
                    label: formatMessage(genericMessages.city),
                    fieldComponentProps: {
                        placeholder: formatMessage(genericMessages.cityPlaceholder),
                    },
                    required: true,
                },
                {
                    name: ['address', 'country'],
                    type: 'CountrySelect',
                    label: formatMessage(genericMessages.country),
                    required: true,
                },
            ],
        },
        [CustomerFormSection.contacts]: {
            title: formatMessage(genericMessages.contact, { count: 0 }),
            editButtonText: formatMessage(genericMessages.contactEditButton),
            fields: [
                {
                    label: formatMessage(genericMessages.primaryLabel),
                    fields: [
                        {
                            name: ['primaryContact', 'lastName'],
                            type: 'string',
                            label: formatMessage(genericMessages.lastName),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.lastNamePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['primaryContact', 'firstName'],
                            type: 'string',
                            label: formatMessage(genericMessages.firstName),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.firstNamePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['primaryContact', 'email'],
                            type: 'email',
                            label: formatMessage(genericMessages.email),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.emailPlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['primaryContact', 'phone'],
                            type: 'PhoneInput',
                            label: formatMessage(genericMessages.phone),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.phonePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['primaryContact', 'jobTitle'],
                            type: 'string',
                            label: formatMessage(genericMessages.jobTitle),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.jobTitlePlaceholder),
                            },
                            required: true,
                        },
                    ],
                },
                {
                    label: formatMessage(genericMessages.administrativeLabel),
                    fields: [
                        {
                            name: ['administrativeContact', 'lastName'],
                            type: 'string',
                            label: formatMessage(genericMessages.lastName),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.lastNamePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['administrativeContact', 'firstName'],
                            type: 'string',
                            label: formatMessage(genericMessages.firstName),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.firstNamePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['administrativeContact', 'email'],
                            type: 'email',
                            label: formatMessage(genericMessages.email),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.emailPlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['administrativeContact', 'phone'],
                            type: 'PhoneInput',
                            label: formatMessage(genericMessages.phone),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.phonePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['administrativeContact', 'jobTitle'],
                            type: 'string',
                            label: formatMessage(genericMessages.jobTitle),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.jobTitlePlaceholder),
                            },
                            required: true,
                        },
                    ],
                },
            ],
        },
        [CustomerFormSection.contract]: {
            title: formatMessage(genericMessages.contract),
            editButtonText: formatMessage(genericMessages.editTypeButton),
            fields: [
                {
                    name: 'type',
                    label: formatMessage(genericMessages.contractType),
                    required: true,
                    renderDetails: (field, record) =>
                        record?.type && formatMessage(customerTypeMessagesMap.get(record?.type)!),
                    renderEdit: () => {
                        const options: Array<{
                            label: string;
                            value: CustomerType;
                        }> = Object.values(CustomerType).map((key) => ({
                            label: formatMessage(customerTypeMessagesMap.get(key)!),
                            value: CustomerType[key],
                        }));

                        return (
                            <Form.Item
                                required
                                name="type"
                                label={formatMessage(genericMessages.contractType)}
                                rules={[getRequiredRule(formatMessage)]}
                                {...sectionCardFormItemLayout}
                            >
                                <Select<CustomerType>
                                    placeholder={formatMessage(customerMessages.selectUnit)}
                                    filterOption={false}
                                    options={options}
                                    showSearch={false}
                                    showArrow
                                />
                            </Form.Item>
                        );
                    },
                },
            ],
        },
        [CustomerFormSection.places]: {
            title: formatMessage(genericMessages.groupLink),
            editButtonText: formatMessage(genericMessages.groupLinkEdit),
            sectionCardProps: {
                buttonProps: {
                    disabled: true,
                },
            },
            fields: [
                {
                    name: 'places',
                    type: 'ValueListItemSelect',
                    fieldComponentProps: {
                        mode: 'multiple',
                        valueListSlug: ValueListSlug.groups,
                    },
                    label: formatMessage(genericMessages.groupLinkLong),
                    required: true,
                    renderDetails: (field, record, dataGetter) => {
                        const places: Customer['places'] = dataGetter(record, field?.name);
                        const filteredPlaces = filterDuplicates<Place>(places, 'group.id');

                        return (
                            <>
                                {filteredPlaces?.map((place) => (
                                    <Tag key={place.id}>{place.group?.fields.reference}</Tag>
                                ))}
                            </>
                        );
                    },
                },
            ],
        },
        [CustomerFormSection.pauses]: {
            title: formatMessage(genericMessages.pauses),
            editButtonText: formatMessage(genericMessages.pausesEditButton),
            fields: [
                {
                    name: ['pauseDuration', 'value'],
                    type: 'number',
                    label: formatMessage(customerMessages.pausesDuration),
                    required: true,
                    fieldComponentProps: {
                        addonAfter: (
                            <Form.Item noStyle name={['pauseDuration', 'unit']}>
                                <Select className="text-lg font-bold text-faded-blue" suffixIcon={<ChevronDown />}>
                                    {Object.keys(PauseDurationUnit).map((unit) => (
                                        <Select.Option key={unit} value={unit}>
                                            {unit}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ),
                        placeholder: formatMessage(customerMessages.pausesDurationPlaceholder),
                        min: 0,
                        style: {
                            width: '100%',
                        },
                    },
                    renderDetails: (field, record) => (
                        <>
                            {record?.pauseDuration?.value} {record?.pauseDuration?.unit}
                        </>
                    ),
                },
            ],
        },
        [CustomerFormSection.billing]: {
            title: formatMessage(genericMessages.billing),
            editButtonText: formatMessage(genericMessages.billingEditButton),
            fields: [
                {
                    name: 'invoicingUnit',
                    required: true,
                    type: 'InvoicingUnitSelect',
                    label: formatMessage(genericMessages.billingUnit),
                    renderDetails: (field, record) =>
                        record?.invoicingUnit &&
                        formatMessage(customerInvoicingUnitMessagesMap.get(record?.invoicingUnit)!),
                },
                {
                    renderEdit: () => {
                        return (
                            <Form.Item noStyle dependencies={['invoicingUnit']}>
                                {({ getFieldValue }) => {
                                    const invoicingUnit = getFieldValue(['invoicingUnit']);
                                    const isVehicle = invoicingUnit === InvoicingUnit.vehicle;
                                    const isService = invoicingUnit === InvoicingUnit.service;

                                    return (
                                        <>
                                            {isVehicle && <CustomerInvoicingUnitVehicle />}
                                            {isService && <CustomerInvoicingUnitService />}
                                        </>
                                    );
                                }}
                            </Form.Item>
                        );
                    },
                    renderDetails: (field, record) => <CustomerInvoicingUnitDetails record={record} />,
                },
            ],
        },
        [CustomerFormSection.forecast]: {
            title: formatMessage(customerMessages.forecastCalendarCreateTitle),
            fields: [
                {
                    renderEdit: (field, record) => (record ? <CustomerCalendar customerId={record.id} /> : <></>),
                },
            ],
        },
    };

    return select ? select.map((sectionName) => sections[sectionName]) : Object.values(sections);
};

export default CustomersList;
