import { Button, Descriptions, Form, FormProps, Tag, Typography } from 'antd';
import { AxiosError } from 'axios';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UseMutateFunction, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from 'react-query';

import { classNames, debug } from '../../helpers';
import { errorMessage, successMessage } from '../../helpers/message';
import formMessages from '../../i18n/formMessages';
import genericMessages from '../../i18n/genericMessages';
import { ValueListItem } from '../../queries/api/types';
import ApiResult from '../ApiResult';
import { Check, X } from '../icons';
import SectionCard, { SectionCardProps } from '../SectionCard';
import FormField, { FormFieldProps } from './FormField';

export const getDataFromFieldName = <TData extends Record<string, any>>(
    record: TData | undefined,
    name: Extract<keyof TData, string> | Array<string | number> | undefined
): any => {
    if (!record || !name) {
        return null;
    }
    try {
        if (Array.isArray(name)) {
            return name.reduce((acc, key) => acc[key], record);
        } else {
            return record?.[name];
        }
    } catch (error) {
        debug.warn('[DetailsFormCard] getDataFromFieldName error', error);
        return null;
    }
};

export interface DetailsFormCardProps<TData, TForm, TUpdatePayload> {
    title: string;
    editButtonText?: string;
    id?: string;
    detailsQueryHandler: (
        id: string | undefined,
        options?: UseQueryOptions<TData, AxiosError, TData> | undefined
    ) => UseQueryResult<TData, AxiosError>;
    updateQueryHandler: (
        options?: UseMutationOptions<TData, AxiosError, TUpdatePayload>
    ) => UseMutationResult<TData, AxiosError, TUpdatePayload, unknown>;
    updatePayload?: TUpdatePayload;
    fields?: Array<FormFieldProps<TData>>;
    initialValueFormatter?: (record: TData | undefined) => Record<string, any>;
    renderDetails?: (fields: Array<FormFieldProps<TData>> | undefined, record: TData | undefined) => JSX.Element;
    renderEdit?: (fields: Array<FormFieldProps<TData>> | undefined, record: TData | undefined) => JSX.Element;
    formatPayload?: (values: TForm, record: TData | undefined) => TUpdatePayload;
    isCreating?: boolean;
    formProps?: FormProps<TForm>;
    sectionCardProps?: Omit<SectionCardProps, 'title'>;
    beforeUpdate?: (updatePayload: any) => Promise<void>;
    onUpdateError?: (
        error: AxiosError<any>,
        update: UseMutateFunction<TData, AxiosError<any>, TUpdatePayload, unknown>,
        payload: any,
        setIsForm: (value: React.SetStateAction<boolean>) => void
    ) => Promise<void>;
}

/**
 * Component that renders a card either displaying the data of a record, or form fields to edit the data of a record
 */
const DetailsFormCard = <TData extends Record<string, any>, TForm, TUpdatePayload extends Record<string, any>>({
    title,
    detailsQueryHandler,
    updateQueryHandler,
    updatePayload,
    id,
    editButtonText,
    fields,
    renderDetails,
    renderEdit,
    initialValueFormatter,
    formatPayload,
    isCreating: isCreatingProps,
    formProps,
    sectionCardProps,
    beforeUpdate,
    onUpdateError,
}: DetailsFormCardProps<TData, TForm, TUpdatePayload>) => {
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();
    const isCreating = isCreatingProps || !id;
    const [isForm, setIsForm] = useState(isCreatingProps || !id);
    const {
        data: details,
        isLoading: isLoadingDetails,
        isError,
        error,
    } = detailsQueryHandler(id, {
        enabled: !!id,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: (data) => {
            if (!isCreating) {
                const values = initialValueFormatter ? initialValueFormatter(data) : data;
                form.setFieldsValue(values);
            }
        },
    });
    const { mutate: update, isLoading: isUpdating } = updateQueryHandler();
    const onSubmit: FormProps['onFinish'] = async (values) => {
        const payload = formatPayload ? formatPayload(values, details) : values;
        await beforeUpdate?.(payload);
        update(
            {
                ...updatePayload,
                ...payload,
                id,
            },
            {
                onSuccess: () => {
                    successMessage({
                        content: formatMessage({
                            id: 'details_form_card.update.success_message',
                            defaultMessage: 'Modifications enregistrées avec succès',
                        }),
                    });
                    setIsForm(false);
                },
                onError: (error) => {
                    onUpdateError?.(
                        error,
                        update,
                        {
                            ...updatePayload,
                            ...payload,
                            id,
                        },
                        setIsForm
                    ) ||
                        errorMessage({
                            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                status: error.response?.status ?? 0,
                            }),
                        });
                },
            }
        );
    };
    const formContent = useMemo(
        () => (
            <>
                {renderEdit
                    ? renderEdit(fields, details)
                    : fields?.map((field, fieldIndex) => (
                          <FormField<TData>
                              {...field}
                              record={details}
                              key={Array.isArray(field.name) ? field.name.join('') : field.name ?? fieldIndex}
                              isLast={fields?.length === fieldIndex + 1}
                          />
                      ))}

                {isForm && !isCreating && (
                    <div className="flex justify-center space-x-4 mt-4">
                        <Button
                            icon={<X />}
                            onClick={() => {
                                form.resetFields();
                                setIsForm(false);
                            }}
                            size="large"
                            disabled={isUpdating}
                        >
                            <FormattedMessage {...formMessages.cancel} tagName="span" />
                        </Button>
                        <Button icon={<Check />} type="primary" htmlType="submit" size="large" loading={isUpdating}>
                            <FormattedMessage {...formMessages.submit} tagName="span" />
                        </Button>
                    </div>
                )}
            </>
        ),
        [details, fields, form, isCreating, isForm, isUpdating, renderEdit]
    );
    const innerDetailsRender = useCallback(
        <TData extends Record<string, any>>(fields: Array<FormFieldProps<TData>> | undefined, record?: TData) => {
            return fields
                ?.filter((field) => (field.shouldRenderDetails ? field.shouldRenderDetails?.(field, record) : true))
                .map((field, fieldIndex) => {
                    let fieldLabel: ReactNode = field.label;

                    if (field.detailsLabel) {
                        if (typeof field.detailsLabel === 'function') {
                            fieldLabel = field.detailsLabel(record);
                        } else {
                            fieldLabel = field.detailsLabel;
                        }
                    }

                    if (field.fields) {
                        // is a fieldset
                        return (
                            <div
                                className={classNames('p-6 border', fieldIndex + 1 < fields.length && 'mb-6')}
                                key={field.label}
                            >
                                {field.label && (
                                    <Typography.Paragraph className="text-dark-blue font-bold text-base mb-2">
                                        {field.label}
                                    </Typography.Paragraph>
                                )}
                                {innerDetailsRender(field.fields, record)}
                            </div>
                        );
                    } else if (field.type === 'list') {
                        return (
                            <div
                                key={Array.isArray(field.name) ? field.name.join('') : field.name ?? fieldIndex}
                                className="flex-col space-y-6"
                            >
                                {getDataFromFieldName<Record<string, any>>(record, field.name)?.map(
                                    (item: any, index: number) => (
                                        <div className="p-6 border" key={item.label || index}>
                                            <div className="flex justify-between items-center">
                                                {field.listItemLabel && (
                                                    <Typography.Paragraph className="text-dark-blue font-bold text-base">
                                                        {formatMessage(field.listItemLabel, { index: index + 1 })}
                                                    </Typography.Paragraph>
                                                )}
                                            </div>
                                            {field.renderDetails
                                                ? field.renderDetails(field, item, getDataFromFieldName)
                                                : innerDetailsRender(field.listFields, item)}
                                        </div>
                                    )
                                )}
                            </div>
                        );
                    } else if (field.type === 'ValueListItemSelect' || field.type === 'ValueListItemButtons') {
                        const value = getDataFromFieldName<TData>(record, field.name);
                        const values =
                            value || value?.[0]
                                ? (field.type === 'ValueListItemSelect' &&
                                      field.fieldComponentProps.mode === 'multiple') ||
                                  field.type === 'ValueListItemButtons'
                                    ? value
                                    : [value]
                                : undefined;

                        return (
                            <Descriptions
                                column={1}
                                colon={false}
                                labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                                key={Array.isArray(field.name) ? field.name.join('') : field.name ?? fieldIndex}
                            >
                                <Descriptions.Item label={fieldLabel}>
                                    {field.renderDetails
                                        ? field.renderDetails(field, record, getDataFromFieldName)
                                        : values?.map((valueListItem: ValueListItem) => (
                                              <Tag key={valueListItem.id}>
                                                  {
                                                      valueListItem.fields?.[
                                                          field.type === 'ValueListItemSelect' &&
                                                          field.fieldComponentProps?.itemLabelKey
                                                              ? field.fieldComponentProps.itemLabelKey
                                                              : Object.keys(valueListItem.fields)?.[0]
                                                      ]
                                                  }
                                              </Tag>
                                          ))}
                                </Descriptions.Item>
                            </Descriptions>
                        );
                    } else {
                        return (
                            <Descriptions
                                column={1}
                                colon={false}
                                labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                                key={Array.isArray(field.name) ? field.name.join('') : field.name ?? fieldIndex}
                            >
                                <Descriptions.Item label={fieldLabel}>
                                    {field.renderDetails
                                        ? field.renderDetails(field, record, getDataFromFieldName)
                                        : getDataFromFieldName<TData>(record, field.name)}
                                </Descriptions.Item>
                            </Descriptions>
                        );
                    }
                });
        },
        [formatMessage]
    );

    return (
        <>
            <SectionCard
                title={title}
                isLoading={!isCreating && isLoadingDetails}
                headerButtonOnClick={() => setIsForm((value) => !value)}
                headerButtonText={!isForm ? editButtonText : undefined}
                {...sectionCardProps}
            >
                {!isCreating && isError ? (
                    <ApiResult status={error.response?.status} />
                ) : isForm ? (
                    isCreating ? ( // when creating the form data is handled by the parent component to be able to edit & submit all blocks at once
                        formContent
                    ) : (
                        <Form<TForm>
                            {...formProps}
                            onFinish={onSubmit}
                            form={form}
                            initialValues={initialValueFormatter ? initialValueFormatter(details) : details}
                            colon={false}
                        >
                            {formContent}
                        </Form>
                    )
                ) : renderDetails ? (
                    renderDetails(fields, details)
                ) : (
                    innerDetailsRender(fields, details)
                )}
            </SectionCard>
        </>
    );
};

export default DetailsFormCard;
