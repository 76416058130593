import { VFC } from 'react';
import { Button, message, Popconfirm, Result, Space, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { show } from '@ebay/nice-modal-react';

import { Organization, Role } from '../../../queries/api/types';
import { useRoleList, useRoleRemove } from '../../../queries/roles';
import useQueryParams from '../../../hooks/queryParams';
import LayoutHeader from '../../../components/LayoutHeader';
import ListTitle from '../../../components/ListTitle';
import RoleFormDrawer from './RoleFormDrawer';
import Seo from '../../../components/Seo';

const RoleList: VFC = () => {
    const [queryParams, setQueryParams] = useQueryParams('roles-list');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const { data: rolesData, isLoading, isFetching, isError, error } = useRoleList({ page, search });

    const onTableChange: TableProps<Role>['onChange'] = (pagination) => {
        setQueryParams({
            page: (pagination.current ?? 1) - 1,
        });
    };

    const roleRemove = useRoleRemove({
        onError: () => {
            message.error('Une erreur est survenue pendant la suppression du rôle');
        },
        onSuccess: () => {
            message.success('Rôle supprimé avec succès');
        },
    });

    const createRoleModalVisible = () => {
        show(RoleFormDrawer);
    };

    const onClickRemoveRoleButton = (id: Role['id']) => {
        roleRemove.mutate(id);
    };

    const onClickUpdateRoleButton = (id: Role['id']) => {
        show(RoleFormDrawer, { roleId: id });
    };

    const columns: ColumnsType<Role> = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '18%',
        },
        {
            title: "Nom de l'organisation",
            dataIndex: 'organization',
            width: '18%',
            render: (obj: Organization) => {
                return obj?.name ?? <span className="text-secondary">Global</span>;
            },
        },
        {
            title: 'Nom du rôle',
            dataIndex: 'name',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            width: 140,
            render: (id: string, obj: Role) => {
                return (
                    <div className="space-x-2">
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={onClickUpdateRoleButton.bind(null, id)}
                        />

                        {obj.isRemovable && (
                            <Popconfirm
                                placement="topLeft"
                                title={'Êtes-vous sûr de supprimer ce rôle ?'}
                                onConfirm={onClickRemoveRoleButton.bind(null, id)}
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button
                                    className="actions-buttons"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    loading={false}
                                />
                            </Popconfirm>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Seo title="Rôles" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={rolesData?.totalCount} className="mb-0">
                        Rôles
                    </ListTitle>
                    <Button type="primary" size="large" onClick={createRoleModalVisible}>
                        Ajouter un rôle
                    </Button>
                </div>
            </LayoutHeader>

            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {isError ? (
                    <Result status={error?.request?.status} />
                ) : (
                    <Table<Role>
                        columns={columns}
                        rowKey="id"
                        loading={isLoading || isFetching}
                        dataSource={rolesData?.items}
                        onChange={onTableChange}
                        pagination={{
                            total: rolesData?.totalCount,
                            current: page + 1,
                            pageSize: rolesData?.pageSize,
                            hideOnSinglePage: true,
                        }}
                    />
                )}
            </Space>
        </>
    );
};

export default RoleList;
