import { FC, useCallback, useMemo } from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { Table, Space, Button, Dropdown, Menu, Input, InputProps, Tag, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { useExportPlaces, usePlaceList } from '../../../queries/places';
import { Place, PlaceType, ValueListSlug } from '../../../queries/api/types';
import ListTitle from '../../../components/ListTitle';
import Seo from '../../../components/Seo';
import { Ban, DotsHorizontal, Download, PencilAlt, Plus, Search } from '../../../components/icons';
import useQueryParams from '../../../hooks/queryParams';
import genericMessages from '../../../i18n/genericMessages';
import { sortOrderConverter } from '../../../helpers';
import { getRoute, RoutePathName } from '../../../routes';
import placeMessages, { placeTypeMessagesMap } from '../../../i18n/placeMessages';
import ValueListItemSelect from '../../../components/selects/ValueListItemSelect';
import PlaceTypeSelect from '../../../components/selects/PlaceTypeSelect';
import BasicList from '../../../components/BasicList';
import CustomerSelect from '../../../components/selects/CustomerSelect';
import PlaceSelect from '../../../components/selects/PlaceSelect';
import ApiResult from '../../../components/ApiResult';
import { errorMessage } from '../../../helpers/message';

let searchTimeout: number;

const PlacesList: FC = () => {
    const { formatMessage } = useIntl();
    const isDisabledPlacesRoute = !!useRouteMatch({
        path: getRoute(RoutePathName.placesDisabled),
        exact: true,
    });
    const [queryParams, setQueryParams] = useQueryParams('places-list');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const types = (queryParams.getAll('types') as PlaceType[]) ?? undefined;
    const groupIds = queryParams.getAll('groupIds') ?? undefined;
    const activityIds = queryParams.getAll('activityIds') ?? undefined;
    const customerIds = queryParams.getAll('customerIds') ?? undefined;
    const secondaryPlaceIds = queryParams.getAll('secondaryPlaceIds') ?? undefined;
    const {
        data: placesList,
        isLoading,
        isFetching,
        isError,
        error,
    } = usePlaceList({
        isActive: !isDisabledPlacesRoute,
        page,
        sort,
        sortOrder,
        search,
        types,
        groupIds,
        activityIds,
        customerIds,
        secondaryPlaceIds,
    });
    const onTableChange: TableProps<Place>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
            });
        },
        [setQueryParams]
    );
    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );

    const { refetch: exportPlaces } = useExportPlaces({
        enabled: false,
        onError: () => {
            errorMessage({ content: formatMessage(genericMessages.defaultExportError) });
        },
    });
    const onClickExport = () => {
        exportPlaces();
    };
    const pageTitle = formatMessage({
        id: 'places_list.title',
        defaultMessage: 'Entrepôts / Magasins',
        description: 'Place page title',
    });
    const pageTitleDisabled = formatMessage({
        id: 'places_list_disabled.title',
        defaultMessage: 'Entrepôts / Magasins inactifs',
        description: 'Place page title',
    });
    const columns: ColumnsType<Place> = useMemo(
        () => [
            {
                key: 'name',
                dataIndex: 'name',
                title: formatMessage(genericMessages.name),
                render: (_, item) => (
                    <Link to={getRoute(RoutePathName.placeDetails, { placeId: item.id })}>{item.name}</Link>
                ),
                sorter: true,
                fixed: 'left',
            },
            {
                key: 'type',
                title: formatMessage(placeMessages.type),
                render: (_, item) => formatMessage(placeTypeMessagesMap.get(item.type)!),
                filteredValue: types,
                filterDropdown: () => (
                    <div className="p-3">
                        <PlaceTypeSelect
                            onChange={(value) => setQueryParams({ types: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                key: 'group',
                title: formatMessage(genericMessages.group, { count: 1 }),
                render: (_, item) => <Tooltip title={item.group?.fields.name}>{item.group?.fields.reference}</Tooltip>,
                filteredValue: groupIds,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={groupIds}
                            valueListSlug={ValueListSlug.groups}
                            onChange={(value) => setQueryParams({ groupIds: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                key: 'address.street',
                title: formatMessage(genericMessages.address, { count: 1 }),
                render: (_, item) => item.address.street,
                sorter: true,
            },
            {
                key: 'address.zipCode',
                title: formatMessage(genericMessages.zipCode),
                render: (_, item) => item.address.zipCode,
                sorter: true,
            },
            {
                key: 'address.city',
                title: formatMessage(genericMessages.city),
                render: (_, item) => item.address.city,
                sorter: true,
            },
            {
                key: 'activity',
                title: formatMessage(placeMessages.activity),
                render: (_, item) => item.activity?.fields[Object.keys(item.activity.fields)[0]],
                filteredValue: activityIds,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={activityIds}
                            valueListSlug={ValueListSlug.placeActivityTypes}
                            onChange={(value) => setQueryParams({ activityIds: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                key: 'customers',
                title: formatMessage(placeMessages.customers),
                render: (_, item) => (
                    <BasicList inline>
                        {item.customers.map((place) => (
                            <li key={place.id}>
                                <Link to={getRoute(RoutePathName.customers, { customerId: place.id })}>
                                    <Tag>{place.name}</Tag>
                                </Link>
                            </li>
                        ))}
                    </BasicList>
                ),
                filteredValue: customerIds,
                filterDropdown: () => (
                    <div className="p-3">
                        <CustomerSelect
                            defaultValue={customerIds}
                            onChange={(value) => setQueryParams({ customerIds: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                key: 'secondaryPlaces',
                title: formatMessage(placeMessages.secondaryPlaces),
                render: (_, item) => (
                    <BasicList inline>
                        {item.secondaryPlaces?.map((place) => (
                            <li key={place.id}>
                                <Link to={getRoute(RoutePathName.placeDetails, { placeId: place.id })}>
                                    <Tag>{place.name}</Tag>
                                </Link>
                            </li>
                        ))}
                    </BasicList>
                ),
                filteredValue: secondaryPlaceIds,
                filterDropdown: () => (
                    <div className="p-3">
                        <PlaceSelect
                            defaultValue={secondaryPlaceIds}
                            onChange={(value) => setQueryParams({ secondaryPlaceIds: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
                width: 300,
            },
        ],
        [activityIds, formatMessage, groupIds, customerIds, secondaryPlaceIds, setQueryParams, types]
    );

    return (
        <>
            <Seo title={isDisabledPlacesRoute ? pageTitleDisabled : pageTitle} />
            <div className="flex justify-between items-center mb-4">
                <ListTitle
                    count={placesList?.totalCount}
                    className="mb-0 uppercase"
                    backRoute={isDisabledPlacesRoute ? getRoute(RoutePathName.places) : undefined}
                >
                    <Route path={getRoute(RoutePathName.places)} exact>
                        {pageTitle}
                    </Route>
                    <Route path={getRoute(RoutePathName.placesDisabled)} exact>
                        {pageTitleDisabled}
                    </Route>
                </ListTitle>
                <Route path={getRoute(RoutePathName.places)} exact>
                    <div className="flex items-center space-x-2">
                        <Link to={getRoute(RoutePathName.placeDetails)}>
                            <Button icon={<Plus className="text-base" />} type="primary">
                                <FormattedMessage {...genericMessages.add} tagName="span" />
                            </Button>
                        </Link>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key="disabled" icon={<Ban className="text-faded-blue text-base" />}>
                                        <Link to={getRoute(RoutePathName.placesDisabled)}>{pageTitleDisabled}</Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        key="export"
                                        icon={<Download className="text-faded-blue text-base" />}
                                        onClick={onClickExport}
                                    >
                                        <FormattedMessage {...genericMessages.exportData} />
                                    </Menu.Item>
                                    <Menu.Item
                                        key="valuesLists"
                                        icon={<PencilAlt className="text-faded-blue text-base" />}
                                    >
                                        <Link to={getRoute(RoutePathName.placesValueLists)}>
                                            <FormattedMessage {...genericMessages.valuesLists} />
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                            placement="bottomRight"
                        >
                            <Button icon={<DotsHorizontal className="text-lg" />} />
                        </Dropdown>
                    </div>
                </Route>
            </div>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Input
                    placeholder={formatMessage({
                        id: 'places_list.search_field.placeholder',
                        defaultMessage: 'Rechercher un nom, une adresse…',
                        description: 'Place page search field placeholder',
                    })}
                    onChange={onSearch}
                    defaultValue={search}
                    prefix={<Search className="text-primary text-base leading-4" />}
                    size="small"
                    allowClear
                />
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Table<Place>
                        rowKey="id"
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={placesList?.items}
                        pagination={{
                            total: placesList?.totalCount,
                            current: page + 1,
                            pageSize: placesList?.pageSize,
                            position: ['bottomCenter'],
                            hideOnSinglePage: true,
                        }}
                        scroll={{
                            x: 1440,
                            y: `calc(100vh - ${(placesList?.pageCount ?? 0) > 1 ? 283 + 64 : 283}px)`,
                            scrollToFirstRowOnChange: true,
                        }}
                        onChange={onTableChange}
                    />
                )}
            </Space>
        </>
    );
};

export default PlacesList;
