import React, { FC, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TableProps } from 'antd/lib/table';
import { Table, Tag, Space, Button, Input, InputProps, Dropdown, Menu, message } from 'antd';
import { useModal } from '@ebay/nice-modal-react';
import { SearchOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import { useUserRemove, useUserResetPassword, useUsersList } from '../../../queries/users';
import { Place, User } from '../../../queries/api/types';
import Seo from '../../../components/Seo';
import LayoutHeader from '../../../components/LayoutHeader';
import ListTitle from '../../../components/ListTitle';
import useQueryParams from '../../../hooks/queryParams';
import { addDefaultColumnSorting, getFullName, sortOrderConverter } from '../../../helpers';
import { getRoute, RoutePathName } from '../../../routes';
import ApiResult from '../../../components/ApiResult';
import { Ban, DotsHorizontal, Eye, Plus, Trash } from '../../../components/icons';
import genericMessages from '../../../i18n/genericMessages';
import { ManagementVehiclesDetailsTabs } from '../../management/vehicles';
import PlaceSelect from '../../../components/selects/PlaceSelect';
import RoleSelect from '../../../components/selects/RoleSelect';
import { errorMessage, successMessage } from '../../../helpers/message';
import userMessages, { userRoleMessagesMap } from '../../../i18n/userMessages';
import ConfirmationModal from '../../../components/ConfirmationModal';

const rowKey = (item: User) => `${item.id}`;
let searchTimeout: number;
const userRemoveConfirmationModalId = 'remove-confirmation-modal';
const userResetPasswordConfirmationModalId = 'reset-password-confirmation-modal';

const UsersList: FC = () => {
    const [queryParams, setQueryParams] = useQueryParams('users-list');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const role = queryParams.getAll('role') ?? undefined;
    const place = queryParams.getAll('place') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const {
        data: usersData,
        isLoading,
        isFetching,
        isError,
        error,
    } = useUsersList({ page, search, sort, sortOrder, place, role });
    const { formatMessage } = useIntl();
    const history = useHistory();
    const userRemoveModal = useModal(userRemoveConfirmationModalId);
    const userResetPasswordModal = useModal(userResetPasswordConfirmationModalId);

    const userRemove = useUserRemove({
        onError: () => {
            errorMessage({ content: formatMessage(userMessages.deleteError) });
        },
        onSuccess: () => {
            successMessage({ content: formatMessage(userMessages.deleteSuccess) });
            userRemoveModal.hide();
        },
    });
    const userResetPassword = useUserResetPassword({
        onMutate: () => {
            message.loading({
                key: 'resetPasswordMessage',
                content: formatMessage(userMessages.resetingPassword),
                duration: 0,
            });
        },
        onError: () => {
            errorMessage({
                key: 'resetPasswordMessage',
                content: formatMessage(userMessages.resetPasswordError),
            });
        },
        onSuccess: () => {
            successMessage({
                key: 'resetPasswordMessage',
                content: formatMessage(userMessages.resetPasswordSuccess),
            });
            userResetPasswordModal.hide();
        },
    });

    const onTableChange: TableProps<User>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;
            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
            });
        },
        [setQueryParams]
    );

    const columns = addDefaultColumnSorting<User>(sort, sortOrder, [
        {
            key: 'fullName',
            title: formatMessage(genericMessages.user, { count: 'one' }),
            render: (_, user) => <>{getFullName(user)}</>,
            sorter: true,
        },
        {
            dataIndex: ['role', 'slug'],
            title: formatMessage(genericMessages.role),
            render: (value) => {
                return <Tag>{formatMessage(userRoleMessagesMap.get(value)!)}</Tag>;
            },
            filteredValue: role,
            filterDropdown: () => (
                <div className="p-3">
                    <RoleSelect
                        onChange={(value) => setQueryParams({ role: value })}
                        mode="multiple"
                        style={{ width: 260 }}
                        size="small"
                    />
                </div>
            ),
        },
        {
            dataIndex: ['places'],
            title: formatMessage(genericMessages.warehouse, { count: 'plural' }),
            render: (_, user) => user.places?.map((place: Place) => <Tag key={place.id}>{place.name}</Tag>),
            filteredValue: place,
            filterDropdown: () => (
                <div className="p-3">
                    <PlaceSelect
                        defaultValue={place}
                        onChange={(value) => setQueryParams({ place: value })}
                        mode="multiple"
                        style={{ width: 260 }}
                        size="small"
                    />
                </div>
            ),
        },
        {
            dataIndex: 'email',
            title: formatMessage(genericMessages.email),
            sorter: true,
        },
        {
            key: 'actions',
            title: formatMessage(genericMessages.actions),
            render: (_, item) => (
                <>
                    <Link
                        to={getRoute(RoutePathName.usersDetails, {
                            userId: item.id,
                            tab: ManagementVehiclesDetailsTabs.history,
                        })}
                    >
                        <Button type="text" icon={<Eye className="text-xl text-faded-blue" />} />
                    </Link>
                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu
                                items={[
                                    {
                                        key: 'resetPassword',
                                        icon: <Ban />,
                                        onClick: () => {
                                            userResetPasswordModal.show().then(() => userResetPassword.mutate(item.id));
                                        },
                                        label: (
                                            <FormattedMessage
                                                id="operator_list.column.actions.menu.reset_password"
                                                defaultMessage="Réinitialiser le mot de passe"
                                            />
                                        ),
                                    },
                                    {
                                        key: 'deleteUser',
                                        icon: <Trash />,
                                        onClick: () => {
                                            userRemoveModal.show().then(() => userRemove.mutate(item.id));
                                        },
                                        label: (
                                            <FormattedMessage
                                                id="operator_list.column.actions.menu.delete_user"
                                                defaultMessage="Supprimer l'utilisateur"
                                            />
                                        ),
                                    },
                                ]}
                            />
                        }
                        placement="topRight"
                    >
                        <Button type="text" icon={<DotsHorizontal className="text-xl text-faded-blue" />} />
                    </Dropdown>
                </>
            ),
            width: 136,
            fixed: 'right',
        },
    ]);

    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );

    return (
        <>
            <Seo title={formatMessage(genericMessages.user, { count: 'plural' })} />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={usersData?.totalCount}>
                        {formatMessage(genericMessages.user, { count: 'plural' })}
                    </ListTitle>
                    <Button
                        className="header-button"
                        size="large"
                        type="primary"
                        onClick={() => {
                            history.push(getRoute(RoutePathName.usersDetails));
                        }}
                        icon={<Plus className="text-base" />}
                    >
                        {formatMessage(genericMessages.add)}
                    </Button>
                </div>
            </LayoutHeader>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Input
                    placeholder={formatMessage(userMessages.searchName)}
                    onChange={onSearch}
                    defaultValue={search}
                    prefix={<SearchOutlined className="text-primary text-base leading-4" />}
                    size="large"
                    allowClear
                />
            </Space>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Table<User>
                        rowKey={rowKey}
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={usersData?.items}
                        pagination={{
                            total: usersData?.totalCount,
                            current: page + 1,
                            pageSize: usersData?.pageSize,
                            position: ['bottomCenter'],
                            hideOnSinglePage: true,
                        }}
                        onChange={onTableChange}
                    />
                )}
                <ConfirmationModal
                    title={formatMessage(userMessages.confirmResetModalTitle)}
                    text={
                        <>
                            <p>{formatMessage(userMessages.confirmResetModalContent)}</p>
                            <p className="text-orange text-bold font-bold">
                                {formatMessage(userMessages.confirmResetModalHelper)}
                            </p>
                        </>
                    }
                    id={userResetPasswordConfirmationModalId}
                    isLoading={userResetPassword.isLoading}
                />
                <ConfirmationModal
                    title={formatMessage(userMessages.confirmDeleteModalTitle)}
                    text={
                        <>
                            <p>{formatMessage(userMessages.confirmDeleteModalContent)}</p>
                            <p className="text-orange text-bold font-bold">
                                {formatMessage(userMessages.confirmDeleteModalHelper)}
                            </p>
                        </>
                    }
                    id={userRemoveConfirmationModalId}
                />
            </Space>
        </>
    );
};

export default UsersList;
