import { VFC } from 'react';
import { useIntl } from 'react-intl';

import TagWithDot, { TagWithDotProps } from '../../../components/TagWithDot';
import { classNames } from '../../../helpers';
import taskGroupMessages, { taskGroupCaptionMessagesMap } from '../../../i18n/taskGroupMessages';
import { TaskGroup, TaskGroupStatus, TaskGroupTransferStatus } from '../../../queries/api/types';
import { useAuth } from '../../../context/AuthContext';

const colorsByStatus = {
    [TaskGroupStatus.toPlan]: 'orange',
    [TaskGroupStatus.readyToStart]: 'dark-blue',
    [TaskGroupStatus.inProgress]: 'duck-blue',
    [TaskGroupStatus.done]: 'green',
    [TaskGroupStatus.toClose]: 'red',
};

interface TaskGroupStatusTagProps extends Omit<TagWithDotProps, 'color'> {
    taskGroup: TaskGroup;
}

const TaskGroupStatusTag: VFC<TaskGroupStatusTagProps> = ({ taskGroup, ...props }) => {
    const { formatMessage } = useIntl();
    const { user } = useAuth();

    const showTransferedStatus =
        taskGroup.transferStatus === TaskGroupTransferStatus.interGroup &&
        !user?.places?.map((place) => place.id).includes(taskGroup.startingPlace.id);

    return (
        <TagWithDot
            {...props}
            color={showTransferedStatus ? 'faded-blue' : colorsByStatus[taskGroup.status]}
            className={classNames('font-bold', props.className)}
            size={props.size || 'small'}
        >
            {formatMessage(
                showTransferedStatus
                    ? taskGroupMessages.transferred
                    : taskGroupCaptionMessagesMap.get(taskGroup.status)!,
                { unitDisplay: undefined }
            )}{' '}
            {!!taskGroup.vehicle?.transporter?.id &&
                `(${formatMessage(taskGroupMessages.external, { unitDisplay: 'narrow' })})`}
        </TagWithDot>
    );
};

export default TaskGroupStatusTag;
