import { createContext, useState, useContext, FC, useMemo } from 'react';

import { Language } from '../i18n/lang';

const availableLangs = Object.values(Language);
export const defaultLanguage = Language.fr;

const initialLanguage = availableLangs.includes(localStorage.getItem('locale') as Language)
    ? (localStorage.getItem('locale') as Language)
    : defaultLanguage;

interface LocaleContextTypes {
    locale: Language;
    setLocale: React.Dispatch<React.SetStateAction<Language>>;
}

export const LocaleContext = createContext<LocaleContextTypes | null>(null);
LocaleContext.displayName = 'LocaleContext';

export const LocaleProvider: FC = ({ children }) => {
    const [locale, setLocale] = useState(initialLanguage);

    const value = useMemo(
        () => ({
            locale,
            setLocale,
        }),
        [locale, setLocale]
    );

    return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};

export const useLocaleContext = () => {
    const context = useContext(LocaleContext);

    if (!context) {
        throw new Error('useLocaleContext must be used within an LocaleProvider');
    }

    return context;
};
