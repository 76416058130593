import { Badge, Button, Col, DrawerProps, Row, Spin, Tabs } from 'antd';
import { antdDrawer, create, useModal } from '@ebay/nice-modal-react';
import { useIntl } from 'react-intl';
import { useCallback, useMemo, useState } from 'react';
import Table, { ColumnsType, TableProps } from 'antd/lib/table';
import dayjs from 'dayjs';

import { TaskGroup, TaskGroupAssignmentProposal } from '../../../queries/api/types';
import CustomDrawer from '../../../components/CustomDrawer';
import { Check, XCircle } from '../../../components/icons';
import genericMessages from '../../../i18n/genericMessages';
import taskGroupMessages from '../../../i18n/taskGroupMessages';
import ListTitle from '../../../components/ListTitle';
import { useListAutoPlanificationProposals, useApplyAutoPlanificationProposals } from '../../../queries/taskGroups';
import showTaskGroupAutoPlanificationModal from './TaskGroupAutoPlanificationModal';
import TaskGroupDetailsDrawer from './TaskGroupDetailsDrawer';
import { getFullName, sortOrderConverter } from '../../../helpers';
import { TaskGroupsAutoPlanificationTabs } from '.';
import { PaginationQuery } from '../../../queries/api';
import { errorMessage, successMessage } from '../../../helpers/message';
import ApplyAutoPlanProposalsErrorModal from './ApplyAutoPlanProposalsErrorModal';

type Props = DrawerProps &
    Record<string, any> & {
        proposals: Array<TaskGroupAssignmentProposal['id']>;
    };

const AutoPlanificationDrawer = create<Props>(({ proposals, ...props }) => {
    const { formatMessage } = useIntl();
    const modal = useModal();
    const applyAutoPlanProposalsErrorModal = useModal(ApplyAutoPlanProposalsErrorModal);
    const { mutateAsync: applyAutoPlanificationProposals, isLoading: isApplying } =
        useApplyAutoPlanificationProposals();
    const detailsDrawer = useModal(TaskGroupDetailsDrawer);
    const hideModal = useCallback(async () => await modal.hide(), [modal]);
    const [activeTab, setActiveTab] = useState(TaskGroupsAutoPlanificationTabs.completeProposals);

    const [paginationConfig, setPaginationConfig] = useState<{
        [TaskGroupsAutoPlanificationTabs.completeProposals]: PaginationQuery;
        [TaskGroupsAutoPlanificationTabs.incompleteProposals]: PaginationQuery;
    }>({
        [TaskGroupsAutoPlanificationTabs.completeProposals]: {
            page: 0,
            pageSize: 50,
        },
        [TaskGroupsAutoPlanificationTabs.incompleteProposals]: {
            page: 0,
            pageSize: 50,
        },
    });

    const [selectedSuggestionIds] = useState<Array<TaskGroup['id']>>([]);

    const { data: autoPlanificationCompleteProposals, isLoading: fetchingAutoPlanificationSuccessfulResults } =
        useListAutoPlanificationProposals(
            {
                proposals,
                successful: true,
                page: paginationConfig[TaskGroupsAutoPlanificationTabs.completeProposals].page,
                pageSize: paginationConfig[TaskGroupsAutoPlanificationTabs.completeProposals].pageSize,
                sort: paginationConfig[TaskGroupsAutoPlanificationTabs.completeProposals].sort,
                sortOrder: paginationConfig[TaskGroupsAutoPlanificationTabs.completeProposals].sortOrder,
            },
            {
                enabled: proposals.length > 0,
                refetchOnWindowFocus: false,
                onSuccess: (data) => {
                    setSelectedProposalIds(data.items.map((proposal) => proposal.id));
                    if (data.items.length === 0) {
                        setActiveTab(TaskGroupsAutoPlanificationTabs.incompleteProposals);
                    }
                },
            }
        );

    const { data: autoPlanificationIncompleteProposals, isLoading: fetchingAutoPlanificationUnsuccessfulResults } =
        useListAutoPlanificationProposals(
            {
                proposals,
                successful: false,
                page: paginationConfig[TaskGroupsAutoPlanificationTabs.incompleteProposals].page,
                pageSize: paginationConfig[TaskGroupsAutoPlanificationTabs.incompleteProposals].pageSize,
                sort: paginationConfig[TaskGroupsAutoPlanificationTabs.incompleteProposals].sort,
                sortOrder: paginationConfig[TaskGroupsAutoPlanificationTabs.incompleteProposals].sortOrder,
            },
            {
                enabled: proposals.length > 0,
                refetchOnWindowFocus: false,
            }
        );

    const columns: ColumnsType<TaskGroupAssignmentProposal> = useMemo(
        () => [
            {
                key: 'reference',
                title: formatMessage({
                    id: 'operators_list.column.ot_number',
                    defaultMessage: 'N° OT',
                }),
                render: (_, proposal) => (
                    <Button
                        type="text"
                        className="custom-btn-text font-bold blue-link"
                        onClick={() => {
                            detailsDrawer.show({ taskGroupId: proposal.taskGroup.id });
                        }}
                    >
                        <span className="t-o-ellipsis" style={{ maxWidth: 160 }}>
                            {proposal.taskGroup.reference}
                        </span>
                    </Button>
                ),
                sorter: true,
                fixed: 'left',
                width: 10,
            },
            {
                dataIndex: 'date',
                key: 'date',
                title: formatMessage(genericMessages.date),
                render: (_, proposal) => dayjs(proposal.taskGroup.date).format('DD/MM/YY'),
                sorter: true,
                width: 10,
            },
            {
                key: 'customerName',
                title: formatMessage(genericMessages.customer, { count: 1 }),
                render: (_, proposal) => proposal.taskGroup.customer.name,
                sorter: true,
                width: 15,
            },
            {
                key: 'places',
                title: formatMessage(genericMessages.place, { count: 0 }),
                render: (_, proposal) => proposal.taskGroup.startingPlace.name,
                width: 20,
            },
            {
                key: 'plateNumber',
                title: formatMessage(taskGroupMessages.suggestedVehicle),
                render: (_, proposal) => proposal.vehicle?.plateNumber || <XCircle className="text-xl text-red" />,
                width: 15,
            },
            {
                key: 'operator',
                title: formatMessage(taskGroupMessages.suggestedOperator),
                render: (_, proposal) =>
                    proposal.operator?.id
                        ? getFullName(proposal.operator)
                        : proposal.vehicle?.transporter?.companyName || <XCircle className="text-xl text-red" />,
                width: 20,
            },
        ],
        [detailsDrawer, formatMessage]
    );

    const [selectAll, setSelectAll] = useState(true);
    const [excludedProposalIds, setExcludedProposalIds] = useState<Array<TaskGroupAssignmentProposal['id']>>([]);
    const [selectedProposalIds, setSelectedProposalIds] = useState<Array<TaskGroupAssignmentProposal['id']>>([]);
    const proposalIdsOnCurrentPage = autoPlanificationCompleteProposals?.items.map((proposal) => proposal.id) || [];

    // Compute excluded proposal ids if "selectAll" mode is active.
    const onSelect = (taskGroupId: TaskGroup['id'], select: boolean) => {
        if (selectAll) {
            if (!select) {
                setExcludedProposalIds([...excludedProposalIds, taskGroupId]);
            } else {
                setExcludedProposalIds((prevExcludedIds) => prevExcludedIds.filter((id) => id !== taskGroupId));
            }
        }
    };

    // Handle table full page selection when "selectAll" mode is active. (Full page selection does not trigger table onSelect event)
    const onFullPageSelection = (mode: 'select' | 'unselect', newSelection: Array<TaskGroup['id']>) => {
        const pageSelection = newSelection.filter((id) => proposalIdsOnCurrentPage?.includes(id));
        const excludedIdsOnOtherPages = excludedProposalIds.filter((id) => !proposalIdsOnCurrentPage.includes(id));
        if (mode === 'unselect') {
            setExcludedProposalIds([...proposalIdsOnCurrentPage, ...pageSelection]);
        } else {
            setExcludedProposalIds([...excludedIdsOnOtherPages]);
        }
    };

    const onTableChange: TableProps<TaskGroupAssignmentProposal>['onChange'] = (pagination, _, sorter) => {
        const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;
        const newPaginationConfig = Object.assign({}, paginationConfig);
        newPaginationConfig[activeTab] = {
            ...newPaginationConfig[activeTab],
            page: (pagination.current ?? 1) - 1,
            sort: sortObj.column?.key ? `computedProperties.${sortObj.column?.key}` : undefined,
            sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
        };
        setPaginationConfig(newPaginationConfig);
    };

    const onConfirmPlanification = async () => {
        applyAutoPlanificationProposals(
            {
                payload: {
                    forAll: selectAll,
                    proposals: (selectAll ? excludedProposalIds : selectedProposalIds).map((proposal) => ({
                        id: proposal,
                    })),
                },
            },
            {
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error?.response?.status,
                        }),
                    });
                },
                onSuccess: (response) => {
                    if (response.error.length > 0) {
                        applyAutoPlanProposalsErrorModal.show({ taskGroups: response.error }).then(() => {
                            applyAutoPlanProposalsErrorModal.hide();
                        });
                    } else {
                        successMessage({ content: formatMessage(taskGroupMessages.planificationSuccessful) });
                    }
                    hideModal();
                },
            }
        );
    };

    const fetchingResults =
        activeTab === TaskGroupsAutoPlanificationTabs.completeProposals
            ? fetchingAutoPlanificationSuccessfulResults
            : fetchingAutoPlanificationUnsuccessfulResults;

    const autoPlanificationResults = {
        [TaskGroupsAutoPlanificationTabs.completeProposals]: autoPlanificationCompleteProposals,
        [TaskGroupsAutoPlanificationTabs.incompleteProposals]: autoPlanificationIncompleteProposals,
    };

    return (
        <CustomDrawer
            className="h-screen"
            width={1138}
            title={formatMessage({
                id: 'task_group.auto_plan.title',
                defaultMessage: 'planification automatique',
                description: 'auto plan drawer title',
            })}
            {...props}
            {...antdDrawer(modal)}
            maskClosable={false}
            footerStyle={{ padding: '16px 24px' }}
            footer={
                <>
                    {activeTab === TaskGroupsAutoPlanificationTabs.completeProposals && (
                        <Row gutter={16}>
                            <Col span={12}>
                                <Button onClick={hideModal} className="text-blue" block>
                                    {formatMessage({
                                        id: 'task_group.auto_planification.drawer.footer.cancel_planification',
                                        defaultMessage: 'Annuler la planification',
                                        description: 'auto planification drawer footer cancel planification',
                                    })}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    onClick={() => {
                                        showTaskGroupAutoPlanificationModal(
                                            selectedSuggestionIds?.length || 0,
                                            onConfirmPlanification
                                        );
                                    }}
                                    type="primary"
                                    block
                                    icon={<Check />}
                                    disabled={
                                        (!selectAll && selectedProposalIds.length === 0) ||
                                        autoPlanificationCompleteProposals?.items.length === 0
                                    }
                                    loading={isApplying}
                                >
                                    {formatMessage({
                                        id: 'task_group.auto_planification.drawer.footer.validate_planification',
                                        defaultMessage: 'Valider les propositions',
                                        description: 'auto planification drawer footer validate planification',
                                    })}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </>
            }
        >
            <div className="h-full">
                {fetchingResults ? (
                    <div className="flex justify-center items-center h-full">
                        <Spin tip={formatMessage(taskGroupMessages.planificationInProgess)} size="large" spinning />
                    </div>
                ) : (
                    <>
                        <ListTitle
                            count={
                                autoPlanificationResults[TaskGroupsAutoPlanificationTabs.completeProposals]
                                    ?.totalCount || 0
                            }
                            className="mb-0 uppercase"
                        >
                            {formatMessage({
                                id: 'task_group.auto_planification.drawer.list_title',
                                defaultMessage: 'Tournées à planifier',
                                description: 'auto planification drawer list title',
                            })}
                        </ListTitle>
                        <Tabs
                            onChange={(value) => setActiveTab(value as TaskGroupsAutoPlanificationTabs)}
                            defaultActiveKey={activeTab}
                            activeKey={activeTab}
                            className="h-full"
                        >
                            {Object.values(TaskGroupsAutoPlanificationTabs).map((proposal) => {
                                return (
                                    <Tabs.TabPane
                                        tab={
                                            <div className="flex justify-between items-center gap-1">
                                                <span className="block">
                                                    {formatMessage(taskGroupMessages[proposal])}
                                                </span>
                                                <Badge
                                                    count={autoPlanificationResults[proposal]?.totalCount}
                                                    showZero
                                                    overflowCount={999999}
                                                />
                                            </div>
                                        }
                                        disabled={autoPlanificationResults[proposal]?.totalCount === 0}
                                        key={proposal}
                                    >
                                        {activeTab === TaskGroupsAutoPlanificationTabs.incompleteProposals && (
                                            <div className="mb-4 font-bold text-base">
                                                {`${formatMessage(taskGroupMessages.missingOperators, {
                                                    count: autoPlanificationIncompleteProposals?.missingOperators,
                                                })} / ${formatMessage(taskGroupMessages.missingVehicles, {
                                                    count: autoPlanificationIncompleteProposals?.missingVehicles,
                                                })}`}
                                            </div>
                                        )}
                                        <Table<TaskGroupAssignmentProposal>
                                            rowKey="id"
                                            columns={columns}
                                            dataSource={autoPlanificationResults[activeTab]?.items}
                                            onChange={onTableChange}
                                            pagination={{
                                                total: autoPlanificationResults[activeTab]?.totalCount,
                                                current: Number(paginationConfig[activeTab].page || 0) + 1,
                                                pageSize: autoPlanificationResults[activeTab]?.pageSize,
                                                position: ['bottomCenter'],
                                                hideOnSinglePage: true,
                                                showSizeChanger: false,
                                            }}
                                            scroll={{
                                                x: 968,
                                                y: 'calc(100vh - 208px)',
                                                scrollToFirstRowOnChange: true,
                                            }}
                                            size="middle"
                                            rowSelection={
                                                activeTab === TaskGroupsAutoPlanificationTabs.completeProposals
                                                    ? {
                                                          onSelect: (taskGroup, select) => {
                                                              onSelect(taskGroup.id, select);
                                                          },
                                                          columnWidth: 5,
                                                          preserveSelectedRowKeys: true,
                                                          onChange: (selectedRowKeys, _, info) => {
                                                              if (info.type === 'all') {
                                                                  setSelectAll((selectAll) => !selectAll);
                                                                  onFullPageSelection(
                                                                      selectedRowKeys.length <
                                                                          selectedProposalIds.length
                                                                          ? 'unselect'
                                                                          : 'select',
                                                                      selectedRowKeys as Array<TaskGroup['id']>
                                                                  );
                                                              }
                                                              setSelectedProposalIds(
                                                                  selectedRowKeys as Array<TaskGroup['id']>
                                                              );
                                                          },
                                                          selectedRowKeys:
                                                              TaskGroupsAutoPlanificationTabs.completeProposals
                                                                  ? selectedProposalIds
                                                                  : undefined,
                                                      }
                                                    : undefined
                                            }
                                        />
                                    </Tabs.TabPane>
                                );
                            })}
                        </Tabs>
                    </>
                )}
            </div>
        </CustomDrawer>
    );
});

export default AutoPlanificationDrawer;
