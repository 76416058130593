/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.5 9h5m-5 3.333h5m1.667 4.167H2.834c-.92 0-1.667-.746-1.667-1.667V3.167c0-.92.746-1.667 1.667-1.667h4.654c.221 0 .433.088.59.244l4.512 4.512a.833.833 0 0 1 .244.59v7.987c0 .92-.746 1.667-1.667 1.667Z"
            stroke="#5C758A"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgDocumentText: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-document-text', props.className)} />
));
export default SvgDocumentText;
