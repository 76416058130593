import { useMemo, VFC } from 'react';
import { Select, SelectProps } from 'antd';
import { useIntl } from 'react-intl';

import { TaskGroupPreBillingStatus } from '../../queries/api/types';
import { preBillingStatusMessageMap } from '../../i18n/preBillingMessages';

const PreBillingStatusSelect: VFC<SelectProps<TaskGroupPreBillingStatus>> = (props) => {
    const { formatMessage } = useIntl();

    const options: Array<{
        label: string;
        value: TaskGroupPreBillingStatus;
    }> = useMemo(
        () =>
            Object.values(TaskGroupPreBillingStatus).map((key) => ({
                label: formatMessage(preBillingStatusMessageMap.get(key)!),
                value: TaskGroupPreBillingStatus[key],
            })),
        [formatMessage]
    );

    return (
        <Select<TaskGroupPreBillingStatus>
            placeholder={formatMessage({
                id: 'pre_billing_status_select.placeholder',
                defaultMessage: 'Tous les statuts',
                description: 'Select placeholder',
            })}
            options={options}
            showSearch={false}
            showArrow
            {...props}
        />
    );
};

export default PreBillingStatusSelect;
