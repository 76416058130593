import api from './apiClient';
import { downloadResponseAsBlob, SearchPaginationQuery } from '.';
import { Transporter, ListResponse, ValueListItem, TransporterService } from './types';

// Controller Interface
export type TransporterCreatePayload = Omit<Transporter, 'id' | 'passkey' | 'groups'> & {
    groups?: Array<ValueListItem['id']> | ValueListItem[];
};

export type TransporterUpdatePayload = Omit<Transporter, 'workingHolidays' | 'groups' | 'services'> & {
    workingHolidays?: Array<ValueListItem['id']> | ValueListItem[];
    groups?: Array<ValueListItem['id']> | ValueListItem[];
    services?: Array<
        Omit<TransporterService, 'type' | 'vehicleType'> & {
            type?: ValueListItem | ValueListItem['id'];
            vehicleType?: ValueListItem | ValueListItem['id'];
        }
    >;
};

export type TransporterIdPayload = Transporter['id'];

export type TransporterListPayload = SearchPaginationQuery & {
    isActive?: boolean;
    groupIds?: Array<ValueListItem['id']>;
    contractTypeIds?: Array<ValueListItem['id']>;
};
export type TransporterListResponse = ListResponse<Transporter>;

// Routes
export const list = async (payload?: TransporterListPayload) => {
    return await api
        .get<TransporterListResponse>(`/transporters`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: TransporterCreatePayload) => {
    return await api.post(`/transporters`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: TransporterUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<Transporter>(`/transporters/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: TransporterIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Transporter>(`/transporters/${id}`).then((response) => response?.data);
};

export const remove = async (id?: TransporterIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/transporters/${id}`).then((response) => response?.data);
};

export const exportTransporters = async () => {
    return await api
        .get<Blob>('/exportAll/transporters', {
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);
            return undefined;
        });
};
