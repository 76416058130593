import { selectUnit } from '@formatjs/intl-utils';
import { IntlShape } from 'react-intl';

import { RoleSlug } from '../queries/api/types';
import formMessages from './formMessages';

export const defaultErrorMessage =
    'Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard ou contacter un administrateur';

export const defaultErrorMessageWithStatus = (status?: string | number) => `${defaultErrorMessage} (${status ?? 0})`;

export const requiredFieldsText = '* Champs obligatoires';
export const requiredFieldText = '* Champ obligatoire';

export const getRequiredRule = (formatMessage: IntlShape['formatMessage']) => ({
    required: true,
    message: formatMessage(formMessages.requiredField),
});

export const formatNumber = (value?: number, options?: Intl.NumberFormatOptions) => {
    if (value === undefined) {
        return 0;
    }

    return Intl.NumberFormat('fr-FR', options).format(value);
};

export const formatDate = (value?: string | Date | null, options?: Intl.DateTimeFormatOptions) => {
    if (value === undefined || value === null) {
        return '—';
    }

    const date = value instanceof Date ? value : new Date(value);

    return Intl.DateTimeFormat(
        'fr-FR',
        options ?? {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        }
    ).format(date);
};

export const formatTime = (val?: string | Date | null, options?: Intl.RelativeTimeFormatOptions) => {
    if (val === undefined || val === null) {
        return '—';
    }

    const date = val instanceof Date ? val : new Date(val);

    return new Intl.DateTimeFormat(
        'fr-FR',
        options ?? {
            hour: 'numeric',
            minute: 'numeric',
        }
    ).format(date);
};

export const formatRelativeTime = (val?: string | Date | null, options?: Intl.RelativeTimeFormatOptions) => {
    if (val === undefined || val === null) {
        return '—';
    }

    const date = val instanceof Date ? val : new Date(val);
    const { value, unit } = selectUnit(Date.now() - (Date.now() - date.getTime()));

    return new Intl.RelativeTimeFormat(
        'fr-FR',
        options ?? {
            numeric: 'auto',
            style: 'short',
        }
    ).format(value, unit);
};

export const formatPrice = (amount: number | undefined, options?: Intl.NumberFormatOptions) => {
    if (amount === undefined) {
        return '—';
    } else {
        return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', ...options }).format(amount);
    }
};

export const translateRoleSlug = (slug?: RoleSlug) => {
    switch (slug) {
        case RoleSlug.superAdmin:
            return 'Super admin';
        case RoleSlug.owner:
            return 'Propriétaire';
        case RoleSlug.admin:
            return 'Administrateur';
        case RoleSlug.user:
            return 'Utilisateur';

        default:
            return slug ?? '—';
    }
};
