import dayjs from 'dayjs';

import {
    DaySlots,
    OperatorPlanning,
    OperatorShiftStatus,
    TaskGroup,
    TaskGroupStatus,
    ValueListItem,
} from '../../../queries/api/types';

type CalendarEvent<T> = Array<T & { start: string; end: string }>;

const colorsPerStatus = {
    [OperatorShiftStatus.holiday]: '#bfbfbf', // @grey-6
    [OperatorShiftStatus.unworked]: '#e5322c', // @red
    [OperatorShiftStatus.planned]: '#5c758a', // @faded-blue
    [OperatorShiftStatus.unavailable]: '#f1eb09', // @yellow
    [OperatorShiftStatus.worked]: '#16a1b8', // @duck-blue
    [OperatorShiftStatus.finished]: '#16a1b8', // @duck-blue
    [OperatorShiftStatus.inProgress]: '#16a1b8', // @duck-blue
};

const taskGroupColorsPerStatus = (taskGroup: TaskGroup): string => {
    if (taskGroup.status === TaskGroupStatus.readyToStart) {
        return dayjs().isAfter(taskGroup.date, 'day') ? '#e5322c' : '#5c758a'; // @faded-blue
    }
    if ([TaskGroupStatus.done, TaskGroupStatus.inProgress, TaskGroupStatus.toClose].includes(taskGroup.status)) {
        return '#16a1b8'; // @duck-blue
    }
    return '';
};

export interface GenericEvent {
    date: string;
    startDate: string;
    endDate?: string;
    mergedEvents?: Array<Omit<GenericEvent, 'mergedEvents'>>;
}

export interface MergedEvent extends Omit<GenericEvent, 'mergedEvents' | 'startDate'> {
    id: string;
    startDate?: string;
    type?: ValueListItem;
    status?: TaskGroupStatus;
    duration?: number;
}

function getDatesRangesAbsenceEvents<T extends GenericEvent>(
    events: T[] = [],
    additionalCondition?: keyof T | undefined
) {
    const eventsSortedByDate = [...events].sort((a, b) =>
        a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0
    );
    const ranges = eventsSortedByDate.reduce((acc: CalendarEvent<T>, current) => {
        if (acc.length === 0) {
            acc.push({
                ...current,
                start: current.startDate ?? dayjs(current.date).startOf('day').toISOString(),
                end: current.endDate ?? dayjs(current.date).endOf('day').toISOString(),
            });
        }

        const currentRange = acc[acc.length - 1];
        if (!currentRange.mergedEvents) {
            currentRange.mergedEvents = [current];
        }
        const endDate =
            currentRange.endDate ??
            dayjs(currentRange.mergedEvents?.[currentRange.mergedEvents.length - 1].date).toISOString();
        const currentDate = current.startDate ?? dayjs(current.date).startOf('day').toISOString();
        if (
            endDate &&
            (dayjs(currentDate).diff(endDate, 'day', true) >= 0.5 ||
                (additionalCondition && currentRange[additionalCondition] !== current[additionalCondition]))
        ) {
            acc.push({
                ...current,
                start: current.startDate ?? dayjs(current.startDate || current.date).toISOString(),
                end: current.endDate ?? dayjs(current.startDate || current.date).toISOString(),
                mergedEvents: [current],
            });
        } else {
            currentRange.mergedEvents.push(current);
            currentRange.end = current.endDate ?? dayjs(current.endDate).toISOString();
            currentRange.endDate = current.endDate ?? dayjs(current.endDate).toISOString();
        }
        return acc;
    }, []);
    return ranges;
}

function getDatesRangesTaskGroupEvents(events: Array<TaskGroup & GenericEvent> = []) {
    const eventsSortedByDate = [...events].sort((a, b) =>
        a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0
    );
    const ranges = eventsSortedByDate.reduce((acc: CalendarEvent<TaskGroup & GenericEvent>, current) => {
        if (acc.length === 0) {
            acc.push({
                ...current,
                start: current.startDate ?? dayjs(current.date).startOf('day').toISOString(),
                end: current.endDate ?? dayjs(current.startDate).endOf('day').toISOString(),
            });
        }

        const currentRange = acc[acc.length - 1];
        if (!currentRange.mergedEvents) {
            currentRange.mergedEvents = [current];
        }
        const endDate =
            currentRange.endDate ??
            dayjs(currentRange.mergedEvents?.[currentRange.mergedEvents.length - 1].date)
                .startOf('day')
                .toISOString();
        const currentDate = current.startDate ?? dayjs(current.date).startOf('day').toISOString();
        if (
            endDate &&
            (dayjs(currentDate).diff(endDate, 'day') >= 0 ||
            (currentRange.status === TaskGroupStatus.readyToStart && !dayjs().isAfter(current.date, 'day'))
                ? dayjs(current.date).diff(currentRange.date) !== 0
                : currentRange.status !== current.status)
        ) {
            acc.push({
                ...current,
                start:
                    current.startDate ??
                    dayjs(current.startDate || current.date)
                        .startOf('day')
                        .toISOString(),
                end:
                    current.endDate ??
                    dayjs(current.startDate || current.date)
                        .endOf('day')
                        .toISOString(),
                mergedEvents: [current],
            });
        } else {
            currentRange.mergedEvents.push(current);
            currentRange.end = current.endDate ?? dayjs(current.startDate).endOf('day').toISOString();
            currentRange.endDate = current.endDate ?? dayjs(current.startDate).endOf('day').toISOString();
        }
        return acc;
    }, []);
    return ranges;
}

const getTaskGroupWorkedTime = (taskGroup: TaskGroup): number => {
    if (!taskGroup.startDate) {
        return 0;
    }
    if (!taskGroup.endDate) {
        return dayjs().diff(taskGroup.startDate, 'minute');
    }
    return dayjs(taskGroup.endDate).diff(taskGroup.startDate, 'minute');
};

// absence formatter
const OperatorPlanningFormatter = (operatorsPlanning: OperatorPlanning[]) => {
    const absences = operatorsPlanning
        .map((operatorPlanning) => {
            return getDatesRangesAbsenceEvents(
                operatorPlanning.absences.map((absence) => ({
                    ...absence,
                    resourceId: operatorPlanning.operator.id,
                    date: absence.startDate,
                    start:
                        absence.preferredSlot === DaySlots.evening
                            ? dayjs(absence.startDate).set('hour', 11).endOf('hour').toISOString()
                            : absence.startDate,
                    startDate:
                        absence.preferredSlot === DaySlots.evening
                            ? dayjs(absence.startDate).set('hour', 11).endOf('hour').toISOString()
                            : absence.startDate,
                    end:
                        absence.preferredSlot === DaySlots.morning
                            ? dayjs(absence.endDate).set('hour', 12).startOf('hour').toISOString()
                            : absence.endDate,
                    endDate:
                        absence.preferredSlot === DaySlots.morning
                            ? dayjs(absence.endDate).set('hour', 12).startOf('hour').toISOString()
                            : absence.endDate,
                    backgroundColor: colorsPerStatus.unavailable,
                })),
                'type'
            );
        })
        .flat();

    // taskGroups formatter
    const formattedTaskGroupsEvent = operatorsPlanning
        .map((operatorPlanning) => {
            const workedTimeByDate = operatorPlanning.taskGroups.reduce(
                (
                    acc: {
                        [key: string]: number;
                    },
                    taskGroup
                ) => {
                    const taskGroupDate = dayjs(taskGroup.date).startOf('day').toISOString();
                    if (acc[taskGroupDate]) {
                        acc[taskGroupDate] += getTaskGroupWorkedTime(taskGroup);
                    } else {
                        acc[taskGroupDate] = getTaskGroupWorkedTime(taskGroup);
                    }
                    return acc;
                },
                {}
            );
            const cleanedTaskGroups = operatorPlanning.taskGroups
                .sort((a, b) => {
                    if (dayjs(b.date).diff(a.date, 'day') !== 0) {
                        return dayjs(b.date).diff(a.date);
                    } else {
                        if (b.status !== TaskGroupStatus.readyToStart && a.status === TaskGroupStatus.readyToStart) {
                            return -1;
                        }
                        if (a.status !== TaskGroupStatus.readyToStart && b.status === TaskGroupStatus.readyToStart) {
                            return 1;
                        }
                        if (
                            ![TaskGroupStatus.toClose, TaskGroupStatus.inProgress].includes(b.status) &&
                            [TaskGroupStatus.toClose, TaskGroupStatus.inProgress].includes(a.status)
                        ) {
                            return -1;
                        }
                        if (
                            ![TaskGroupStatus.toClose, TaskGroupStatus.inProgress].includes(a.status) &&
                            [TaskGroupStatus.toClose, TaskGroupStatus.inProgress].includes(b.status)
                        ) {
                            return 1;
                        }
                        if (!b.endDate) {
                            return -1;
                        }
                        return dayjs(a.endDate).diff(b.endDate);
                    }
                })
                .reduce((acc: TaskGroup[], current) => {
                    const currentDate = dayjs(current.date).startOf('day').toISOString();
                    if (!acc.some((taskGroup) => dayjs(taskGroup.date).isSame(currentDate, 'day'))) {
                        acc.push({ ...current, date: currentDate });
                    }
                    return acc;
                }, []);
            const mergedEvents = getDatesRangesTaskGroupEvents(
                cleanedTaskGroups.map((taskGroup) => ({
                    ...taskGroup,
                    startDate: taskGroup.startDate || taskGroup.date,
                    workedTime: workedTimeByDate[taskGroup.date],
                }))
            );
            const formattedEvents = mergedEvents.map((taskGroup) => ({
                ...taskGroup,
                taskGroupStatus: taskGroup.status,
                resourceId: operatorPlanning.operator.id,
                start: dayjs(taskGroup.startDate || taskGroup.date)
                    .startOf('day')
                    .toISOString(),
                end: dayjs(taskGroup.endDate || taskGroup.date)
                    .endOf('day')
                    .toISOString(),
                backgroundColor: taskGroupColorsPerStatus(taskGroup),
            }));

            return formattedEvents;
        })
        .flat();
    return [...absences, ...formattedTaskGroupsEvent];
};

export default OperatorPlanningFormatter;
