import dayjs from 'dayjs';
import { ReactNode, VFC } from 'react';
import { useIntl } from 'react-intl';
import { Alert } from 'antd';

import { Issue, IssueCommentType } from '../../../../queries/api/types';
import issueMessages from '../../../../i18n/issueMessages';
import { useAuth } from '../../../../context/AuthContext';

interface IssueCardCommentsProps {
    commentType: IssueCommentType;
    issue: Issue;
    sectionTitle?: ReactNode;
}

const IssueCardComments: VFC<IssueCardCommentsProps> = ({ commentType, issue, sectionTitle }) => {
    const { formatMessage } = useIntl();
    const { user } = useAuth();

    const comments = issue.comments?.filter((comment) => comment.type === commentType);

    if (comments.length === 0) return null;

    return (
        <>
            <Alert
                className="bg-grey-light-blue border-0 px-4 py-3 text-sm font-semibold"
                message={
                    <>
                        <p className="text-faded-blue mb-2">{sectionTitle}</p>

                        <ul className="flex flex-col gap-3 list-style-none p-0">
                            {comments?.map((comment) => (
                                <li key={comment.id}>
                                    {comment.user?.id === user?.id ? (
                                        <div className="py-1 px-2 bg-white rounded">
                                            <p className="text-sm text-blue font-semibold mb-0">
                                                {formatMessage(issueMessages.userComment)}
                                            </p>
                                            <p className="text-sm font-medium text-dark-blue">{comment.text}</p>
                                        </div>
                                    ) : (
                                        <>
                                            <p className="text-sm text-faded-blue font-semibold mb-2">
                                                •{' '}
                                                {formatMessage(issueMessages.otherUserComment, {
                                                    otherUser: `${String(user?.firstName)} ${String(user?.lastName)}`,
                                                    date: dayjs(issue.date).format('DD/MM/YYYY'),
                                                })}
                                            </p>
                                            <div className="p-1 bg-white rounded">
                                                <p className="text-sm font-medium text-dark-blue">{comment.text}</p>
                                            </div>
                                        </>
                                    )}
                                    <div
                                        style={{
                                            height: 0,
                                            width: 0,
                                            borderLeft: '12px solid transparent',
                                            borderRight: '12px solid transparent',
                                            borderTop: '12px solid #fff',
                                            marginLeft: 6,
                                            marginTop: -3,
                                            paddingTop: 3,
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>
                    </>
                }
            />
        </>
    );
};

export default IssueCardComments;
