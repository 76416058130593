import { Dayjs } from 'dayjs';
import { forwardRef } from 'react';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import { HiOutlineClock } from 'react-icons/hi';

import DatePicker from './DatePicker';

export type TimePickerProps = Omit<PickerTimeProps<Dayjs>, 'picker'>;

const TimePicker = forwardRef<any, TimePickerProps>((props, ref) => {
    return <DatePicker {...props} picker="time" suffixIcon={<HiOutlineClock />} mode={undefined} ref={ref} />;
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
