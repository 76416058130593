import { Button, Form, FormProps, Input, Skeleton } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from 'react-query';
import { Link, LinkProps } from 'react-router-dom';

import { errorMessage, successMessage } from '../../helpers/message';
import genericMessages from '../../i18n/genericMessages';
import { ArrowLeft, CheckCircle, Pencil, XCircle } from '../icons';
import { classNames } from '../../helpers';
import '../../assets/styles/components/DetailsFormTitle.less';

export interface DetailsFormTitleProps<TData, UpdatePayload> {
    backRoute: LinkProps['to'];
    id?: string;
    detailsQueryHandler: (
        id: string | undefined,
        options?: UseQueryOptions<TData, AxiosError, TData> | undefined
    ) => UseQueryResult<TData, AxiosError>;
    updateQueryHandler: (
        options?: UseMutationOptions<TData, AxiosError, UpdatePayload>
    ) => UseMutationResult<TData, AxiosError, UpdatePayload, unknown>;
    placeholder?: string;
    name?: Extract<keyof TData, string> | 'name';
}

const DetailsFormEditableTitle = <TData extends Record<string, any>, UpdatePayload extends Record<string, any>>({
    detailsQueryHandler,
    updateQueryHandler,
    id,
    backRoute,
    placeholder,
    name = 'name',
}: DetailsFormTitleProps<TData, UpdatePayload>) => {
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();
    const [isEditing, setIsEditing] = useState(false);
    const {
        data: details,
        isLoading: isLoadingDetails,
        isSuccess,
    } = detailsQueryHandler(id, {
        enabled: !!id,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            form.setFieldsValue({ [name]: data[name] });
            setValue(data[name]);
        },
    });
    const [value, setValue] = useState<string | undefined>(details?.[name]);
    const { mutate: update, isLoading: isUpdating } = updateQueryHandler();
    const onSubmit: FormProps['onFinish'] = (values) => {
        update(
            {
                ...details,
                ...values,
                id,
            },
            {
                onSuccess: () => {
                    successMessage({
                        content: formatMessage({
                            id: 'details_form_card.update.success_message',
                            defaultMessage: 'Modifications enregistrées avec succès',
                        }),
                    });
                    setIsEditing(false);
                },
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error.response?.status ?? 0,
                        }),
                    });
                },
            }
        );
    };

    return (
        <Form form={form} onFinish={onSubmit} initialValues={{ [name]: details?.[name] }}>
            <div id="details-form-title" className="flex items-center">
                <Skeleton loading={isLoadingDetails}>
                    <Link to={backRoute} className="mr-3">
                        <ArrowLeft className="text-xl leading-5" />
                    </Link>
                </Skeleton>
                {isSuccess && (
                    <div className="flex items-center space-x-2">
                        <Form.Item name={[name]} noStyle>
                            <Input
                                placeholder={placeholder}
                                className={classNames(isEditing && 'is-editing')}
                                onFocus={() => setIsEditing(true)}
                                onChange={(e) => setValue(e.target.value)}
                                style={{ width: value?.length ? `${value.length + 2.5}ch` : '6ch' }}
                            />
                        </Form.Item>
                        {isEditing ? (
                            <>
                                <Button
                                    icon={<XCircle className="text-3.5xl" />}
                                    type="text"
                                    size="small"
                                    onClick={() => {
                                        setIsEditing(false);
                                        form.resetFields();
                                    }}
                                    disabled={isUpdating}
                                    danger
                                />
                                <Button
                                    key="save"
                                    icon={<CheckCircle className="text-3.5xl" />}
                                    type="text"
                                    size="small"
                                    className="btn-text-success"
                                    htmlType="submit"
                                    loading={isUpdating}
                                />
                            </>
                        ) : (
                            <Button
                                key="edit"
                                icon={<Pencil className="text-xl" />}
                                type="text"
                                size="small"
                                className="btn-faded-blue"
                                onClick={() => {
                                    setIsEditing(true);
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        </Form>
    );
};

export default DetailsFormEditableTitle;
