import { Dayjs } from 'dayjs';

import api from './apiClient';
import { Customer, CustomerType, GenericStatus, InvoicingPrice, ListResponse, ValueListItem } from './types';
import { SearchPaginationQuery, downloadResponseAsBlob } from '.';

// Controller Interface
export interface CustomerCreatePayload {
    name: Customer['name'];
}

export interface CustomerUpdatePayload extends Omit<Customer, 'id' | 'invoicingPrices' | 'places'> {
    id?: Customer['id'];
    invoicingPrices?: Array<
        Omit<
            InvoicingPrice,
            'vehicleType' | 'serviceType' | 'fromDate' | 'toDate' | 'standard' | 'weekend' | 'holiday' | 'reuse'
        > & {
            vehicleType?: ValueListItem['id'];
            serviceType?: ValueListItem['id'];
            standard?: number;
            weekend?: number;
            holiday?: number;
            reuse?: number;
            fromDate: Dayjs;
            toDate: Dayjs;
        }
    >;
    places?: Array<ValueListItem['id']>;
}

export type CustomerIdPayload = Customer['id'];

export type CustomerListPayload = SearchPaginationQuery & {
    isActive?: boolean;
    type?: CustomerType;
    groupIds?: Array<ValueListItem['id']>;
    forecastStatus?: GenericStatus;
};
export type CustomerListResponse = ListResponse<Customer>;

// Routes
export const list = async (payload?: CustomerListPayload) => {
    return await api.get<CustomerListResponse>(`/customers`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: CustomerCreatePayload) => {
    return await api.post(`/customers`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: CustomerUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<Customer>(`/customers/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: CustomerIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Customer>(`/customers/${id}`).then((response) => response?.data);
};

export const remove = async (id?: CustomerIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/customers/${id}`).then((response) => response?.data);
};

export const exportCustomers = async () => {
    return await api
        .get<Blob>('/exportAll/customers', {
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);
            return undefined;
        });
};
