import { useState, forwardRef, Fragment } from 'react';
import { Empty, Select, Spin, Tooltip } from 'antd';
import { SelectProps } from 'antd/lib/select';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectRef } from 'rc-select';
import { useIntl } from 'react-intl';

import { useCustomersList } from '../../queries/customers';
import { Customer, GenericStatus } from '../../queries/api/types';
import { useDebounce } from '../../hooks';
import { ExclamationOutline } from '../icons';
import taskGroupMessages from '../../i18n/taskGroupMessages';

type SelectValue = Customer['id'] | Array<Customer['id']>;

export interface CustomerSelectProps extends SelectProps<SelectValue> {
    initialValue?: Customer[];
    validForcastOnly?: boolean;
}

const CustomerSelect = forwardRef<BaseSelectRef, CustomerSelectProps>(
    ({ initialValue, validForcastOnly, ...props }, ref) => {
        const { formatMessage } = useIntl();
        const [search, setSearch] = useState<string>();
        const debouncedSearch = useDebounce(search, 300);
        const { data: list, isLoading } = useCustomersList({
            search: debouncedSearch || undefined,
            pageSize: 50,
        });

        return (
            <Select<SelectValue>
                ref={ref}
                placeholder={formatMessage({
                    id: 'customer_select.placeholder',
                    defaultMessage: 'Rechercher un client',
                })}
                notFoundContent={isLoading ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={setSearch}
                style={{ width: '100%' }}
                loading={isLoading}
                size="middle"
                showSearch
                allowClear
                {...props}
            >
                {[
                    ...(initialValue || []), // Display initial value
                    ...(list?.items.filter((a) => !initialValue?.map((b) => b.id).includes(a.id)) ?? []), // Search items, excluding initial value
                ].map((item) =>
                    validForcastOnly ? (
                        <Fragment key={item.id}>
                            {item.forecastStatus === GenericStatus.error ? (
                                <Select.Option value={item.id} disabled>
                                    <Tooltip title={formatMessage(taskGroupMessages.noBudgetTooltip)} color="red">
                                        <span className="flex items-center">
                                            <ExclamationOutline className="text-red icon-sm text-small mr-2" />{' '}
                                            {item.name}
                                        </span>
                                    </Tooltip>
                                </Select.Option>
                            ) : (
                                <Select.Option value={item.id}>{item.name}</Select.Option>
                            )}
                        </Fragment>
                    ) : (
                        <Select.Option value={item.id} key={item.id}>
                            {item.name}
                        </Select.Option>
                    )
                )}
            </Select>
        );
    }
);

export default CustomerSelect;
