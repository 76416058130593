import { Permission, RoleSlug, User, PermissionRight } from '../queries/api/types';

export const hasPermission = (user?: User, permission?: Permission, right: PermissionRight = PermissionRight.read) => {
    if (!permission || user?.mergedPermissions?.[Permission.superAdmin] === PermissionRight.write) {
        return true;
    }
    switch (right) {
        case PermissionRight.read:
            return Boolean(
                [PermissionRight.read, PermissionRight.write].includes(
                    user?.mergedPermissions?.[permission] ?? PermissionRight.disabled
                )
            );
        case PermissionRight.write:
            return user?.mergedPermissions?.[permission] === PermissionRight.write;
    }
    return false;
};

export const hasRole = (user?: User, roles?: RoleSlug[]) => {
    return user?.role?.slug && roles?.includes(user.role.slug);
};

export const isAdmin = (user?: User) => {
    return hasRole(user, [RoleSlug.admin]) || hasRole(user, [RoleSlug.superAdmin]);
};

export const isSuperAdmin = (user?: User) => {
    return hasRole(user, [RoleSlug.superAdmin]);
};
