import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Input, Button, Alert, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import LoginLayout from '../../components/LoginLayout';
import { getRoute, RoutePathName } from '../../routes';
import { useAuth } from '../../context/AuthContext';
import { ForgottenPasswordPayload } from '../../queries/api/auth';
import genericMessages from '../../i18n/genericMessages';
import { getRequiredRule } from '../../i18n';
import formMessages from '../../i18n/formMessages';

const ForgottenPassword: FC = () => {
    const { formatMessage } = useIntl();
    const { forgottenPassword } = useAuth();
    const { mutate: sendForgottenPassword, error, isSuccess, isLoading } = forgottenPassword;
    const [email, setEmail] = useState<string | undefined>();
    const onFormValidSubmit: FormProps['onFinish'] = (values: ForgottenPasswordPayload) => {
        setEmail(values.username);
        sendForgottenPassword(values);
    };
    const pageTitle = formatMessage({
        id: 'forgotten_password.title',
        defaultMessage: 'Mot de passe oublié ?',
        description: 'Forgotten password page title',
    });

    let errorMessage = error ? formatMessage(genericMessages.defaultError) : null;

    if (error?.response?.status === 404) {
        errorMessage = formatMessage({
            id: 'forgotten_password.error_message.email',
            defaultMessage: 'Adresse e-mail non trouvée.',
            description: 'forgotten password email not found',
        });
    }

    useEffect(
        () => () => {
            setEmail(undefined);
        },
        [setEmail]
    );

    return (
        <LoginLayout title={pageTitle} seoTitle={pageTitle}>
            <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                {isSuccess ? (
                    <Typography.Paragraph>
                        <FormattedMessage
                            id="forgotten_password.paragraph.send_email"
                            defaultMessage="Nous venons de vous envoyer un lien pour réinitialiser votre mot de passe à l'adresse {email}. {br}Merci de surveiller vos e-mails."
                            description="Reset password mail sent explanation"
                            values={{
                                email,
                                br: (
                                    <>
                                        <br />
                                        <br />
                                    </>
                                ),
                            }}
                            tagName="p"
                        />
                    </Typography.Paragraph>
                ) : (
                    <>
                        <Typography.Paragraph>
                            <FormattedMessage
                                id="forgotten_password.paragraph.intro"
                                defaultMessage="Saisir l'adresse e-mail associé à votre compte, nous vous enverrons un mail avec un lien pour réinitialiser votre mot de passe"
                                description="Forgotten password page intro text"
                                tagName="p"
                            />
                        </Typography.Paragraph>
                        <Form.Item
                            name="username"
                            label={formatMessage({
                                id: 'forgotten_password.form.item.email.label',
                                defaultMessage: 'Adresse e-mail',
                                description: 'forgotten password email field label',
                            })}
                            rules={[
                                getRequiredRule(formatMessage),
                                {
                                    type: 'email',
                                    message: formatMessage(formMessages.invalidEmail),
                                },
                            ]}
                            validateTrigger="onBlur"
                        >
                            <Input
                                placeholder={formatMessage({
                                    id: 'forgotten_password.form.item.email.placeholder',
                                    defaultMessage: 'Saisissez votre adresse e-mail',
                                    description: 'forgotten password email field placeholder',
                                })}
                            />
                        </Form.Item>
                        {errorMessage && <Alert type="error" message={errorMessage} className="mb-6" />}
                        <Form.Item>
                            <Button type="primary" size="large" htmlType="submit" loading={isLoading} block>
                                <FormattedMessage
                                    id="forgotten_password.form.submit_button"
                                    defaultMessage="Réinitialiser votre mot de passe"
                                    description="Forgotten password submit button"
                                />
                            </Button>
                        </Form.Item>
                    </>
                )}
                <Link to={getRoute(RoutePathName.login)}>
                    <FormattedMessage
                        id="forgotten_password.link.page_back"
                        defaultMessage="Revenir à l'écran de connexion"
                        description="Forgotten password page back to login link"
                    />
                </Link>
            </Form>
        </LoginLayout>
    );
};

export default ForgottenPassword;
