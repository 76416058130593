import { FormInstance } from 'antd';
import { AxiosError } from 'axios';
import { IntlShape } from 'react-intl';

import formMessages from '../i18n/formMessages';
import genericMessages from '../i18n/genericMessages';
import { errorMessage } from './message';

interface FormErrorParams {
    error: AxiosError;
    form: FormInstance;
    formatMessage: IntlShape['formatMessage'];
}

export const formError = ({ error, form, formatMessage }: FormErrorParams) => {
    if (error?.response?.status === 409) {
        const field = Object.keys(error.response?.data?.fields)?.[0];

        if (field) {
            form.setFields([
                {
                    name: field,
                    errors: [formatMessage(formMessages.conflictError)],
                },
            ]);
            errorMessage({
                content: formatMessage(formMessages.genericConflictError),
            });
            document.querySelector('.ant-form-item-has-error')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            errorMessage({
                content: formatMessage(formMessages.genericConflictError),
            });
        }
    } else {
        errorMessage({
            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                status: error.response?.status,
            }),
        });
    }
};
