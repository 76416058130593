import { Button, ButtonProps, Card, FormItemProps, Spin, Typography } from 'antd';
import { FC, ReactNode } from 'react';

export const sectionCardFormItemLayout: FormItemProps = {
    labelCol: { sm: { span: 6 }, lg: { span: 6 }, style: { marginRight: 16 } },
    wrapperCol: { sm: { span: 18 }, lg: { span: 12 } },
    colon: false,
};

export interface SectionCardProps {
    title: ReactNode;
    headerButtonText?: string;
    headerButtonOnClick?: () => void;
    isLoading?: boolean;
    buttonProps?: ButtonProps;
}

const SectionCard: FC<SectionCardProps> = ({
    headerButtonText,
    headerButtonOnClick,
    title,
    isLoading,
    children,
    buttonProps,
}) => (
    <Spin spinning={isLoading}>
        <Card>
            <div className="flex justify-between items-center mb-6">
                <Typography.Title level={3} className="text-blue font-bold">
                    {title}
                </Typography.Title>
                {headerButtonText && (
                    <Button onClick={headerButtonOnClick} {...buttonProps}>
                        {headerButtonText}
                    </Button>
                )}
            </div>
            {children}
        </Card>
    </Spin>
);

export default SectionCard;
