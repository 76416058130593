/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 2.5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1V3H19a2 2 0 0 1 2 2v16.5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2.5v-.5Zm2 2h5v-1h-5v1Zm7 1a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V5H5v16.5h14V5h-2.5v.5Zm-2.293 3.793a1 1 0 0 1 0 1.414l-2.293 2.294h2.588a1 1 0 0 1 .707 1.707l-4.002 4a1 1 0 1 1-1.414-1.414L12.087 15H9.5a1 1 0 0 1-.707-1.707l4-4.001a1 1 0 0 1 1.414 0Z"
            fill="currentColor"
            fillOpacity={0.85}
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgOrder: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-order', props.className)} />
));
export default SvgOrder;
