/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.06 1.398a1 1 0 0 1 1.157.509 2 2 0 0 0 3.566 0 1 1 0 0 1 1.158-.51 10.983 10.983 0 0 1 4.548 2.546 1 1 0 0 1 .216 1.174A2 2 0 0 0 21.5 8h.028a1 1 0 0 1 .968.7 11.045 11.045 0 0 1 .28 5.515 1 1 0 0 1-1.077.795 2 2 0 0 0-1.729 3.279 1 1 0 0 1-.043 1.338 10.998 10.998 0 0 1-4.784 2.917 1 1 0 0 1-1.237-.654 2.001 2.001 0 0 0-3.812 0 1 1 0 0 1-1.237.654 10.998 10.998 0 0 1-4.784-2.917 1 1 0 0 1-.043-1.338A2 2 0 0 0 2.3 15.01a1 1 0 0 1-1.078-.795 11.044 11.044 0 0 1 .28-5.515 1 1 0 0 1 .97-.7H2.5a2 2 0 0 0 1.795-2.883 1 1 0 0 1 .216-1.174A10.984 10.984 0 0 1 9.06 1.398ZM6.367 4.979a4.004 4.004 0 0 1-3.13 4.953 9.029 9.029 0 0 0-.179 3.107 4 4 0 0 1 3.023 5.742 9 9 0 0 0 2.546 1.566A3.996 3.996 0 0 1 12 18.5c1.418 0 2.661.737 3.372 1.847a9 9 0 0 0 2.546-1.566 4 4 0 0 1 3.023-5.742 9.103 9.103 0 0 0-.18-3.107 4.001 4.001 0 0 1-3.13-4.953 8.986 8.986 0 0 0-2.544-1.436A3.992 3.992 0 0 1 12 5a3.992 3.992 0 0 1-3.087-1.457A8.985 8.985 0 0 0 6.368 4.98ZM7.5 12a4.5 4.5 0 1 1 9 .001 4.5 4.5 0 0 1-9 0ZM12 9.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgSettingTwo: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-setting-two', props.className)} />
));
export default SvgSettingTwo;
