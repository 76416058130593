import { useMemo, VFC } from 'react';
import { Button, Space, Form, FormProps } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useUserCreate, useUserDetails, useUserRemove, useUserUpdate } from '../../../queries/users';
import Seo from '../../../components/Seo';
import { getRoute, RoutePathName } from '../../../routes';
import { RoleSlug, User } from '../../../queries/api/types';
import DetailsFormCard from '../../../components/form/DetailsFormCard';
import { UserUpdatePayload } from '../../../queries/api/users';
import genericMessages from '../../../i18n/genericMessages';
import userMessages from '../../../i18n/userMessages';
import ListTitle from '../../../components/ListTitle';
import ApiResult from '../../../components/ApiResult';
import formMessages from '../../../i18n/formMessages';
import { errorMessage, successMessage } from '../../../helpers/message';
import { Check, Trash } from '../../../components/icons';
import ConfirmationModal from '../../../components/ConfirmationModal';
import DispatcherAlertModal from './DispatcherAlertModal';
import {
    formatUserCreatePayload,
    formatUserInitialValue,
    formatUserUpdatePayload,
    getUserFormSections,
    UserForm,
} from '.';
import { useRoleList } from '../../../queries/roles';

const userRemoveConfirmationModalId = 'remove-confirmation-modal';

const UserDetails: VFC = () => {
    const { userId } = useParams<{ userId?: string }>();
    const [form] = Form.useForm();
    const isCreating = !userId;
    const {
        data: userDetails,
        isError,
        error,
    } = useUserDetails(userId, {
        enabled: !isCreating,
    });
    const { formatMessage } = useIntl();
    const pageTitle = isCreating
        ? formatMessage({
              id: 'user_details.create.title',
              defaultMessage: 'Ajouter un utilisateur',
              description: 'User creation page title',
          })
        : formatMessage(userMessages.detailsPageTitle) || formatMessage(genericMessages.editing);

    const history = useHistory();
    const { mutate: createUser } = useUserCreate();
    const userRemoveModal = useModal(userRemoveConfirmationModalId);
    const formatInitialValue = (value: User | undefined) =>
        ({
            ...value,
            role: userDetails?.role?.id,
        } as UserForm);

    const dispatcherAlertModal = useModal(DispatcherAlertModal);

    const sendForceCreateUser: FormProps['onFinish'] = (values) => {
        const payload = formatUserCreatePayload(values);
        createUser(
            { ...payload, checkDispatcher: false },
            {
                onSuccess: () => {
                    successMessage({
                        content: formatMessage({
                            id: 'user_details.create.success_message',
                            defaultMessage: 'Utilisateur ajouté avec succès',
                        }),
                    });
                    history.push(getRoute(RoutePathName.usersList));
                },
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error.response?.status,
                        }),
                    });
                },
            }
        );
    };
    const sendCreateUser: FormProps['onFinish'] = (values) => {
        const payload = formatUserCreatePayload(values);
        createUser(payload, {
            onSuccess: () => {
                successMessage({
                    content: formatMessage({
                        id: 'user_details.create.success_message',
                        defaultMessage: 'Utilisateur ajouté avec succès',
                    }),
                });
                history.push(getRoute(RoutePathName.usersList));
            },
            onError: (error) => {
                if (error.response?.status === 409) {
                    const places = error.response.data?.fields.places;
                    dispatcherAlertModal.show({ places }).then(() => {
                        sendForceCreateUser(values);
                        dispatcherAlertModal.hide();
                    });
                } else {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error.response?.status,
                        }),
                    });
                }
            },
        });
    };

    const userRemove = useUserRemove({
        onError: () => {
            errorMessage({ content: formatMessage(userMessages.deleteError) });
        },
        onSuccess: () => {
            successMessage({ content: formatMessage(userMessages.deleteSuccess) });
            userRemoveModal.hide();
            history.push(getRoute(RoutePathName.usersList));
        },
    });
    const { data: roleList } = useRoleList({});
    const dispatcherIds = roleList?.items
        .filter((role) => [RoleSlug.superDispatcher, RoleSlug.dispatcher].includes(role.slug))
        .map((role) => role.id);
    const sections = useMemo(
        () => getUserFormSections(formatMessage, userDetails, dispatcherIds),
        [dispatcherIds, userDetails, formatMessage]
    );
    const sectionCards = useMemo(
        () =>
            sections.map((section) => (
                <DetailsFormCard<User, UserForm, UserUpdatePayload>
                    {...section}
                    key={section.title}
                    id={userId}
                    detailsQueryHandler={useUserDetails}
                    updateQueryHandler={useUserUpdate}
                    initialValueFormatter={formatUserInitialValue}
                    formatPayload={(values) => formatUserUpdatePayload(values, userDetails, dispatcherIds)}
                />
            )),
        [sections, userId, dispatcherIds, userDetails]
    );

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                <ListTitle className="mb-0 uppercase" backRoute={getRoute(RoutePathName.usersList)} editable={false}>
                    {pageTitle}
                </ListTitle>
                {!isCreating && (
                    <Button
                        danger
                        className="h-10 w-10 flex items-center justify-center"
                        onClick={() => {
                            userRemoveModal.show().then(() => userRemove.mutate(userId));
                        }}
                    >
                        <Trash className="text-lg" />
                    </Button>
                )}
            </div>
            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    {isCreating ? (
                        <Form
                            form={form}
                            onFinish={sendCreateUser}
                            initialValues={formatInitialValue(userDetails)}
                            scrollToFirstError
                        >
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                {sectionCards}
                                <div className="flex justify-center">
                                    <Button htmlType="submit" type="primary" icon={<Check />} size="large">
                                        <FormattedMessage {...formMessages.saveData} tagName="span" />
                                    </Button>
                                </div>
                            </Space>
                        </Form>
                    ) : (
                        sectionCards
                    )}
                    <ConfirmationModal
                        title={formatMessage(userMessages.confirmDeleteModalTitle)}
                        closable={false}
                        text={
                            <>
                                <p>{formatMessage(userMessages.confirmDeleteModalContent)}</p>
                                <p className="text-orange text-bold font-bold">
                                    {formatMessage(userMessages.confirmDeleteModalHelper)}
                                </p>
                            </>
                        }
                        id={userRemoveConfirmationModalId}
                        isLoading={userRemove.isLoading}
                    />
                </Space>
            )}
        </>
    );
};

export default UserDetails;
