import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Form, FormProps, Input, InputNumber, ModalProps, Row, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import CustomModal from '../../components/CustomModal';
import { TaskGroup, TaskGroupService, TaskGroupServiceType } from '../../queries/api/types';
import { useTaskGroupCreateService } from '../../queries/taskGroups';
import genericMessages from '../../i18n/genericMessages';
import formMessages from '../../i18n/formMessages';
import { useTaskGroupServiceDetails, useTaskGroupServiceUpdate } from '../../queries/taskGroupServices';
import { getRequiredRule } from '../../i18n';
import { errorMessage, successMessage } from '../../helpers/message';
import preBillingMessages from '../../i18n/preBillingMessages';

export interface TaskGroupServiceFormModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, unknown> {
    taskGroupServiceType: TaskGroupServiceType;
    taskGroupServiceId?: TaskGroupService['id'];
    taskGroupId?: TaskGroup['id'];
}

const TaskGroupServiceFormModal = NiceModal.create<TaskGroupServiceFormModalProps>(
    ({ taskGroupServiceType, taskGroupServiceId, taskGroupId, ...props }) => {
        const modal = useModal();
        const { formatMessage } = useIntl();
        const isEditing = !!taskGroupServiceId;
        const [form] = Form.useForm();
        const { mutate: createService, isLoading: isCreating } = useTaskGroupCreateService();
        const { mutate: updateService, isLoading: isUpdating } = useTaskGroupServiceUpdate();
        const isLoading = isCreating || isUpdating;
        const { data: taskGroupServiceDetails, isLoading: isLoadingDetails } = useTaskGroupServiceDetails(
            taskGroupServiceId,
            {
                enabled: modal.visible && isEditing,
                onSuccess: (data) => {
                    form.setFieldsValue(data);
                },
            }
        );
        const onFormValidSubmit: FormProps<TaskGroupService>['onFinish'] = (values) => {
            if (isEditing) {
                updateService(
                    {
                        ...values,
                        type: taskGroupServiceType,
                        id: taskGroupServiceId,
                    },
                    {
                        onSuccess: () => {
                            successMessage({
                                content: formatMessage({
                                    id: 'pre_billing.task_group_services.form_modal.edit.success',
                                    defaultMessage: 'Prestation modifiée avec succès',
                                }),
                            });
                            modal.hide();
                        },
                        onError: (error) => {
                            errorMessage({
                                content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                    status: error?.response?.status,
                                }),
                            });
                        },
                    }
                );
            } else {
                createService(
                    {
                        ...values,
                        type: taskGroupServiceType,
                        taskGroupId: taskGroupId ?? '',
                    },
                    {
                        onSuccess: () => {
                            successMessage({
                                content: formatMessage({
                                    id: 'pre_billing.task_group_services.form_modal.create.success',
                                    defaultMessage: 'Prestation ajoutée avec succès',
                                }),
                            });
                            modal.hide();
                        },
                        onError: (error) => {
                            errorMessage({
                                content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                    status: error?.response?.status,
                                }),
                            });
                        },
                    }
                );
            }
        };

        return (
            <CustomModal
                footer={false}
                width={472}
                title={
                    isEditing
                        ? formatMessage({
                              id: 'pre_billing.task_group_services.form_modal.edit.title',
                              defaultMessage: 'Modifier la prestation supplémentaire',
                          })
                        : formatMessage(preBillingMessages.addAdditionalServicesTooltip)
                }
                {...props}
                {...antdModal(modal)}
            >
                <Spin spinning={isLoadingDetails}>
                    <Form
                        onFinish={onFormValidSubmit}
                        layout="horizontal"
                        initialValues={isEditing ? { ...taskGroupServiceDetails } : undefined}
                        labelCol={{ span: 6, style: { marginRight: 16 } }}
                        wrapperCol={{ span: 18 }}
                        form={form}
                        requiredMark
                    >
                        <Form.Item
                            label={formatMessage(preBillingMessages.taskGroupServiceLabel)}
                            name="label"
                            rules={[getRequiredRule(formatMessage)]}
                        >
                            <Input placeholder={formatMessage(preBillingMessages.taskGroupServiceLabelPlaceholder)} />
                        </Form.Item>
                        <Form.Item
                            label={formatMessage(preBillingMessages.taskGroupServiceAmount)}
                            name="amount"
                            rules={[getRequiredRule(formatMessage)]}
                        >
                            <InputNumber
                                placeholder={formatMessage(preBillingMessages.taskGroupServiceAmountPlaceholder)}
                                addonAfter="€"
                                className="w-full"
                                min={0}
                            />
                        </Form.Item>
                        <Row justify="end" gutter={8} className="mt-8">
                            <Col>
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={() => {
                                        modal.hide();
                                    }}
                                >
                                    <FormattedMessage {...formMessages.cancel} />
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit" loading={isLoading}>
                                    <FormattedMessage {...formMessages.validate} />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </CustomModal>
        );
    }
);

export default TaskGroupServiceFormModal;
