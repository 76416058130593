import { useState, useEffect } from 'react';

const usePersistentState = (key: string, defaultValue?: string) => {
    const [value, setValue] = useState(() => {
        const savedValue = localStorage.getItem(key);
        return savedValue ? JSON.parse(savedValue) : defaultValue;
    });

    useEffect(() => {
        if (value) {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.removeItem(key);
        }
    }, [key, value]);

    return [value, setValue];
};

export default usePersistentState;
