import { Button, Form, FormProps, Input, ModalProps } from 'antd';
import { useIntl } from 'react-intl';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';

import { useIssueUpdate } from '../../../../queries/issues';
import { Issue, IssueCommentType, IssueStatus, Vehicle } from '../../../../queries/api/types';
import { successMessage } from '../../../../helpers/message';
import { useAuth } from '../../../../context/AuthContext';
import { getRequiredRule } from '../../../../i18n';
import issueMessages from '../../../../i18n/issueMessages';
import formMessages from '../../../../i18n/formMessages';
import { ExclamationCircle } from '../../../../components/icons';
import CustomModal from '../../../../components/CustomModal';

export interface IssueCardModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    commentType: IssueCommentType;
    description: string;
    title: string;
    isCommentRequired?: boolean; // default false
    issue: Issue;
    newStatus: IssueStatus;
    onSuccessMessage: string;
    showIcon?: boolean; // default true
    showLabel?: boolean; // default true
    vehicleId: Vehicle['id'];
}

interface IssueCommentFormProps {
    text: string;
}

const IssueCardModal = NiceModal.create<IssueCardModalProps>(
    ({
        commentType,
        description,
        title,
        isCommentRequired = false,
        issue,
        newStatus,
        onSuccessMessage,
        showIcon = true,
        showLabel = true,
        vehicleId,
    }) => {
        const modal = useModal();
        const { formatMessage } = useIntl();
        const { mutate: updateIssue } = useIssueUpdate();
        const { user } = useAuth();

        const onSubmit: FormProps['onFinish'] = (values: IssueCommentFormProps) => {
            updateIssue(
                {
                    ...issue,
                    status: newStatus,
                    comments: values.text
                        ? [
                              {
                                  text: values.text,
                                  type: commentType,
                                  user,
                              },
                          ]
                        : [],
                    issue: issue.id,
                    vehicle: vehicleId,
                },
                {
                    onSuccess: () => {
                        successMessage({
                            content: onSuccessMessage,
                        });

                        modal.hide();
                    },
                }
            );
        };

        return (
            <CustomModal {...antdModal(modal)} closeIcon={<></>} footer={false}>
                <Form onFinish={onSubmit}>
                    <div className="flex gap-4">
                        {showIcon && <ExclamationCircle className="icon-md fill-orange stroke-white" />}

                        <div className="w-full">
                            <p className="text-lg font-bold text-blue mb-0">{title}</p>
                            <p className="text-base text-black font-medium mt-4 mb-6">{description}</p>

                            {showLabel && (
                                <label htmlFor="text" className="text-base text-black font-medium">
                                    {formatMessage(issueMessages.addCommentLabel)}
                                </label>
                            )}

                            <Form.Item name="text" rules={isCommentRequired ? [getRequiredRule(formatMessage)] : []}>
                                <Input
                                    className="mt-1"
                                    placeholder={formatMessage(issueMessages.addCommentPlaceholder)}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="flex justify-end gap-4">
                        <Button
                            type="primary"
                            onClick={() => {
                                modal.hide();
                            }}
                            ghost
                        >
                            {formatMessage(formMessages.cancel)}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {formatMessage(formMessages.validate)}
                        </Button>
                    </div>
                </Form>
            </CustomModal>
        );
    }
);

export default IssueCardModal;
