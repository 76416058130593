import { Card, CardProps, Spin, Table, TableProps, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState, VFC } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import ApiResult from '../../../components/ApiResult';
import LabelWithTooltipIcon from '../../../components/LabelWithTooltipIcon';
import { sortOrderConverter } from '../../../helpers';
import operatorMessages from '../../../i18n/operatorMessages';
import { Operator, OperatorAbsenceByType } from '../../../queries/api/types';
import { useOperatorListAbscencesByType } from '../../../queries/operators';

interface OperatorAbsenceListCardProps extends CardProps {
    operatorId: Operator['id'];
}

const OperatorAbsenceListCard: VFC<OperatorAbsenceListCardProps> = ({ operatorId, ...cardProps }) => {
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState<string>();
    const [sortOrder, setSortOrder] = useState<string>();
    const { formatMessage } = useIntl();
    const {
        data: absences,
        isLoading,
        error,
        isError,
    } = useOperatorListAbscencesByType({
        id: operatorId,
        sort,
        sortOrder,
        page,
        pageSize: 8,
    });
    const onTableChange: TableProps<OperatorAbsenceByType>['onChange'] = (pagination, _, sorter) => {
        const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

        setPage((pagination.current ?? 1) - 1);
        setSort(sortObj.column?.key ? `${sortObj.column?.key}` : undefined);
        setSortOrder(sortObj.order ? sortOrderConverter(sortObj.order) : undefined);
    };
    const columns = useMemo<ColumnsType<OperatorAbsenceByType>>(
        () => [
            {
                key: 'type',
                title: formatMessage(operatorMessages.absenceReason),
                render: (value, record) => record.type?.fields.operatorAbsenceReason,
            },
            {
                key: 'days',
                title: formatMessage({
                    id: 'management.operators.history.total_of_abscence_by_type.total_days',
                    defaultMessage: 'Total jours',
                }),
                render: (value, record) => <FormattedNumber value={record.days} />,
                sorter: true,
            },
        ],
        [formatMessage]
    );

    return (
        <Spin spinning={isLoading} wrapperClassName={cardProps.className}>
            <Card {...cardProps}>
                <Typography.Title level={2} className="text-base font-medium text-faded-blue mb-6">
                    <LabelWithTooltipIcon tooltip="à définir">
                        <FormattedMessage
                            id="management.operators.history.total_of_abscence_by_type"
                            defaultMessage="Total des jours d’absences par motif"
                            description="Card title"
                        />
                    </LabelWithTooltipIcon>
                </Typography.Title>
                {isError ? (
                    <ApiResult status={error.response?.status} />
                ) : (
                    <Table<OperatorAbsenceByType>
                        columns={columns}
                        rowKey="id"
                        dataSource={absences?.items}
                        onChange={onTableChange}
                        pagination={{
                            total: absences?.totalCount,
                            current: page + 1,
                            pageSize: absences?.pageSize,
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            showSizeChanger: false,
                            className: 'mb-0',
                        }}
                        size="small"
                        bordered
                    />
                )}
            </Card>
        </Spin>
    );
};

export default OperatorAbsenceListCard;
