/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.793.75a.75.75 0 0 1 .42 0l6.61 1.93a.75.75 0 0 1 .54.72v3.96c0 4.484-2.87 8.465-7.125 9.882a.75.75 0 0 1-.474 0A10.419 10.419 0 0 1 .638 7.357V3.4a.75.75 0 0 1 .54-.72L7.793.75ZM2.138 3.961v3.395c0 3.756 2.35 7.1 5.863 8.38a8.917 8.917 0 0 0 5.861-8.378V3.962L8.003 2.251 2.138 3.962Zm3.196 2.27a.75.75 0 0 1 1.06 0L7.943 7.78 9.49 6.232a.75.75 0 0 1 1.06 1.06L9.004 8.84l1.548 1.548a.75.75 0 1 1-1.061 1.06L7.942 9.901l-1.547 1.547a.75.75 0 0 1-1.061-1.06L6.882 8.84 5.334 7.292a.75.75 0 0 1 0-1.06Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgIncident: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-incident', props.className)} />
));
export default SvgIncident;
