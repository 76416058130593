import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import dayjs, { Dayjs } from 'dayjs';
import jwt_decode from 'jwt-decode';

import { Address, TaskGroupPreBillingStatus, User } from '../queries/api/types';

interface LoggerType {
    isAllowed: boolean;
    log: (messages?: any, ...optionalParams: any[]) => void;
    warn: (messages?: any, ...optionalParams: any[]) => void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = process.env.NODE_ENV !== 'production';
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: dodgerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: cornflowerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: darkorange; font-weight: bold', messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: tomato; font-weight: bold', messages, ...optionalParams);
        }
    }
}

export const debug = new Logger();

export const getFullName = (user?: Pick<User, 'firstName' | 'lastName'>) => {
    if (!user?.firstName && !user?.lastName) {
        return '';
    }

    return `${user?.firstName ?? ''}${user?.lastName ? ` ${user?.lastName}` : ''}`.trim();
};

export const positiveToNegative = (num: number) => {
    return -Math.abs(num);
};

export const negativeToPositive = (num: number) => {
    return Math.abs(num);
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const addYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export const isBeforeToday = (date: Date) => {
    return dayjs(date).isBefore(dayjs(), 'day');
};

export const isToday = (date: Date) => {
    return dayjs(date).isSame(dayjs(), 'date');
};

export function classNames(...args: Array<string | undefined | boolean>) {
    return [...args].filter(Boolean).join(' ');
}

export const stripUndefinedKeysFromObject = (object: { [key: string]: any }) =>
    Object.keys(object).forEach((key) => {
        if (object[key] === undefined) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete object[key];
        }
    });

export const checkIfTokenExpired = (token: string) => {
    try {
        const decoded: { exp: number } = jwt_decode(token);
        return decoded.exp * 1000 - Date.now() < 0;
    } catch (error) {
        return true;
    }
};

export const urlSearchParamsToObject = (urlSearchParams: URLSearchParams) =>
    Array.from(urlSearchParams.entries()).reduce<Record<string, any>>((acc, param) => {
        if (Object.prototype.hasOwnProperty.call(acc, param[0])) {
            if (Array.isArray(acc[param[0]])) {
                return { ...acc, [param[0]]: [...acc[param[0]], param[1]] };
            } else {
                return { ...acc, [param[0]]: [acc[param[0]], param[1]] };
            }
        }

        return { ...acc, [param[0]]: param[1] };
    }, {});

export const requiredRule = { required: true, message: 'champ requis' };

export const sortOrderConverter = (value: string) => {
    switch (value) {
        case 'ascend':
            return 'asc';
        case 'descend':
            return 'desc';
        default:
            return value;
    }
};

export const addDefaultColumnSorting = <T>(
    sort: string | undefined,
    sortOrder: string | undefined,
    columns: ColumnsType<T>
) => {
    return columns.map((column) => {
        if (sort && sortOrder && column.key === sort) {
            return {
                ...column,
                defaultSortOrder: sortOrder === 'asc' ? ('ascend' as SortOrder) : ('descend' as SortOrder),
            };
        }
        return column;
    });
};

export const formatAddress = (address?: Address) => {
    return [address?.street, [address?.zipCode, address?.city].filter(Boolean).join(' ')].filter(Boolean).join(', ');
};

export const getTimeAsDayjs = (time: number | undefined, defaultValue?: string) => {
    const stringTime = `${time ?? defaultValue ?? ''}`.padStart(4, '0');

    return dayjs()
        .hour(parseInt(stringTime.substring(0, 2), 10))
        .minute(parseInt(stringTime.substring(2), 10));
};

export const getTimeAsNumber = (time?: Dayjs) => {
    return time
        ? parseInt(`${String(time?.hour()).padStart(2, '0')}${String(time?.minute()).padStart(2, '0')}`)
        : undefined;
};

export const formatForecastDate = (date: Dayjs) => {
    return date.format('YYYY-MM-DD');
};

/**
 * Remove certain props from an object
 * @param obj Object
 * @param keys Object keys
 * @returns Object without certain props
 */
export function omit<T, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
    const data = { ...obj };
    for (const key of keys) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete data[key];
    }
    return data;
}

/**
 * Get certain props from an object using a path
 * @param obj Object
 * @param path Object path
 * @returns Object path value
 */
export function getObjValue(obj: any, path: string) {
    if (!path) return obj;

    return path.split('.').reduce((acc, curr) => acc[curr], obj);
}

/**
 * Filter duplicated keys in array, using a path to compare values
 * @param arr Array
 * @param path Object path inside array (use dotted notation, ex: "user.id")
 * @returns Filtered array with unique values
 */
export function filterDuplicates<T>(arr?: T[], path?: string) {
    if (!arr || !path) return;

    const uniqueIds = new Set();

    const filtered = arr.filter((item) => {
        const value = getObjValue(item, path);
        const isDuplicate = uniqueIds.has(value);

        uniqueIds.add(value);

        return !isDuplicate;
    });

    return filtered;
}

export const getPreBillingStatusColor = (status: TaskGroupPreBillingStatus) => {
    switch (status) {
        case TaskGroupPreBillingStatus.toPreBill:
            return 'orange';
        case TaskGroupPreBillingStatus.preBilled:
            return 'green';

        default:
            return 'default';
    }
};

export const downloadFile = (url?: string, filename?: string) => {
    if (!url) {
        return;
    }

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename ?? 'download');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};
