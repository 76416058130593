import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Modal, ModalProps, Row } from 'antd';
import { ReactNode } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import genericMessages from '../i18n/genericMessages';

interface ConfirmationModalProps extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>, Record<string, any> {
    title?: string;
    text: ReactNode;
    isLoading?: boolean;
    cancelText?: string;
    confirmText?: string;
}
const ConfirmationModal = NiceModal.create<ConfirmationModalProps>(
    ({ title, text, isLoading, cancelText, confirmText, ...props }) => {
        const { formatMessage } = useIntl();
        const confirmModal = useModal();

        return (
            <Modal
                {...props}
                {...antdModal(confirmModal)}
                afterClose={confirmModal.remove}
                closable={false}
                footer={false}
                centered
            >
                <Row>
                    <Col span={2}>
                        <ExclamationCircleFilled className="text-lg text-orange" />
                    </Col>
                    <Col span={22}>
                        <p className="text-lg font-bold text-blue">{title}</p>
                        <p>{text}</p>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col span={24} className="flex justify-end gap-2">
                        <Button key="cancelBtn" onClick={confirmModal.hide} className="text-blue">
                            {cancelText || formatMessage(genericMessages.cancel)}
                        </Button>
                        <Button key="yesBtn" onClick={() => confirmModal.resolve()} type="primary" loading={isLoading}>
                            {confirmText || formatMessage(genericMessages.validate)}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
);
export default ConfirmationModal;
