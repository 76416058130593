import { FC } from 'react';
import { Redirect } from 'react-router-dom';

import { getRoute, RoutePathName } from '../../routes';
import { ManagementTaskGroupsTabs } from '../management/taskGroups';

const Home: FC = () => {
    return (
        <Redirect to={getRoute(RoutePathName.managementTaskGroups, { tab: ManagementTaskGroupsTabs.allStatuses })} />
    );
};

export default Home;
