import { VFC } from 'react';
import { Tag, TagProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import '../assets/styles/components/TagWithDot.less';
import { classNames } from '../helpers';

const getSizeClassName = (size?: SizeType) => {
    switch (size) {
        case 'small':
            return 'w-1.5 h-1.5';
        case 'large':
            return 'w-2 h-2';
        default:
            return '';
    }
};

export interface TagWithDotProps extends TagProps {
    color: string; // based on TagWithDot.less
    size?: SizeType;
}

const TagWithDot: VFC<TagWithDotProps> = ({ children, color, size = 'large', ...props }) => {
    return (
        <Tag
            {...props}
            className={classNames(
                `inline-flex justify-between items-center gap-2 px-2 py-[1px] uppercase tag-${color}`,
                props.className
            )}
        >
            <div className={classNames('tag-dot rounded-full shrink-0', getSizeClassName(size))} />
            <div className="tag-body">{children}</div>
        </Tag>
    );
};

export default TagWithDot;
