import { useEffect, useState, VFC } from 'react';
import { Dayjs } from 'dayjs';
import frLocale from '@fullcalendar/core/locales/fr';
import FullCalendar, { CalendarOptions } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import constants from '../config/constants';
import { useLayout } from '../context/LayoutContext';

import '../assets/styles/components/CustomFullCalendar.less';
import '../assets/styles/components/Plannings.less';

export enum FullCalendarTimelineViews {
    resourceTimelineDay = 'resourceTimelineDay',
    resourceTimelineWeek = 'resourceTimelineWeek',
    resourceTimelineMonth = 'resourceTimelineMonth',
}

export interface CustomCalendarTimelineProps extends CalendarOptions {
    activeDate: Dayjs;
    initialView: FullCalendarTimelineViews;
    resizeKey?: string;
}

const CustomCalendarTimeline: VFC<CustomCalendarTimelineProps> = ({ activeDate, initialView, resizeKey, ...props }) => {
    const { isSidebarCollapsed } = useLayout();
    const [calendar, setCalendar] = useState<FullCalendar>();
    const calendarApi = calendar?.getApi();

    useEffect(() => {
        window.setTimeout(() => {
            calendarApi?.updateSize();
        }, 200);
    }, [isSidebarCollapsed, calendarApi, calendar, resizeKey]);

    useEffect(() => {
        if (activeDate) {
            calendarApi?.gotoDate(activeDate.toDate());
        }
    }, [activeDate, calendarApi]);

    useEffect(() => {
        calendarApi?.changeView(initialView);
    }, [calendarApi, initialView]);

    return (
        <div className="custom-fullcalendar">
            <FullCalendar
                schedulerLicenseKey={constants.FULLCALENDAR_LICENSE_KEY}
                ref={(ref) => setCalendar(ref ?? undefined)}
                editable={false}
                headerToolbar={false}
                height="auto"
                initialView={initialView}
                locale={frLocale}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                resourceAreaWidth={457}
                slotMinWidth={115}
                {...props}
            />
        </div>
    );
};

export default CustomCalendarTimeline;
