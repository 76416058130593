import { useCallback, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Dropdown, Menu, Spin, Tabs } from 'antd';
import { useModal } from '@ebay/nice-modal-react';

import { getRoute, RoutePathName } from '../../../routes';
import { Operator, OperatorAbsenceStatus } from '../../../queries/api/types';
import { useOperatorDetails, useOperatorExport, useSendMobilicEmail } from '../../../queries/operators';
import operatorMessages from '../../../i18n/operatorMessages';
import genericMessages from '../../../i18n/genericMessages';
import { getFullName } from '../../../helpers';
import { errorMessage, successMessage } from '../../../helpers/message';
import ListTitle from '../../../components/ListTitle';
import ApiResult from '../../../components/ApiResult';
import { Download, LockClosed, Qrcode, SmartPhone } from '../../../components/icons';
import { ManagementOperatorsDetailsTabs } from '.';
import ManagementOperatorsDetailsInfos from './ManagementOperatorsDetailsInfos';
import ManagementOperatorsDetailsHistory from './ManagementOperatorsDetailsHistory';
import ManagementOperatorsDetailsUnavailabilities from './ManagementOperatorsDetailsUnavailabilities';
import useQueryParams from '../../../hooks/queryParams';
import SvgDotsHorizontal from '../../../components/icons/DotsHorizontal';
import { downloadQrCodeAsImage } from '../../../helpers/qrCode';
import OperatorInformationsModal from '../../../components/modals/OperatorInformationsModal';

const ManagementOperatorsDetails: VFC = () => {
    const { operatorId, tab = ManagementOperatorsDetailsTabs.history } =
        useParams<{ operatorId: Operator['id']; tab: ManagementOperatorsDetailsTabs }>();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const infoModal = useModal(OperatorInformationsModal);
    const { data: operatorDetails, isError, error } = useOperatorDetails(operatorId, { enabled: !!operatorId });
    const [sendMobilicEmail, setSendMobilicEmail] = useState<boolean>(false);
    useSendMobilicEmail(operatorId, {
        enabled: !!operatorId && sendMobilicEmail,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onError: (error) => {
            errorMessage({
                content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
            });
        },
        onSuccess: () => {
            successMessage({
                content: formatMessage(genericMessages.emailSendSuccess),
            });
        },
        onSettled: () => {
            setSendMobilicEmail(false);
        },
    });
    const pageTitle = [getFullName(operatorDetails), operatorDetails?.reference && `(${operatorDetails?.reference})`]
        .filter(Boolean)
        .join(' ') || <Spin />;
    const onChangeTab = useCallback(
        (tab) => {
            history.push(
                getRoute(RoutePathName.managementOperatorsDetails, {
                    operatorId,
                    tab: tab as ManagementOperatorsDetailsTabs,
                })
            );
        },
        [history, operatorId]
    );

    const [queryParams] = useQueryParams('ManagementOperatorsDetailsUnavailabilities');
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const status = (queryParams.getAll('status') as OperatorAbsenceStatus[]) ?? undefined;

    const [isExportEnabled, setIsExportEnabled] = useState(false);
    const { isLoading: isExporting } = useOperatorExport(
        { operatorId, exportType: tab, sort, sortOrder, status },
        {
            enabled: isExportEnabled,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
                });
            },
            onSettled: () => {
                setIsExportEnabled(false);
            },
        }
    );

    const dropdownOverlay = (
        <Menu
            items={[
                {
                    key: 'loginInfos',
                    icon: <LockClosed className="text-primary" />,
                    disabled: !operatorDetails,
                    onClick: () => {
                        infoModal.show({ operator: operatorDetails });
                    },
                    label: (
                        <FormattedMessage
                            id="operator_list.column.actions.menu.login_infos"
                            defaultMessage="Informations de connexion"
                        />
                    ),
                },
                {
                    key: 'qrCode',
                    icon: <Qrcode className="text-primary" />,
                    disabled: !operatorDetails,
                    onClick: () => {
                        if (operatorDetails) {
                            downloadQrCodeAsImage(operatorDetails.id, `qrcode-chauffeur-${operatorDetails.reference}`);
                        }
                    },
                    label: (
                        <FormattedMessage
                            id="operator_list.column.actions.menu.qr_code"
                            defaultMessage="Télécharger le QR Code"
                        />
                    ),
                },
                {
                    key: 'sendMobilicLink',
                    icon: <SmartPhone />,
                    onClick: () => {
                        setSendMobilicEmail(true);
                    },
                    label: <FormattedMessage {...operatorMessages.sendMobilicLink} />,
                },
            ]}
        />
    );

    return isError ? (
        <ApiResult status={error.response?.status} />
    ) : (
        <>
            <div className="flex justify-between items-center space-x-2">
                <ListTitle className="mb-0 uppercase" backRoute={getRoute(RoutePathName.managementOperators)}>
                    {pageTitle}
                </ListTitle>
                <div>
                    <Button
                        type="primary"
                        icon={<Download className="text-base" />}
                        onClick={() => setIsExportEnabled(true)}
                        loading={isExporting}
                    >
                        <FormattedMessage {...genericMessages.export} tagName="span" />
                    </Button>
                    <Dropdown
                        overlay={dropdownOverlay}
                        placement="bottomRight"
                        arrow={{ pointAtCenter: true }}
                        trigger={['click']}
                    >
                        <Button className="ml-4" type="default" icon={<SvgDotsHorizontal className="text-base" />} />
                    </Dropdown>
                </div>
            </div>
            <Tabs onChange={onChangeTab} defaultActiveKey={tab}>
                {Object.values(ManagementOperatorsDetailsTabs).map((tab) => {
                    switch (tab) {
                        case ManagementOperatorsDetailsTabs.information:
                            return (
                                <Tabs.TabPane tab={formatMessage(genericMessages.informations)} key={tab}>
                                    <ManagementOperatorsDetailsInfos />
                                </Tabs.TabPane>
                            );

                        case ManagementOperatorsDetailsTabs.history:
                            return (
                                <Tabs.TabPane tab={formatMessage(genericMessages.historyAndAnalytics)} key={tab}>
                                    <ManagementOperatorsDetailsHistory />
                                </Tabs.TabPane>
                            );

                        case ManagementOperatorsDetailsTabs.absences:
                            return (
                                <Tabs.TabPane tab={formatMessage(operatorMessages.unavailabilities)} key={tab}>
                                    <ManagementOperatorsDetailsUnavailabilities />
                                </Tabs.TabPane>
                            );

                        default:
                            return null;
                    }
                })}
            </Tabs>
        </>
    );
};

export default ManagementOperatorsDetails;
