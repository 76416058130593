import { VFC } from 'react';
import { Card, Col, Row, Space, Typography } from 'antd';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { useParams } from 'react-router-dom';

import Seo from '../../../components/Seo';
import { Operator } from '../../../queries/api/types';
import { useOperatorDetails } from '../../../queries/operators';
import ApiResult from '../../../components/ApiResult';
import LabelWithTooltipIcon from '../../../components/LabelWithTooltipIcon';
import NoData from '../../../components/NoData';
import OperatorWorkedDaysListCard from './OperatorWorkedDaysListCard';
import OperatorTaskGroupListCard from './OperatorTaskGroupListCard';
import OperatorAbsenceListCard from './OperatorAbsenceListCard';
import FormattedDuration from '../../../components/FormattedDuration';

const ManagementOperatorsDetailsHistory: VFC = () => {
    const { operatorId } = useParams<{ operatorId: Operator['id'] }>();
    const {
        data: operatorDetails,
        isLoading,
        isError,
        error,
    } = useOperatorDetails(operatorId, { enabled: !!operatorId });
    const pageTitle = operatorDetails?.reference;

    return (
        <>
            <Seo title={pageTitle} />
            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={8}>
                            <Card loading={isLoading} className="h-full">
                                <Typography.Title level={2} className="text-base font-medium text-faded-blue">
                                    <LabelWithTooltipIcon
                                        tooltip={
                                            <FormattedMessage
                                                id="management.operators.history.data_from_mobile_app"
                                                defaultMessage="Données provenant de l'application Mobile"
                                                description="Card title tooltip"
                                            />
                                        }
                                    >
                                        <FormattedMessage
                                            id="management.operators.history.last_working_day"
                                            defaultMessage="Dernier jour travaillé"
                                            description="Card title"
                                        />
                                    </LabelWithTooltipIcon>
                                </Typography.Title>
                                <div className="flex items-center gap-4">
                                    {operatorDetails?.computedProperties.lastWorkingDayStart ? (
                                        <>
                                            <span className="text-2xl font-semibold uppercase">
                                                <FormattedDate
                                                    value={operatorDetails?.computedProperties.lastWorkingDayStart}
                                                />
                                            </span>
                                            <span className="text-lg font-medium uppercase border rounded py-1 px-2">
                                                <FormattedTime
                                                    value={operatorDetails?.computedProperties.lastWorkingDayStart}
                                                />
                                                {' → '}
                                                {operatorDetails?.computedProperties.lastWorkingDayEnd ? (
                                                    <FormattedTime
                                                        value={operatorDetails?.computedProperties.lastWorkingDayEnd}
                                                    />
                                                ) : (
                                                    ' - '
                                                )}
                                            </span>
                                        </>
                                    ) : (
                                        <span className="text-xl font-semibold">
                                            <NoData />
                                        </span>
                                    )}
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card loading={isLoading} className="h-full">
                                <Typography.Title level={2} className="text-base font-medium text-faded-blue">
                                    <LabelWithTooltipIcon
                                        tooltip={
                                            <FormattedMessage
                                                id="management.operators.history.hours_worked_over_semester_tooltip"
                                                defaultMessage="Cumul des heures travaillées et déclarées via l'application mobile"
                                                description="Card title tooltip"
                                            />
                                        }
                                    >
                                        <Col>
                                            <Row>
                                                <FormattedMessage
                                                    id="management.operators.history.hours_worked"
                                                    defaultMessage="Temps de travail cumulé"
                                                    description="Card title1"
                                                />
                                            </Row>
                                            <Row>
                                                <FormattedMessage
                                                    id="management.operators.history.over_semester"
                                                    defaultMessage="sur le semestre"
                                                    description="Card title2"
                                                />
                                            </Row>
                                            <Row className="text-xs">
                                                <FormattedMessage
                                                    id="management.operators.history.current_semester"
                                                    defaultMessage="(semestre en cours)"
                                                    description="Card subtitle"
                                                />
                                            </Row>
                                        </Col>
                                    </LabelWithTooltipIcon>
                                </Typography.Title>
                                <span className="text-2xl font-semibold">
                                    <span className="uppercase">
                                        <FormattedDuration
                                            value={operatorDetails?.computedProperties.timeWorkedOverSemester ?? 0}
                                        ></FormattedDuration>
                                    </span>
                                </span>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card loading={isLoading} className="h-full">
                                <Typography.Title level={2} className="text-base font-medium text-faded-blue">
                                    <LabelWithTooltipIcon
                                        tooltip={
                                            <FormattedMessage
                                                id="management.operators.history.overtime_over_semester_tooltip"
                                                defaultMessage="Heures réalisées au delà de l'accord de modulation"
                                                description="Card title tooltip"
                                            />
                                        }
                                    >
                                        <Col>
                                            <Row>
                                                <FormattedMessage
                                                    id="management.operators.history.overtime"
                                                    defaultMessage="Heures sup. cumulées"
                                                    description="Card title3"
                                                />
                                            </Row>
                                            <Row>
                                                <FormattedMessage
                                                    id="management.operators.history.over_semester"
                                                    defaultMessage="sur le semestre"
                                                    description="Card title2"
                                                />
                                            </Row>
                                            <Row className="text-xs">
                                                <FormattedMessage
                                                    id="management.operators.history.current_semester"
                                                    defaultMessage="(semestre en cours)"
                                                    description="Card subtitle"
                                                />
                                            </Row>
                                        </Col>
                                    </LabelWithTooltipIcon>
                                </Typography.Title>
                                <span className="text-2xl font-semibold uppercase">
                                    <FormattedDuration
                                        value={operatorDetails?.computedProperties.overtimeOverSemester ?? 0}
                                    ></FormattedDuration>
                                </span>
                            </Card>
                        </Col>
                    </Row>
                    <OperatorWorkedDaysListCard operatorId={operatorId} />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <OperatorTaskGroupListCard operatorId={operatorId} className="h-full" />
                        </Col>
                        <Col xs={24} lg={12}>
                            <OperatorAbsenceListCard operatorId={operatorId} className="h-full" />
                        </Col>
                    </Row>
                </Space>
            )}
        </>
    );
};

export default ManagementOperatorsDetailsHistory;
