import { useMemo, VFC } from 'react';
import { Select, SelectProps } from 'antd';
import { useIntl } from 'react-intl';

import { CustomerType } from '../../queries/api/types';
import { customerTypeMessagesMap } from '../../i18n/customerMessages';

const CustomerTypeTagSelect: VFC<SelectProps<CustomerType>> = (props) => {
    const { formatMessage } = useIntl();

    const options: Array<{
        label: string;
        value: CustomerType;
    }> = useMemo(
        () =>
            Object.values(CustomerType).map((key) => ({
                label: formatMessage(customerTypeMessagesMap.get(key)!),
                value: CustomerType[key],
            })),
        [formatMessage]
    );

    return (
        <Select<CustomerType>
            placeholder={formatMessage({
                id: 'customer_type_select.placeholder',
                defaultMessage: 'Choisir un type',
            })}
            filterOption={false}
            options={options}
            showSearch={false}
            showArrow
            {...props}
        />
    );
};

export default CustomerTypeTagSelect;
