/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.667 8.333v4.334a1 1 0 0 0 1 1h8.666a1 1 0 0 0 1-1V8.333"
            stroke="#F97315"
            strokeWidth={1.75}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.667 5c0-.368.298-.667.666-.667h11.334c.368 0 .666.299.666.667v2.667a.667.667 0 0 1-.666.666H2.333a.667.667 0 0 1-.666-.666V5Z"
            stroke="#F97315"
            strokeWidth={1.75}
            strokeLinejoin="round"
        />
        <path
            d="M10.333 4.333V3a.667.667 0 0 0-.666-.667H6.333A.667.667 0 0 0 5.667 3v1.333M5 7.667v2M11 7.667v2"
            stroke="#F97315"
            strokeWidth={1.75}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgRepair: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-repair', props.className)} />
));
export default SvgRepair;
