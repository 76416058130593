import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { authorizeMobilic, MobilicAuthorizeResponse } from './api/apiMobilic';

export enum AuthorizeType {
    operator = 'operator',
    place = 'place',
}

export const useAuthorizeMobilic = (
    options?: UseMutationOptions<MobilicAuthorizeResponse, AxiosError, { code: string; type: AuthorizeType }>
) => {
    return useMutation<MobilicAuthorizeResponse, AxiosError, { code: string; type: AuthorizeType }>(
        async (params) => await authorizeMobilic(params.code, params.type),
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);
            },
        }
    );
};
