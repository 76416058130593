import { FC } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import { HiOutlineInformationCircle } from 'react-icons/hi';

interface LabelWithTooltipIconProps {
    tooltip: TooltipProps['title'];
}

const LabelWithTooltipIcon: FC<LabelWithTooltipIconProps> = ({ tooltip, children }) => (
    <span className="flex items-center">
        {children}
        <Tooltip title={tooltip}>
            <HiOutlineInformationCircle style={{ fontSize: '20px', marginLeft: '0.5rem' }} />
        </Tooltip>
    </span>
);

export default LabelWithTooltipIcon;
