import { Button, Typography } from 'antd';
import { VFC } from 'react';
import { useIntl } from 'react-intl';

import { ExclamationCircle } from '../../../../components/icons';
import formMessages from '../../../../i18n/formMessages';

interface ForecastRemoveConfirmModalProps {
    handleCancelButtonClick: () => void;
    handleSubmitButtonClick: () => void;
}

const ForecastRemoveConfirmModal: VFC<ForecastRemoveConfirmModalProps> = ({
    handleCancelButtonClick,
    handleSubmitButtonClick,
}) => {
    const { formatMessage } = useIntl();

    return (
        <div className="flex flex-col justify-between">
            <div className="flex gap-5">
                <ExclamationCircle className="icon-md" />
                <div>
                    <Typography.Title className="font-bold text-lg text-primary">
                        {formatMessage(formMessages.deleteConfirmTitle)}
                    </Typography.Title>
                    <Typography.Title className="font-medium text-base text-black mt-4">
                        {formatMessage(formMessages.deleteConfirmMessage)}
                    </Typography.Title>
                </div>
            </div>

            <div className="flex gap-2 justify-end mt-8">
                <Button ghost type="primary" onClick={handleCancelButtonClick}>
                    {formatMessage(formMessages.cancel)}
                </Button>

                <Button danger type="primary" onClick={handleSubmitButtonClick}>
                    {formatMessage(formMessages.delete)}
                </Button>
            </div>
        </div>
    );
};

export default ForecastRemoveConfirmModal;
