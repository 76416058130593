import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Form, FormProps, ModalProps, Row } from 'antd';
import { useIntl } from 'react-intl';
import { ReactNode } from 'react';
import { HiX } from 'react-icons/hi';

import { requiredRule } from '../helpers';
import genericMessages from '../i18n/genericMessages';
import TaskGroupVehicleSelector from '../pages/management/taskGroups/TaskGroupVehicleSelector';
import { useTaskGroupListAvailableVehicles } from '../queries/taskGroups';
import CustomModal from './CustomModal';

interface SelectVehiculeModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    title?: ReactNode;
    isLoading?: boolean;
    cancelText?: string;
    confirmText?: string;
    taskGroupId: string;
}
const SelectVehiculeModal = NiceModal.create<SelectVehiculeModalProps>(
    ({ title, isLoading, cancelText, confirmText, taskGroupId, ...props }) => {
        const { formatMessage } = useIntl();
        const selectModal = useModal();
        const [form] = Form.useForm<{ vehicleId: string }>();
        const vehicleId = Form.useWatch('vehicleId', form);
        const { data: availableVehicles } = useTaskGroupListAvailableVehicles({
            id: taskGroupId,
            pageSize: 999,
        });

        const onSubmit: FormProps<{ vehicleId: string }>['onFinish'] = (_value) => {
            selectModal.resolve(vehicleId);
        };

        return (
            <CustomModal
                {...props}
                {...antdModal(selectModal)}
                afterClose={selectModal.remove}
                title={title}
                closable={true}
                footer={false}
                centered
                closeIcon={<HiX className="text-blue text-xl" />}
            >
                <Row>
                    <Col span={24}>
                        <Form name="vehiculeForm" form={form} onFinish={onSubmit}>
                            <Form.Item name="vehicleId" required={true} rules={[requiredRule]} className="mb-4">
                                <TaskGroupVehicleSelector
                                    vehicles={availableVehicles}
                                    placement="bottom"
                                    vehicleId={vehicleId}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="flex justify-end gap-2">
                        <Button key="cancelBtn" className="text-blue" onClick={selectModal.hide}>
                            {cancelText || formatMessage(genericMessages.cancel)}
                        </Button>
                        <Button key="yesBtn" onClick={() => form.submit()} type="primary" loading={isLoading}>
                            {confirmText || formatMessage(genericMessages.validate)}
                        </Button>
                    </Col>
                </Row>
            </CustomModal>
        );
    }
);
export default SelectVehiculeModal;
