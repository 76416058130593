import { FC, useCallback, useMemo } from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { Table, Space, Button, Dropdown, Menu, Input, InputProps, Tooltip, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { useTransporterList, useExportTransporters } from '../../../queries/transporters';
import { Transporter, ValueListSlug } from '../../../queries/api/types';
import ListTitle from '../../../components/ListTitle';
import Seo from '../../../components/Seo';
import { Ban, DotsHorizontal, Download, PencilAlt, Plus, Search } from '../../../components/icons';
import useQueryParams from '../../../hooks/queryParams';
import genericMessages from '../../../i18n/genericMessages';
import { formatAddress, sortOrderConverter } from '../../../helpers';
import { getRoute, RoutePathName } from '../../../routes';
import ValueListItemSelect from '../../../components/selects/ValueListItemSelect';
import ContactTag from '../../../components/ContactTag';
import BasicList from '../../../components/BasicList';
import { TransporterCreateStep } from './TransporterCreate';
import ApiResult from '../../../components/ApiResult';
import { errorMessage } from '../../../helpers/message';

const getDetailsLink = (record: Transporter) => {
    const stepsLength = Object.keys(TransporterCreateStep).length;
    const areCreationStepsNotDone = (record.meta?.creationStep ?? 0) < stepsLength - 1;

    return areCreationStepsNotDone
        ? {
              pathname: getRoute(RoutePathName.transporterCreate, {
                  step: (record.meta?.creationStep ?? 0) + 1,
              }),
              search: new URLSearchParams({ transporterId: record.id }).toString(),
          }
        : getRoute(RoutePathName.transporterDetails, { transporterId: record.id });
};

let searchTimeout: number;

const TransportersList: FC = () => {
    const { formatMessage } = useIntl();
    const isDisabledTransportersRoute = !!useRouteMatch({
        path: getRoute(RoutePathName.transportersDisabled),
        exact: true,
    });
    const [queryParams, setQueryParams] = useQueryParams('transporters-list');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const groupIds = queryParams.getAll('groupIds') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const {
        data: transportersList,
        isLoading,
        isFetching,
        isError,
        error,
    } = useTransporterList({ isActive: !isDisabledTransportersRoute, page, search, groupIds, sort, sortOrder });
    const onTableChange: TableProps<Transporter>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
            });
        },
        [setQueryParams]
    );
    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );
    const { refetch: exportTransporters } = useExportTransporters({
        enabled: false,
        onError: () => {
            errorMessage({ content: formatMessage(genericMessages.defaultExportError) });
        },
    });
    const onClickExport = () => {
        exportTransporters();
    };
    const columns: ColumnsType<Transporter> = useMemo(
        () => [
            {
                key: 'name',
                title: formatMessage(genericMessages.name),
                render: (_, item) => (
                    <Link to={getDetailsLink(item)} className="blue-link font-bold">
                        {item.name}
                    </Link>
                ),
                sorter: true,
                fixed: 'left',
            },
            {
                key: 'groups',
                title: formatMessage(genericMessages.group, { count: 2 }),
                render: (_, item) => (
                    <BasicList inline>
                        {item.groups.map((group) => (
                            <li key={group.id}>
                                <Tooltip title={group.fields.name}>
                                    <Tag>{group.fields.reference}</Tag>
                                </Tooltip>
                            </li>
                        ))}
                    </BasicList>
                ),
                filteredValue: groupIds,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={groupIds}
                            valueListSlug={ValueListSlug.groups}
                            onChange={(value) => setQueryParams({ groupIds: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                key: 'address',
                title: formatMessage({
                    id: 'transporter_list.column.address',
                    defaultMessage: 'Adresse de production',
                }),
                render: (_, item) => formatAddress(item.address),
                sorter: true,
            },
            {
                key: 'billingAddress',
                title: formatMessage({
                    id: 'transporter_list.column.billing_address',
                    defaultMessage: 'Adresse de facturation',
                }),
                render: (_, item) => formatAddress(item.billingAddress),
                sorter: true,
            },
            {
                key: 'primaryContact',
                title: formatMessage(genericMessages.primaryContact),
                render: (_, item) => <ContactTag contact={item.primaryContact} />,
                sorter: true,
            },
            {
                key: 'administrativeContact',
                title: formatMessage(genericMessages.administrativeContact),
                render: (_, item) => <ContactTag contact={item.administrativeContact} />,
                sorter: true,
            },
        ],
        [formatMessage, groupIds, setQueryParams]
    );

    return (
        <>
            <Seo
                title={formatMessage({
                    id: 'transporters_list.title',
                    defaultMessage: 'Partenaires transport',
                    description: 'Transporter page title',
                })}
            />
            <div className="flex justify-between items-center mb-6">
                <ListTitle
                    count={transportersList?.totalCount}
                    className="mb-0 uppercase"
                    backRoute={isDisabledTransportersRoute ? getRoute(RoutePathName.transporters) : undefined}
                >
                    <Route path={getRoute(RoutePathName.transporters)} exact>
                        <FormattedMessage
                            id="transporters_list.header.title"
                            defaultMessage="Partenaires transport"
                            description="Header list title"
                        />
                    </Route>
                    <Route path={getRoute(RoutePathName.transportersDisabled)} exact>
                        <FormattedMessage
                            id="transporters_list_disabled.header.title"
                            defaultMessage="Partenaires transport inactifs"
                            description="Header list title"
                        />
                    </Route>
                </ListTitle>
                <Route path={getRoute(RoutePathName.transporters)} exact>
                    <div className="flex items-center space-x-2">
                        <Link to={getRoute(RoutePathName.transporterCreate)}>
                            <Button icon={<Plus className="text-base" />} type="primary">
                                <FormattedMessage {...genericMessages.add} tagName="span" />
                            </Button>
                        </Link>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key="disabled" icon={<Ban className="text-faded-blue text-base" />}>
                                        <Link to={getRoute(RoutePathName.transportersDisabled)}>
                                            <FormattedMessage
                                                id="transporters_list.header.menu.disabled_transporters"
                                                defaultMessage="Partenaires transport inactifs"
                                                description="Header list menu item"
                                            />
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        key="export"
                                        icon={<Download className="text-faded-blue text-base" />}
                                        onClick={onClickExport}
                                    >
                                        <FormattedMessage {...genericMessages.exportData} />
                                    </Menu.Item>
                                    <Menu.Item
                                        key="valuesLists"
                                        icon={<PencilAlt className="text-faded-blue text-base" />}
                                    >
                                        <Link to={getRoute(RoutePathName.transportersValueLists)}>
                                            <FormattedMessage {...genericMessages.valuesLists} />
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                            placement="bottomRight"
                        >
                            <Button icon={<DotsHorizontal className="text-lg" />} />
                        </Dropdown>
                    </div>
                </Route>
            </div>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Input
                    placeholder={formatMessage({
                        id: 'transporters_list.search_field.placeholder',
                        defaultMessage: 'Rechercher une dénomination commerciale, un contact ...',
                        description: 'Transporter page search field placeholder',
                    })}
                    onChange={onSearch}
                    defaultValue={search}
                    prefix={<Search className="text-primary text-base leading-4" />}
                    size="small"
                    allowClear
                />
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Table<Transporter>
                        rowKey="id"
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={transportersList?.items}
                        pagination={{
                            total: transportersList?.totalCount,
                            current: page + 1,
                            pageSize: transportersList?.pageSize,
                            position: ['bottomCenter'],
                            hideOnSinglePage: true,
                        }}
                        scroll={{
                            x: 1440,
                            y: `calc(100vh - ${(transportersList?.pageCount ?? 0) > 1 ? 291 + 64 : 291}px)`,
                            scrollToFirstRowOnChange: true,
                        }}
                        onChange={onTableChange}
                    />
                )}
            </Space>
        </>
    );
};

export default TransportersList;
