import { VFC } from 'react';
import { Dropdown, Avatar, Menu, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { useAuth } from '../context/AuthContext';
import { Logout } from './icons';

const UserDropdown: VFC = () => {
    const { user, logout } = useAuth();
    const menu = (
        <Menu>
            <Menu.Item
                key="logout"
                icon={<Logout />}
                onClick={() => {
                    logout.mutate();
                }}
            >
                <FormattedMessage
                    id="user_dropdown.menu.logout"
                    defaultMessage="Se déconnecter"
                    description="user dropdown menu item"
                />
            </Menu.Item>
        </Menu>
    );

    return user ? (
        <Dropdown trigger={['click']} overlay={menu} placement="topRight">
            <Button id="user-menu-button" type="text">
                <Avatar size={40} shape="square">
                    {user?.firstName?.substring(0, 1) || <UserOutlined />}
                </Avatar>
                <div id="user-menu-button-content">
                    <span>{user?.firstName}</span>
                </div>
            </Button>
        </Dropdown>
    ) : null;
};

export default UserDropdown;
