import { Card, Spin, Table, TableProps, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import ApiResult from '../../../components/ApiResult';
import LabelWithTooltipIcon from '../../../components/LabelWithTooltipIcon';
import { getFullName, sortOrderConverter } from '../../../helpers';
import genericMessages from '../../../i18n/genericMessages';
import { TaskGroup, TaskGroupStatus, Vehicle } from '../../../queries/api/types';
import { useVehicleListTaskGroups } from '../../../queries/vehicles';
import { getRoute, RoutePathName } from '../../../routes';
import useQueryParams from '../../../hooks/queryParams';

interface VehicleOperatorListCardProps {
    vehicleId: Vehicle['id'];
}

const VehicleOperatorListCard: VFC<VehicleOperatorListCardProps> = ({ vehicleId }) => {
    const [queryParams] = useQueryParams('vehicle-details');
    const fromDate = queryParams.get('fromDate') ?? undefined;
    const toDate = queryParams.get('toDate') ?? undefined;
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState<string | undefined>('date');
    const [sortOrder, setSortOrder] = useState<string | undefined>('desc');
    const { formatMessage, formatDate } = useIntl();
    const {
        data: taskGroups,
        isLoading,
        error,
        isError,
    } = useVehicleListTaskGroups({
        id: vehicleId,
        fromDate,
        toDate,
        page,
        sort,
        sortOrder,
        pageSize: 8,
        status: [TaskGroupStatus.done],
    });
    const onTableChange: TableProps<TaskGroup>['onChange'] = (pagination, _, sorter) => {
        const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

        setPage((pagination.current ?? 1) - 1);
        setSort(sortObj.column?.key ? `${sortObj.column?.key}` : undefined);
        setSortOrder(sortObj.order ? sortOrderConverter(sortObj.order) : undefined);
    };
    const columns = useMemo<ColumnsType<TaskGroup>>(
        () => [
            {
                key: 'operator',
                title: formatMessage(genericMessages.operator),
                render: (value, record) => (
                    <Link
                        to={getRoute(RoutePathName.operatorDetails, { operatorId: record.operator?.id || '' })}
                        className="blue-link font-semibold"
                    >
                        {getFullName(record.operator)}
                    </Link>
                ),
                sorter: true,
            },
            {
                dataIndex: 'reference',
                key: 'reference',
                title: formatMessage(genericMessages.taskGroup, { count: 1 }),
                sorter: true,
            },
            {
                key: 'date',
                title: formatMessage(genericMessages.date),
                render: (value, record) => formatDate(record.date),
                sorter: true,
                defaultSortOrder: 'descend',
            },
        ],
        [formatDate, formatMessage]
    );

    return (
        <Spin spinning={isLoading}>
            <Card>
                <Typography.Title level={2} className="text-base font-medium text-faded-blue mb-6">
                    <LabelWithTooltipIcon tooltip="à définir">
                        <FormattedMessage
                            id="management.vehicles.history.operators"
                            defaultMessage="Chauffeurs ayant utilisé le véhicule"
                            description="Card title"
                        />
                    </LabelWithTooltipIcon>
                </Typography.Title>
                {isError ? (
                    <ApiResult status={error.response?.status} />
                ) : (
                    <Table<TaskGroup>
                        columns={columns}
                        rowKey="id"
                        dataSource={taskGroups?.items}
                        onChange={onTableChange}
                        pagination={{
                            total: taskGroups?.totalCount,
                            current: page + 1,
                            pageSize: taskGroups?.pageSize,
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            showSizeChanger: false,
                            className: 'mb-0',
                        }}
                        size="small"
                        bordered
                    />
                )}
            </Card>
        </Spin>
    );
};

export default VehicleOperatorListCard;
