import { VFC } from 'react';
import { Button, Form, InputNumber, Select } from 'antd';
import { useIntl } from 'react-intl';

import { getRequiredRule } from '../../../../i18n';
import customerMessages from '../../../../i18n/customerMessages';
import genericMessages from '../../../../i18n/genericMessages';
import formMessages from '../../../../i18n/formMessages';
import { classNames } from '../../../../helpers';
import { ChevronDown } from '../../../../components/icons';
import { CustomerForecastForm } from './CustomerCalendar';

interface ForecastFormModalProps {
    formInitialValues: CustomerForecastForm;
    handleCloseModal: (arg: unknown) => void;
    handleRemoveButtonClick: (arg: CustomerForecastForm) => void;
    handleSubmit: (arg: CustomerForecastForm) => void;
}

const ForecastFormModal: VFC<ForecastFormModalProps> = ({
    formInitialValues,
    handleCloseModal,
    handleRemoveButtonClick,
    handleSubmit,
}) => {
    const { formatMessage } = useIntl();

    return (
        <Form
            initialValues={formInitialValues}
            onFinish={(values) =>
                handleSubmit({
                    ...formInitialValues,
                    ...values,
                })
            }
        >
            <Form.Item
                required
                rules={[getRequiredRule(formatMessage)]}
                name="capacity"
                label={formatMessage(customerMessages.forecastModalOtMaximum)}
            >
                <InputNumber
                    min={0}
                    placeholder={formatMessage(genericMessages.numberSelect)}
                    style={{ width: '100%' }}
                />
            </Form.Item>

            <Form.Item name="tolerance" label={formatMessage(genericMessages.tolerancePercentage)}>
                <InputNumber
                    addonAfter="%"
                    addonBefore={
                        <Form.Item noStyle name="toleranceSign">
                            <Select className="font-medium text-xl text-faded-blue" suffixIcon={<ChevronDown />}>
                                <Select.Option value="+">+</Select.Option>
                                <Select.Option value="-">-</Select.Option>
                            </Select>
                        </Form.Item>
                    }
                    min={0}
                    placeholder={formatMessage(genericMessages.percentageSelect)}
                    style={{ width: '100%' }}
                />
            </Form.Item>

            <div
                className={classNames(
                    'flex',
                    formInitialValues.source === 'create' ? 'justify-end' : 'justify-between'
                )}
            >
                {formInitialValues.source === 'update' && (
                    <Button danger type="ghost" onClick={() => handleRemoveButtonClick(formInitialValues)}>
                        {formatMessage(formMessages.deleteSelection)}
                    </Button>
                )}

                <div className="flex gap-2">
                    <Button ghost onClick={handleCloseModal} type="primary">
                        {formatMessage(formMessages.cancel)}
                    </Button>

                    <Button htmlType="submit" type="primary">
                        {formatMessage(formMessages.validate)}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default ForecastFormModal;
