import { VFC } from 'react';
import { Space, Tabs, Spin, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Seo from '../../../components/Seo';
import ListTitle from '../../../components/ListTitle';
import { useValueListList } from '../../../queries/valueLists';
import ApiResult from '../../../components/ApiResult';
import ValueListForm from '../../../components/valueList/ValueListForm';
import { getRoute, RoutePathName } from '../../../routes';
import { ValueListSlug } from '../../../queries/api/types';
import ValueListTable from '../../../components/valueList/ValueListTable';
import { Plus } from '../../../components/icons';

const OperatorsValueList: VFC = () => {
    const { formatMessage } = useIntl();
    const { tab } = useLocation<{ tab: ValueListSlug }>().state ?? {};
    const history = useHistory();
    const { data: valueLists, isLoading, isFetching, isError, error } = useValueListList();
    const items = valueLists?.items?.filter((item) =>
        [ValueListSlug.operatorContracts, ValueListSlug.operatorAbsenceReasons].includes(item.slug as ValueListSlug)
    );
    const profiles = valueLists?.items?.find((item) => item.slug === ValueListSlug.operatorProfiles);
    const pageTitle = formatMessage({
        id: 'operators_value_list.title',
        defaultMessage: 'Données sociales',
        description: 'Operators value list page title',
    });
    const addProfileRouteParams = new URLSearchParams({ valueListSlug: profiles?.slug ?? '' }).toString();

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                <ListTitle className="mb-0 uppercase" backRoute={getRoute(RoutePathName.operators)}>
                    {pageTitle}
                </ListTitle>
            </div>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Spin spinning={isLoading || isFetching}>
                        <Tabs defaultActiveKey={tab}>
                            {items?.map((valueList) => (
                                <Tabs.TabPane key={valueList.slug} tab={valueList.title}>
                                    <ValueListForm valueListSlug={valueList.slug} queryParamsKey={valueList.slug} />
                                </Tabs.TabPane>
                            ))}
                            {profiles && (
                                <Tabs.TabPane key={profiles.slug} tab={profiles.title}>
                                    <div className="flex justify-between items-center mb-6">
                                        <ListTitle level={3} className="mb-0" alt>
                                            <FormattedMessage
                                                id="operators_value_list.profiles_tab.title"
                                                defaultMessage="Profils de contrats “types”"
                                            />
                                        </ListTitle>
                                        <Link
                                            to={{
                                                pathname: getRoute(RoutePathName.operatorsProfileForm),
                                                search: addProfileRouteParams,
                                            }}
                                        >
                                            <Button type="primary" size="large" icon={<Plus />}>
                                                <FormattedMessage
                                                    id="operators_value_list.profiles_tab.add_profile_button"
                                                    defaultMessage="Ajouter un profil"
                                                    tagName="span"
                                                />
                                            </Button>
                                        </Link>
                                    </div>
                                    <ValueListTable
                                        valueListSlug={profiles.slug}
                                        queryParamsKey="operatorsValueListProfiles"
                                        onClickEdit={(item) => {
                                            history.push({
                                                pathname: getRoute(RoutePathName.operatorsProfileForm, {
                                                    valueListItemId: item.id,
                                                }),
                                                search: addProfileRouteParams,
                                            });
                                        }}
                                    />
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                    </Spin>
                )}
            </Space>
        </>
    );
};

export default OperatorsValueList;
