import { useCallback, useMemo, VFC } from 'react';
import { Card, Space, Tabs, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { getRoute, RoutePathName } from '../../../routes';
import { Customer } from '../../../queries/api/types';
import { useCustomerDetails, useCustomerUpdate } from '../../../queries/customers';
import { CustomerUpdatePayload } from '../../../queries/api/customers';
import customerMessages from '../../../i18n/customerMessages';
import genericMessages from '../../../i18n/genericMessages';
import Seo from '../../../components/Seo';
import ApiResult from '../../../components/ApiResult';
import DetailsFormCard from '../../../components/form/DetailsFormCard';
import DetailsFormEditableTitle from '../../../components/form/DetailsFormEditableTitle';
import StatusSwitch from '../../../components/form/StatusSwitch';
import { CustomerCalendar } from './forecasts';
import {
    CustomerDetailsTabs,
    CustomerForm,
    CustomerFormSection,
    formatCustomerInitialValues,
    formatCustomerUpdatePayload,
    getFormSections,
} from '.';

const CustomerDetails: VFC = () => {
    const { customerId, tab } = useParams<{ customerId: Customer['id']; tab: CustomerDetailsTabs }>();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { data: customerDetails, isError, error } = useCustomerDetails(customerId, { enabled: !!customerId });
    const pageTitle = customerDetails?.reference || formatMessage(genericMessages.editing);

    const onChangeTab = useCallback(
        (tab) => {
            history.push(
                getRoute(RoutePathName.customerDetails, {
                    customerId,
                    tab,
                })
            );
        },
        [history, customerId]
    );

    const sections = useMemo(
        () =>
            getFormSections(
                formatMessage,
                Object.values(CustomerFormSection).filter((section) => section !== CustomerFormSection.forecast)
            ),
        [formatMessage]
    );
    const sectionCards = useMemo(
        () =>
            sections.map((section) => (
                <DetailsFormCard<Customer, CustomerForm, CustomerUpdatePayload>
                    {...section}
                    key={section.title}
                    detailsQueryHandler={useCustomerDetails}
                    updateQueryHandler={useCustomerUpdate}
                    initialValueFormatter={formatCustomerInitialValues}
                    formatPayload={formatCustomerUpdatePayload()}
                    id={customerId}
                />
            )),
        [customerId, sections]
    );

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6 mt-4">
                <DetailsFormEditableTitle
                    backRoute={getRoute(RoutePathName.customers)}
                    detailsQueryHandler={useCustomerDetails}
                    updateQueryHandler={useCustomerUpdate}
                    placeholder={formatMessage(genericMessages.name)}
                    id={customerId}
                />
                <StatusSwitch
                    detailsQueryHandler={useCustomerDetails}
                    updateQueryHandler={useCustomerUpdate}
                    id={customerId}
                />
            </div>
            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Tabs onChange={onChangeTab} defaultActiveKey={tab}>
                        {Object.values(CustomerDetailsTabs).map((tab) => {
                            switch (tab) {
                                case CustomerDetailsTabs.forecast:
                                    return (
                                        <Tabs.TabPane tab={formatMessage(customerMessages.forecast)} key={tab}>
                                            <Card>
                                                <div className="flex items-center mb-6">
                                                    <Typography.Title level={3} className="text-blue font-bold">
                                                        {formatMessage(customerMessages.forecastCalendarDetailsTitle)}
                                                    </Typography.Title>
                                                </div>
                                                <CustomerCalendar customerId={customerId} />
                                            </Card>
                                        </Tabs.TabPane>
                                    );

                                case CustomerDetailsTabs.informations:
                                    return (
                                        <Tabs.TabPane tab={formatMessage(genericMessages.informations)} key={tab}>
                                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                                {sectionCards}
                                            </Space>
                                        </Tabs.TabPane>
                                    );

                                default:
                                    return null;
                            }
                        })}
                    </Tabs>
                </Space>
            )}
        </>
    );
};

export default CustomerDetails;
