import { SearchPaginationQuery } from '.';
import api from './apiClient';
import { Customer, PublicHolidays } from './types';

export type PublicHolidaysListPayload = SearchPaginationQuery & {
    customerIds: Array<Customer['id']>;
    year: string;
};
export type PublicHolidaysListResponse = PublicHolidays[];

export const list = async ({ year, ...payload }: PublicHolidaysListPayload) => {
    if (!payload.customerIds) {
        throw new Error('missing customers ids');
    }

    return await api
        .get<PublicHolidaysListResponse>(`/customers/publicHolidays/${year}`, {
            params: payload,
        })
        .then((response) => response?.data);
};
