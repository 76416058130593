import { FC } from 'react';
import Popconfirm, { PopconfirmProps } from 'antd/lib/popconfirm';
import { useIntl } from 'react-intl';

import formMessages from '../i18n/formMessages';

interface DeletePopConfirmProps extends Omit<PopconfirmProps, 'title'> {
    title?: PopconfirmProps['title'];
    isLoading?: boolean;
}

const DeletePopConfirm: FC<DeletePopConfirmProps> = ({ children, isLoading, ...props }) => {
    const { formatMessage } = useIntl();
    return (
        <Popconfirm
            title={formatMessage({ id: 'delete_pop_confirm.title', defaultMessage: 'Confirmez-vous la suppression ?' })}
            okText={formatMessage({ id: 'delete_pop_confirm.ok_text', defaultMessage: 'Confirmer' })}
            cancelText={formatMessage(formMessages.cancel)}
            okButtonProps={{
                size: 'small',
                danger: true,
                loading: isLoading,
            }}
            cancelButtonProps={{
                size: 'small',
            }}
            {...props}
        >
            {children ?? formatMessage(formMessages.delete)}
        </Popconfirm>
    );
};

export default DeletePopConfirm;
