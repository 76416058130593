import { FC } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { Button, message, Popconfirm, Table, TableProps, Tooltip } from 'antd';
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { show } from '@ebay/nice-modal-react';

import '../../../assets/styles/Applications.less';
import Seo from '../../../components/Seo';
import ListTitle from '../../../components/ListTitle';
import LayoutHeader from '../../../components/LayoutHeader';
import ApplicationClientFormDrawer from './ApplicationClientFormDrawer';
import ApplicationFormDrawer from './ApplicationFormDrawer';
import { useApplicationRemove, useApplicationsList } from '../../../queries/applications';
import useQueryParams from '../../../hooks/queryParams';
import { useApplicationClientRemove } from '../../../queries/applicationClients';
import { Application, ApplicationClient } from '../../../queries/api/types';

const Applications: FC = () => {
    const [queryParams, setQueryParams] = useQueryParams('applications-list');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const { data: applicationsData, isLoading, isFetching } = useApplicationsList({ page });
    const applicationRemove = useApplicationRemove({
        onError: (error) => {
            message.error(
                `Une erreur est survenue pendant la suppression l'application (${error.response?.status ?? '0'})`
            );
        },
        onSuccess: () => {
            message.success('Application supprimée avec succès');
        },
    });
    const applicationClientRemove = useApplicationClientRemove({
        onError: () => {
            message.error("Une erreur est survenue pendant la suppression l'application client");
        },
        onSuccess: () => {
            message.success('Application client supprimée avec succès');
        },
    });
    const onTableChange: TableProps<Application>['onChange'] = (pagination) => {
        setQueryParams({
            page: (pagination.current ?? 1) - 1,
        });
    };
    const rowKey = (item: Application) => `${item.id}`;
    const onClickCreateApplication = () => {
        show(ApplicationFormDrawer);
    };
    // On Click Action For Application
    const onClickRemoveApplicationButton = (id: Application['id']) => {
        applicationRemove.mutate({ id });
    };
    const onClickEditApplicationButton = (id: Application['id']) => {
        show(ApplicationFormDrawer, { applicationId: id });
    };
    const onClickAddApplicationClientButton = (id: Application['id']) => {
        show(ApplicationClientFormDrawer, { applicationId: id });
    };
    // On Click Action For Application Cient
    const onClickEditApplicationClientButton = (id: ApplicationClient['id'], applicationId: Application['id']) => {
        show(ApplicationClientFormDrawer, { applicationId, applicationClientId: id });
    };
    const onClickRemoveApplicationClientButton = (id: ApplicationClient['id'], applicationId: Application['id']) => {
        applicationClientRemove.mutate({
            id,
            applicationId,
        });
    };
    const columns: Array<ColumnProps<Application>> = [
        {
            title: "Nom de l'application",
            dataIndex: 'name',
        },
        {
            title: 'Application clients',
            key: 'clients',
            render: (record: Application) => record.applicationClients?.length ?? 0,
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            width: '15%',
            render: (id: string, record: Application) => (
                <>
                    <Tooltip overlay="Ajouter une application client">
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<AppstoreAddOutlined />}
                            onClick={onClickAddApplicationClientButton.bind(null, id)}
                        />
                    </Tooltip>
                    <Tooltip overlay="Modifier">
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={onClickEditApplicationButton.bind(null, id)}
                        />
                    </Tooltip>

                    <Tooltip overlay="Supprimer">
                        <Popconfirm
                            placement="topLeft"
                            title={'Êtes-vous sûr de supprimer cette application ?'}
                            onConfirm={onClickRemoveApplicationButton.bind(null, id)}
                            okText="Oui"
                            cancelText="Non"
                            disabled={record.applicationClients?.length !== 0}
                        >
                            <Button
                                className="actions-buttons"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                disabled={record.applicationClients?.length !== 0}
                            />
                        </Popconfirm>
                    </Tooltip>
                </>
            ),
        },
    ];

    const expandedRowRender = (record: Application) => {
        const subColumns: Array<ColumnProps<ApplicationClient>> = [
            {
                title: "Nom de l'aplication client",
                dataIndex: 'name',
            },
            {
                title: 'API Key',
                dataIndex: 'apiKey',
            },
            {
                title: "Type d'appareil",
                dataIndex: 'type',
            },
            {
                title: 'Actions',
                dataIndex: 'id',
                width: '15%',
                render: (id: string) => {
                    return (
                        <>
                            <Tooltip overlay="Modifier">
                                <Button
                                    className="actions-buttons"
                                    shape="circle"
                                    icon={<EditOutlined />}
                                    onClick={onClickEditApplicationClientButton.bind(null, id, record.id)}
                                />
                            </Tooltip>
                            <Tooltip overlay="Supprimer">
                                <Popconfirm
                                    placement="topLeft"
                                    title={'Êtes-vous sûr de supprimer cette application client ?'}
                                    onConfirm={onClickRemoveApplicationClientButton.bind(null, id, record.id)}
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button className="actions-buttons" shape="circle" icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </Tooltip>
                        </>
                    );
                },
            },
        ];

        const expandedRow = (
            <Table columns={subColumns} dataSource={record.applicationClients} pagination={false} rowKey={'id'} />
        );
        return expandedRow;
    };

    return (
        <>
            <Seo title="Applications" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={applicationsData?.totalCount} className="mb-0">
                        Applications
                    </ListTitle>
                    <Button className="header-button" size="large" type="primary" onClick={onClickCreateApplication}>
                        Ajouter une application
                    </Button>
                </div>
            </LayoutHeader>

            <Table<Application>
                className="applications-list"
                columns={columns}
                rowKey={rowKey}
                loading={isLoading || isFetching}
                dataSource={applicationsData?.items}
                expandable={{ expandedRowRender }}
                onChange={onTableChange}
                pagination={{
                    total: applicationsData?.totalCount,
                    current: page + 1,
                    pageSize: applicationsData?.pageSize,
                }}
            />
        </>
    );
};

export default Applications;
