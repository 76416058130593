/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.667 4.333h6.666M3.667 7h2.666M7 12.333 4.333 9.667h-2A1.333 1.333 0 0 1 1 8.333V3c0-.736.597-1.333 1.333-1.333h9.334C12.403 1.667 13 2.264 13 3v5.333c0 .737-.597 1.334-1.333 1.334h-2L7 12.333Z"
            stroke="#5C758A"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgComment: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-comment', props.className)} />
));
export default SvgComment;
