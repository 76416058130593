import { FC, ReactNode } from 'react';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Input, Button, Alert, Spin } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import LoginLayout from '../../components/LoginLayout';
import { getRoute, RoutePathName } from '../../routes';
import { useAuth } from '../../context/AuthContext';
import genericMessages from '../../i18n/genericMessages';
import { getRequiredRule } from '../../i18n';

const Login: FC = () => {
    const { formatMessage } = useIntl();
    const { from } = useLocation<{ from?: Location }>().state ?? {};
    const { user, isCheckingSession, login, checkSessionError } = useAuth();
    const { isLoading, mutate: sendLogin, isError, error } = login;
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        sendLogin(values);
    };

    let errorMessage: ReactNode = isError ? formatMessage(genericMessages.defaultError) : null;

    if (isError) {
        switch (error?.response?.status) {
            case 401:
                errorMessage = (
                    <FormattedMessage
                        id="login.error.401"
                        defaultMessage="Informations de connexion incorrectes"
                        description="login invalid credentials"
                    />
                );
                break;

            case 403:
                errorMessage = (
                    <FormattedMessage
                        id="login.error.403"
                        defaultMessage="Vous n'avez pas l'autorisation nécessaire"
                        description="login user not allowed"
                    />
                );
                break;

            case 404:
                errorMessage = (
                    <FormattedMessage
                        id="login.error.404"
                        defaultMessage="Nom d'utilisateur introuvable"
                        description="login user not found"
                    />
                );
                break;

            case 409:
                errorMessage = (
                    <FormattedMessage
                        id="login.error.409"
                        defaultMessage={`Un compte existe déjà avec cette adresse email, avez-vous <link>oublié votre mot de passe</link> ?`}
                        description="login user not found"
                        values={{
                            link: (chunk: ReactNode) => (
                                <Link to={getRoute(RoutePathName.forgottenPassword)}>{chunk}</Link>
                            ),
                        }}
                    />
                );
                break;

            default:
                errorMessage = formatMessage(genericMessages.defaultError);
                break;
        }
    }

    if (user && !checkSessionError) {
        return <Redirect to={from ?? getRoute(RoutePathName.home)} />;
    }

    return (
        <LoginLayout
            title={formatMessage({
                id: 'login.title',
                defaultMessage: 'Connectez-vous',
                description: 'Login page title',
            })}
            seoTitle={formatMessage({
                id: 'login.title.seo',
                defaultMessage: 'Connexion',
                description: 'Login page seo title',
            })}
        >
            <Spin spinning={isCheckingSession}>
                <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                    <Form.Item
                        name="username"
                        label={formatMessage({
                            id: 'login.form.item.email.label',
                            defaultMessage: 'Adresse e-mail',
                            description: 'form item label',
                        })}
                        rules={[getRequiredRule(formatMessage)]}
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'login.form.item.email.placeholder',
                                defaultMessage: 'Saisissez votre e-mail',
                                description: 'input placeholder',
                            })}
                        />
                    </Form.Item>

                    <Form.Item
                        className="password-row"
                        label={formatMessage({
                            id: 'login.form.item.password.label',
                            defaultMessage: 'Mot de passe',
                            description: 'form item label',
                        })}
                        name="password"
                        rules={[getRequiredRule(formatMessage)]}
                    >
                        <Input.Password
                            placeholder={formatMessage({
                                id: 'login.form.item.password.placeholder',
                                defaultMessage: 'Saisissez votre mot de passe',
                                description: 'input placeholder',
                            })}
                            iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
                        />
                    </Form.Item>

                    {errorMessage ? (
                        <Alert type="error" message={errorMessage} style={{ marginBottom: '1.5rem' }} />
                    ) : null}

                    <Form.Item>
                        <Button type="primary" size="large" block htmlType="submit" loading={isLoading}>
                            <FormattedMessage
                                id="login.form.submit_button"
                                defaultMessage="Se connecter"
                                description="Login form submit button"
                            />
                        </Button>
                    </Form.Item>

                    <Link to={getRoute(RoutePathName.forgottenPassword)}>
                        <FormattedMessage
                            id="login.link.forgotten_password"
                            defaultMessage="Mot de passe oublié ?"
                            description="Login forgotten password link"
                        />
                    </Link>
                </Form>
            </Spin>
        </LoginLayout>
    );
};

export default Login;
