import { useState, VFC } from 'react';
import { Card, Divider, Popover, Spin } from 'antd';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { SlotLabelContentArg } from '@fullcalendar/react';
import { useParams } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';

import genericMessages from '../../../i18n/genericMessages';
import CustomCalendarTimeline, { FullCalendarTimelineViews } from '../../../components/CustomCalendarTimeline';
import { OperatorAvailability, VehicleAvailability } from '../../../queries/api/types';
import { PlanningsTabs } from './ManagementPlannings';
import planningsMessages from '../../../i18n/planningsMessages';
import { formatForecastDate } from '../../../helpers';
import customerMessages from '../../../i18n/customerMessages';
import { useCustomerForecastsList } from '../../../queries/customers';

export interface PlanningsAvailabilitiesProps {
    activeDate: Dayjs;
    activeView: FullCalendarTimelineViews;
    operatorAvailabilities?: OperatorAvailability[];
    vehicleAvailabilities?: VehicleAvailability[];
}

const PlanningsAvailabilities: VFC<PlanningsAvailabilitiesProps> = ({
    activeDate,
    activeView,
    operatorAvailabilities,
    vehicleAvailabilities,
}) => {
    const { formatMessage } = useIntl();
    const { tab } = useParams<{ tab: PlanningsTabs }>();

    const [forecastDate, setForecastDate] = useState<Date | undefined>(undefined);

    const {
        data: forecastsList,
        isLoading: isForecastsListLoading,
        isFetching: isForecastsListFetching,
    } = useCustomerForecastsList(
        {
            fromDate: formatForecastDate(dayjs(forecastDate).startOf('day')),
            toDate: formatForecastDate(dayjs(forecastDate).endOf('day')),
        },
        { enabled: Boolean(forecastDate) }
    );

    const renderAvailability = (date: Date) => {
        const formatParamDate = dayjs(date).format('YYYY-MM-DD');

        if (operatorAvailabilities) {
            const findDate = operatorAvailabilities?.find((arg) => {
                const formatCurrDate = dayjs(arg.date).format('YYYY-MM-DD');
                return formatCurrDate === formatParamDate;
            });

            return <span className="text-base font-medium text-black">{findDate?.availableOperators || '-'}</span>;
        }

        if (vehicleAvailabilities) {
            const findDate = vehicleAvailabilities?.find((arg) => {
                const formatCurrDate = dayjs(arg.date).format('YYYY-MM-DD');
                return formatCurrDate === formatParamDate;
            });

            return <span className="text-base font-medium text-black">{findDate?.availableVehicles || '-'}</span>;
        }

        return '-';
    };

    const resourceAreaColumns = [
        {
            headerContent: (
                <span>
                    {formatMessage(genericMessages.availabilities)}{' '}
                    <Popover content={formatMessage(planningsMessages.availabilitiesTooltip, { ressource: tab })}>
                        <QuestionCircleOutlined />
                    </Popover>
                </span>
            ),
        },
    ];

    const dropDownContent =
        isForecastsListFetching || isForecastsListLoading ? (
            <Spin />
        ) : (
            <>
                <div className="font-bold" key={'forecast'}>
                    <FormattedMessage {...customerMessages.forecast} />
                </div>
                {forecastsList?.items.map((customerForecasts) => (
                    <div className="text-dark-blue" key={customerForecasts.customer.id}>
                        {customerForecasts.customer.name} : {customerForecasts.capacity} OT
                    </div>
                ))}
            </>
        );

    const renderSlotLabelContent = ({ date }: SlotLabelContentArg) => {
        return (
            <>
                <Popover
                    onVisibleChange={(visible) => {
                        if (visible) {
                            setForecastDate(date);
                        } else {
                            setForecastDate(undefined);
                        }
                    }}
                    content={dropDownContent}
                    placement="bottom"
                    trigger="click"
                >
                    <span className="block mt-2">
                        <FormattedDate value={date} weekday="short" day="numeric" month="2-digit" />
                    </span>
                </Popover>
                <Divider style={{ marginTop: 8, marginBottom: 4 }} />
                {renderAvailability(date)}
            </>
        );
    };

    return (
        <Card
            className="border-t-0 border-b-0 custom-fullcalendar__availabilities"
            bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        >
            <CustomCalendarTimeline
                activeDate={activeDate}
                initialView={activeView}
                resizeKey={tab}
                resourceAreaColumns={resourceAreaColumns}
                slotLabelContent={renderSlotLabelContent}
                slotDuration="24:00"
                slotLabelFormat={{
                    weekday: 'short',
                    day: 'numeric',
                    month: '2-digit',
                }}
                slotMinWidth={115}
            />
        </Card>
    );
};

export default PlanningsAvailabilities;
