import { useState, useEffect } from 'react';

interface ScrollPosition {
    x: number;
    y: number;
}

const isBrowser = typeof window !== `undefined`;

const isWindow = (element?: HTMLElement | Window): element is Window => (element as Window).document !== undefined;

function getScrollPosition<T extends HTMLElement>(element?: T | Window): ScrollPosition {
    if (isBrowser) {
        if (isWindow(element)) {
            return { x: window.pageXOffset, y: window.pageYOffset };
        } else {
            return { x: element?.scrollLeft || 0, y: element?.scrollTop || 0 };
        }
    } else {
        return { x: 0, y: 0 };
    }
}

export function useScrollPosition<T extends HTMLElement>(el?: T | null): ScrollPosition {
    const element = el || window;
    const [position, setScrollPosition] = useState<ScrollPosition>(getScrollPosition(element));

    useEffect(() => {
        let requestRunning: number | null = null;

        function handleScroll() {
            if (isBrowser && requestRunning === null) {
                requestRunning = window.requestAnimationFrame(() => {
                    setScrollPosition(getScrollPosition(element));
                    requestRunning = null;
                });
            }
        }

        if (isBrowser) {
            element.addEventListener('scroll', handleScroll);
            return () => element.removeEventListener('scroll', handleScroll);
        } else {
            // eslint-disable-next-line no-useless-return
            return;
        }
    }, [element]);

    return position;
}

export function useScrollXPosition<T extends HTMLElement>(el?: T | null): number {
    const { x } = useScrollPosition<T>(el);
    return x;
}

export function useScrollYPosition<T extends HTMLElement>(el?: T | null): number {
    const { y } = useScrollPosition<T>(el);
    return y;
}
