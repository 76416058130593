import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { OperatorShift, ListResponse, OperatorPlanning } from './types';

// Controller Interface
export interface OperatorShiftCreatePayload {
    id: string;
}

export interface OperatorShiftUpdatePayload extends Omit<OperatorShift, 'id'> {
    id?: OperatorShift['id'];
}

export type OperatorShiftIdPayload = OperatorShift['id'];

export type OperatorShiftListPayload = SearchPaginationQuery & {
    fromDate?: string;
    toDate?: string;
};
export type OperatorShiftListResponse = ListResponse<OperatorPlanning>;

// Routes
export const list = async (payload?: OperatorShiftListPayload) => {
    return await api
        .get<OperatorShiftListResponse>(`/planning/operators`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: OperatorShiftCreatePayload) => {
    return await api.post(`/operatorShifts`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: OperatorShiftUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<OperatorShift>(`/operatorShifts/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: OperatorShiftIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<OperatorShift>(`/operatorShifts/${id}`).then((response) => response?.data);
};

export const remove = async (id?: OperatorShiftIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/operatorShifts/${id}`).then((response) => response?.data);
};
