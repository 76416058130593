import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Input, Table, Space, TableProps, InputProps, Tooltip, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { HiOutlineCalendar } from 'react-icons/hi';
import dayjs from 'dayjs';

import Seo from '../../components/Seo';
import ListTitle from '../../components/ListTitle';
import ApiResult from '../../components/ApiResult';
import useQueryParams from '../../hooks/queryParams';
import { sortOrderConverter } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import { Download, Eye, Search } from '../../components/icons';
import genericMessages from '../../i18n/genericMessages';
import preBillingMessages from '../../i18n/preBillingMessages';
import DatePicker from '../../components/DatePicker';
import CustomerSelect from '../../components/selects/CustomerSelect';
import {
    usePreBillingCustomerDetailsExport,
    usePreBillingCustomerExport,
    usePreBillingCustomersList,
} from '../../queries/preBilling';
import { Customer, PreBillingCustomer } from '../../queries/api/types';
import { errorMessage } from '../../helpers/message';

let searchTimeout: number;

const PreBillingCustomersList: FC = () => {
    const { formatMessage, formatNumber } = useIntl();
    const [isExportAllEnabled, setIsExportAllEnabled] = useState(false);
    const [currentCustomerExporting, setCurrentCustomerExporting] = useState<Customer['id']>();
    const [queryParams, setQueryParams] = useQueryParams('PreBillingCustomersList');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const customerIds = queryParams.getAll('customerIds') ?? undefined;
    const fromDate = queryParams.get('fromDate') ?? undefined;
    const toDate = queryParams.get('toDate') ?? undefined;
    const {
        data: customers,
        isLoading,
        isFetching,
        isError,
        error,
    } = usePreBillingCustomersList({
        page,
        search,
        sort,
        sortOrder,
        customer: customerIds,
        fromDate,
        toDate,
    });
    const { isLoading: isExportingAll } = usePreBillingCustomerExport(
        { fromDate, toDate },
        {
            enabled: isExportAllEnabled,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
                });
            },
            onSettled: () => {
                setIsExportAllEnabled(false);
            },
        }
    );
    usePreBillingCustomerDetailsExport(
        {
            customerId: currentCustomerExporting,
        },
        {
            enabled: Boolean(currentCustomerExporting),
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
                });
            },
            onSettled: () => {
                setCurrentCustomerExporting(undefined);
            },
        }
    );
    const onTableChange: TableProps<PreBillingCustomer>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
            });
        },
        [setQueryParams]
    );
    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                    sort: undefined,
                    sortOrder: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );

    const getDetailsLink = useCallback(
        (id: string) => {
            const searchParams = new URLSearchParams();
            if (fromDate && toDate) {
                searchParams.append('fromDate', fromDate);
                searchParams.append('toDate', toDate);
            }
            if (search) {
                searchParams.append('search', search);
            }
            customerIds.forEach((id) => searchParams.append('customerIds', id));
            return {
                pathname: getRoute(RoutePathName.preBillingCustomersDetails, {
                    customerId: id,
                }),
                search: searchParams.toString(),
            };
        },
        [fromDate, toDate, search, customerIds]
    );
    const columns: ColumnsType<PreBillingCustomer> = useMemo(
        () => [
            {
                key: 'customerName',
                title: formatMessage(genericMessages.customer, { count: 1 }),
                render: (_, item) => (
                    <Link to={getDetailsLink(item.id)} className="blue-link font-bold">
                        {item.customerName}
                    </Link>
                ),
                sorter: true,
                width: 230,
            },
            {
                key: 'taskGroupCount',
                title: formatMessage(genericMessages.taskGroup, { count: 2 }),
                render: (_, item) => (item.taskGroupCount ? formatNumber(item.taskGroupCount) : '-'),
            },
            {
                key: 'handlingUnit',
                title: formatMessage(preBillingMessages.handlingUnitShort),
                render: (_, item) => (item.handlingUnit ? formatNumber(item.handlingUnit) : '-'),
            },
            {
                key: 'taskCount',
                title: formatMessage(preBillingMessages.taskCountShort),
                render: (_, item) => (item.taskCount ? formatNumber(item.taskCount) : '-'),
            },
            {
                key: 'mileage',
                title: formatMessage(preBillingMessages.mileage),
                render: (_, item) =>
                    item.mileage ? <FormattedNumber value={item.mileage} style="unit" unit="kilometer" /> : '-',
            },
            {
                key: 'sold',
                title: formatMessage(preBillingMessages.soldAmount),
                render: (_, item) =>
                    item.sold ? (
                        <Tooltip
                            title={
                                <>
                                    {formatMessage(genericMessages.taskGroup, { count: 2 })} :{' '}
                                    <FormattedNumber
                                        style="currency"
                                        currency="EUR"
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={0}
                                        value={item.sold}
                                    />
                                    <br />
                                    {formatMessage(preBillingMessages.extraServices)} :{' '}
                                    <FormattedNumber
                                        style="currency"
                                        currency="EUR"
                                        maximumFractionDigits={2}
                                        minimumFractionDigits={0}
                                        value={item.additionalServices}
                                    />
                                </>
                            }
                        >
                            <FormattedNumber
                                style="currency"
                                currency="EUR"
                                maximumFractionDigits={2}
                                minimumFractionDigits={0}
                                value={item.sold}
                            />
                        </Tooltip>
                    ) : (
                        '-'
                    ),
                sorter: true,
            },
            {
                key: 'purchased',
                title: formatMessage(preBillingMessages.boughtAmount),
                render: (_, item) =>
                    item.purchased ? (
                        <FormattedNumber
                            style="currency"
                            currency="EUR"
                            maximumFractionDigits={2}
                            minimumFractionDigits={0}
                            value={item.purchased}
                        />
                    ) : (
                        '-'
                    ),
                sorter: true,
            },
            {
                key: 'margin',
                title: formatMessage(preBillingMessages.marginAmount),
                render: (_, item) =>
                    item.margin ? (
                        <FormattedNumber
                            style="currency"
                            currency="EUR"
                            maximumFractionDigits={2}
                            minimumFractionDigits={0}
                            value={item.margin}
                        />
                    ) : (
                        '-'
                    ),
                sorter: true,
            },
            {
                key: 'actions',
                title: formatMessage(genericMessages.actions),
                render: (_, item) => (
                    <div className="flex items-center space-x-2">
                        <Link to={getDetailsLink(item.id)}>
                            <Button type="text" icon={<Eye className="text-xl text-faded-blue" />} />
                        </Link>
                        <Button
                            type="text"
                            icon={<Download className="text-xl text-faded-blue" />}
                            onClick={() => setCurrentCustomerExporting(item.id)}
                            loading={currentCustomerExporting === item.id}
                        />
                    </div>
                ),
                width: 136,
                fixed: 'right',
            },
        ],
        [formatMessage, getDetailsLink, formatNumber, currentCustomerExporting]
    );
    const pageTitle = formatMessage({
        id: 'pre_billing.customers.title',
        defaultMessage: 'Pré-facturation Clients',
        description: 'Customer pre billing list title',
    });

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                <ListTitle count={customers?.totalCount} className="mb-0 uppercase">
                    {pageTitle}
                </ListTitle>
                <Button
                    type="primary"
                    icon={<Download className="text-base" />}
                    onClick={() => setIsExportAllEnabled(true)}
                    loading={isExportingAll}
                >
                    <FormattedMessage {...genericMessages.export} tagName="span" />
                </Button>
            </div>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <div className="flex items-center gap-4">
                    <DatePicker.RangePicker
                        defaultValue={fromDate && toDate ? [dayjs(fromDate), dayjs(toDate)] : null}
                        onChange={(values) => {
                            setQueryParams({
                                fromDate: values?.[0]?.toISOString(),
                                toDate: values?.[1]?.toISOString(),
                            });
                        }}
                        style={{ width: 284 }}
                        format="DD/MM/YYYY"
                        suffixIcon={<HiOutlineCalendar />}
                        size="small"
                    />
                    <CustomerSelect
                        onChange={(value) => setQueryParams({ customerIds: value })}
                        mode="multiple"
                        style={{ width: 280 }}
                        size="small"
                    />
                    <Input
                        placeholder={formatMessage(preBillingMessages.searchInputPlaceholder)}
                        onChange={onSearch}
                        defaultValue={search}
                        prefix={<Search className="text-primary text-base leading-4" />}
                        size="small"
                        className="flex-1"
                        allowClear
                    />
                </div>
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Table<PreBillingCustomer>
                        rowKey="id"
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={customers?.items}
                        pagination={{
                            total: customers?.totalCount,
                            current: page + 1,
                            pageSize: customers?.pageSize,
                            position: ['bottomCenter'],
                            hideOnSinglePage: true,
                        }}
                        scroll={{
                            x: 1440,
                            y: 'calc(100vh - 244px)',
                            scrollToFirstRowOnChange: true,
                        }}
                        onChange={onTableChange}
                    />
                )}
            </Space>
        </>
    );
};

export default PreBillingCustomersList;
