import React, { FC, useContext, useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/es/checkbox';
import { GroupContext } from 'antd/es/checkbox/Group';

import '../assets/styles/components/CheckboxButton.less';

import { classNames } from '../helpers';
import { CheckCircle } from './icons';

const CheckboxButton: FC<CheckboxProps> = ({ checked, onChange, ...props }) => {
    const checkboxGroupContext = useContext(GroupContext);
    const [isChecked, setIsChecked] = useState(checked ?? checkboxGroupContext?.value?.includes(props.value) ?? false);
    const onChangePrivate: CheckboxProps['onChange'] = (e) => {
        setIsChecked(e.target.checked);
        onChange?.(e);
    };

    return (
        <div className={classNames('checkbox-button', isChecked && 'checkbox-button-checked')}>
            <Checkbox
                {...props}
                checked={isChecked}
                onChange={onChangePrivate}
                children={
                    <>
                        {props.children} {isChecked && <CheckCircle className="text-duck-blue" />}
                    </>
                }
            />
        </div>
    );
};

export default CheckboxButton;
