import { AxiosResponse } from 'axios';

import { downloadFile } from '../../helpers';
import * as auth from './auth';

export type ApiResponse = Record<string, any>;

export interface RequestError {
    data?: any;
    headers?: any;
    message: string;
    status: number;
}

export interface PaginationQuery {
    page?: number;
    pageSize?: number;
    sort?: string;
    sortOrder?: string;
}

export interface SearchQuery {
    search?: string;
}

export interface DatesQuery {
    fromDate?: string;
    toDate?: string;
}

export type SearchPaginationQuery = PaginationQuery & SearchQuery;

export default {
    auth,
};

export const downloadResponseAsBlob = (response: AxiosResponse<Blob>) => {
    const result = response.data as unknown as BlobPart;
    const filename = response.headers['content-disposition']?.split('"')[1];
    const blob = new Blob([result], { type: 'blob' });
    const url = window.URL.createObjectURL(blob);

    downloadFile(url, filename);

    window.setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, 35000);
};
