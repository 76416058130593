import { VFC } from 'react';
import { Button, Form, InputNumber } from 'antd';
import { FormattedNumber, useIntl } from 'react-intl';

import { Customer } from '../../../queries/api/types';
import { Trash } from '../../../components/icons';
import customerMessages from '../../../i18n/customerMessages';
import genericMessages from '../../../i18n/genericMessages';

interface CustomerTableProps {
    isEditMode?: boolean; // default true
    invoicingPricesField: Customer['invoicingPrices'];
    record?: Customer;
}

const CustomerTable: VFC<CustomerTableProps> = ({ isEditMode = true, invoicingPricesField, record }) => {
    const { formatMessage } = useIntl();

    return (
        <div>
            <div className="flex items-center justify-between gap-12 h-12 px-6 bg-anti-flash-white border text-blue text-base font-semibold">
                <span className="w-full">{formatMessage(genericMessages.vehicleType)}</span>

                <span className="w-full">{formatMessage(customerMessages.invoicingPricesStandard)}</span>

                <span className="w-full">{formatMessage(customerMessages.invoicingPricesWeekend)}</span>

                <span className="w-full">{formatMessage(customerMessages.invoicingPricesHoliday)}</span>

                <span className="w-full">{formatMessage(customerMessages.invoicingPricesReuse)}</span>

                {isEditMode && <span className="w-full">{formatMessage(customerMessages.invoicingPricesAction)}</span>}
            </div>

            {isEditMode && (
                <Form.List name={['invoicingPricesVehicle']}>
                    {(fields, { remove }) =>
                        fields?.map(({ key, name: subName }, fieldIndex) => (
                            <div
                                key={`${key}${String(invoicingPricesField[fieldIndex].vehicleType?.id)}`}
                                className="bg-white border border-t-0"
                            >
                                <div className="flex items-center justify-between gap-12 h-16 px-6">
                                    <div className="w-full">
                                        <span
                                            className="text-black text-base t-o-ellipsis"
                                            style={{
                                                width: 142,
                                            }}
                                        >
                                            {invoicingPricesField[fieldIndex].vehicleType?.fields.vehicleType}
                                        </span>
                                    </div>

                                    <div className="w-full">
                                        <Form.Item noStyle name={[subName, 'standard']}>
                                            <InputNumber addonAfter="€" min={0} />
                                        </Form.Item>
                                    </div>

                                    <div className="w-full">
                                        <Form.Item noStyle name={[subName, 'weekend']}>
                                            <InputNumber addonAfter="€" min={0} />
                                        </Form.Item>
                                    </div>

                                    <div className="w-full">
                                        <Form.Item noStyle name={[subName, 'holiday']}>
                                            <InputNumber addonAfter="€" min={0} />
                                        </Form.Item>
                                    </div>

                                    <div className="w-full">
                                        <Form.Item noStyle name={[subName, 'reuse']}>
                                            <InputNumber addonAfter="€" min={0} />
                                        </Form.Item>
                                    </div>

                                    <div className="w-full">
                                        <Button type="text" icon={<Trash />} onClick={() => remove(subName)} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Form.List>
            )}

            {!isEditMode &&
                record?.invoicingPrices.map((invoicingPrice) => {
                    const { holiday, reuse, standard, vehicleType, weekend } = invoicingPrice;

                    if (!vehicleType) return null;

                    return (
                        <div key={vehicleType.id} className="bg-white border border-t-0">
                            <div className="flex items-center justify-between gap-12 h-12 px-6 text-black text-base">
                                <div className="w-full">
                                    <span
                                        className="t-o-ellipsis"
                                        style={{
                                            width: 142,
                                        }}
                                    >
                                        {vehicleType?.fields.vehicleType}
                                    </span>
                                </div>

                                <span className="w-full">
                                    {standard !== undefined ? (
                                        <FormattedNumber style="currency" currency="EUR" value={standard} />
                                    ) : (
                                        '-'
                                    )}
                                </span>
                                <span className="w-full">
                                    {weekend !== undefined ? (
                                        <FormattedNumber style="currency" currency="EUR" value={weekend} />
                                    ) : (
                                        '-'
                                    )}
                                </span>

                                <span className="w-full">
                                    {holiday !== undefined ? (
                                        <FormattedNumber style="currency" currency="EUR" value={holiday} />
                                    ) : (
                                        '-'
                                    )}
                                </span>

                                <span className="w-full">
                                    {reuse !== undefined ? (
                                        <FormattedNumber style="currency" currency="EUR" value={reuse} />
                                    ) : (
                                        '-'
                                    )}
                                </span>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default CustomerTable;
