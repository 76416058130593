import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    OrganizationCreatePayload,
    OrganizationIdPayload,
    OrganizationListPayload,
    OrganizationListResponse,
    OrganizationUpdatePayload,
    remove,
    update,
} from './api/organizations';
import { Organization } from './api/types';

export const organizationKeys = {
    all: ['organizations'],
    lists: () => [...organizationKeys.all, 'list'],
    list: (params?: OrganizationListPayload) => [...organizationKeys.lists(), params],
    details: () => [...organizationKeys.all, 'details'],
    detail: (id?: OrganizationIdPayload) => [...organizationKeys.details(), id],
};

export const useOrganizationsList = <TData = OrganizationListResponse>(
    params: OrganizationListPayload | undefined,
    options?: UseQueryOptions<OrganizationListResponse, AxiosError, TData>
) => {
    return useQuery<OrganizationListResponse, AxiosError, TData>(
        organizationKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useOrganizationDetails = <TData = Organization>(
    id: OrganizationIdPayload | undefined,
    options?: UseQueryOptions<Organization, AxiosError, TData>
) => {
    return useQuery<Organization, AxiosError, TData>(
        organizationKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useOrganizationCreate = (
    options?: UseMutationOptions<Organization, AxiosError, OrganizationCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Organization, AxiosError, OrganizationCreatePayload>(async (params) => await create(params), {
        ...options,
        onSettled: (...args) => {
            options?.onSettled?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(organizationKeys.lists());
        },
    });
};

export const useOrganizationUpdate = (
    options?: UseMutationOptions<Organization, AxiosError, OrganizationUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Organization, AxiosError, OrganizationUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(organizationKeys.detail(variables.id));

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(organizationKeys.lists());
        },
    });
};

export const useOrganizationRemove = (options?: UseMutationOptions<undefined, AxiosError, OrganizationIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, OrganizationIdPayload>(async (params) => await remove(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(organizationKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(organizationKeys.lists());
        },
    });
};
