import { Card, Form, FormInstance, FormProps, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessage, successMessage } from '../../../helpers/message';
import useQueryParams from '../../../hooks/queryParams';
import genericMessages from '../../../i18n/genericMessages';
import { ValueListItem, ValueListSlug } from '../../../queries/api/types';
import { useValueListItemList, useValueListItemRemove, useValueListItemUpdate } from '../../../queries/valueListItems';
import { useValueListList } from '../../../queries/valueLists';
import ApiResult from '../../../components/ApiResult';
import EditableCell from '../../../components/form/EditableCell';
import EditableCellActions from '../../../components/form/EditableCellActions';
import { unitMessageMap } from '../../../i18n/unitMessages';

const VehicleBrandsAndModelsValueListTable: VFC = () => {
    const { formatMessage } = useIntl();
    const [queryParams, setQueryParams] = useQueryParams('vehiclesBrandsAndModels');
    const [form] = Form.useForm();
    const [, setFormErrors] = useState<ReturnType<FormInstance['getFieldsError']>>();
    const sort = queryParams.get('sort') || undefined;
    const sortOrder = queryParams.get('sortOrder') || undefined;
    const {
        data: valueLists,
        isSuccess: allValuelistsListSuccess,
        isLoading: allValuelistsListIsLoading,
        isFetching: allValuelistsListIsFetching,
        isError: allValuelistsListIsError,
        error: allValuelistsListError,
    } = useValueListList({
        pageSize: 200,
    });
    const brandsValueList = valueLists?.items?.find((item) => item.slug === ValueListSlug.vehicleBrands);
    const modelsValueList = valueLists?.items?.find((item) => item.slug === ValueListSlug.vehicleModels);
    const { mutate: updateValueListItem, isLoading: isUpdating } = useValueListItemUpdate();
    const { mutate: removeValueListItem, isLoading: isRemoving } = useValueListItemRemove();
    const {
        data: brandsValueListItem,
        isSuccess: brandsValueListItemIsSuccess,
        isLoading: brandsValueListItemIsLoading,
        isFetching: brandsValueListItemIsFetching,
        isError: brandsValueListItemIsError,
        error: brandsValueListItemError,
    } = useValueListItemList(
        { valueListSlug: brandsValueList?.slug, sort, sortOrder, pageSize: 200 },
        { enabled: !!brandsValueList?.slug }
    );
    const {
        data: modelsValueListItem,
        isSuccess: modelsValueListItemIsSuccess,
        isLoading: modelsValueListItemIsLoading,
        isFetching: modelsValueListItemIsFetching,
        isError: modelsValueListItemIsError,
        error: modelsValueListItemError,
    } = useValueListItemList(
        { valueListSlug: modelsValueList?.slug, sort, sortOrder, pageSize: 200 },
        { enabled: !!modelsValueList?.slug }
    );
    const isSuccess = allValuelistsListSuccess && brandsValueListItemIsSuccess && modelsValueListItemIsSuccess;
    const isLoading = allValuelistsListIsLoading || brandsValueListItemIsLoading || modelsValueListItemIsLoading;
    const isFetching = allValuelistsListIsFetching || brandsValueListItemIsFetching || modelsValueListItemIsFetching;
    const isError = allValuelistsListIsError || brandsValueListItemIsError || modelsValueListItemIsError;
    const fields = brandsValueList?.fields;
    let columns: ColumnsType<ValueListItem> | undefined = fields?.map((field) => ({
        title: field.columnTitle || field.title,
        key: field.sortKey || field.title,
        sorter: field.isSortable,
        dataIndex: ['fields', field.name],
        render: (record) =>
            field.unit ? (
                <>
                    {record} <FormattedMessage {...unitMessageMap.get(field.unit)} />
                </>
            ) : (
                record
            ),
        onCell: (record) => ({
            form,
            // record,
            valueType: field.valueType,
            name: ['fields', field.name],
            title: field.columnTitle || field.title,
            required: field.isRequired,
            // translation: true,
            editing: isEditing(record),
        }),
    }));
    const onTableChange: TableProps<ValueListItem>['onChange'] = (pagination) => {
        setQueryParams({
            page: (pagination.current ?? 1) - 1,
        });
    };
    const [editingItem, setEditingItem] = useState<ValueListItem>();
    const isEditing = (record: ValueListItem) => record.id === editingItem?.id;
    const editItem = (record: ValueListItem) => () => {
        form.resetFields();
        form.setFieldsValue({ ...record });
        setEditingItem(record);
    };
    const expandedRowRender = (record: ValueListItem) => {
        const models = modelsValueListItem?.items?.filter((item) => item.meta?.brandValueListItemId === record.id);
        const field = modelsValueList?.fields?.[0];
        const subColumns: ColumnsType<ValueListItem> = [
            {
                key: 'model',
                dataIndex: ['fields', field?.name ?? ''],
                onCell: (record) => ({
                    form,
                    valueType: field?.valueType,
                    name: ['fields', field?.name],
                    title: field?.columnTitle || field?.title,
                    required: field?.isRequired,
                    editing: isEditing(record),
                }),
            },
            {
                title: 'Actions',
                width: 128,
                render: (_, valueListItem) => (
                    <EditableCellActions
                        isEditing={isEditing(valueListItem)}
                        onEdit={editItem(valueListItem)}
                        onRemove={removeItem(valueListItem)}
                        onCancel={cancelItem}
                        loading={isUpdating || isRemoving}
                    />
                ),
            },
        ];

        const expandedRow = (
            <Table
                columns={subColumns}
                dataSource={models}
                pagination={false}
                showHeader={false}
                rowKey="id"
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
            />
        );
        return expandedRow;
    };

    const cancelItem = () => {
        setEditingItem(undefined);
    };

    const onFormFinish: FormProps['onFinish'] = (values) => {
        const isModel = !!editingItem?.meta?.brandValueListItemId;
        updateValueListItem(
            {
                id: editingItem?.id,
                valueListSlug: isModel ? modelsValueList?.slug : brandsValueList?.slug,
                title: 'dummy',
                meta: editingItem?.meta ?? {},
                ...values,
            },
            {
                onSuccess: () => {
                    successMessage({
                        content: formatMessage({
                            id: 'value_list_table.edit.success_message',
                            defaultMessage: 'Valeur modifiée avec succès',
                        }),
                    });
                    setEditingItem(undefined);
                },
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error.response?.status,
                        }),
                    });
                },
            }
        );
    };

    const removeItem = (record: ValueListItem) => () => {
        const isModel = !!record.meta?.brandValueListItemId;
        removeValueListItem({ valueListSlug: isModel ? modelsValueList?.slug : brandsValueList?.slug, id: record.id });
    };

    const onFormValuesChange = () => {
        setFormErrors(form.getFieldsError());
    };

    const onFormError = () => {
        setFormErrors(form.getFieldsError());
    };

    if (isError) {
        return (
            <ApiResult
                status={
                    (allValuelistsListError || brandsValueListItemError || modelsValueListItemError)?.response?.status
                }
            />
        );
    }

    if (columns) {
        columns = columns.concat([
            {
                title: 'Actions',
                width: 128,
                render: (_, valueListItem) => (
                    <EditableCellActions
                        isEditing={isEditing(valueListItem)}
                        onEdit={editItem(valueListItem)}
                        onRemove={removeItem(valueListItem)}
                        onCancel={cancelItem}
                        loading={isUpdating || isRemoving}
                    />
                ),
            },
        ]);
    }

    return isSuccess && !columns ? (
        <ApiResult />
    ) : (
        <Form form={form} onFinishFailed={onFormError} onFinish={onFormFinish} onValuesChange={onFormValuesChange}>
            <Card>
                <Table<ValueListItem>
                    columns={columns}
                    className="value-list-table table-fill-card"
                    rowKey="id"
                    loading={isLoading || isFetching}
                    dataSource={brandsValueListItem?.items}
                    onChange={onTableChange}
                    expandable={{
                        expandedRowRender,
                        columnWidth: 10,
                        indentSize: 64,
                        rowExpandable: (record) =>
                            !!modelsValueListItem?.items?.some((item) => item.meta?.brandValueListItemId === record.id),
                    }}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    pagination={false}
                />
            </Card>
        </Form>
    );
};

export default VehicleBrandsAndModelsValueListTable;
