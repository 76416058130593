import { useMemo, VFC } from 'react';
import { Select, SelectProps } from 'antd';
import { useIntl } from 'react-intl';

import { PlaceType } from '../../queries/api/types';
import { placeTypeMessagesMap } from '../../i18n/placeMessages';

const PlaceTypeTagSelect: VFC<SelectProps<PlaceType>> = (props) => {
    const { formatMessage } = useIntl();

    const options: Array<{
        label: string;
        value: PlaceType;
    }> = useMemo(
        () =>
            Object.values(PlaceType).map((key) => ({
                label: formatMessage(placeTypeMessagesMap.get(key)!),
                value: PlaceType[key],
            })),
        [formatMessage]
    );

    return (
        <Select<PlaceType>
            placeholder={formatMessage({
                id: 'place_type_select.placeholder',
                defaultMessage: 'Choisir un type',
            })}
            filterOption={false}
            options={options}
            showSearch={false}
            showArrow
            {...props}
        />
    );
};

export default PlaceTypeTagSelect;
