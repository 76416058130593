import { Badge, Card } from 'antd';
import React, { VFC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { OperatorShiftStatus, VehicleShiftStatus } from '../../../queries/api/types';
import {
    planningsCaptionOperatorMessagesMap,
    planningsCaptionVehicleMessagesMap,
} from '../../../i18n/planningsMessages';
import PlaceSelect, { PlaceSelectProps } from '../../../components/selects/PlaceSelect';
import { PlanningsTabs } from './ManagementPlannings';

const OperatorCaption = () => {
    const { formatMessage } = useIntl();

    return (
        <ul
            className="flex justify-between items-center list-style-none gap-2 mb-0 px-2 py-1 rounded"
            style={{ backgroundColor: '#F2F5F7', height: 40 }}
        >
            {Object.values(OperatorShiftStatus)
                .filter((status) => ![OperatorShiftStatus.inProgress, OperatorShiftStatus.finished].includes(status))
                .map((key) => (
                    <li key={key} className="flex justify-between items-center gap-2">
                        <div className={`caption caption-bg-${key}`} />
                        <span className="block">{formatMessage(planningsCaptionOperatorMessagesMap.get(key)!)}</span>
                    </li>
                ))}
        </ul>
    );
};

const VehicleCaption = () => {
    const { formatMessage } = useIntl();

    return (
        <ul
            className="flex justify-between items-center list-style-none gap-2 mb-0 px-2 py-1 rounded"
            style={{ backgroundColor: '#F2F5F7', height: 40 }}
        >
            {Object.values(VehicleShiftStatus).map((key) => (
                <li key={key} className="flex justify-between items-center gap-2">
                    <div className={`caption caption-bg-${key}`} />
                    <span className="block">{formatMessage(planningsCaptionVehicleMessagesMap.get(key)!)}</span>
                </li>
            ))}
        </ul>
    );
};

export interface PlanningsSubHeaderProps {
    badgeCount?: number;
    handlePlaceSelectChange: PlaceSelectProps['onChange'];
    label: string;
    type: 'operator' | 'vehicle';
}

const PlanningsSubHeader: VFC<PlanningsSubHeaderProps> = ({ badgeCount, handlePlaceSelectChange, label, type }) => {
    const [place, setPlace] = useState<string[]>();
    const { tab } = useParams<{ tab: PlanningsTabs }>();

    const onInternalChange: PlaceSelectProps['onChange'] = useCallback(
        (value, option) => {
            setPlace(value as string[]);
            handlePlaceSelectChange?.(value, option);
        },
        [handlePlaceSelectChange]
    );

    useEffect(() => {
        setPlace([]);
    }, [tab]);
    return (
        <Card className="border-t-0 border-b-0">
            <div className="flex justify-between items-center">
                <div className="flex justify-between items-center gap-1">
                    <span className="block text-lg font-bold">{label}</span>
                    <Badge count={badgeCount} overflowCount={999999} style={{ backgroundColor: '#16A1B8' }} />
                </div>

                <div className="flex justify-end items-center gap-6 w-full">
                    <PlaceSelect
                        mode="tags"
                        size="small"
                        style={{ width: 360 }}
                        value={place}
                        onChange={onInternalChange}
                    />

                    {type === 'operator' && <OperatorCaption />}
                    {type === 'vehicle' && <VehicleCaption />}
                </div>
            </div>
        </Card>
    );
};

export default PlanningsSubHeader;
