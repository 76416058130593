import { VFC } from 'react';

import { Issue, IssueStatus } from '../../../../queries/api/types';
import { DocumentCheckOutlined, Incident, Toolkit } from '../../../../components/icons';
import { classNames } from '../../../../helpers';

interface IssueCardIconProps {
    issue: Issue;
}

const IssueCardIcon: VFC<IssueCardIconProps> = ({ issue }) => {
    const isPending = issue.status === IssueStatus.opened;
    const isRepairing = issue.status === IssueStatus.repairing;
    const isClosed = issue.status === IssueStatus.closed;

    return (
        <div
            className={classNames(
                'flex justify-center items-center rounded-full',
                isPending && 'bg-faded-blue',
                isRepairing && 'bg-orange',
                isClosed && 'bg-green'
            )}
            style={{ height: 32, width: 32 }}
        >
            {isPending && <Incident className="icon-sm fill-white" />}
            {isRepairing && <Toolkit className="icon-sm stroke-white" />}
            {isClosed && <DocumentCheckOutlined className="icon-sm fill-white" />}
        </div>
    );
};

export default IssueCardIcon;
