import { useMemo, VFC } from 'react';
import { Space } from 'antd';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import Seo from '../../../components/Seo';
import { Operator } from '../../../queries/api/types';
import { useOperatorDetails, useOperatorUpdate } from '../../../queries/operators';
import DetailsFormCard from '../../../components/form/DetailsFormCard';
import ApiResult from '../../../components/ApiResult';
import { OperatorUpdatePayload } from '../../../queries/api/operators';
import {
    formatOperatorInitialValues,
    formatOperatorUpdatePayload,
    getOperatorFormSections,
    OperatorForm,
} from '../../settings/operators';
import { getFullName } from '../../../helpers';

const ManagementOperatorsDetailsInfos: VFC = () => {
    const { operatorId } = useParams<{ operatorId: Operator['id'] }>();
    const { formatMessage } = useIntl();
    const { data: operatorDetails, isError, error } = useOperatorDetails(operatorId, { enabled: !!operatorId });
    const pageTitle = [getFullName(operatorDetails), operatorDetails?.reference].filter(Boolean).join(' - ');
    const sections = useMemo(() => getOperatorFormSections(formatMessage).splice(1), [formatMessage]);
    const sectionCards = useMemo(
        () =>
            sections.map((section) => (
                <DetailsFormCard<Operator, OperatorForm, OperatorUpdatePayload>
                    {...section}
                    editButtonText={undefined}
                    key={section.title}
                    detailsQueryHandler={useOperatorDetails}
                    updateQueryHandler={useOperatorUpdate}
                    initialValueFormatter={formatOperatorInitialValues}
                    formatPayload={formatOperatorUpdatePayload}
                    id={operatorId}
                />
            )),
        [operatorId, sections]
    );

    return (
        <>
            <Seo title={pageTitle} />
            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    {sectionCards}
                </Space>
            )}
        </>
    );
};

export default ManagementOperatorsDetailsInfos;
