import { useCallback, useState } from 'react';
import { Empty, Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { useIntl } from 'react-intl';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

import { useDebounce } from '../../hooks';
import { SearchPaginationQuery } from '../../queries/api';
import { ListResponse } from '../../queries/api/types';

export interface ApiSelectProps<TData, TValue, TPayload> extends SelectProps {
    onChange?: (value: TValue | TValue[] | undefined) => void;
    initialValue?: TData[] | TData;
    /*
     * Name of the property to get the value inside TData
     */
    valueProp: Extract<keyof TData, string> | string[];
    /*
     * Name of the property to get the label inside TData
     */
    labelProp: Extract<keyof TData, string> | string[];
    /*
     * API hook handler
     */
    listQueryHandler: (
        params: SearchPaginationQuery | TPayload | undefined,
        options?: UseQueryOptions<ListResponse<TData>, AxiosError, TData> | undefined
    ) => UseQueryResult<TData, AxiosError>;
    queryPayload?: TPayload;
}

const ApiSelect = <TData extends Record<string, any>, TValue extends string | number, TPayload>({
    onChange,
    initialValue,
    valueProp,
    labelProp,
    listQueryHandler,
    queryPayload,
    ...props
}: ApiSelectProps<TData, TValue, TPayload>) => {
    type SelectValue = TValue | TValue[];
    const { formatMessage } = useIntl();
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300) as string;
    const getValue = useCallback(
        (item: TData): TValue => {
            if (Array.isArray(valueProp)) {
                return valueProp.reduce((acc, key) => acc[key], item) as TValue;
            } else {
                return item[valueProp];
            }
        },
        [valueProp]
    );
    const [value, setValue] = useState<SelectValue | undefined>(
        initialValue ? (Array.isArray(initialValue) ? initialValue.map(getValue) : getValue(initialValue)) : undefined
    );
    const { data: list, isLoading } = listQueryHandler({
        ...queryPayload,
        search: debouncedSearch || undefined,
        pageSize: 50,
    });

    const onChangeSelect: SelectProps<SelectValue>['onChange'] = (newValue) => {
        setValue(newValue);
        onChange?.(newValue);
    };

    return (
        <Select<SelectValue>
            placeholder={formatMessage({
                id: 'api_select.placeholder',
                defaultMessage: 'Rechercher',
            })}
            notFoundContent={isLoading ? <Spin size="small" /> : <Empty />}
            filterOption={false}
            onSearch={setSearch}
            onChange={onChangeSelect}
            style={{ width: '100%' }}
            loading={isLoading}
            size="middle"
            showSearch
            allowClear
            value={value}
            {...props}
        >
            {[
                ...(initialValue ? (Array.isArray(initialValue) ? initialValue : [initialValue]) : []), // Display initial value
                ...(list?.items.filter((item: TData) => !initialValue?.map(getValue).includes(getValue(item))) ?? []), // Search items, excluding initial value
            ].map((item) => (
                <Select.Option value={getValue(item)} key={getValue(item) as string}>
                    {Array.isArray(labelProp) ? labelProp.reduce((acc, key) => acc[key], item) : item?.[labelProp]}
                </Select.Option>
            ))}
        </Select>
    );
};

export default ApiSelect;
