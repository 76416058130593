import { KeyboardEvent, VFC } from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { ModalProps, Typography } from 'antd';

import CustomModal from '../../../../components/CustomModal';
import { Issue, RemoteFile } from '../../../../queries/api/types';

interface ImgModalProps extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>, Record<string, any> {
    remoteFile: RemoteFile;
}

const ImgModal = NiceModal.create<ImgModalProps>(({ remoteFile }) => {
    const modal = useModal();

    return (
        <CustomModal {...antdModal(modal)} closeIcon={<></>} footer={false} width={500}>
            <div style={{ height: 500 }}>
                <img alt={remoteFile.originalName} className="h-full w-full of-cover" src={remoteFile.url} />
            </div>
        </CustomModal>
    );
});

interface IssueCardBodyProps {
    issue: Issue;
}

const IssueCardBody: VFC<IssueCardBodyProps> = ({ issue }) => {
    const modal = useModal(ImgModal);

    const handleImgClick = (remoteFile: RemoteFile, event?: KeyboardEvent) => {
        if (!event) {
            modal.show({ remoteFile });
        }

        if (event && (event.code === 'Space' || event.code === 'Enter')) {
            modal.show({ remoteFile });
        }
    };

    return (
        <>
            {issue.remoteFiles.length > 0 && (
                <ul className="flex justify-start items-center gap-6 p-0 list-style-none flex-wrap">
                    {issue.remoteFiles.map((remoteFile) => (
                        <li key={remoteFile.id} className="border" style={{ height: 60, width: 60 }}>
                            <div
                                className="h-full w-full"
                                onClick={() => handleImgClick(remoteFile)}
                                onKeyDown={(e) => handleImgClick(remoteFile, e)}
                                role="button"
                                tabIndex={0}
                            >
                                <img
                                    alt={remoteFile.originalName}
                                    className="h-full w-full of-cover cursor-pointer"
                                    src={remoteFile.thumbnailUrl || remoteFile.url}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            )}

            <Typography.Text className="text-base text-black font-medium">{issue.description}</Typography.Text>
        </>
    );
};

export default IssueCardBody;
