import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import {
    listCustomers,
    listCustomerTaskGroups,
    PreBillingCustomersListPayload,
    PreBillingCustomersListResponse,
    PreBillingTransportersListResponse,
    PreBillingTransportersListPayload,
    listTransporters,
    listTransporterTaskGroups,
    PreBillingCustomerTaskGroupsListPayload,
    PreBillingListTaskGroupResponse,
    PreBillingTransporterTaskGroupsListPayload,
    PreBillingCustomerDetailsPayload,
    PreBillingTransporterDetailsPayload,
    detailsCustomer,
    detailsTransporter,
    exportPreBillingCustomers,
    PreBillingExportCustomerDetailsPayload,
    exportPreBillingCustomerDetails,
    exportPreBillingTaskGroup,
    PreBillingExportTaskGroupPayload,
    PreBillingExportCustomerDetailsClientPayload,
    exportPreBillingCustomerDetailsClient,
    exportPreBillingTransporters,
    exportPreBillingTransporterDetails,
    PreBillingExportTransporterDetailsPayload,
    PreBillingTransportersExportPayload,
} from './api/preBilling';
import { PreBillingCustomer, PreBillingTransporter } from './api/types';

export const preBillingKeys = {
    all: ['preBilling'],
    lists: () => [...preBillingKeys.all, 'list'],
    listCustomers: (params?: PreBillingCustomersListPayload) => [...preBillingKeys.lists(), 'customers', params],
    listTransporters: (params?: PreBillingTransportersListPayload) => [
        ...preBillingKeys.lists(),
        'transporters',
        params,
    ],
    listsTaskGroups: () => [...preBillingKeys.all, 'taskGroups'],
    listCustomerTaskGroups: (params?: PreBillingCustomerTaskGroupsListPayload) => [
        ...preBillingKeys.listsTaskGroups(),
        'customerTaskGroups',
        params,
    ],
    listTransporterTaskGroups: (params?: PreBillingTransporterTaskGroupsListPayload) => [
        ...preBillingKeys.listsTaskGroups(),
        'transporterTaskGroups',
        params,
    ],
    details: () => [...preBillingKeys.all, 'details'],
    detailCustomer: (params?: PreBillingCustomerDetailsPayload) => [...preBillingKeys.details(), 'customer', params],
    detailTransporter: (params?: PreBillingTransporterDetailsPayload) => [
        ...preBillingKeys.details(),
        'transporter',
        params,
    ],
    export: () => [...preBillingKeys.all, 'export'],
    exportCustomers: () => [...preBillingKeys.export(), 'customers'],
    exportCustomerDetails: (params?: PreBillingExportCustomerDetailsPayload) => [
        ...preBillingKeys.exportCustomers(),
        'details',
        params,
    ],
    exportCustomerDetailsClient: (params?: PreBillingExportCustomerDetailsClientPayload) => [
        ...preBillingKeys.exportCustomers(),
        'details',
        params,
    ],
    exportTransporters: () => [...preBillingKeys.export(), 'transporter'],
    exportTransporterDetails: (params?: PreBillingExportTransporterDetailsPayload) => [
        ...preBillingKeys.exportTransporters(),
        'details',
        params,
    ],
    exportTaskGroup: (params?: PreBillingExportTaskGroupPayload) => [...preBillingKeys.export(), 'taskGroup', params],
};

export const usePreBillingCustomersList = <TData = PreBillingCustomersListResponse>(
    params?: PreBillingCustomersListPayload,
    options?: UseQueryOptions<PreBillingCustomersListResponse, AxiosError, TData>
) => {
    return useQuery<PreBillingCustomersListResponse, AxiosError, TData>(
        preBillingKeys.listCustomers(params),
        async () => await listCustomers(params),
        {
            keepPreviousData: true,
            ...options,
        }
    );
};

export const usePreBillingTransportersList = <TData = PreBillingTransportersListResponse>(
    params?: PreBillingTransportersListPayload,
    options?: UseQueryOptions<PreBillingTransportersListResponse, AxiosError, TData>
) => {
    return useQuery<PreBillingTransportersListResponse, AxiosError, TData>(
        preBillingKeys.listTransporters(params),
        async () => await listTransporters(params),
        {
            keepPreviousData: true,
            ...options,
        }
    );
};

export const usePreBillingCustomerTaskGroupsList = <TData = PreBillingListTaskGroupResponse>(
    params: PreBillingCustomerTaskGroupsListPayload,
    options?: UseQueryOptions<PreBillingListTaskGroupResponse, AxiosError, TData>
) => {
    return useQuery<PreBillingListTaskGroupResponse, AxiosError, TData>(
        preBillingKeys.listCustomerTaskGroups(params),
        async () => await listCustomerTaskGroups(params),
        options
    );
};

export const usePreBillingTransporterTaskGroupsList = <TData = PreBillingListTaskGroupResponse>(
    params: PreBillingTransporterTaskGroupsListPayload,
    options?: UseQueryOptions<PreBillingListTaskGroupResponse, AxiosError, TData>
) => {
    return useQuery<PreBillingListTaskGroupResponse, AxiosError, TData>(
        preBillingKeys.listTransporterTaskGroups(params),
        async () => await listTransporterTaskGroups(params),
        options
    );
};

export const usePreBillingCustomerDetails = <TData = PreBillingCustomer>(
    params: PreBillingCustomerDetailsPayload,
    options?: UseQueryOptions<PreBillingCustomer, AxiosError, TData>
) => {
    return useQuery<PreBillingCustomer, AxiosError, TData>(
        preBillingKeys.detailCustomer(params),
        async () => await detailsCustomer(params),
        options
    );
};

export const usePreBillingTransporterDetails = <TData = PreBillingTransporter>(
    params: PreBillingTransporterDetailsPayload,
    options?: UseQueryOptions<PreBillingTransporter, AxiosError, TData>
) => {
    return useQuery<PreBillingTransporter, AxiosError, TData>(
        preBillingKeys.detailTransporter(params),
        async () => await detailsTransporter(params),
        options
    );
};

export const usePreBillingCustomerExport = <TData = undefined>(
    params: PreBillingTransportersExportPayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        preBillingKeys.exportCustomers(),
        async () => await exportPreBillingCustomers(params),
        options
    );
};

export const usePreBillingCustomerDetailsExport = <TData = undefined>(
    params: PreBillingExportCustomerDetailsPayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        preBillingKeys.exportCustomerDetails(params),
        async () => await exportPreBillingCustomerDetails(params),
        options
    );
};

export const usePreBillingCustomerDetailsClientExport = <TData = undefined>(
    params: PreBillingExportCustomerDetailsClientPayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        preBillingKeys.exportCustomerDetailsClient(params),
        async () => await exportPreBillingCustomerDetailsClient(params),
        options
    );
};

export const usePreBillingTransporterExport = <TData = undefined>(
    params: PreBillingTransportersExportPayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        preBillingKeys.exportTransporters(),
        async () => await exportPreBillingTransporters(params),
        options
    );
};

export const usePreBillingTransporterDetailsExport = <TData = undefined>(
    params: PreBillingExportTransporterDetailsPayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        preBillingKeys.exportTransporterDetails(params),
        async () => await exportPreBillingTransporterDetails(params),
        options
    );
};

export const usePreBillingTaskGroupExport = <TData = undefined>(
    params: PreBillingExportTaskGroupPayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        preBillingKeys.exportTaskGroup(params),
        async () => await exportPreBillingTaskGroup(params),
        options
    );
};
