import { useCallback, useMemo, useState, VFC } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { DateRange, EventClickArg, EventContentArg } from '@fullcalendar/react';
import { FormProps, ModalProps } from 'antd';
import { useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';

import { Customer, CustomerForecast } from '../../../../queries/api/types';
import customerMessages from '../../../../i18n/customerMessages';
import formMessages from '../../../../i18n/formMessages';
import { formatForecastDate, isBeforeToday, negativeToPositive, positiveToNegative } from '../../../../helpers';
import { successMessage } from '../../../../helpers/message';
import CustomCalendar from '../../../../components/CustomCalendar';
import ModalOverlap from '../../../../components/ModalOverlap';
import ForecastFormModal from './ForecastFormModal';
import ForecastRemoveConfirmModal from './ForecastRemoveConfirmModal';
import CustomCalendarEvent from '../../../../components/CustomCalendarEvent';
import {
    useCustomerForecastsCreate,
    useCustomerForecastsDetails,
    useCustomerForecastsRemove,
    useCustomerForecastsUpdate,
} from '../../../../queries/customers';

interface CustomerCalendarProps {
    customerId: Customer['id'];
}
export interface CustomerForecastForm extends Omit<CustomerForecast, 'id' | 'customer' | 'date' | 'tolerance'> {
    capacity: number;
    date: DateRange;
    source: 'create' | 'update';
    tolerance?: number;
    toleranceSign?: '+' | '-';
}

interface OpenModalProps {
    date: DateRange;
    formInitialValues: CustomerForecastForm;
}

const CustomerCalendar: VFC<CustomerCalendarProps> = ({ customerId }) => {
    const { formatMessage } = useIntl();

    const { mutate: createCustomerForecast } = useCustomerForecastsCreate();
    const { mutate: updateCustomerForecast } = useCustomerForecastsUpdate();
    const { mutate: removeCustomerForecast } = useCustomerForecastsRemove();

    const [activeDate, setActiveDate] = useState<Dayjs>(dayjs());
    const { data: customerForecastDetails } = useCustomerForecastsDetails({
        customer: customerId,
        fromDate: formatForecastDate(activeDate.startOf('month').subtract(1, 'week')),
        toDate: formatForecastDate(activeDate.endOf('month').add(1, 'week')),
    });

    const onSubmit: FormProps<CustomerForecastForm>['onFinish'] = useCallback(
        (values: CustomerForecastForm) => {
            if (!customerId) return;

            const { capacity, date, source, tolerance, toleranceSign } = values || {};

            const payload = {
                customer: customerId,
                date: formatForecastDate(dayjs(date.start)),
                capacity,
                ...(tolerance && {
                    tolerance: toleranceSign === '+' ? negativeToPositive(tolerance) : positiveToNegative(tolerance),
                }),
            };

            const eventsToCreate = dayjs(date.end).diff(date.start, 'day');

            if (source === 'create') {
                Array.from({ length: eventsToCreate }).forEach((item, index) => {
                    const incrDate = formatForecastDate(dayjs(date.start).add(index, 'day'));

                    createCustomerForecast(
                        {
                            ...payload,
                            date: incrDate, // override payload initial date
                        },
                        {
                            onSuccess: closeModal,
                        }
                    );
                });
            }

            if (source === 'update') {
                updateCustomerForecast(payload, {
                    onSuccess: closeModal,
                });
            }
        },
        [customerId, createCustomerForecast, updateCustomerForecast]
    );

    const calendarEvents = useMemo(
        () =>
            customerForecastDetails?.items.map((detail) => ({
                id: detail.customer.id,
                title: `${String(detail.capacity)} OT`,
                start: detail.date,
                end: detail.date,
                extendedProps: detail,
            })),
        [customerForecastDetails?.items]
    );

    const renderEventContent = ({
        event: {
            extendedProps: { capacity, date, tolerance },
        },
    }: EventContentArg) => <CustomCalendarEvent capacity={capacity} date={date} tolerance={tolerance} />;

    const handleEventClick = (eventClick: EventClickArg) => {
        const dateRange = eventClick.event._instance?.range;
        const { capacity, tolerance } = eventClick?.event?.extendedProps || {};

        if (dateRange) {
            if (isBeforeToday(dateRange.start)) return;

            const formInitialValues: CustomerForecastForm = {
                capacity,
                date: dateRange,
                source: 'update',
                tolerance: negativeToPositive(tolerance),
                toleranceSign: tolerance >= 0 ? '+' : '-',
            };

            openModal({ date: dateRange, formInitialValues });
        }
    };

    const handleDateChange = (date: Dayjs) => {
        setActiveDate(date);
    };

    const handleDateSelect = (date: DateRange) => {
        if (isBeforeToday(date.start)) return;

        const formInitialValues: CustomerForecastForm = {
            capacity: 0,
            date,
            source: 'create',
            toleranceSign: '+',
        };

        openModal({ date, formInitialValues });
    };

    const handleRemoveButtonClick = (customerForecast: CustomerForecastForm) => {
        const modalProps: ModalProps = {
            centered: true,
            children: (
                <ForecastRemoveConfirmModal
                    handleCancelButtonClick={() =>
                        openModal({ date: customerForecast.date, formInitialValues: customerForecast })
                    }
                    handleSubmitButtonClick={() => handleConfirmRemove(customerForecast.date)}
                />
            ),
            closable: false,
            footer: false,
        };

        NiceModal.show(ModalOverlap, { ...modalProps });
    };

    const handleConfirmRemove = (date: DateRange) => {
        if (!customerId) return;

        const payload = {
            customer: customerId,
            date: formatForecastDate(dayjs(date.start)),
        };

        removeCustomerForecast(payload, {
            onSuccess: () => {
                closeModal();

                successMessage({
                    content: formatMessage(formMessages.deleteSuccess),
                });
            },
        });
    };

    const openModal = ({ date, formInitialValues }: OpenModalProps) => {
        const isSingleDate =
            dayjs(date.start).isSame(dayjs(date.end).subtract(1, 'day')) ||
            dayjs(date.start).isSame(dayjs(date.end).subtract(1, 'hour'));

        const singleDateTitle = formatMessage(customerMessages.forecastModalTitle, {
            fromDate: dayjs(date.start).format('DD/MM/YY'),
        });

        const rangeDateTitle = formatMessage(customerMessages.forecastModalTitleRange, {
            fromDate: dayjs(date.start).format('DD/MM/YY'),
            toDate: dayjs(date.end).subtract(1, 'day').format('DD/MM/YY'),
        });

        const modalProps: ModalProps = {
            centered: true,
            children: (
                <ForecastFormModal
                    formInitialValues={formInitialValues}
                    handleCloseModal={closeModal}
                    handleRemoveButtonClick={handleRemoveButtonClick}
                    handleSubmit={onSubmit}
                />
            ),
            footer: false,
            title: isSingleDate ? singleDateTitle : rangeDateTitle,
            width: 652,
        };

        NiceModal.show(ModalOverlap, { ...modalProps });

        return false;
    };

    const closeModal = async () => await NiceModal.hide(ModalOverlap);

    return (
        <CustomCalendar
            events={calendarEvents}
            fixedWeekCount={false}
            initialView="dayGridMonth"
            onDateChange={handleDateChange}
            onDateSelect={handleDateSelect}
            onEventClick={handleEventClick}
            renderEventContent={renderEventContent}
            selectOverlap={false}
            weekNumbers
            weekText=""
        />
    );
};

export default CustomerCalendar;
