import { VFC } from 'react';
import { Card, Col, Row, Space, Typography } from 'antd';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import Seo from '../../../components/Seo';
import { Vehicle } from '../../../queries/api/types';
import { useVehicleDetails } from '../../../queries/vehicles';
import ApiResult from '../../../components/ApiResult';
import LabelWithTooltipIcon from '../../../components/LabelWithTooltipIcon';
import genericMessages from '../../../i18n/genericMessages';
import vehicleMessages from '../../../i18n/vehicleMessages';
import TaskGroupHistoryCard from './TaskGroupHistoryCard';
import VehicleOperatorListCard from './VehicleOperatorListCard';

const ManagementVehiclesDetailsHistory: VFC = () => {
    const { vehicleId } = useParams<{ vehicleId: Vehicle['id'] }>();
    const { formatMessage } = useIntl();
    const { data: vehicleDetails, isLoading, isError, error } = useVehicleDetails(vehicleId, { enabled: !!vehicleId });
    const pageTitle = vehicleDetails?.reference;

    return (
        <>
            <Seo title={pageTitle} />
            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={8}>
                            <Card loading={isLoading} className="h-full">
                                <Typography.Title level={2} className="text-base font-medium text-faded-blue">
                                    <LabelWithTooltipIcon tooltip={formatMessage(vehicleMessages.samsara)}>
                                        <FormattedMessage
                                            id="management.vehicles.history.last_mileage"
                                            defaultMessage="Dernier kilométrage"
                                            description="Card title"
                                        />
                                    </LabelWithTooltipIcon>
                                </Typography.Title>
                                <span className="text-2xl font-semibold uppercase">
                                    <FormattedNumber
                                        value={vehicleDetails?.mileage ?? 0}
                                        style="unit"
                                        unit="kilometer"
                                    />
                                </span>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card loading={isLoading} className="h-full">
                                <Typography.Title level={2} className="text-base font-medium text-faded-blue">
                                    <LabelWithTooltipIcon tooltip={formatMessage(vehicleMessages.samsara)}>
                                        <FormattedMessage
                                            id="management.vehicles.history.last_task"
                                            defaultMessage="Dernière course"
                                            description="Card title"
                                        />
                                    </LabelWithTooltipIcon>
                                </Typography.Title>
                                <span className="text-2xl font-semibold">
                                    {vehicleDetails?.computedProperties.lastTaskAt ? (
                                        <FormattedDate
                                            value={vehicleDetails?.computedProperties.lastTaskAt}
                                            day="2-digit"
                                            month="2-digit"
                                            year="2-digit"
                                            hour="2-digit"
                                            minute="2-digit"
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </span>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card loading={isLoading} className="h-full">
                                <Typography.Title level={2} className="text-base font-medium text-faded-blue">
                                    <LabelWithTooltipIcon tooltip={formatMessage(vehicleMessages.samsara)}>
                                        <FormattedMessage
                                            id="management.vehicles.history.last_position"
                                            defaultMessage="Dernière position"
                                            description="Card title"
                                        />
                                    </LabelWithTooltipIcon>
                                </Typography.Title>
                                <span className="text-lg font-semibold">
                                    {vehicleDetails?.computedProperties.lastAddress || (
                                        <span className="text-secondary">{formatMessage(genericMessages.noData)}</span>
                                    )}
                                </span>
                            </Card>
                        </Col>
                    </Row>
                    <TaskGroupHistoryCard vehicleId={vehicleId} />
                    <VehicleOperatorListCard vehicleId={vehicleId} />
                </Space>
            )}
        </>
    );
};

export default ManagementVehiclesDetailsHistory;
