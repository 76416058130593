import { useMemo, VFC } from 'react';
import { Badge, Button, Card, Divider, Form, FormProps, Input, Space, Spin, Tag, Typography } from 'antd';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useModal } from '@ebay/nice-modal-react';

import Seo from '../../../components/Seo';
import genericMessages from '../../../i18n/genericMessages';
import placeMessages from '../../../i18n/placeMessages';
import { getRoute, RoutePathName } from '../../../routes';
import {
    Customer,
    DockType,
    Place,
    PlaceType,
    ValueListItem,
    ValueListSlug,
    WeekDay,
    WeekDaysRecord,
} from '../../../queries/api/types';
import {
    usePlaceCreate,
    usePlaceDetails,
    usePlaceUpdate,
    usePlaceUpdateAdminMobilicToken,
} from '../../../queries/places';
import DetailsFormCard, { DetailsFormCardProps } from '../../../components/form/DetailsFormCard';
import ApiResult from '../../../components/ApiResult';
import { PlaceUpdatePayload } from '../../../queries/api/places';
import { errorMessage, successMessage } from '../../../helpers/message';
import formMessages from '../../../i18n/formMessages';
import { Check } from '../../../components/icons';
import DetailsFormEditableTitle from '../../../components/form/DetailsFormEditableTitle';
import ListTitle from '../../../components/ListTitle';
import OpenHoursDetails from '../../../components/form/OpenHoursDetails';
import { getTimeAsDayjs, getTimeAsNumber } from '../../../helpers';
import WorkingHolidaysDetails from '../../../components/form/WorkingHolidaysDetails';
import PlaceClosedDaysFields from './PlaceClosedDaysFields';
import PlaceClosedDaysDetails from './PlaceClosedDaysDetails';
import { getRequiredRule } from '../../../i18n';
import { mobilicAuthentication } from '../../../helpers/mobilicAuthentication';
import usePersistentState from '../../../hooks/usePersistentState';
import ConfirmationModal from '../../../components/ConfirmationModal';

const initialOpenHourDay = {
    isEnabled: true,
    fromHour: dayjs().hour(6).minute(0),
    toHour: dayjs().hour(20).minute(0),
};

const formatInitialValue = (value: Place | undefined) =>
    ({
        ...value,
        type: value?.type || PlaceType.warehouse,
        group: value?.group?.id,
        activity: value?.activity?.id,
        customers: value?.customers.map((customer) => customer.id),
        secondaryPlaces: value?.secondaryPlaces?.map((customer) => customer.id),
        managers: value?.managers || [
            {
                firstName: '',
                lastName: '',
                email: '',
                jobTitle: '',
                phone: '',
            },
        ],
        openHours: {
            ...Object.values(WeekDay).reduce(
                (acc, day) => ({
                    ...acc,
                    [day]: {
                        ...initialOpenHourDay,
                        ...(day === WeekDay.sunday ? { isEnabled: false } : {}),
                        fromHour: getTimeAsDayjs(value?.openHours?.[day]?.fromHour, '0600'),
                        toHour: getTimeAsDayjs(value?.openHours?.[day]?.toHour, '2000'),
                    },
                }),
                {}
            ),
        },
        closedDays: value?.closedDays?.map(({ fromDate, toDate, isFullDay }) => ({
            isFullDay,
            fromDate: dayjs(fromDate),
            toDate: dayjs(toDate),
        })),
        firstLoadingHour: getTimeAsDayjs(value?.firstLoadingHour, '0600'),
        workingHolidays: value?.workingHolidays?.map((valueListItem) => valueListItem.id) || [],
    } as PlaceForm);

const formatPlacePayload = (details?: Place) => (values: PlaceForm) => ({
    ...details,
    ...values,
    customers: values.customers || details?.customers.map((customer) => customer.id) || [],
    secondaryPlaces: values.secondaryPlaces || details?.secondaryPlaces?.map((place) => place.id) || [],
    openHours: {
        ...Object.values(WeekDay).reduce(
            (acc, day) => ({
                ...acc,
                [day]: {
                    ...values?.openHours?.[day],
                    fromHour: getTimeAsNumber(values.openHours?.[day]?.fromHour),
                    toHour: getTimeAsNumber(values.openHours?.[day]?.toHour),
                },
            }),
            {}
        ),
    } as PlaceUpdatePayload['openHours'],
    closedDays:
        (values.closedDays?.map(({ fromDate, toDate, isFullDay }) => ({
            isFullDay,
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString(),
        })) as PlaceUpdatePayload['closedDays']) || [],
    firstLoadingHour: values.firstLoadingHour
        ? parseInt(
              `${String(values.firstLoadingHour.hour()).padStart(2, '0')}${String(
                  values.firstLoadingHour.minute()
              ).padStart(2, '0')}`
          )
        : undefined,
    docks:
        (values.docks?.map((dock) => ({
            ...dock,
            allowedVehicleTypes: [dock.allowedVehicleTypes],
        })) as PlaceUpdatePayload['docks']) || [],
    dockTypes: values.dockTypes || [],
    workingHolidays: values.workingHolidays || [],
});

interface PlaceForm
    extends Omit<
        Place,
        | 'id'
        | 'firstLoadingHour'
        | 'openHours'
        | 'closedDays'
        | 'docks'
        | 'dockTypes'
        | 'workingHolidays'
        | 'customers'
        | 'secondaryPlaces'
    > {
    firstLoadingHour?: Dayjs;
    openHours?: WeekDaysRecord<{
        isEnabled: boolean;
        fromHour?: Dayjs;
        toHour?: Dayjs;
    }>;
    closedDays?: Array<{
        fromDate: Dayjs;
        toDate: Dayjs;
        isFullDay?: boolean;
    }>;
    docks?: Array<{
        name: string;
        allowedVehicleTypes: Array<ValueListItem['id']>;
    }>;
    dockTypes?: DockType[];
    workingHolidays?: Array<ValueListItem['id']>;
    customers?: Array<Customer['id']>;
    secondaryPlaces?: Array<Place['id']>;
}
const removeMobilicCompanyModalId = 'remove-mobilic-company-modal';
const replaceMobilicCompanyModalId = 'replace-mobilic-company-modal';

const PlacesDetails: VFC = () => {
    const { placeId } = useParams<{ placeId?: Place['id'] }>();
    const [form] = Form.useForm();
    const isCreating = !placeId;
    const { formatMessage } = useIntl();
    const history = useHistory();
    const { data: placeDetails, isError, error } = usePlaceDetails(placeId, { enabled: !!placeId });
    const { mutate: createPlace } = usePlaceCreate();
    const removeMobilicCompanyModal = useModal(removeMobilicCompanyModalId);
    const replaceMobilicCompanyModal = useModal(replaceMobilicCompanyModalId);
    const { mutate: placeUpdateAdminMobilicToken } = usePlaceUpdateAdminMobilicToken({
        onError: () => {
            errorMessage({ content: formatMessage(genericMessages.defaultError) });
        },
        onSuccess: () => {
            successMessage({
                content: formatMessage({
                    id: 'details_form_card.update.success_message',
                    defaultMessage: 'Modifications enregistrées avec succès',
                }),
            });
            removeMobilicCompanyModal.hide();
        },
    });
    const pageTitle = isCreating
        ? formatMessage({
              id: 'place_details.create.title',
              defaultMessage: 'Ajouter un entrepôt / magasin',
              description: 'Place creation page title',
          })
        : placeDetails?.name || formatMessage(genericMessages.editing);
    const onCreateSubmit: FormProps['onFinish'] = (values) => {
        const payload = formatPlacePayload()(values);
        createPlace(payload, {
            onSuccess: () => {
                successMessage({
                    content:
                        payload.type === PlaceType.warehouse
                            ? formatMessage({
                                  id: 'place_details.create.warehouse.success_message',
                                  defaultMessage: 'Entrepôt ajouté avec succès',
                              })
                            : formatMessage({
                                  id: 'place_details.create.shop.success_message',
                                  defaultMessage: 'Magasin ajouté avec succès',
                              }),
                });
                history.push(getRoute(RoutePathName.places));
            },
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error.response?.status }),
                });
            },
        });
    };
    const sections = useMemo<
        Array<
            Pick<
                DetailsFormCardProps<Place, PlaceForm, PlaceUpdatePayload>,
                'title' | 'editButtonText' | 'fields' | 'renderEdit' | 'renderDetails'
            >
        >
    >(
        () => [
            {
                title: formatMessage(genericMessages.generalInformations),
                editButtonText: formatMessage(placeMessages.generalInformationsEdit),
                fields: [
                    {
                        name: 'type',
                        type: 'PlaceTypeSegmented',
                        label: formatMessage(placeMessages.type),
                        required: true,
                    },
                    {
                        name: 'group',
                        type: 'ValueListItemSelect',
                        fieldComponentProps: {
                            valueListSlug: ValueListSlug.groups,
                        },
                        label: formatMessage(placeMessages.groupLong),
                        required: true,
                    },
                    {
                        name: 'activity',
                        type: 'ValueListItemSelect',
                        fieldComponentProps: {
                            valueListSlug: ValueListSlug.placeActivityTypes,
                        },
                        label: formatMessage(placeMessages.activity),
                        required: true,
                    },
                    {
                        name: 'customers',
                        type: 'CustomerSelect',
                        label: formatMessage(placeMessages.customers),
                        fieldComponentProps: {
                            mode: 'multiple',
                            initialValue: placeDetails?.customers,
                        },
                        required: true,
                        renderDetails: (field, record) =>
                            record?.customers.map((customer) => <Tag key={customer.id}>{customer.name}</Tag>),
                    },
                    {
                        name: 'secondaryPlaces',
                        type: 'PlaceSelect',
                        fieldComponentProps: {
                            mode: 'multiple',
                            initialValue: placeDetails?.secondaryPlaces,
                            excludedPlaceIds: [placeId!],
                        },
                        label: formatMessage(placeMessages.secondaryPlaces),
                        renderDetails: (field, record) =>
                            record?.secondaryPlaces?.map((place) => <Tag key={place.id}>{place.name}</Tag>),
                    },
                    {
                        name: 'mobilicCompanyId',
                        type: 'string',
                        label: formatMessage(placeMessages.mobilicPlaceIdName),
                        fieldComponentProps: {
                            placeholder: formatMessage(placeMessages.mobilicPlaceIdPlaceHolder),
                        },
                        required: true,
                    },
                    {
                        name: 'firstLoadingHour',
                        type: 'TimePicker',
                        renderDetails: (field, record) => (
                            <FormattedTime value={getTimeAsDayjs(record?.firstLoadingHour).toDate()} />
                        ),
                        label: formatMessage(placeMessages.firstLoadingHour),
                    },
                ],
            },
            {
                title: formatMessage(genericMessages.postalAddress),
                editButtonText: formatMessage(genericMessages.editAddress),
                fields: [
                    {
                        name: ['address', 'street'],
                        type: 'string',
                        label: formatMessage(genericMessages.address, { count: 1 }),
                        fieldComponentProps: {
                            placeholder: formatMessage(genericMessages.addressPlaceholder),
                        },
                        required: true,
                    },
                    {
                        name: ['address', 'zipCode'],
                        type: 'string',
                        label: formatMessage(genericMessages.zipCode),
                        fieldComponentProps: {
                            placeholder: formatMessage(genericMessages.zipCodePlaceholder),
                        },
                        required: true,
                    },
                    {
                        name: ['address', 'city'],
                        type: 'string',
                        label: formatMessage(genericMessages.city),
                        fieldComponentProps: {
                            placeholder: formatMessage(genericMessages.cityPlaceholder),
                        },
                        required: true,
                    },
                    {
                        name: ['address', 'country'],
                        type: 'CountrySelect',
                        label: formatMessage(genericMessages.country),
                        required: true,
                    },
                ],
            },
            {
                title: formatMessage(placeMessages.managers, { count: 2 }),
                editButtonText: formatMessage(placeMessages.managersEditButton),
                fields: [
                    {
                        name: 'managers',
                        type: 'list',
                        listItemLabel: placeMessages.managerIndex,
                        listMinimumIndexToRemove: 1,
                        listAddButtonLabel: formatMessage(placeMessages.managersAddButton),
                        required: true,
                        listFields: [
                            {
                                name: 'firstName',
                                type: 'string',
                                label: formatMessage(genericMessages.firstName),
                                fieldComponentProps: {
                                    placeholder: formatMessage(genericMessages.firstNamePlaceholder),
                                },
                                required: true,
                            },
                            {
                                name: 'lastName',
                                type: 'string',
                                label: formatMessage(genericMessages.lastName),
                                fieldComponentProps: {
                                    placeholder: formatMessage(genericMessages.lastNamePlaceholder),
                                },
                                required: true,
                            },
                            {
                                name: 'email',
                                type: 'email',
                                label: formatMessage(genericMessages.email),
                                fieldComponentProps: {
                                    placeholder: formatMessage(genericMessages.emailPlaceholder),
                                },
                                required: true,
                            },
                            {
                                name: 'phone',
                                type: 'PhoneInput',
                                label: formatMessage(genericMessages.phone),
                                fieldComponentProps: {
                                    placeholder: formatMessage(genericMessages.phonePlaceholder),
                                },
                                required: true,
                            },
                            {
                                name: 'jobTitle',
                                type: 'string',
                                label: formatMessage(genericMessages.jobTitle),
                                fieldComponentProps: {
                                    placeholder: formatMessage(genericMessages.jobTitlePlaceholder),
                                },
                                required: true,
                            },
                        ],
                    },
                ],
            },
            {
                title: formatMessage(placeMessages.openHoursSection),
                editButtonText: formatMessage(placeMessages.openHoursEditButton),
                fields: Object.values(WeekDay).map((weekDay) => ({
                    name: ['openHours', weekDay],
                    type: 'OpenHoursFormFields',
                    fieldComponentProps: {
                        enabledMessage: genericMessages.openFromTo,
                        disabledMessage: genericMessages.closed,
                        errorMessage: placeMessages.openHoursErrorFromMustBeBeforeTo,
                    },
                    renderDetails: (field, record, dataGetter) => (
                        <OpenHoursDetails<Place>
                            field={field}
                            record={record}
                            dataGetter={dataGetter}
                            disabledMessage={formatMessage(genericMessages.closed)}
                        />
                    ),
                    label: formatMessage(genericMessages[weekDay]),
                })),
            },
            {
                title: formatMessage(genericMessages.workingHolidays),
                editButtonText: formatMessage(genericMessages.workingHolidaysEditButton),
                fields: [
                    {
                        name: 'workingHolidays',
                        // required: true,
                        type: 'ValueListItemButtons',
                        fieldComponentProps: {
                            valueListSlug: ValueListSlug.holidays,
                        },
                        label: formatMessage(genericMessages.workingHolidaysLabel),
                        detailsLabel: formatMessage(genericMessages.workingHolidaysDetailLabel),
                        renderDetails: (field, record) => <WorkingHolidaysDetails record={record} />,
                        formItemProps: {
                            labelCol: { span: 24 },
                            wrapperCol: { span: 24 },
                        },
                    },
                ],
            },
            {
                title: formatMessage(placeMessages.closedDays),
                editButtonText: formatMessage(placeMessages.closedDaysEditButton),
                renderEdit: () => <PlaceClosedDaysFields />,
                renderDetails: (fields, record) => <PlaceClosedDaysDetails record={record} />,
            },
            {
                title: formatMessage(placeMessages.docks),
                editButtonText: formatMessage(placeMessages.dockTypesEditButton),
                fields: [
                    {
                        name: 'dockTypes',
                        type: 'list',
                        listItemLabel: placeMessages.dockTypeIndex,
                        label: formatMessage(placeMessages.dockTypesLabel),
                        listAddButtonLabel: formatMessage(placeMessages.dockTypesAddButton),
                        required: true,
                        listFields: [
                            {
                                name: 'type',
                                type: 'ValueListItemSelect',
                                fieldComponentProps: {
                                    valueListSlug: ValueListSlug.dockTypes,
                                },
                                label: formatMessage(placeMessages.dockTypeLabel),
                                required: true,
                            },
                            {
                                name: 'count',
                                type: 'number',
                                label: formatMessage(placeMessages.dockTypeCountLabel),
                                required: true,
                            },
                        ],
                    },
                ],
            },
            {
                title: formatMessage(placeMessages.vehiclesByDockSection),
                editButtonText: formatMessage(placeMessages.vehiclesByDockEditButton),
                fields: [
                    {
                        name: 'docks',
                        type: 'list',
                        listItemLabel: placeMessages.vehiclesByDockIndex,
                        label: formatMessage(placeMessages.vehiclesByDockLabel),
                        listAddButtonLabel: formatMessage(placeMessages.vehiclesByDockAddButton),
                        required: true,
                        listFields: [
                            {
                                name: 'name',
                                type: 'string',
                                label: formatMessage(placeMessages.vehiclesByDockDockLabel),
                                fieldComponentProps: {
                                    placeholder: formatMessage(placeMessages.vehiclesByDockDockPlaceholder),
                                },
                                required: true,
                            },
                            {
                                name: 'allowedVehicleTypes',
                                type: 'ValueListItemSelect',
                                fieldComponentProps: {
                                    valueListSlug: ValueListSlug.vehicleTypes,
                                },
                                label: formatMessage(genericMessages.vehicleType),
                                required: true,
                            },
                        ],
                    },
                ],
            },
        ],
        [formatMessage, placeDetails?.customers, placeDetails?.secondaryPlaces, placeId]
    );
    const sectionCards = useMemo(
        () =>
            sections.map((section) => (
                <DetailsFormCard<Place, PlaceForm, PlaceUpdatePayload>
                    {...section}
                    key={section.title}
                    detailsQueryHandler={usePlaceDetails}
                    updateQueryHandler={usePlaceUpdate}
                    initialValueFormatter={formatInitialValue}
                    formatPayload={formatPlacePayload(placeDetails)}
                    id={placeId}
                />
            )),
        [placeId, sections, placeDetails]
    );

    const [, setUpdatingPlaceId] = usePersistentState('updatingPlaceId');

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                {isCreating ? (
                    <ListTitle className="mb-0 uppercase" backRoute={getRoute(RoutePathName.places)}>
                        {pageTitle}
                    </ListTitle>
                ) : (
                    <DetailsFormEditableTitle
                        backRoute={getRoute(RoutePathName.places)}
                        detailsQueryHandler={usePlaceDetails}
                        updateQueryHandler={usePlaceUpdate}
                        placeholder={formatMessage(placeMessages.namePlaceholder)}
                        id={placeId}
                    />
                )}
            </div>
            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    {isCreating ? (
                        <Form
                            form={form}
                            onFinish={onCreateSubmit}
                            initialValues={formatInitialValue(placeDetails)}
                            scrollToFirstError
                        >
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                <Form.Item name="name" className="mb-0" rules={[getRequiredRule(formatMessage)]}>
                                    <Input placeholder={formatMessage(placeMessages.namePlaceholder)} />
                                </Form.Item>
                                {sectionCards}
                                <div className="flex justify-center">
                                    <Button htmlType="submit" type="primary" icon={<Check />} size="large">
                                        <FormattedMessage {...formMessages.saveData} tagName="span" />
                                    </Button>
                                </div>
                            </Space>
                        </Form>
                    ) : (
                        <>
                            <Spin spinning={placeDetails === undefined}>
                                <Card>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center gap-2">
                                            <Typography.Title level={3} className="text-blue font-bold mb-0">
                                                {formatMessage(placeMessages.mobilicToken)}
                                            </Typography.Title>
                                            <Divider type="vertical" style={{ height: '22px' }} />
                                            <div>
                                                {placeDetails?.hasMobilicAdminToken ? (
                                                    <>
                                                        <Badge status="success" />
                                                        {formatMessage(placeMessages.mobilicTokenActivated)}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Badge status="error" />
                                                        {formatMessage(placeMessages.mobilicTokenDisabled)}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex gap-4">
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    if (placeDetails?.hasMobilicAdminToken) {
                                                        replaceMobilicCompanyModal.show().then(() => {
                                                            setUpdatingPlaceId(placeDetails?.id);
                                                            mobilicAuthentication();
                                                        });
                                                    } else {
                                                        setUpdatingPlaceId(placeDetails?.id);
                                                        mobilicAuthentication();
                                                    }
                                                }}
                                            >
                                                {placeDetails?.hasMobilicAdminToken
                                                    ? formatMessage(placeMessages.mobilicTokenUpdate)
                                                    : formatMessage(placeMessages.mobilicTokenGenerate)}
                                            </Button>
                                            {placeDetails?.hasMobilicAdminToken && (
                                                <Button
                                                    onClick={() => {
                                                        removeMobilicCompanyModal.show().then(() => {
                                                            placeUpdateAdminMobilicToken({
                                                                id: placeDetails?.id,
                                                                adminMobilicToken: null,
                                                            });
                                                        });
                                                    }}
                                                >
                                                    {formatMessage(placeMessages.mobilicTokenDelete)}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Card>
                            </Spin>
                            {sectionCards}
                        </>
                    )}
                    <ConfirmationModal
                        title={formatMessage(placeMessages.mobilicTokenDeleteConfirmation)}
                        closable={false}
                        text={
                            <>
                                <p>{formatMessage(placeMessages.mobilicTokenDeleteContent)}</p>
                            </>
                        }
                        id={removeMobilicCompanyModalId}
                    />
                    <ConfirmationModal
                        title={formatMessage(placeMessages.mobilicTokenUpdateConfirmation)}
                        closable={false}
                        text={
                            <>
                                <p>{formatMessage(placeMessages.mobilicTokenUpdateContent)}</p>
                            </>
                        }
                        id={replaceMobilicCompanyModalId}
                    />
                </Space>
            )}
        </>
    );
};

export default PlacesDetails;
