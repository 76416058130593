import { useMemo, VFC } from 'react';
import { Select, SelectProps } from 'antd';
import { useIntl } from 'react-intl';

import { RoleSlug } from '../../queries/api/types';
import userMessages, { userRoleMessagesMap } from '../../i18n/userMessages';
import { useRoleList } from '../../queries/roles';

export type RoleSelectProps = SelectProps<RoleSlug>;

const RoleSelect: VFC<RoleSelectProps> = (props) => {
    const { formatMessage } = useIntl();
    const { data } = useRoleList({});
    const options: Array<{
        label: string;
        value: string;
    }> = useMemo(() => {
        if (data) {
            return data.items.map((role) => ({
                label: formatMessage(userRoleMessagesMap.get(role.slug)!),
                value: role.id,
            }));
        }
        return [];
    }, [data, formatMessage]);
    return (
        <Select
            placeholder={formatMessage(userMessages.roleSelectPlaceholder)}
            filterOption={false}
            options={options}
            showSearch={false}
            showArrow
            {...props}
        />
    );
};

export default RoleSelect;
