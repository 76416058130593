import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Modal, ModalProps, Row, Tag } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import genericMessages from '../../../i18n/genericMessages';
import { Place } from '../../../queries/api/types';

interface ConfirmationModalProps extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>, Record<string, any> {
    places?: Place[];
}
const DispatcherAlertModal = NiceModal.create<ConfirmationModalProps>(({ places, isLoading, ...props }) => {
    const confirmModal = useModal();
    const { formatMessage } = useIntl();
    return (
        <Modal
            {...props}
            {...antdModal(confirmModal)}
            afterClose={confirmModal.remove}
            closable={false}
            footer={false}
            centered
        >
            <Row>
                <Col span={2}>
                    <ExclamationCircleFilled className="text-lg text-orange" />
                </Col>
                <Col span={22}>
                    <p className="text-lg font-bold text-blue">
                        {formatMessage({
                            id: 'dispatcherAlertModal.title',
                            defaultMessage: 'Rôle “Dispatcher avec planif. auto”',
                            description: 'dispatcher alert modal title',
                        })}
                    </p>
                    <p>
                        {formatMessage({
                            id: 'dispatcherAlertModal.message',
                            defaultMessage:
                                'Il existe déjà au moins un utilisateur avec ce rôle pour le ou les entrepôts suivants: ',
                            description: 'dispatcher alert modal message',
                        })}
                    </p>
                    <div className="mb-4">
                        {places?.map((places) => (
                            <Tag key={places.id} className="mb-2">
                                {places.name}
                            </Tag>
                        ))}
                    </div>
                    <p>
                        {formatMessage({
                            id: 'dispatcherAlertModal.question',
                            defaultMessage: 'Que souhaitez-vous faire ?',
                            description: 'dispatcher alert modal question',
                        })}
                    </p>
                    <p className="font-bold text-orange">
                        {formatMessage({
                            id: 'dispatcherAlertModal.advice',
                            defaultMessage:
                                'Il est recommandé de n’avoir qu’un seul utilisateur par entrepôt avec ce rôle.',
                            description: 'dispatcher alert modal advice',
                        })}
                    </p>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col span={24} className="flex justify-end gap-2">
                    <Button key="cancelBtn" onClick={confirmModal.hide}>
                        {formatMessage(genericMessages.cancel)}
                    </Button>
                    <Button key="yesBtn" onClick={() => confirmModal.resolve()} type="primary" loading={isLoading}>
                        {formatMessage(genericMessages.validate)}
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
});
export default DispatcherAlertModal;
