import { Form, FormInstance } from 'antd';
import { FormattedNumber, IntlShape } from 'react-intl';

import { DetailsFormCardProps } from '../../../components/form/DetailsFormCard';
import { sectionCardFormItemLayout } from '../../../components/SectionCard';
import ApiSelect from '../../../components/selects/ApiSelect';
import ValueListItemSelect from '../../../components/selects/ValueListItemSelect';
import { getRequiredRule } from '../../../i18n';
import genericMessages from '../../../i18n/genericMessages';
import vehicleMessages from '../../../i18n/vehicleMessages';
import { TransporterListPayload } from '../../../queries/api/transporters';
import { Transporter, ValueListItem, ValueListSlug, Vehicle } from '../../../queries/api/types';
import { VehicleCreatePayload, VehicleUpdatePayload } from '../../../queries/api/vehicles';
import { useTransporterList } from '../../../queries/transporters';
import VehicleDimensionsFields from './VehicleDimensionsFields';
import VehicleOtherFields from './VehicleOtherFields';

export { default as VehiclesList } from './VehiclesList';
export { default as VehiclesValueList } from './VehiclesValueList';
export { default as VehiclesDetails } from './VehiclesDetails';

export const formatVehicleInitialValue = (details: Vehicle | undefined) =>
    ({
        ...details,
        groups: details?.groups.map((group) => group.id),
        ownershipType: details?.ownershipType?.id,
        type: details?.type?.id,
        brand: details?.brand?.id,
        model: details?.model?.id,
        gasType: details?.gasType?.id,
        transportType: details?.transportType?.id,
        transporter: details?.transporter?.id,
    } as VehicleForm); // cast to avoid required props (which are not required as initial values. form validation ensures we cannot submit empty required fields)

export const formatVehicleCreatePayload = (values: VehicleForm): VehicleCreatePayload => ({
    ...values,
    meta: values.meta ?? {
        properties: {},
    },
});

export const formatVehicleUpdatePayload = (values: VehicleForm, vehicle: Vehicle | undefined): VehicleUpdatePayload => {
    if (!vehicle?.id) {
        throw new Error('missing id');
    }

    return {
        ...vehicle,
        ...values,
        transporter: values.transporter || '',
    };
};

export interface VehicleForm
    extends Omit<
        Vehicle,
        | 'id'
        | 'computedProperties'
        | 'groups'
        | 'ownershipType'
        | 'type'
        | 'brand'
        | 'model'
        | 'gasType'
        | 'transportType'
        | 'transporter'
        | 'meta'
    > {
    groups: Array<ValueListItem['id']>;
    ownershipType: ValueListItem['id'];
    type: ValueListItem['id'];
    brand: ValueListItem['id'];
    model: ValueListItem['id'];
    gasType: ValueListItem['id'];
    transportType: ValueListItem['id'];
    transporter: Transporter['id'];
    meta?: Vehicle['meta'];
}

export const getVehicleFormSections = ({
    formatMessage,
    relayOwnershipTypeId,
    transporterOwnershipTypeId,
    form,
}: {
    formatMessage: IntlShape['formatMessage'];
    relayOwnershipTypeId?: string;
    transporterOwnershipTypeId?: string;
    form?: FormInstance;
    noEditButton?: boolean;
}) => {
    const sections: Array<
        Pick<
            DetailsFormCardProps<Vehicle, VehicleForm, VehicleUpdatePayload>,
            'title' | 'editButtonText' | 'fields' | 'renderEdit' | 'renderDetails'
        >
    > = [
        {
            title: formatMessage(vehicleMessages.ownership),
            editButtonText: formatMessage({
                id: 'vehicle.ownership.edit_button',
                defaultMessage: 'Modifier la proriété',
            }),
            fields: [
                {
                    name: 'ownershipType',
                    type: 'ValueListItemSelect',
                    label: formatMessage({
                        id: 'vehicle.ownership_type.label',
                        defaultMessage: 'Propriété du véhicule',
                    }),
                    fieldComponentProps: {
                        valueListSlug: ValueListSlug.vehicleOwnershipTypes,
                        itemLabelKey: 'vehicleOwnershipTypeName',
                    },
                    required: true,
                },
                {
                    name: 'transporter',
                    detailsLabel: (record) => <>{record?.transporter && formatMessage(genericMessages.partner)}</>,
                    formItemProps: {
                        dependencies: ['ownershipType'],
                    },
                    shouldRenderDetails: (field, record) => Boolean(record?.transporter),
                    renderEdit: () => (
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                                getFieldValue(['ownershipType']) && (
                                    <>
                                        {getFieldValue(['ownershipType']) === relayOwnershipTypeId && (
                                            <Form.Item
                                                label={formatMessage({
                                                    id: 'vehicle.ownership_transporter.label',
                                                    defaultMessage: 'Partenaire',
                                                })}
                                                name="transporter"
                                                className="mb-0"
                                                {...sectionCardFormItemLayout}
                                                required
                                            >
                                                <ApiSelect<Transporter, Transporter['id'], TransporterListPayload>
                                                    listQueryHandler={useTransporterList}
                                                    valueProp="id"
                                                    labelProp="name"
                                                />
                                            </Form.Item>
                                        )}

                                        {getFieldValue(['ownershipType']) === transporterOwnershipTypeId && (
                                            <Form.Item
                                                label={formatMessage({
                                                    id: 'vehicle.ownership_transporter.label',
                                                    defaultMessage: 'Partenaire',
                                                })}
                                                name="transporter"
                                                className="mb-0"
                                                {...sectionCardFormItemLayout}
                                                required
                                            >
                                                <ApiSelect<Transporter, Transporter['id'], TransporterListPayload>
                                                    listQueryHandler={useTransporterList}
                                                    valueProp="id"
                                                    labelProp="name"
                                                />
                                            </Form.Item>
                                        )}
                                    </>
                                )
                            }
                        </Form.Item>
                    ),
                    renderDetails: (field, record) => record?.transporter?.name,
                },
            ],
        },
        {
            title: formatMessage(genericMessages.groupLink),
            editButtonText: formatMessage(genericMessages.groupLinkEdit),
            fields: [
                {
                    name: 'groups',
                    type: 'ValueListItemSelect',
                    fieldComponentProps: {
                        mode: 'multiple',
                        valueListSlug: ValueListSlug.groups,
                        itemLabelKey: 'reference',
                    },
                    label: formatMessage(genericMessages.groupLinkLong),
                    required: true,
                },
            ],
        },
        {
            title: formatMessage(vehicleMessages.plateNumber),
            editButtonText: formatMessage({
                id: 'vehicle.place_number.edit_button',
                defaultMessage: "Modifier l'immatriculation",
            }),
            fields: [
                {
                    name: 'plateNumber',
                    type: 'string',
                    label: formatMessage(vehicleMessages.plateNumber),
                    required: true,
                },
            ],
        },
        {
            title: formatMessage({
                id: 'vehicle.brand_and_model',
                defaultMessage: 'Marque et modèle',
            }),
            editButtonText: formatMessage({
                id: 'vehicle.brand_and_model.edit_button',
                defaultMessage: 'Modifier Marque / modèle',
            }),
            fields: [
                {
                    name: 'brand',
                    label: formatMessage(genericMessages.brand, { count: 1 }),
                    renderEdit: () => (
                        <Form.Item
                            label={formatMessage(genericMessages.brand, { count: 1 })}
                            name="brand"
                            {...sectionCardFormItemLayout}
                            rules={[getRequiredRule(formatMessage)]}
                            required
                        >
                            <ValueListItemSelect
                                valueListSlug={ValueListSlug.vehicleBrands}
                                onChange={() => form?.setFieldsValue({ model: undefined })}
                            />
                        </Form.Item>
                    ),
                    renderDetails: (field, record) =>
                        record?.brand?.fields?.[Object.keys(record.brand?.fields)?.[0]] ?? '-',
                },
                {
                    name: 'model',
                    label: formatMessage(genericMessages.model, { count: 1 }),
                    renderEdit: () => (
                        <Form.Item noStyle dependencies={['brand']}>
                            {({ getFieldValue }) => (
                                <Form.Item
                                    label={formatMessage(genericMessages.model, { count: 1 })}
                                    name="model"
                                    className="mb-0"
                                    {...sectionCardFormItemLayout}
                                    rules={[getRequiredRule(formatMessage)]}
                                    required
                                >
                                    <ValueListItemSelect
                                        valueListSlug={ValueListSlug.vehicleModels}
                                        disabled={!getFieldValue(['brand'])}
                                        filterOptions={(item) =>
                                            item.meta?.brandValueListItemId === getFieldValue(['brand'])
                                        }
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    ),
                    renderDetails: (field, record) =>
                        record?.model?.fields?.[Object.keys(record.model?.fields)?.[0]] ?? '-',
                },
            ],
        },
        {
            title: formatMessage(genericMessages.vehicleType),
            editButtonText: formatMessage(genericMessages.editTypeButton),
            fields: [
                {
                    name: 'type',
                    required: true,
                    label: formatMessage(genericMessages.vehicleType),
                    renderEdit: () => (
                        <Form.Item
                            label={formatMessage(genericMessages.vehicleType)}
                            name="type"
                            {...sectionCardFormItemLayout}
                            rules={[getRequiredRule(formatMessage)]}
                            required
                        >
                            <ValueListItemSelect
                                valueListSlug={ValueListSlug.vehicleTypes}
                                // onChange={() => form.setFieldsValue({ model: undefined })} // set default value for sizes
                            />
                        </Form.Item>
                    ),
                    renderDetails: (field, record) =>
                        record?.type?.fields?.[Object.keys(record.type?.fields)?.[0]] ?? '-',
                },
                {
                    label: formatMessage({
                        id: 'vehicle.usable_size.label',
                        defaultMessage: 'Dimensions utiles (LxlxH)',
                    }),
                    renderEdit: () => (
                        <VehicleDimensionsFields
                            name="usableSize"
                            label={formatMessage({
                                id: 'vehicle.usable_size.label',
                                defaultMessage: 'Dimensions utiles (LxlxH)',
                            })}
                        />
                    ),
                    renderDetails: (field, record) =>
                        `${record?.usableSize?.width ?? '-'} x ${record?.usableSize?.length ?? '-'} x ${
                            record?.usableSize?.height ?? '-'
                        } cm`,
                },
                {
                    label: formatMessage({
                        id: 'vehicle.size.label',
                        defaultMessage: 'Dimensions hors tout (LxlxH)',
                    }),
                    renderEdit: () => (
                        <VehicleDimensionsFields
                            name="size"
                            label={formatMessage({
                                id: 'vehicle.size.label',
                                defaultMessage: 'Dimensions hors tout (LxlxH)',
                            })}
                        />
                    ),
                    renderDetails: (field, record) =>
                        `${record?.size?.width ?? '-'} x ${record?.size?.length ?? '-'} x ${
                            record?.size?.height ?? '-'
                        } cm`,
                },
            ],
        },
        {
            title: formatMessage({
                id: 'vehicle.gas',
                defaultMessage: 'Carburant',
            }),
            editButtonText: formatMessage({
                id: 'vehicle.gas.edit_button',
                defaultMessage: 'Modifier le carburant',
            }),
            fields: [
                {
                    name: 'gasType',
                    type: 'ValueListItemSelect',
                    fieldComponentProps: {
                        valueListSlug: ValueListSlug.vehicleGasTypes,
                    },
                    label: formatMessage({
                        id: 'vehicle.gas',
                        defaultMessage: 'Carburant',
                    }),
                    required: true,
                },
                {
                    name: 'carbonEmission',
                    type: 'number',
                    fieldComponentProps: {
                        addonAfter: 'g/km',
                    },
                    label: formatMessage({
                        id: 'vehicle.carbon_emission.label',
                        defaultMessage: 'Taux d’émission CO2 pour 100km',
                    }),
                    required: true,
                },
            ],
        },
        {
            title: formatMessage({
                id: 'vehicle.transport',
                defaultMessage: 'Transport',
            }),
            editButtonText: formatMessage({
                id: 'vehicle.transport.edit_button',
                defaultMessage: 'Modifier le transport',
            }),
            fields: [
                {
                    name: 'transportType',
                    type: 'ValueListItemSelect',
                    fieldComponentProps: {
                        valueListSlug: ValueListSlug.vehicleTransportTypes,
                    },
                    label: formatMessage({
                        id: 'vehicle.transport.label',
                        defaultMessage: 'Type de transport',
                    }),
                    required: true,
                },
            ],
        },
        {
            title: formatMessage({
                id: 'vehicle.daily_cost',
                defaultMessage: 'Coût théorique jour',
            }),
            editButtonText: formatMessage({
                id: 'vehicle.daily_cost.edit_button',
                defaultMessage: 'Modifier le coût',
            }),
            fields: [
                {
                    name: 'dailyCost',
                    type: 'number',
                    fieldComponentProps: {
                        addonAfter: '€',
                    },
                    label: formatMessage(genericMessages.cost),
                    required: true,
                    renderDetails: (field, record) => (
                        <FormattedNumber
                            style="currency"
                            currency="EUR"
                            maximumFractionDigits={2}
                            minimumFractionDigits={0}
                            value={record?.dailyCost ?? 0}
                        />
                    ),
                },
            ],
        },
        {
            title: formatMessage({
                id: 'vehicle.other',
                defaultMessage: 'Autres caractéristiques',
            }),
            editButtonText: formatMessage(genericMessages.edit),
            fields: [
                {
                    name: ['meta', 'properties'],
                    renderEdit: () => <VehicleOtherFields />,
                    renderDetails: (field, record) => <VehicleOtherFields details={record} />,
                },
            ],
        },
    ];

    return sections;
};
