import { Table } from 'antd';
import { VFC } from 'react';
import { FormattedDate, FormattedDateTimeRange, FormattedMessage } from 'react-intl';

import genericMessages from '../../../i18n/genericMessages';
import transporterMessages from '../../../i18n/transporterMessages';
import { Transporter } from '../../../queries/api/types';

interface TransporterExceptionnalDaysDetailsProps {
    record?: Transporter;
}

const TransporterExceptionnalDaysDetails: VFC<TransporterExceptionnalDaysDetailsProps> = ({ record }) =>
    (record?.exceptionnalDays || []).length > 0 ? (
        <Table<Transporter['exceptionnalDays'][0]>
            columns={[
                {
                    key: 'day',
                    title: <FormattedMessage {...genericMessages.day} />,
                    render: (value, record) => (
                        <FormattedDate
                            value={record.fromDate}
                            weekday="long"
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                        />
                    ),
                },
                {
                    key: 'hours',
                    title: <FormattedMessage {...genericMessages.hours} />,
                    render: (value, record) =>
                        record.isOpened ? (
                            <FormattedDateTimeRange
                                from={new Date(record.fromDate)}
                                to={new Date(record.toDate)}
                                hour="2-digit"
                                minute="2-digit"
                            />
                        ) : (
                            <FormattedMessage {...transporterMessages.unavailable} tagName="span" />
                        ),
                },
            ]}
            rowKey="fromDate"
            dataSource={record?.exceptionnalDays}
            pagination={false}
        />
    ) : (
        <FormattedMessage {...transporterMessages.exceptionnalDaysEmpty} />
    );

export default TransporterExceptionnalDaysDetails;
