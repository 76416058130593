import { SupportedLanguage } from '../queries/api/types';

type LangImport = { [key in SupportedLanguage]: any };

const availableLangs = ['es', 'de', 'ru', 'fr', 'jp', 'cn', 'pt', 'it', 'ir', 'ar', 'tr', 'id', 'en'];

const intlData = availableLangs.reduce<LangImport | Record<string, unknown>>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => await import(`react-phone-input-2/lang/${lang}.json`),
    }),
    {}
);

export const fullCountryLabel = async (locale: string) => {
    if (availableLangs.includes(locale)) {
        if (locale === SupportedLanguage.en) {
            const localeData = (await import('../i18n/countries/en.json')).default;
            return localeData;
        } else {
            const localeData = (await intlData[locale as SupportedLanguage]?.())?.default;
            return localeData;
        }
    }
};
