import { useMemo, VFC } from 'react';
import { Button, Form, FormProps, Input, Space } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import Seo from '../../../components/Seo';
import genericMessages from '../../../i18n/genericMessages';
import vehicleMessages from '../../../i18n/vehicleMessages';
import { getRoute, RoutePathName } from '../../../routes';
import { Vehicle, ValueListSlug } from '../../../queries/api/types';
import { useVehicleCreate, useVehicleDetails, useVehicleUpdate } from '../../../queries/vehicles';
import DetailsFormCard from '../../../components/form/DetailsFormCard';
import ApiResult from '../../../components/ApiResult';
import { VehicleUpdatePayload } from '../../../queries/api/vehicles';
import { successMessage } from '../../../helpers/message';
import formMessages from '../../../i18n/formMessages';
import { Check } from '../../../components/icons';
import DetailsFormEditableTitle from '../../../components/form/DetailsFormEditableTitle';
import ListTitle from '../../../components/ListTitle';
import { formError } from '../../../helpers/form';
import StatusSwitch from '../../../components/form/StatusSwitch';
import { useValueListItemList } from '../../../queries/valueListItems';
import {
    formatVehicleCreatePayload,
    formatVehicleInitialValue,
    formatVehicleUpdatePayload,
    getVehicleFormSections,
    VehicleForm,
} from '.';

const VehiclesDetails: VFC = () => {
    const { vehicleId } = useParams<{ vehicleId?: Vehicle['id'] }>();
    const history = useHistory();
    const [form] = Form.useForm();
    const isCreating = !vehicleId;
    const { formatMessage } = useIntl();
    const { data: vehicleDetails, isError, error } = useVehicleDetails(vehicleId, { enabled: !!vehicleId });
    const { mutate: createVehicle, isLoading: isLoadingCreating } = useVehicleCreate();
    const pageTitle = isCreating
        ? formatMessage({
              id: 'vehicle_details.create.title',
              defaultMessage: 'Ajouter véhicule',
              description: 'Vehicle creation page title',
          })
        : vehicleDetails?.reference || formatMessage(genericMessages.editing);
    const onCreateSubmit: FormProps['onFinish'] = (values) => {
        const payload = formatVehicleCreatePayload(values);
        createVehicle(payload, {
            onSuccess: () => {
                successMessage({
                    content: formatMessage({
                        id: 'vehicle_details.create.success_message',
                        defaultMessage: 'Véhicule ajouté avec succès',
                    }),
                });
                history.push(getRoute(RoutePathName.vehicles));
            },
            onError: (error) => formError({ error, form, formatMessage }),
        });
    };
    const { data: vehicleOwnershipTypes } = useValueListItemList({
        valueListSlug: ValueListSlug.vehicleOwnershipTypes,
    });
    const relayOwnershipTypeId = vehicleOwnershipTypes?.items?.find(
        (item) => item.fields.vehicleOwnershipTypeReference === 'relay'
    )?.id;
    const transporterOwnershipTypeId = vehicleOwnershipTypes?.items?.find(
        (item) => item.fields.vehicleOwnershipTypeReference === 'transporter'
    )?.id;
    const sections = useMemo(
        () => getVehicleFormSections({ formatMessage, relayOwnershipTypeId, transporterOwnershipTypeId, form }),
        [formatMessage, relayOwnershipTypeId, transporterOwnershipTypeId, form]
    );
    const sectionCards = useMemo(
        () =>
            sections.map((section) => (
                <DetailsFormCard<Vehicle, VehicleForm, VehicleUpdatePayload>
                    {...section}
                    key={section.title}
                    detailsQueryHandler={useVehicleDetails}
                    updateQueryHandler={useVehicleUpdate}
                    initialValueFormatter={formatVehicleInitialValue}
                    formatPayload={formatVehicleUpdatePayload}
                    id={vehicleId}
                />
            )),
        [vehicleId, sections]
    );
    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                {isCreating ? (
                    <ListTitle className="mb-0 uppercase" backRoute={getRoute(RoutePathName.vehicles)}>
                        {pageTitle}
                    </ListTitle>
                ) : (
                    <>
                        <DetailsFormEditableTitle
                            backRoute={getRoute(RoutePathName.vehicles)}
                            detailsQueryHandler={useVehicleDetails}
                            updateQueryHandler={useVehicleUpdate}
                            placeholder={formatMessage(vehicleMessages.referencePlaceholder)}
                            id={vehicleId}
                            name="reference"
                        />
                        <StatusSwitch
                            detailsQueryHandler={useVehicleDetails}
                            updateQueryHandler={useVehicleUpdate}
                            id={vehicleId}
                        />
                    </>
                )}
            </div>
            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    {isCreating ? (
                        <Form
                            form={form}
                            onFinish={onCreateSubmit}
                            initialValues={formatVehicleInitialValue(vehicleDetails)}
                            scrollToFirstError
                        >
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                <Form.Item name="reference" className="mb-0">
                                    <Input placeholder={formatMessage(vehicleMessages.referencePlaceholder)} />
                                </Form.Item>
                                {sectionCards}
                                <div className="flex justify-center">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        icon={<Check />}
                                        size="large"
                                        loading={isLoadingCreating}
                                    >
                                        <FormattedMessage {...formMessages.saveData} tagName="span" />
                                    </Button>
                                </div>
                            </Space>
                        </Form>
                    ) : (
                        sectionCards
                    )}
                </Space>
            )}
        </>
    );
};

export default VehiclesDetails;
