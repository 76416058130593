import { useCallback, useMemo, VFC } from 'react';
import { Button, Form, FormProps, Input, Space, Steps } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useParams } from 'react-router-dom';

import Seo from '../../../components/Seo';
import genericMessages from '../../../i18n/genericMessages';
import { getRoute, RoutePathName } from '../../../routes';
import { Transporter, WeekDay } from '../../../queries/api/types';
import DetailsFormCard, { DetailsFormCardProps } from '../../../components/form/DetailsFormCard';
import { errorMessage, successMessage } from '../../../helpers/message';
import formMessages from '../../../i18n/formMessages';
import { Check } from '../../../components/icons';
import ListTitle from '../../../components/ListTitle';
import { useTransporterCreate, useTransporterDetails, useTransporterUpdate } from '../../../queries/transporters';
import { TransporterCreatePayload, TransporterUpdatePayload } from '../../../queries/api/transporters';
import {
    formatTransporterInitialValue,
    formatTransporterUpdatePayload,
    getFormSections,
    TransporterForm,
    TransporterFormSection,
} from '.';
import { getTimeAsNumber } from '../../../helpers';
import useQueryParams from '../../../hooks/queryParams';
import { getRequiredRule } from '../../../i18n';

const formatCreatePayload = (values: TransporterForm): TransporterCreatePayload => ({
    ...values,
    openHours: {
        ...(Object.values(WeekDay).reduce(
            (acc, day) => ({
                ...acc,
                [day]: {
                    fromHour: getTimeAsNumber(formatTransporterInitialValue().openHours[day].fromHour),
                    toHour: getTimeAsNumber(formatTransporterInitialValue().openHours[day].toHour),
                    isEnabled: formatTransporterInitialValue().openHours[day].isEnabled,
                },
            }),
            {}
        ) as Transporter['openHours']),
    },
    exceptionnalDays: [],
    services: [],
    workingHolidays: [],
    meta: {
        ...values.meta,
        creationStep: 0,
    },
    ...(values.meta?.isSameAddressForBilling !== undefined
        ? {
              billingAddress: {
                  ...(values.meta?.isSameAddressForBilling
                      ? {
                            ...values.address,
                        }
                      : values.billingAddress ?? {}),
              },
          }
        : {}),
});

export enum TransporterCreateStep {
    generalInformations = 'generalInformations',
    services = 'services',
    availabilities = 'availabilities',
}

const TransporterCreate: VFC = () => {
    const history = useHistory();
    const params = useParams<{ step?: TransporterCreateStep }>();
    const [queryParams] = useQueryParams('TransporterCreate');
    const transporterId = queryParams.get('transporterId') ?? undefined;
    const step = parseInt(params.step || '0', 10);
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const steps = useMemo<
        Array<{
            title: string;
            sections: Array<
                Pick<
                    DetailsFormCardProps<Transporter, TransporterForm, TransporterUpdatePayload>,
                    'title' | 'editButtonText' | 'fields' | 'renderEdit'
                >
            >;
        }>
    >(
        () => [
            {
                title: formatMessage(genericMessages.generalInformations),
                sections: getFormSections(formatMessage, [
                    TransporterFormSection.companyName,
                    TransporterFormSection.groups,
                    TransporterFormSection.addresses,
                    TransporterFormSection.contacts,
                ]),
            },
            {
                title: formatMessage(genericMessages.services, { count: 2 }),
                sections: getFormSections(formatMessage, [TransporterFormSection.services]),
            },
            {
                title: formatMessage(genericMessages.availabilities),
                sections: getFormSections(formatMessage, [
                    TransporterFormSection.openHours,
                    TransporterFormSection.workingHolidays,
                    TransporterFormSection.exceptionnalDays,
                ]),
            },
        ],
        [formatMessage]
    );
    const nextStep = step < steps.length - 1 ? step + 1 : undefined;
    const { data: transporterDetails } = useTransporterDetails(transporterId, {
        enabled: !!transporterId,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });
    const { mutate: createTransporter, isLoading: isCreating } = useTransporterCreate();
    const { mutate: updateTransporter, isLoading: isUpdating } = useTransporterUpdate();
    const pageTitle = formatMessage({
        id: 'transporter.create.title',
        defaultMessage: 'Ajouter un partenaire transport',
        description: 'Transporter creation page title',
    });
    const onSubmit: FormProps<TransporterForm>['onFinish'] = useCallback(
        (values) => {
            if (transporterId) {
                updateTransporter(formatTransporterUpdatePayload(step)(values, transporterDetails), {
                    onSuccess: () => {
                        if (nextStep) {
                            history.push({
                                pathname: getRoute(RoutePathName.transporterCreate, { step: nextStep }),
                                search: queryParams.toString(),
                            });
                        } else {
                            successMessage({
                                content: formatMessage({
                                    id: 'transporter.update.success_message',
                                    defaultMessage: 'Partenaire de transport créé avec succès',
                                }),
                            });
                            history.push(getRoute(RoutePathName.transporters));
                        }
                    },
                    onError: (error) => {
                        errorMessage({
                            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                status: error.response?.status,
                            }),
                        });
                    },
                });
            } else {
                createTransporter(formatCreatePayload(values), {
                    onSuccess: (data) => {
                        history.push({
                            pathname: getRoute(RoutePathName.transporterCreate, { step: 1 }),
                            search: new URLSearchParams({ transporterId: data.id }).toString(),
                        });
                    },
                    onError: (error) => {
                        errorMessage({
                            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                status: error.response?.status,
                            }),
                        });
                    },
                });
            }
        },
        [
            createTransporter,
            formatMessage,
            history,
            nextStep,
            queryParams,
            step,
            transporterDetails,
            transporterId,
            updateTransporter,
        ]
    );

    return (
        <>
            <Seo title={pageTitle} />
            <ListTitle className="uppercase mb-0" backRoute={getRoute(RoutePathName.transporters)}>
                {pageTitle}
            </ListTitle>
            <div className="flex justify-center items-center p-6 mx-auto mb-4" style={{ width: '78%' }}>
                <Steps current={step}>
                    {steps.map((step) => (
                        <Steps.Step key={step.title} title={step.title} />
                    ))}
                </Steps>
            </div>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    initialValues={formatTransporterInitialValue(transporterDetails)}
                    scrollToFirstError
                >
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        {step === 0 && (
                            <Form.Item name="name" className="mb-0" rules={[getRequiredRule(formatMessage)]}>
                                <Input size="large" placeholder={formatMessage(genericMessages.name)} />
                            </Form.Item>
                        )}
                        {steps[step].sections.map((section) => (
                            <DetailsFormCard<Transporter, TransporterForm, TransporterUpdatePayload>
                                {...section}
                                key={section.title}
                                detailsQueryHandler={useTransporterDetails}
                                updateQueryHandler={useTransporterUpdate}
                                initialValueFormatter={formatTransporterInitialValue}
                                formatPayload={formatTransporterUpdatePayload(step)}
                                id={transporterId}
                                isCreating
                            />
                        ))}
                        <div className="flex justify-center gap-2">
                            {step > 0 && (
                                <Link
                                    to={{
                                        pathname: getRoute(RoutePathName.transporterCreate, { step: step - 1 }),
                                        search: queryParams.toString(),
                                    }}
                                >
                                    <Button type="primary" size="large" ghost>
                                        <FormattedMessage {...formMessages.previousStepButton} tagName="span" />
                                    </Button>
                                </Link>
                            )}
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<Check />}
                                loading={isCreating || isUpdating}
                                size="large"
                            >
                                {step === steps.length - 1 ? (
                                    <FormattedMessage {...formMessages.saveAndCloseButton} tagName="span" />
                                ) : (
                                    <FormattedMessage {...formMessages.saveAndContinueButton} tagName="span" />
                                )}
                            </Button>
                        </div>
                    </Space>
                </Form>
            </Space>
        </>
    );
};

export default TransporterCreate;
