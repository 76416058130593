import qr from 'qr-image';

function toBase64(arr: Buffer) {
    // arr = new Uint8Array(arr) if it's an ArrayBuffer
    return btoa(arr.reduce((data, byte) => data + String.fromCharCode(byte), ''));
}

export const downloadQrCodeAsImage = (data: string, filename?: string) => {
    const result = qr.imageSync(data);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.download = filename ?? 'QRCode';
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
    };

    img.src = `data:image/png;base64,${toBase64(result)}`;
};
