/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 9.375v4.875c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.875 5.625a.75.75 0 0 1 .75-.75h12.75a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75H2.625a.75.75 0 0 1-.75-.75v-3Z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinejoin="round"
        />
        <path
            d="M11.625 4.875v-1.5a.75.75 0 0 0-.75-.75h-3.75a.75.75 0 0 0-.75.75v1.5M5.625 8.625v2.25M12.375 8.625v2.25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgToolkit: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-toolkit', props.className)} />
));
export default SvgToolkit;
