import { useState, forwardRef } from 'react';
import { Empty, Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectRef } from 'rc-select';
import { useIntl } from 'react-intl';

import { usePlaceList } from '../../queries/places';
import { Place } from '../../queries/api/types';
import { useDebounce } from '../../hooks';

type SelectValue = Place['id'] | Array<Place['id']>;
export interface PlaceSelectProps extends SelectProps<SelectValue> {
    initialValue?: Array<Pick<Place, 'id' | 'name'>>;
    excludedPlaceIds?: Array<Place['id']>;
}

const PlaceSelect = forwardRef<BaseSelectRef, PlaceSelectProps>(({ initialValue, excludedPlaceIds, ...props }, ref) => {
    const { formatMessage } = useIntl();
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);
    const { data: list, isLoading } = usePlaceList({
        search: debouncedSearch || undefined,
        pageSize: 50,
    });
    return (
        <Select<SelectValue>
            ref={ref}
            placeholder={formatMessage({
                id: 'place_select.placeholder',
                defaultMessage: 'Rechercher un entrepôt',
            })}
            notFoundContent={isLoading ? <Spin size="small" /> : <Empty />}
            filterOption={false}
            onSearch={setSearch}
            style={{ width: '100%' }}
            loading={isLoading}
            size="middle"
            showSearch
            allowClear
            {...props}
        >
            {[
                ...(initialValue || []), // Display initial value
                ...(list?.items
                    .filter((a) => !initialValue?.map((b) => b.id).includes(a.id))
                    .filter((place) => !excludedPlaceIds?.includes(place.id)) ?? []), // Search items, excluding initial value
            ].map((item) => (
                <Select.Option value={item.id} key={item.id}>
                    {item.name}
                </Select.Option>
            ))}
        </Select>
    );
});

export default PlaceSelect;
