import { FC } from 'react';
import { Layout, Typography } from 'antd';
import { Breakpoint } from 'react-socks';

import '../assets/styles/Login.less';

import background from '../assets/images/login-background.jpg';
import Seo from './Seo';
import Logo from './Logo';

interface LoginLayoutProps {
    seoTitle: string;
    title: string;
}

const LoginLayout: FC<LoginLayoutProps> = ({ seoTitle, title, children }) => (
    <Layout id="login-layout">
        <Seo title={seoTitle} />
        <div id="login-section">
            <div id="login-section-inner">
                <Typography.Title level={1}>{title}</Typography.Title>
                {children}
            </div>
        </div>
        <Breakpoint className="login-image" small down>
            <Logo color="red" />
            <img src={background} alt="Camions ID Logistics" />
        </Breakpoint>
    </Layout>
);

export default LoginLayout;
