import { defineMessages } from 'react-intl';

import { ValueListFieldUnit } from '../queries/api/types';

const unitMessages = defineMessages({
    secondShort: {
        id: 'unit_messages.second.short',
        defaultMessage: 's',
        description: 'Unit short',
    },
    minuteShort: {
        id: 'unit_messages.minute.short',
        defaultMessage: 'm',
        description: 'Unit short',
    },
    hourShort: {
        id: 'unit_messages.hour.short',
        defaultMessage: 'h',
        description: 'Unit short',
    },
    dayShort: {
        id: 'unit_messages.day.short',
        defaultMessage: 'j',
        description: 'Unit short',
    },
    weekShort: {
        id: 'unit_messages.week.short',
        defaultMessage: 'sem',
        description: 'Unit short',
    },
    monthShort: {
        id: 'unit_messages.month.short',
        defaultMessage: 'mo',
        description: 'Unit short',
    },
    yearShort: {
        id: 'unit_messages.year.short',
        defaultMessage: 'an',
        description: 'Unit short',
    },
});

export default unitMessages;

export const unitMessageMap = new Map([
    [ValueListFieldUnit.second, unitMessages.secondShort],
    [ValueListFieldUnit.minute, unitMessages.minuteShort],
    [ValueListFieldUnit.hour, unitMessages.hourShort],
    [ValueListFieldUnit.day, unitMessages.dayShort],
    [ValueListFieldUnit.week, unitMessages.weekShort],
    [ValueListFieldUnit.month, unitMessages.monthShort],
    [ValueListFieldUnit.year, unitMessages.yearShort],
]);
