import { Divider, Select, SelectProps } from 'antd';
import { VFC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { classNames } from '../../helpers';
import { fullCountryLabel } from '../../helpers/country';
import { SupportedLanguage } from '../../queries/api/types';

export type CountrySelectProps = SelectProps<SupportedLanguage>;

const CountrySelect: VFC<CountrySelectProps> = (props) => {
    const { locale, formatMessage } = useIntl();
    const [localization, setLocalization] = useState<{ [key: string]: string } | undefined>();

    useEffect(() => {
        (async () => {
            const localData = await fullCountryLabel(locale);
            setLocalization(localData);
        })();
    }, [locale]);

    return localization ? (
        <Select
            {...props}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
                return option?.text?.toString().toLowerCase().startsWith(input.toLowerCase()) ?? false;
            }}
            placeholder={formatMessage({
                id: 'country_select.placeholder',
                defaultMessage: 'Choisir un pays',
                description: 'Country select placeholder',
            })}
            dropdownClassName="lang-select-dropdown"
        >
            <Select.Option value="fr" key="fr" text={localization.fr}>
                <span className={classNames('flag', 'fr')} /> {localization.fr}
            </Select.Option>
            <Select.Option value="" style={{ minHeight: 0, padding: 0 }} disabled>
                <Divider style={{ margin: '0.25rem 0' }} />
            </Select.Option>
            {Object.keys(localization)
                .filter((key) => !['fr'].includes(key))
                .map((lang) => (
                    <Select.Option value={lang} key={lang} text={localization[lang]}>
                        <span className={classNames('flag', lang)} /> {localization[lang]}
                    </Select.Option>
                ))}
        </Select>
    ) : null;
};

export default CountrySelect;
