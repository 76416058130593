import { Button, Divider, Dropdown, Menu, Tag, Typography } from 'antd';
import { VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';

import { Issue, IssueCommentType, IssueCriticityLevel, IssueStatus, Vehicle } from '../../../../queries/api/types';
import { useIssueRemove } from '../../../../queries/issues';
import { classNames } from '../../../../helpers';
import { successMessage } from '../../../../helpers/message';
import issueMessages, { issueCriticityLevelMessageMap, issueStatusMessageMap } from '../../../../i18n/issueMessages';
import {
    Calendar,
    Comment,
    DocumentText,
    DotsVertical,
    ExclamationCircle,
    Trash,
    User,
} from '../../../../components/icons';
import TagWithDot from '../../../../components/TagWithDot';
import IssueCardModal, { IssueCardModalProps } from './IssueCardModal';
import DeletePopConfirm from '../../../../components/DeletePopConfirm';
import { useAuth } from '../../../../context/AuthContext';
import { isAdmin } from '../../../../helpers/security';

interface IssueCardHeaderProps {
    issue: Issue;
    vehicleId: Vehicle['id'];
}

const IssueCardHeader: VFC<IssueCardHeaderProps> = ({ issue, vehicleId }) => {
    const { formatMessage, formatDate, formatTime } = useIntl();
    const { category, criticityLevel, operator, status, subCategory, taskGroup, date } = issue;
    const { critical, high, medium } = IssueCriticityLevel;
    const { mutate: removeIssue } = useIssueRemove();

    const modal = useModal(IssueCardModal);

    const isPending = status === IssueStatus.opened;
    const isRepairing = status === IssueStatus.repairing;
    const tagWithDotColor = isPending ? 'faded-blue' : isRepairing ? 'orange' : 'green';

    const handleAddComment = () => {
        const modalParams: IssueCardModalProps = {
            title: formatMessage(issueMessages.addCommentTitle),
            description: '',
            issue,
            newStatus: status,
            onSuccessMessage: formatMessage(issueMessages.addCommentSuccess),
            showIcon: false,
            showLabel: false,
            isCommentRequired: true,
            vehicleId,
            commentType: IssueCommentType.adding,
        };

        modal.show(modalParams);
    };

    const handleRemoveIssueClick = () => {
        removeIssue(
            {
                issue: issue.id,
                vehicle: vehicleId,
            },
            {
                onSuccess: () => {
                    successMessage({
                        content: formatMessage(issueMessages.removeIssueSuccess),
                    });
                },
            }
        );
    };

    const { user } = useAuth();
    const isAuthorized = isAdmin(user);

    return (
        <>
            <div className="flex justify-between items-center">
                <div>
                    <Tag className="text-sm font-medium capitalize px-2 py-1 text-white bg-dark-blue border-dark-blue">
                        {category?.fields?.category}
                    </Tag>
                    <Tag className="text-sm font-medium capitalize px-2 py-1 bg-grey-3">
                        {subCategory?.fields?.subCategory}
                    </Tag>
                </div>

                <div className="flex items-center gap-6">
                    <TagWithDot color={tagWithDotColor}>
                        <span className="block text-sm font-bold">
                            {formatMessage(issueStatusMessageMap.get(status)!)}
                        </span>
                    </TagWithDot>

                    {isAuthorized && (
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu
                                    items={[
                                        {
                                            key: 'addComment',
                                            icon: <Comment />,
                                            label: (
                                                <FormattedMessage
                                                    id="management_vehicles.issues.actions.menu.add_comment"
                                                    defaultMessage="Ajouter un commentaire"
                                                />
                                            ),
                                            onClick: handleAddComment,
                                        },
                                        {
                                            key: 'removeIssue',
                                            icon: <Trash />,
                                            label: (
                                                <DeletePopConfirm onConfirm={handleRemoveIssueClick}>
                                                    <div role="button">
                                                        <FormattedMessage
                                                            id="management_vehicles.issues.actions.menu.remove"
                                                            defaultMessage="Supprimer l'incident"
                                                        />
                                                    </div>
                                                </DeletePopConfirm>
                                            ),
                                        },
                                    ]}
                                />
                            }
                            placement="bottomLeft"
                        >
                            <Button
                                style={{ width: 'unset' }}
                                type="text"
                                icon={<DotsVertical className="text-base" />}
                            />
                        </Dropdown>
                    )}
                </div>
            </div>

            <div className="flex flex-col gap-4">
                <Divider className="my-0 bg-grey-blue" />

                <div className="flex items-center gap-6 text-base font-medium">
                    <div className="flex justify-between items-center gap-2">
                        <Calendar />
                        <FormattedMessage
                            id="management_vehicles.issues.issue.date"
                            defaultMessage="{weekday} {date} - {time}"
                            values={{
                                weekday:
                                    formatDate(date, { weekday: 'long' }).charAt(0).toUpperCase() +
                                    formatDate(date, { weekday: 'long' }).slice(1),
                                date: formatDate(date),
                                time: formatTime(date),
                            }}
                        />
                    </div>

                    {operator && (
                        <>
                            <Divider className="bg-grey-blue" type="vertical" style={{ height: 24 }} />
                            <div className="flex justify-between items-center gap-2">
                                <User />
                                <Typography.Text>
                                    {operator.firstName} {operator.lastName}
                                </Typography.Text>
                            </div>
                        </>
                    )}

                    {taskGroup?.reference && (
                        <>
                            <Divider className="bg-grey-blue" type="vertical" style={{ height: 24 }} />
                            <div className="flex justify-between items-center gap-2">
                                <DocumentText />
                                <Typography.Text>
                                    {formatMessage(issueMessages.taskGroupReference, {
                                        reference: taskGroup.reference,
                                    })}
                                </Typography.Text>
                            </div>
                        </>
                    )}

                    <Divider className="bg-grey-blue" type="vertical" style={{ height: 24 }} />

                    <div className="flex justify-between items-center gap-2">
                        <ExclamationCircle
                            className={classNames(
                                (criticityLevel === high || criticityLevel === critical) && 'stroke-red',
                                criticityLevel === medium && 'stroke-orange'
                            )}
                        />
                        <Typography.Text className={classNames(criticityLevel === critical && 'text-red')}>
                            {formatMessage(issueCriticityLevelMessageMap.get(criticityLevel)!)}
                        </Typography.Text>
                    </div>
                </div>

                <Divider className="my-0 bg-grey-blue" />
            </div>
        </>
    );
};

export default IssueCardHeader;
