import { Form, Switch } from 'antd';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import TimePicker from '../TimePicker';
import { FormFieldProps } from './FormField';

export interface OpenHoursFormFieldsProps<TData> {
    field?: FormFieldProps<TData>;
    enabledMessage: MessageDescriptor;
    disabledMessage: MessageDescriptor;
    errorMessage: MessageDescriptor;
}

const OpenHoursFormFields = <TData extends Record<string, any>>({
    field,
    enabledMessage,
    disabledMessage,
    errorMessage,
}: OpenHoursFormFieldsProps<TData>) => {
    const { formatMessage } = useIntl();

    if (!field?.name) {
        return null;
    }

    const fieldName = field ? (Array.isArray(field?.name) ? field?.name : [field?.name]) : [];

    return (
        <Form.Item
            labelCol={{ sm: { span: 6 }, lg: { span: 8 }, style: { marginRight: 16 } }}
            wrapperCol={{ sm: { span: 18 }, lg: { span: 16 } }}
        >
            <div className="space-x-4 flex items-center">
                <Form.Item name={[...fieldName, 'isEnabled']} valuePropName="checked" noStyle>
                    <Switch />
                </Form.Item>
                <Form.Item dependencies={[[...fieldName, 'isEnabled']]} noStyle>
                    {({ getFieldValue }) =>
                        getFieldValue([...fieldName, 'isEnabled']) ? (
                            <FormattedMessage
                                tagName="span"
                                {...enabledMessage}
                                values={{
                                    from: (
                                        <Form.Item
                                            name={[...fieldName, 'fromHour']}
                                            dependencies={[[...fieldName, 'toHour']]}
                                            noStyle
                                        >
                                            <TimePicker
                                                format="HH:mm"
                                                size="small"
                                                minuteStep={5}
                                                style={{ margin: '0 4px' }}
                                            />
                                        </Form.Item>
                                    ),
                                    to: (
                                        <Form.Item
                                            name={[...fieldName, 'toHour']}
                                            dependencies={[[...fieldName, 'fromHour']]}
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    async validator(_, value) {
                                                        if (
                                                            !value ||
                                                            getFieldValue([...fieldName, 'fromHour']) < value
                                                        ) {
                                                            return await Promise.resolve();
                                                        }
                                                        return await Promise.reject(
                                                            new Error(formatMessage(errorMessage))
                                                        );
                                                    },
                                                }),
                                            ]}
                                            noStyle
                                        >
                                            <TimePicker
                                                format="HH:mm"
                                                size="small"
                                                minuteStep={5}
                                                style={{ margin: '0 4px' }}
                                            />
                                        </Form.Item>
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage {...disabledMessage} tagName="span" />
                        )
                    }
                </Form.Item>
            </div>
        </Form.Item>
    );
};

export default OpenHoursFormFields;
