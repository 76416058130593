import { useIntl } from 'react-intl';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Descriptions, ModalProps, Typography } from 'antd';
import { HiOutlineRefresh } from 'react-icons/hi';
import { useState } from 'react';

import CustomModal from '../CustomModal';
import { Operator } from '../../queries/api/types';
import genericMessages from '../../i18n/genericMessages';
import { getFullName } from '../../helpers';
import { useGenerateNewOperatorPassword } from '../../queries/operators';
import { successMessage, errorMessage } from '../../helpers/message';
import operatorMessages from '../../i18n/operatorMessages';
import ConfirmationModal from '../ConfirmationModal';

export interface OperatorInformationsModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    operator: Operator;
}

const generateNewPasswordConfirmationModalId = 'generate-new-password-confirmation-modal';

const OperatorInformationsModal = NiceModal.create<OperatorInformationsModalProps>(({ operator: currentOperator }) => {
    const { formatMessage } = useIntl();
    const modal = useModal();
    const confirmationModal = useModal(generateNewPasswordConfirmationModalId);
    const [operator, setOperator] = useState(currentOperator);
    const { mutate: generateNewPassword, isLoading: isGeneratingPassword } = useGenerateNewOperatorPassword({
        onSuccess: (data) => {
            setOperator(data);
            confirmationModal.hide();
            successMessage({ content: formatMessage(operatorMessages.generateNewPasswordSucess) });
        },
        onError: () => {
            errorMessage({ content: formatMessage(operatorMessages.generateNewPasswordError) });
        },
    });
    const generateNewPasswordHandler = () => {
        confirmationModal.show().then(() => {
            generateNewPassword(operator.id);
        });
    };

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={false}
            title={formatMessage({
                id: 'operator_list.column.actions.menu.login_infos',
                defaultMessage: 'Informations de connexion',
            })}
            width={400}
        >
            <Descriptions
                column={1}
                colon={false}
                labelStyle={{ width: 120, justifyContent: 'flex-end', textAlign: 'right' }}
                className="mb-2"
            >
                <Descriptions.Item label={formatMessage(genericMessages.operator)}>
                    {getFullName(operator.account)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({
                        id: 'operator_informations_modal.identifier',
                        defaultMessage: 'Identifiant',
                    })}
                >
                    <Typography.Text copyable>{operator.account?.email}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({
                        id: 'operator_informations_modal.password',
                        defaultMessage: 'Mot de passe',
                    })}
                >
                    <Typography.Text copyable>{operator.account?.password}</Typography.Text>
                </Descriptions.Item>
            </Descriptions>
            <div className="flex justify-center">
                <Button
                    icon={<HiOutlineRefresh className="font-16" />}
                    className="flex items-center text-blue"
                    onClick={generateNewPasswordHandler}
                >
                    <span className="ml-1">
                        {formatMessage({
                            id: 'operator_informations_modal.new_password',
                            defaultMessage: 'Nouveau mot de passe',
                        })}
                    </span>
                </Button>
            </div>
            <ConfirmationModal
                title={formatMessage(genericMessages.confirmation)}
                text={formatMessage({
                    id: 'operator.generate_new_password.modal.text',
                    defaultMessage: 'Êtes-vous sûr de vouloir générer un nouveau mot de passe pour ce chauffeur ?',
                    description: 'Operator generate new password modal text',
                })}
                id={generateNewPasswordConfirmationModalId}
                isLoading={isGeneratingPassword}
            />
        </CustomModal>
    );
});

export default OperatorInformationsModal;
