import { VFC } from 'react';
import { Checkbox, Spin } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

import { ValueListSlug } from '../../queries/api/types';
import { useValueListItemList } from '../../queries/valueListItems';
import BasicList from '../BasicList';
import CheckboxButton from '../CheckboxButton';
import ApiResult from '../ApiResult';

export interface ValueListItemButtonsProps extends CheckboxGroupProps {
    valueListSlug: ValueListSlug;
}

const ValueListItemButtons: VFC<ValueListItemButtonsProps> = ({ valueListSlug, ...props }) => {
    const {
        data: list,
        isLoading,
        isError,
        error,
    } = useValueListItemList({
        valueListSlug,
        pageSize: 200,
    });

    return (
        <Checkbox.Group {...props}>
            {isError && <ApiResult status={error?.response?.status} />}
            {!isError &&
                (isLoading ? (
                    <Spin spinning={isLoading} style={{ height: '3rem' }} />
                ) : (
                    <BasicList inline>
                        {list?.items?.map((item) => (
                            <li key={item.id}>
                                <CheckboxButton value={item.id}>
                                    {item.fields[Object.keys(item.fields)[0]]}
                                </CheckboxButton>
                            </li>
                        ))}
                    </BasicList>
                ))}
        </Checkbox.Group>
    );
};

export default ValueListItemButtons;
