import { Switch, SwitchProps } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from 'react-query';

import { errorMessage, successMessage } from '../../helpers/message';
import genericMessages from '../../i18n/genericMessages';

export interface StatusSwitchProps<TData, UpdatePayload> {
    id?: string;
    detailsQueryHandler: (
        id: string | undefined,
        options?: UseQueryOptions<TData, AxiosError, TData> | undefined
    ) => UseQueryResult<TData, AxiosError>;
    updateQueryHandler: (
        options?: UseMutationOptions<TData, AxiosError, UpdatePayload>
    ) => UseMutationResult<TData, AxiosError, UpdatePayload, unknown>;
}

const StatusSwitch = <TData extends Record<string, any>, UpdatePayload extends Record<string, any>>({
    detailsQueryHandler,
    updateQueryHandler,
    id,
}: StatusSwitchProps<TData, UpdatePayload>) => {
    const { formatMessage } = useIntl();
    const { data: details, isLoading: isLoadingDetails } = detailsQueryHandler(id, {
        enabled: !!id,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setIsActive(data.isActive);
        },
    });
    const [isActive, setIsActive] = useState(details?.isActive);
    const { mutate: update, isLoading: isUpdating } = updateQueryHandler();
    const onChange: SwitchProps['onChange'] = (value) => {
        update(
            {
                ...details,
                isActive: value,
                id,
            } as unknown as UpdatePayload,
            {
                onSuccess: () => {
                    successMessage({
                        content: formatMessage({
                            id: 'details_form_card.update.success_message',
                            defaultMessage: 'Modifications enregistrées avec succès',
                        }),
                    });
                    setIsActive(value);
                },
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error.response?.status ?? 0,
                        }),
                    });
                },
            }
        );
    };

    return (
        <div className="flex items-center">
            <label htmlFor="isActive" className="text-blue font-bold text-lg mr-3">
                <FormattedMessage id="status_switch.label" defaultMessage="Statut" />
            </label>
            <Switch loading={isLoadingDetails || isUpdating} id="isActive" checked={isActive} onChange={onChange} />
            <span className="ml-2">
                {isActive ? (
                    <FormattedMessage id="status_switch.active" defaultMessage="Actif" />
                ) : (
                    <FormattedMessage id="status_switch.inactive" defaultMessage="Inactif" />
                )}
            </span>
        </div>
    );
};

export default StatusSwitch;
