import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Modal, ModalProps, Row, Select, Space, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { useState } from 'react';

import taskGroupMessages from '../../../i18n/taskGroupMessages';
import genericMessages from '../../../i18n/genericMessages';
import placeMessages from '../../../i18n/placeMessages';
import { Place, TaskGroup } from '../../../queries/api/types';
import { useTaskGroupTransfer } from '../../../queries/taskGroups';

interface TransferTaskGroupModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    taskGroupId: TaskGroup['id'];
    places: Place[];
}

const TransferTaskGroupModal = NiceModal.create<TransferTaskGroupModalProps>(({ taskGroupId, places, ...props }) => {
    const transferTaskGroupModal = useModal();
    const { formatMessage } = useIntl();
    const [selectedPlace, setSelectedPlace] = useState<Place['id']>();
    const { mutateAsync: transferTaskGroup, isLoading } = useTaskGroupTransfer({
        onSuccess: () => {
            transferTaskGroupModal.resolve();
            transferTaskGroupModal.hide();
        },
    });

    const confirmHandler = () => {
        transferTaskGroup({ taskGroupId: taskGroupId, startingPlace: selectedPlace! });
    };

    return (
        <Modal
            {...props}
            {...antdModal(transferTaskGroupModal)}
            title={formatMessage(taskGroupMessages.transferTaskGroup)}
            afterClose={transferTaskGroupModal.remove}
            closable
            centered
            bodyStyle={{ paddingBottom: '22px' }}
            footer={false}
            maskClosable={!isLoading}
        >
            <Row>
                <Col span={24}>
                    <div className="flex items-center">
                        <Typography.Text className="text-base mr-6">
                            {formatMessage(placeMessages.warehouse)}*
                        </Typography.Text>
                        <Select
                            onSelect={(value: string) => setSelectedPlace(value)}
                            className="flex-1"
                            placeholder={formatMessage(genericMessages.select)}
                            optionFilterProp="children"
                            options={places.map((place) => ({ value: place.id, label: place.name }))}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="flex justify-end mt-4">
                        <Space direction="horizontal">
                            <Button
                                key="cancelBtn"
                                className="text-blue"
                                onClick={() => {
                                    transferTaskGroupModal.hide();
                                }}
                            >
                                {formatMessage(genericMessages.cancel)}
                            </Button>
                            <Button
                                key="confirmBtn"
                                type="primary"
                                onClick={confirmHandler}
                                disabled={!selectedPlace}
                                loading={isLoading}
                            >
                                {formatMessage(genericMessages.validate)}
                            </Button>
                        </Space>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
});

export default TransferTaskGroupModal;
