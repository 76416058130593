import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    ApplicationClientCreatePayload,
    ApplicationClientIdPayload,
    remove,
    update,
    ApplicationClientUpdatePayload,
} from './api/applicationsClients';
import { ApplicationClient } from './api/types';
import { applicationKeys } from './applications';

export const applicationClientKeys = {
    all: ['applicationClients'],
    details: () => [...applicationClientKeys.all, 'details'],
    detail: (params: ApplicationClientIdPayload) => [...applicationClientKeys.details(), params],
};

export const useApplicationClientDetails = <TData = ApplicationClient>(
    params: ApplicationClientIdPayload,
    options: UseQueryOptions<ApplicationClient, AxiosError, TData>
) => {
    return useQuery<ApplicationClient, AxiosError, TData>(
        applicationClientKeys.detail(params),
        async () => await details(params),
        options
    );
};

export const useApplicationClientCreate = (
    options: UseMutationOptions<ApplicationClient, AxiosError, ApplicationClientCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<ApplicationClient, AxiosError, ApplicationClientCreatePayload>(
        async (params) => await create(params),
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options.onSettled?.(data, error, variables, context);

                // invalidate application list query to refetch with the newly added item
                queryClient.invalidateQueries(applicationKeys.lists());

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(
                    applicationClientKeys.detail({ applicationId: variables.application, id: data?.id ?? '' })
                );
            },
        }
    );
};

export const useApplicationClientUpdate = (
    options: UseMutationOptions<ApplicationClient, AxiosError, ApplicationClientUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<ApplicationClient, AxiosError, ApplicationClientUpdatePayload>(
        async (params) => await update(params),
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options.onSettled?.(data, error, variables, context);

                // invalidate application list query to refetch with the newly added item
                queryClient.invalidateQueries(applicationKeys.lists());

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(
                    applicationClientKeys.detail({ applicationId: variables.application, id: variables.id })
                );
            },
        }
    );
};

export const useApplicationClientRemove = (
    options: UseMutationOptions<undefined, AxiosError, ApplicationClientIdPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, ApplicationClientIdPayload>(async (params) => await remove(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options.onSettled?.(data, error, variables, context);

            // invalidate application list query to refetch with the newly added item
            queryClient.invalidateQueries(applicationKeys.lists());

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(applicationClientKeys.detail(variables));
        },
    });
};
