/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 2.333C4 1.597 4.597 1 5.333 1h1.334a1.333 1.333 0 1 1 0 2.667H5.334A1.333 1.333 0 0 1 4 2.333Z"
            fill="#00467F"
        />
        <path
            d="M4.53 7.803a.75.75 0 0 0-1.06 1.06l1.06-1.06Zm.803 1.864-.53.53a.75.75 0 0 0 1.06 0l-.53-.53ZM8.53 7.53a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm1.387-3.863v8h1.5v-8h-1.5Zm-.583 8.583H2.667v1.5h6.667v-1.5Zm-7.25-.583v-8h-1.5v8h1.5Zm.583-8.584H4v-1.5H2.667v1.5Zm5.333 0h1.334v-1.5H8v1.5ZM2.667 12.25a.583.583 0 0 1-.583-.583h-1.5c0 1.15.932 2.083 2.083 2.083v-1.5Zm7.25-.583a.583.583 0 0 1-.583.583v1.5c1.15 0 2.083-.933 2.083-2.083h-1.5Zm1.5-8c0-1.15-.933-2.084-2.083-2.084v1.5c.322 0 .583.261.583.584h1.5Zm-9.334 0c0-.322.262-.584.584-.584v-1.5c-1.15 0-2.084.933-2.084 2.084h1.5ZM3.47 8.864l1.333 1.333 1.06-1.06L4.53 7.802l-1.06 1.06Zm2.394 1.333L8.53 7.53 7.47 6.47 4.803 9.136l1.06 1.061Zm-.53-8.447h1.333V.25H5.334v1.5Zm1.333 1.167H5.334v1.5h1.333v-1.5Zm-1.333 0a.583.583 0 0 1-.584-.584h-1.5c0 1.15.933 2.084 2.083 2.084v-1.5Zm1.916-.584a.583.583 0 0 1-.583.584v1.5c1.15 0 2.083-.933 2.083-2.084h-1.5Zm-.583-.583c.322 0 .583.261.583.583h1.5C8.75 1.183 7.817.25 6.667.25v1.5ZM5.334.25c-1.151 0-2.084.933-2.084 2.083h1.5c0-.322.261-.583.583-.583V.25Z"
            fill="#00467F"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgDocumentCheck: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-document-check', props.className)} />
));
export default SvgDocumentCheck;
