import { defineMessages } from 'react-intl';

const transporterMessages = defineMessages({
    serviceIndex: {
        id: 'transporter.services.index',
        defaultMessage: 'Prestation n°{index}',
    },
    serviceTypeLabel: {
        id: 'transporter.services.type.label',
        defaultMessage: 'Type de prestation',
    },
    serviceDailyCountLabel: {
        id: 'transporter.services.daily_count.label',
        defaultMessage: 'Véhicules / jour garantis',
    },
    serviceReferencePeriodLabel: {
        id: 'transporter.services.reference_period.label',
        defaultMessage: 'Période de référence',
    },
    servicePenaltyCostLabel: {
        id: 'transporter.services.penalty_cost.label',
        defaultMessage: 'Pénalité de non-respect du minimum garanti',
    },
    serviceToleranceLabel: {
        id: 'transporter.services.tolerance.label',
        defaultMessage: 'Nb ou % de véhicules complémentaires négociés',
    },
    openHoursLabel: {
        id: 'transporter.open_hours.label',
        defaultMessage: 'Jours et horaires de disponibilité contractuelle',
    },
    unavailable: {
        id: 'transporter.open_hours.unavailable',
        defaultMessage: 'Indisponible',
    },
    availableFromTo: {
        id: 'transporter.open_hours.available_from_to',
        defaultMessage: 'Disponible de {from} à {to}',
    },
    openHoursErrorFromMustBeBeforeTo: {
        id: 'transporter.open_hours.error.from_higher_than_to',
        defaultMessage: "L'horaire de fin doit être supérieure à l'horaire de début",
    },
    exceptionnalDaysLabel: {
        id: 'transporter.exceptionnal_days.label',
        defaultMessage: 'Exceptions de disponibilité',
    },
    exceptionnalDaysEditButton: {
        id: 'transporter.exceptionnal_days.edit_button',
        defaultMessage: 'Modifier les exceptions',
    },
    exceptionnalDaysDateLabel: {
        id: 'transporter.exceptionnal_days.date.label',
        defaultMessage: 'Ajouter ci-dessous les exceptions de disponibilité/fermeture :',
    },
    exceptionnalDaysEmpty: {
        id: 'transporter.exceptionnal_days.empty',
        defaultMessage: 'Aucune exceptions de disponibilité/fermeture',
    },
});

export default transporterMessages;
