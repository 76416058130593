import { useMemo, VFC } from 'react';
import { Space } from 'antd';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import Seo from '../../../components/Seo';
import { Vehicle } from '../../../queries/api/types';
import { useVehicleDetails, useVehicleUpdate } from '../../../queries/vehicles';
import DetailsFormCard from '../../../components/form/DetailsFormCard';
import ApiResult from '../../../components/ApiResult';
import { VehicleUpdatePayload } from '../../../queries/api/vehicles';
import {
    formatVehicleInitialValue,
    formatVehicleUpdatePayload,
    getVehicleFormSections,
    VehicleForm,
} from '../../settings/vehicles';

const ManagementVehiclesDetailsInfos: VFC = () => {
    const { vehicleId } = useParams<{ vehicleId: Vehicle['id'] }>();
    const { formatMessage } = useIntl();
    const { data: vehicleDetails, isError, error } = useVehicleDetails(vehicleId, { enabled: !!vehicleId });
    const pageTitle = vehicleDetails?.reference;
    const sections = useMemo(() => getVehicleFormSections({ formatMessage, noEditButton: true }), [formatMessage]);
    const sectionCards = useMemo(
        () =>
            sections.map((section) => (
                <DetailsFormCard<Vehicle, VehicleForm, VehicleUpdatePayload>
                    {...section}
                    editButtonText={undefined}
                    key={section.title}
                    detailsQueryHandler={useVehicleDetails}
                    updateQueryHandler={useVehicleUpdate}
                    initialValueFormatter={formatVehicleInitialValue}
                    formatPayload={formatVehicleUpdatePayload}
                    id={vehicleId}
                />
            )),
        [vehicleId, sections]
    );

    return (
        <>
            <Seo title={pageTitle} />
            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    {sectionCards}
                </Space>
            )}
        </>
    );
};

export default ManagementVehiclesDetailsInfos;
