import { defineMessages } from 'react-intl';

const operatorMessages = defineMessages({
    listPageTitle: {
        id: 'operator_list.title',
        defaultMessage: 'Chauffeurs',
        description: 'Operators page title',
    },
    inactiveListPageTitle: {
        id: 'operator_list.inactive.title',
        defaultMessage: 'Chauffeurs inactifs',
        description: 'Operators inactive page title',
    },
    createPageTitle: {
        id: 'operator_details.create.title',
        defaultMessage: 'Ajouter un chauffeur',
        description: 'Operator creation page title',
    },
    reference: {
        id: 'operator_details.reference',
        defaultMessage: 'Saisir un matricule',
        description: 'Enter a reference',
    },
    identity: {
        id: 'operator_details.identity',
        defaultMessage: 'Identité',
        description: 'Identity',
    },
    attachedTo: {
        id: 'operator_details.attachment.attached_to',
        defaultMessage: 'Entité',
        description: 'Operator attachment',
    },
    transporter: {
        id: 'operator_details.attachment.transporter',
        defaultMessage: 'Partenaire transport',
        description: 'Transport partner',
    },
    profileAndContract: {
        id: 'operator_details.profile_and_contract',
        defaultMessage: 'Profil & Contrat',
        description: 'Profile and contract',
    },
    profile: {
        id: 'operator_details.profile',
        defaultMessage: 'Profil',
        description: 'Profile',
    },
    contractStartedAt: {
        id: 'operator_details.contractStartedAt',
        defaultMessage: 'Date de début de contrat',
        description: 'Contract start date',
    },
    slots: {
        id: 'operator_details.slots',
        defaultMessage: 'Créneaux',
        description: 'Slots',
    },
    preferedSlots: {
        id: 'operator_details.prefered_slots',
        defaultMessage: 'Créneaux affectés en priorité',
        description: 'Prefered slots',
    },
    editContract: {
        id: 'operator_details.edit_contract',
        defaultMessage: 'Modifier le contrat',
        description: 'Edit contract',
    },
    editSlots: {
        id: 'operator_details.edit_slots',
        defaultMessage: 'Modifier les créneaux',
        description: 'Edit slots',
    },
    createSuccessMessage: {
        id: 'operator_details.create.success_message',
        defaultMessage: 'Chauffeur ajouté avec succès',
        description: 'Operator added with success',
    },
    absenceReason: {
        id: 'operator.absence.reason',
        defaultMessage: 'Motif',
        description: 'Operator abscence reason',
    },
    unavailabilities: {
        id: 'operator.unavailabilities',
        defaultMessage: 'Indisponibilités',
        description: 'Operator unavailabilities',
    },
    generateNewPasswordSucess: {
        id: 'operator.generate_new_password.success_message',
        defaultMessage: 'Nouveau mot de passe généré avec succes.',
        description: 'Operator generate new password success message',
    },
    generateNewPasswordError: {
        id: 'operator.generate_new_password.error_message',
        defaultMessage: 'Erreur lors de la génération du nouveau mot de passe.',
        description: 'Operator generate new password error message',
    },
    sendMobilicLink: {
        id: 'operator.mobilic_link.send',
        defaultMessage: 'Envoyer un lien de connexion Mobilic',
    },
});

export default operatorMessages;
