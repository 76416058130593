import { RcFile } from 'antd/lib/upload';

import api from './apiClient';
import { downloadResponseAsBlob, PaginationQuery, SearchPaginationQuery } from '.';
import {
    Vehicle,
    ListResponse,
    Transporter,
    ValueListItem,
    TaskGroup,
    Issue,
    IssueStatus,
    TaskGroupStatus,
    IssueCriticityLevel,
} from './types';
import { ManagementVehiclesDetailsTabs } from '../../pages/management/vehicles';
import { VehiclePlanningAvailabilitiesPayload } from './taskGroups';

// Controller Interface
export type VehicleCreatePayload = Omit<
    Vehicle,
    | 'id'
    | 'computedProperties'
    | 'groups'
    | 'ownershipType'
    | 'type'
    | 'brand'
    | 'model'
    | 'gasType'
    | 'transportType'
    | 'transporter'
> & {
    groups: Array<ValueListItem['id']> | ValueListItem[];
    ownershipType: ValueListItem['id'] | ValueListItem;
    type: ValueListItem['id'] | ValueListItem;
    brand: ValueListItem['id'] | ValueListItem;
    model: ValueListItem['id'] | ValueListItem;
    gasType: ValueListItem['id'] | ValueListItem;
    transportType: ValueListItem['id'] | ValueListItem;
    transporter: Transporter['id'];
};

export type VehicleUpdatePayload = VehicleCreatePayload & Pick<Vehicle, 'id'>;

export type VehicleIdPayload = Vehicle['id'];

export type ExportVehiclePayload = {
    vehicleId: Vehicle['id'];
    exportType: ManagementVehiclesDetailsTabs;
    fromDate?: string;
    toDate?: string;
};

export type VehicleListPayload = SearchPaginationQuery & {
    ownerIds?: Array<Transporter['id']>;
    brandIds?: Array<ValueListItem['id']>;
    modelIds?: Array<ValueListItem['id']>;
    typeIds?: Array<ValueListItem['id']>;
    groupIds?: Array<ValueListItem['id']>;
    isActive?: boolean;
    inHouse?: boolean;
};
export type VehicleListResponse = ListResponse<Vehicle>;

export interface VehicleListTaskGroupsPayload extends PaginationQuery {
    id: Vehicle['id'];
    fromDate?: string;
    toDate?: string;
    status?: TaskGroupStatus[];
}

export type VehicleListTaskGroupsResponse = ListResponse<TaskGroup>;

export type VehicleListIssuesPayload = SearchPaginationQuery & {
    id: Vehicle['id'];
    date?: string;
    status?: IssueStatus | IssueStatus[];
};

export type VehicleListIssuesResponse = ListResponse<Issue>;

export type VehicleIssueCreatePayload = {
    criticityLevel: IssueCriticityLevel;
    category: ValueListItem['id'];
    subCategory?: ValueListItem['id'];
    date: string;
    description: string;
    files: RcFile[];
    status: IssueStatus;
    reference: string;
};
export type VehicleIssueCreatePayloadFormData = {
    id: Vehicle['id'];
    payload: FormData;
};

// Routes
export const list = async (payload?: VehicleListPayload) => {
    return await api.get<VehicleListResponse>(`/vehicles`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: VehicleCreatePayload) => {
    return await api.post(`/vehicles`, payload).then((response) => response?.data);
};

export const createIssue = async (payload: VehicleIssueCreatePayloadFormData) => {
    if (!payload.id) {
        throw new Error('missing id');
    }
    return await api.post(`/vehicles/${payload.id}/issues`, payload.payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: VehicleUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<Vehicle>(`/vehicles/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: VehicleIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Vehicle>(`/vehicles/${id}`).then((response) => response?.data);
};

export const remove = async (id?: VehicleIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/vehicles/${id}`).then((response) => response?.data);
};

export const listTaskGroups = async ({ id, ...payload }: VehicleListTaskGroupsPayload) => {
    return await api
        .get<VehicleListTaskGroupsResponse>(`/vehicles/${id}/taskGroups`, { params: payload })
        .then((response) => response?.data);
};

export const listIssues = async ({ id, ...payload }: VehicleListIssuesPayload) => {
    return await api
        .get<VehicleListIssuesResponse>(`/vehicles/${id}/issues`, { params: payload })
        .then((response) => response?.data);
};

export const exportVehicle = async ({ vehicleId, exportType, fromDate, toDate }: ExportVehiclePayload) => {
    return await api
        .get<Blob>(`/vehicles/${vehicleId}/${exportType}/export`, {
            params: {
                fromDate,
                toDate,
            },
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);

            return undefined;
        });
};

export const exportVehicles = async () => {
    return await api
        .get<Blob>(`/exportAll/vehicles`, {
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);
            return undefined;
        });
};

export const exportVehiclesPlanning = async (payload: VehiclePlanningAvailabilitiesPayload) => {
    return await api
        .get<Blob>(`/planning/vehicles`, {
            params: payload,
            responseType: 'blob',
            headers: {
                accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        })
        .then((response) => {
            downloadResponseAsBlob(response);
            return undefined;
        });
};
