import { defineMessages } from 'react-intl';

import { RoleSlug } from '../queries/api/types';

const userMessages = defineMessages({
    firstName: {
        id: 'user_details.firstName',
        defaultMessage: 'Prénom',
    },
    lastName: {
        id: 'user_details.lastName',
        defaultMessage: 'Nom',
    },
    generalInformationsEdit: {
        id: 'user_details.section.general_informations.edit_button',
        defaultMessage: 'Modifier les informations',
        description: 'User details section edit button',
    },
    role: {
        id: 'user_details.role',
        defaultMessage: 'Rôle',
        description: 'Role',
    },
    roleAndPlaces: {
        id: 'user_details.roleAndPlaces',
        defaultMessage: 'Rôle et rattachement',
        description: 'Role and places',
    },
    roleLabel: {
        id: 'user_details.roleLabel',
        defaultMessage: "Rôle de l'utilisateur",
        description: 'User details role label',
    },
    roleSelectPlaceholder: {
        id: 'user_details.create.input.roleSelect.placeholder',
        defaultMessage: 'Saisir un rôle',
    },
    editRole: {
        id: 'edit_role',
        defaultMessage: 'Modifier le role',
    },
    superAdmin: {
        id: 'user.role.superAdmin',
        defaultMessage: 'superAdmin',
        description: 'User super admin role',
    },
    owner: {
        id: 'user.role.owner',
        defaultMessage: 'owner',
        description: 'User owner role',
    },
    admin: {
        id: 'user.role.admin',
        defaultMessage: 'Administrateur',
        description: 'User admin role',
    },
    operator: {
        id: 'user.role.operator',
        defaultMessage: 'chauffeur',
        description: 'User role',
    },
    backOfficeAdmin: {
        id: 'user.role.backOfficeAdmin',
        defaultMessage: 'Back office (administratif)',
        description: 'User Admin Back Office role',
    },
    superDispatcher: {
        id: 'user.role.superDispatcher',
        defaultMessage: 'Dispatcher (avec planif auto)',
        description: 'User super dispatcher role',
    },
    dispatcher: {
        id: 'user.role.dispatcher',
        defaultMessage: 'Dispatcher',
        description: 'User dispatcher role',
    },
    vehicleTracker: {
        id: 'user.role.vehicleTracker',
        defaultMessage: 'Suivi de parc',
        description: 'User vehicleTracker role',
    },
    user: {
        id: 'user.user',
        defaultMessage: 'user',
        description: 'User role',
    },
    firstNamePlaceholder: {
        id: 'user_details.create.input.firstName.placeholder',
        defaultMessage: 'Saisir un prénom',
    },
    lastNamePlaceholder: {
        id: 'user_details.create.input.lastName.placeholder',
        defaultMessage: 'Saisir un nom',
    },
    groupLinkLabel: {
        id: 'user_details.groupLinkLabel',
        defaultMessage: 'Entrepôts de rattachement',
        description: 'User details groupLink label',
    },
    detailsPageTitle: {
        id: 'user_details.details.title',
        defaultMessage: 'Détails de l’utilisateur',
    },
    edit: {
        id: 'user.edit',
        defaultMessage: "Modifier l'utilisateur",
    },
    create: {
        id: 'user.create',
        defaultMessage: 'Créer un utilisateur',
    },
    updateSuccess: {
        id: 'user_details.update.success',
        defaultMessage: "L'utilisateur a été mis à jour avec succès",
    },
    updateError: {
        id: 'user_details.update.error',
        defaultMessage: "Une erreur est survenue pendant la mise à jour de l'utilisateur",
    },
    deleteSuccess: {
        id: 'user_details.delete.success',
        defaultMessage: "L'utilisateur a été créé avec succès",
    },
    deleteError: {
        id: 'user_details.delete.error',
        defaultMessage: "Une erreur est survenue pendant la mise à jour de l'utilisateur",
    },
    resetPasswordSuccess: {
        id: 'user.resetPassword.success',
        defaultMessage: 'Email de réinitialisation envoyé avec succès',
    },
    resetingPassword: {
        id: 'user.resetingPassword',
        defaultMessage: 'Envoi du mail de réinitialisation du mot de passe en cours',
    },
    resetPasswordError: {
        id: 'user.resetPassword.error',
        defaultMessage: 'Une erreur est survenue pendant la réinitialisation du mot de passe',
    },
    logAs: {
        id: 'user.logAs',
        defaultMessage: 'Se connecter en tant que',
    },
    searchName: {
        id: 'user.searchName',
        defaultMessage: 'Rechercher un nom...',
    },
    confirmResetModalTitle: {
        id: 'user.modal.confirmResetModalTitle',
        defaultMessage: 'Confirmation de réinitialisation',
    },
    confirmResetModalContent: {
        id: 'user.modal.confirmResetModalContent',
        defaultMessage: 'Êtes-vous sur de vouloir réinitialiser le mot de passe de cet utilisateur ?',
    },
    confirmResetModalHelper: {
        id: 'user.modal.confirmResetModalHelper',
        defaultMessage: 'Un lien de modification du mot de passe sera envoyé sur la boite e-mail de l’utilisateur.',
    },
    confirmDeleteModalTitle: {
        id: 'user.modal.confirmDeleteModalTitle',
        defaultMessage: 'Confirmation de suppression',
    },
    confirmDeleteModalContent: {
        id: 'user.modal.confirmDeleteModalContent',
        defaultMessage: 'Êtes-vous sur de vouloir supprimer le compte de cet utilisateur ?',
    },
    confirmDeleteModalHelper: {
        id: 'user.modal.confirmDeleteModalHelper',
        defaultMessage: ' Les données seront supprimées définitivement !',
    },
});

export default userMessages;

export const userRoleMessagesMap = new Map([
    [RoleSlug.superAdmin, userMessages.superAdmin],
    [RoleSlug.owner, userMessages.owner],
    [RoleSlug.admin, userMessages.admin],
    [RoleSlug.backOfficeAdmin, userMessages.backOfficeAdmin],
    [RoleSlug.superDispatcher, userMessages.superDispatcher],
    [RoleSlug.dispatcher, userMessages.dispatcher],
    [RoleSlug.vehicleTracker, userMessages.vehicleTracker],
    [RoleSlug.user, userMessages.user],
    [RoleSlug.operator, userMessages.operator],
]);
