import { VFC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Alert } from 'antd';

import { GenericStatus } from '../queries/api/types';
import { useCustomersList } from '../queries/customers';
import customerMessages from '../i18n/customerMessages';
import { CustomerDetailsTabs } from '../pages/settings/customers';
import { getRoute, RoutePathName } from '../routes';
import { ExclamationOutline } from './icons';
import { useAuth } from '../context/AuthContext';
import { isAdmin } from '../helpers/security';

const NoBudgetHeader: VFC = () => {
    const { formatMessage } = useIntl();

    const { user } = useAuth();
    const isAuthorized = isAdmin(user);

    const { data: customersList } = useCustomersList({
        forecastStatus: GenericStatus.error,
    });

    if (!user || !customersList?.items || customersList.items.length === 0) return null;

    const linkRoute =
        customersList.items.length > 1
            ? getRoute(RoutePathName.customers)
            : getRoute(RoutePathName.customerDetails, {
                  customerId: customersList.items[0].id,
                  tab: CustomerDetailsTabs.forecast,
              });

    return (
        <Alert
            style={{ height: 44 }}
            type="error"
            message={
                <div className="flex justify-center items-center gap-3">
                    <ExclamationOutline className="text-red icon-sm" />

                    {isAuthorized ? (
                        <Link to={linkRoute}>
                            <h1 className="text-sm text-red font-bold mb-0">
                                {formatMessage(customerMessages.noBudgetHeaderAdmin)}
                            </h1>
                        </Link>
                    ) : (
                        <h1 className="text-sm text-red font-bold mb-0">
                            {formatMessage(customerMessages.noBudgetHeaderDefaultUser)}
                        </h1>
                    )}
                </div>
            }
        />
    );
};

export default NoBudgetHeader;
