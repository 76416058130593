import { Dayjs } from 'dayjs';
import { FormattedDate } from 'react-intl';

export const formatDateOverlap = (date: Dayjs) => {
    const firstDayOfWeek = date.startOf('week');
    const lastDayOfWeek = date.endOf('week');
    const isSameMonth = firstDayOfWeek.month() === lastDayOfWeek.month();

    return (
        <>
            <FormattedDate day="numeric" month={isSameMonth ? undefined : 'short'} value={date.day(1).toDate()} /> -{' '}
            <FormattedDate dateStyle="medium" value={date.day(7).toDate()} />
        </>
    );
};
