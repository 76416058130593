import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    OperatorShiftCreatePayload,
    OperatorShiftIdPayload,
    OperatorShiftListPayload,
    OperatorShiftListResponse,
    OperatorShiftUpdatePayload,
    remove,
    update,
} from './api/operatorShifts';
import { OperatorShift } from './api/types';

export const operatorShiftsKeys = {
    all: ['operatorShifts'],
    lists: () => [...operatorShiftsKeys.all, 'list'],
    list: (params?: OperatorShiftListPayload) => [...operatorShiftsKeys.lists(), params],
    details: () => [...operatorShiftsKeys.all, 'details'],
    detail: (id?: OperatorShiftIdPayload) => [...operatorShiftsKeys.details(), id],
};

export const useOperatorShiftList = <TData = OperatorShiftListResponse>(
    params: OperatorShiftListPayload,
    options?: UseQueryOptions<OperatorShiftListResponse, AxiosError, TData>
) => {
    return useQuery<OperatorShiftListResponse, AxiosError, TData>(
        operatorShiftsKeys.list(params),
        async () => await list(params),
        options
    );
};

export const useOperatorShiftDetails = <TData = OperatorShift>(
    id?: OperatorShiftIdPayload,
    options?: UseQueryOptions<OperatorShift, AxiosError, TData>
) => {
    return useQuery<OperatorShift, AxiosError, TData>(
        operatorShiftsKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useOperatorShiftCreate = (
    options?: UseMutationOptions<OperatorShift, AxiosError, OperatorShiftCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<OperatorShift, AxiosError, OperatorShiftCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(operatorShiftsKeys.lists());
        },
    });
};

export const useOperatorShiftUpdate = (
    options?: UseMutationOptions<OperatorShift, AxiosError, OperatorShiftUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<OperatorShift, AxiosError, OperatorShiftUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(operatorShiftsKeys.detail(variables.id));
        },
    });
};

export const useOperatorShiftRemove = (options?: UseMutationOptions<undefined, AxiosError, OperatorShiftIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, OperatorShiftIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(operatorShiftsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(operatorShiftsKeys.lists());
        },
    });
};
