import { FC } from 'react';
import { Form, Input, Typography } from 'antd';

import { requiredRule } from '../../helpers';

interface UserAccountFieldsProps {
    title?: string;
    isMe?: boolean;
}

const UserAccountFields: FC<UserAccountFieldsProps> = ({ title, isMe }) => (
    <>
        {title && (
            <Typography.Title level={2} className="mb-32">
                {title}
            </Typography.Title>
        )}
        <Form.Item name={['user', 'lastName']} label="Nom" rules={[requiredRule]}>
            <Input placeholder={`Saisir ${isMe ? 'votre' : 'un'} nom`} />
        </Form.Item>

        <Form.Item name={['user', 'firstName']} label="Prénom" rules={[requiredRule]}>
            <Input placeholder={`Saisir ${isMe ? 'votre' : 'un'} prénom`} />
        </Form.Item>

        <Form.Item
            name={['user', 'email']}
            label="Adresse e-mail"
            rules={[requiredRule, { type: 'email', message: 'Veuillez saisir un e-mail valide' }]}
        >
            <Input placeholder={`Saisir ${isMe ? 'votre' : 'un'} e-mail`} />
        </Form.Item>
    </>
);

export default UserAccountFields;
