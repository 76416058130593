import { defineMessages } from 'react-intl';

import { CustomerType, InvoicingUnit } from '../queries/api/types';

const customerMessages = defineMessages({
    createTitle: {
        id: 'customer.create_title',
        defaultMessage: 'Ajouter un client',
        description: 'Customer creation page title',
    },
    contractAndBreakTime: {
        id: 'customer.contractAndBreakTime',
        defaultMessage: 'Contrat & temps de pause',
        description: 'Contract and break time',
    },
    selectUnit: {
        id: 'customer.select_unit',
        defaultMessage: 'Sélectionner une unité',
        description: 'Select unit',
    },
    forecast: {
        id: 'customer.forecast',
        defaultMessage: 'Forecast',
        description: 'Forecast',
    },
    forecastCalendarCreateTitle: {
        id: 'customer.forecast_calendar_create.title',
        defaultMessage: 'Saisissez le volume d’OT maximum prévisionnel à réaliser par jour',
    },
    forecastCalendarDetailsTitle: {
        id: 'customer.forecast_calendar_details.title',
        defaultMessage: 'Volume maximum d’OT à réaliser par jour négocié par le client',
    },
    addressTitle: {
        id: 'customer.address_title',
        defaultMessage: 'Adresse postale',
        description: 'Address',
    },
    address: {
        id: 'customer.address',
        defaultMessage: 'Adresse',
        description: 'Address',
    },
    minimumWorkingTimePerDay: {
        id: 'customer.minimum_working_time_per_day',
        defaultMessage: 'Temps de travail minimum (par jour)',
        description: 'Minimum working time (per day)',
    },
    minimumWorkingTimePerDayPlaceholder: {
        id: 'customer.minimum_working_time_per_day.placeholder',
        defaultMessage: 'Saisir une durée minimum',
        description: 'Enter a minimum duration',
    },
    maximumWorkingTimePerDay: {
        id: 'customer.maximum_working_time_per_day',
        defaultMessage: 'Temps de travail maximum (par jour)',
        description: 'Maximum working time (per day)',
    },
    maximumWorkingTimePerDayPlaceholder: {
        id: 'customer.maximum_working_time_per_day.placeholder',
        defaultMessage: 'Saisir une durée maximum',
        description: 'Enter a maximum duration',
    },
    minimumWorkingTimePerWeek: {
        id: 'customer.minimum_working_time_per_week',
        defaultMessage: 'Temps de travail minimum (par semaine)',
        description: 'Minimum working time (per week)',
    },
    minimumWorkingTimePerWeekPlaceholder: {
        id: 'customer.minimum_working_time_per_week.placeholder',
        defaultMessage: 'Saisir une durée minimum',
        description: 'Enter a minimum duration',
    },
    maximumWorkingTimePerWeek: {
        id: 'customer.maximum_working_time_per_week',
        defaultMessage: 'Temps de travail maximum (par semaine)',
        description: 'Maximum working time (per week)',
    },
    maximumWorkingTimePerWeekPlaceholder: {
        id: 'customer.maximum_working_time_per_week.placeholder',
        defaultMessage: 'Saisir une durée maximum',
        description: 'Enter a maximum duration',
    },
    invoicingUnitService: {
        id: 'customer.invoicing_unit_service',
        defaultMessage: 'Prestation',
        description: 'Invoicing unit service',
    },
    invoicingUnitVehicle: {
        id: 'customer.invoicing_unit_vehicle',
        defaultMessage: 'Véhicule',
        description: 'Invoicing unit vehicle',
    },
    invoicingUnitPrices: {
        id: 'customer.invoicing_unit_prices',
        defaultMessage: 'Période de validité des prix',
        description: 'Price validity period',
    },
    invoicingUnitVehiclePrices: {
        id: 'customer.invoicing_unit_vehicle_prices',
        defaultMessage: 'Indiquer les prix pour chaque type de véhicule de la flotte',
        description: 'Vehicle price',
    },
    invoicingUnitVehiclePricesPlaceholder: {
        id: 'customer.invoicing_unit_vehicle_prices.placeholder',
        defaultMessage: 'Sélectionner un type de véhicule à ajouter',
        description: 'Vehicle price placeholder',
    },
    invoicingPricesStandard: {
        id: 'customer.invoicing_prices.standard',
        defaultMessage: 'Usage standard',
        description: 'Standard use',
    },
    invoicingPricesWeekend: {
        id: 'customer.invoicing_prices.weekend',
        defaultMessage: 'Week-end',
        description: 'Week-end',
    },
    invoicingPricesHoliday: {
        id: 'customer.invoicing_prices.holiday',
        defaultMessage: 'Jours exceptionnels',
        description: 'Holiday',
    },
    invoicingPricesReuse: {
        id: 'customer.invoicing_prices.reuse',
        defaultMessage: 'Ré-utilisation',
        description: 'Reuse',
    },
    invoicingPricesAction: {
        id: 'customer.invoicing_prices.action',
        defaultMessage: 'Action',
        description: 'Action',
    },
    pausesDuration: {
        id: 'customer.pauses_duration',
        defaultMessage: 'Durée de la pause',
        description: 'Pause duration',
    },
    pausesDurationPlaceholder: {
        id: 'customer.pauses_duration.placeholder',
        defaultMessage: 'Saisir une durée',
        description: 'Select pause duration',
    },
    blockDuration: {
        id: 'customer.block_duration',
        defaultMessage: 'Durée de la tranche',
        description: 'Block duration',
    },
    scheduling: {
        id: 'customer.scheduling',
        defaultMessage: 'Scheduling',
        description: 'Customer type',
    },
    standard: {
        id: 'customer.standard',
        defaultMessage: 'Standard',
        description: 'Customer type',
    },
    betweenHours: {
        id: 'customer.betweenHours',
        defaultMessage: 'Entre {fromHour}h et {toHour}h',
    },
    minimumHours: {
        id: 'customer.minimumHours',
        defaultMessage: '{hour}h minimum',
    },
    workingHoursErrorMinimum: {
        id: 'customer.working_hours_error.minimum',
        defaultMessage: 'Le temps minimum doit être inférieur au temps maximum',
    },
    workingHoursErrorMaximum: {
        id: 'customer.working_hours_error.maximum',
        defaultMessage: 'Le temps maximum doit être supérieur au temps minimum',
    },
    forecastModalOtMaximum: {
        id: 'customer.forecast_modal.ot_maximum',
        defaultMessage: 'OT maximum à réaliser',
    },
    forecastModalTitle: {
        id: 'customer.forecast_modal.title',
        defaultMessage: 'Définir le volume prévu d’OT à réaliser le {fromDate}',
    },
    forecastModalTitleRange: {
        id: 'customer.forecast_modal.title_range',
        defaultMessage: 'Définir le volume prévu d’OT à réaliser du {fromDate} au {toDate}',
    },
    forecastListModalTitle: {
        id: 'customer.forecast_list_modal.title_range',
        defaultMessage: "Saisir le volume réel d'OT réalisé",
    },
    noBudgetHeaderAdmin: {
        id: 'customer.no_budget_header_admin',
        defaultMessage:
            'Un ou plusieurs clients n’ont plus de données prévisionnelles budgétaires. Veuillez saisir les données dès maintenant.',
    },
    noBudgetHeaderDefaultUser: {
        id: 'customer.no_budget_header_default_user',
        defaultMessage:
            'Un ou plusieurs clients n’ont plus de données prévisionnelles budgétaires. Veuillez contacter un administrateur.',
    },
    forecastEmpty: {
        id: 'customer.forecast_empty',
        defaultMessage: 'Aucun budget défini pour cette journée',
    },
    createVolumeSuccessMessage: {
        id: 'customer.create_volume.success_message',
        defaultMessage: 'Volume saisi avec succès',
    },
});

export default customerMessages;

export const customerInvoicingUnitMessagesMap = new Map([
    [InvoicingUnit.service, customerMessages.invoicingUnitService],
    [InvoicingUnit.vehicle, customerMessages.invoicingUnitVehicle],
]);

export const customerTypeMessagesMap = new Map([
    [CustomerType.scheduling, customerMessages.scheduling],
    [CustomerType.standard, customerMessages.standard],
]);
