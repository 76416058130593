/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.375.75a.75.75 0 0 1 .75.75v.75h3.75V1.5a.75.75 0 0 1 1.5 0v.75h3.375a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1-1.5 1.5H2.25a1.5 1.5 0 0 1-1.5-1.5v-12a1.5 1.5 0 0 1 1.5-1.5h3.375V1.5a.75.75 0 0 1 .75-.75Zm-.75 3H2.25v3h13.5v-3h-3.375v.75a.75.75 0 0 1-1.5 0v-.75h-3.75v.75a.75.75 0 0 1-1.5 0v-.75Zm10.125 4.5h-3.375v3h3.375v-3Zm0 4.5h-3.375v3h3.375v-3Zm-4.875 3v-3h-3.75v3h3.75Zm-5.25 0v-3H2.25v3h3.375Zm-3.375-4.5h3.375v-3H2.25v3Zm4.875-3v3h3.75v-3h-3.75Z"
            fill="#5C758A"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgCalendar: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-calendar', props.className)} />
));
export default SvgCalendar;
