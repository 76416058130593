import { Descriptions, Form, Switch } from 'antd';
import { VFC } from 'react';
import { useIntl } from 'react-intl';

import { sectionCardFormItemLayout } from '../../../components/SectionCard';
import { classNames } from '../../../helpers';
import { getRequiredRule } from '../../../i18n';
import genericMessages from '../../../i18n/genericMessages';
import { ValueListSlug, Vehicle } from '../../../queries/api/types';
import { useValueListItemList } from '../../../queries/valueListItems';

interface VehicleOtherFieldsProps {
    details?: Vehicle;
}

const VehicleOtherFields: VFC<VehicleOtherFieldsProps> = ({ details }) => {
    const { formatMessage } = useIntl();
    const { data } = useValueListItemList({
        valueListSlug: ValueListSlug.vehicleProperties,
    });

    if (details) {
        return (
            <>
                {!Object.keys(details.meta ?? {}).length && formatMessage(genericMessages.noData)}
                {Object.keys(details.meta ?? {}).map((valueListItemId) => (
                    <Descriptions
                        column={1}
                        colon={false}
                        labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                        key={valueListItemId}
                    >
                        <Descriptions.Item
                            label={
                                data?.items.find((valueListItem) => valueListItem.id === valueListItemId)?.fields
                                    ?.property
                            }
                        >
                            {details.meta?.[valueListItemId]
                                ? formatMessage(genericMessages.yes)
                                : formatMessage(genericMessages.no)}
                        </Descriptions.Item>
                    </Descriptions>
                ))}
            </>
        );
    }

    return (
        <>
            {data?.items.map((item, index) => (
                <Form.Item
                    key={item.id}
                    name={['meta', 'properties', item.id]}
                    label={item.fields[Object.keys(item.fields)[0]]}
                    {...sectionCardFormItemLayout}
                    rules={[getRequiredRule(formatMessage)]}
                    valuePropName="checked"
                    className={classNames(data.items?.length === index + 1 && 'mb-0')}
                    required
                >
                    <Switch />
                </Form.Item>
            ))}
        </>
    );
};

export default VehicleOtherFields;
