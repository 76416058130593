import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { Card } from 'antd';
import { Dayjs } from 'dayjs';

import { classNames, isBeforeToday, isToday } from '../../../helpers';
import { useScrollYPosition } from '../../../hooks/useScrollPosition';
import { CheckCircleGreen } from '../../../components/icons';
import CalendarControls from '../../../components/CalendarControls';

interface ForecastsManagementHeaderProps {
    activeDate: Dayjs;
    activeWeek: Dayjs[];
    additionalControls?: ReactNode;
    setActiveDate: Dispatch<SetStateAction<Dayjs>>;
    title: string | ReactNode;
}

const ForecastsManagementHeader: FC<ForecastsManagementHeaderProps> = ({
    activeDate,
    activeWeek,
    additionalControls,
    setActiveDate,
    title,
}) => {
    const scrollY = useScrollYPosition();
    const isScrolled = scrollY > 145;

    return (
        <Card
            className="border-b-0"
            style={{
                transition: 'all 0.7s',
                ...(isScrolled && {
                    boxShadow: '0px 17px 16px -11px rgba(0, 0, 0, 0.12)',
                }),
            }}
        >
            <div className="flex justify-between items-center">
                <CalendarControls
                    activeDate={activeDate}
                    additionalControls={additionalControls}
                    handleChangeDate={setActiveDate}
                    mode="week"
                    title={title}
                />
            </div>

            <ul className="flex justify-between list-style-none mt-6 mb-0 p-0">
                {activeWeek.map((day, i) => (
                    <li
                        key={i}
                        className={classNames(
                            'flex justify-center items-center border border-l-0 w-full text-faded-blue',
                            i === 0 && 'border-l',
                            isBeforeToday(day.toDate()) && 'bg-grey-light-blue',
                            isToday(day.toDate()) && 'bg-duck-blue-transparent text-duck-blue'
                        )}
                        style={{ height: 39 }}
                    >
                        {isBeforeToday(day.toDate()) && <CheckCircleGreen className="mr-1" />}
                        <span className="block text-sm font-bold">{day.format('ddd DD/MM')}</span>
                    </li>
                ))}
            </ul>
        </Card>
    );
};

export default ForecastsManagementHeader;
