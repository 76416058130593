import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Modal, ModalProps, Row, Space, Tag, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import taskGroupMessages from '../../../i18n/taskGroupMessages';
import genericMessages from '../../../i18n/genericMessages';
import { TaskGroup } from '../../../queries/api/types';

interface ApplyAutoPlanProposalsErrorModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    taskGroups: TaskGroup[];
    onConfirm?: () => void;
}

const ApplyAutoPlanProposalsErrorModal = NiceModal.create<ApplyAutoPlanProposalsErrorModalProps>(
    ({ taskGroups, onConfirm, ...props }) => {
        const customModal = useModal();
        const { formatMessage } = useIntl();
        const closeHandler = () => {
            customModal.hide();
        };
        const confirmHandler = () => {
            onConfirm?.();
            customModal.hide();
        };
        return (
            <Modal
                {...props}
                {...antdModal(customModal)}
                afterClose={customModal.remove}
                closable={false}
                centered
                bodyStyle={{ paddingBottom: '22px' }}
                footer={false}
            >
                <Row>
                    <Col span={4} className="flex justify-center text-2xl !text-red">
                        <ExclamationCircleFilled className="text-orange" />
                    </Col>
                    <Col span={20}>
                        <Typography.Title className="mb-4">
                            {formatMessage(taskGroupMessages.proposalsAppliedWithErrorsTitle)}
                        </Typography.Title>
                        <Typography.Text className="mb-4">
                            {formatMessage(taskGroupMessages.proposalsAppliedWithErrorsContent)}
                        </Typography.Text>
                        {taskGroups.map((taskGroup) => (
                            <Tag key={taskGroup.id}>{taskGroup.reference}</Tag>
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="flex justify-end mt-4">
                            <Space direction="horizontal" size={8} className="mb-2.5">
                                <Button className="text-blue" key="cancelBtn" onClick={closeHandler}>
                                    {formatMessage(genericMessages.cancel)}
                                </Button>
                                <Button
                                    type="primary"
                                    key="confirmBtn"
                                    onClick={() => {
                                        confirmHandler();
                                    }}
                                >
                                    {formatMessage(genericMessages.validate)}
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Modal>
        );
    }
);

export default ApplyAutoPlanProposalsErrorModal;
