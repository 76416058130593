import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, FormProps, Input, InputNumber, Switch } from 'antd';
import { VFC } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import { errorMessage, successMessage } from '../../helpers/message';
import { getRequiredRule } from '../../i18n';
import formMessages from '../../i18n/formMessages';
import genericMessages from '../../i18n/genericMessages';
import { ValueList, ValueListField, ValueListFieldValueType } from '../../queries/api/types';
import { useValueListItemCreate } from '../../queries/valueListItems';
import { useValueListDetails } from '../../queries/valueLists';
import ApiResult from '../ApiResult';

const renderValueForm = (
    { title, name: fieldName, minValue, maxValue, isRequired: required, valueType }: ValueListField,
    formatMessage: IntlShape['formatMessage']
) => {
    let field;

    switch (valueType) {
        case ValueListFieldValueType.number:
            field = <InputNumber min={minValue} max={maxValue} placeholder="0" />;
            break;

        case ValueListFieldValueType.string:
            field = <Input placeholder={formatMessage(formMessages.valueListInputPlaceholder)} />;
            break;

        case ValueListFieldValueType.boolean:
            field = <Switch />;
            break;

        default:
            field = null;
    }

    return (
        <Form.Item
            name={['fields', fieldName]}
            label={title}
            rules={required ? [getRequiredRule(formatMessage)] : undefined}
            valuePropName={valueType === ValueListFieldValueType.boolean ? 'checked' : 'value'}
            required={required}
            key={fieldName}
        >
            {field}
        </Form.Item>
    );
};

interface ValueListAddCardProps {
    valueListSlug: ValueList['slug'];
}

const ValueListAddCard: VFC<ValueListAddCardProps> = ({ valueListSlug }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const { data: valueList, isLoading, isError, error } = useValueListDetails(valueListSlug);
    const { mutate: createValueListItem, isLoading: isCreating } = useValueListItemCreate();
    const onSubmit: FormProps['onFinish'] = (values) => {
        createValueListItem(
            { valueListSlug, title: 'dummy', meta: {}, ...values },
            {
                onSuccess: () => {
                    form.resetFields();
                    successMessage({
                        content: formatMessage({
                            id: 'value_list_add_card.success_message',
                            defaultMessage: 'Valeur ajoutée avec succès',
                        }),
                    });
                },
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error.response?.status,
                        }),
                    });
                },
            }
        );
    };

    return (
        <Card title={valueList?.title} loading={isLoading}>
            {isError ? (
                <ApiResult status={error?.response?.status} />
            ) : (
                <Form form={form} onFinish={onSubmit} layout="vertical" requiredMark>
                    {valueList?.fields?.map((field) => renderValueForm(field, formatMessage))}
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        htmlType="submit"
                        loading={isCreating}
                        block
                    >
                        <FormattedMessage {...formMessages.add} tagName="span" />
                    </Button>
                </Form>
            )}
        </Card>
    );
};

export default ValueListAddCard;
