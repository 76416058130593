import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Modal, ModalProps, Row, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import taskGroupMessages from '../../../i18n/taskGroupMessages';
import genericMessages from '../../../i18n/genericMessages';

interface TaskGroupPlanificationModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    onConfirm: () => void;
}

const TaskGroupPlanificationModal = NiceModal.create<TaskGroupPlanificationModalProps>(({ onConfirm, ...props }) => {
    const customModal = useModal();
    const { formatMessage } = useIntl();
    const closeHandler = () => {
        customModal.hide();
    };
    const confirmHandler = () => {
        onConfirm();
        customModal.hide();
    };
    return (
        <Modal
            {...props}
            {...antdModal(customModal)}
            afterClose={customModal.remove}
            closable={false}
            centered
            bodyStyle={{ paddingBottom: '22px' }}
            footer={false}
        >
            <Row>
                <Col span={4} className="flex justify-center text-2xl !text-red">
                    <ExclamationCircleFilled className="text-orange" />
                </Col>
                <Col span={20}>
                    <div className="text-lg font-bold mb-4 text-primary">
                        {formatMessage(taskGroupMessages.confirmPlanificationModalTitle, { count: 1 })}
                    </div>
                    <div className="text-base">{formatMessage(taskGroupMessages.confirmPlanificationModalContent)}</div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="flex justify-end mt-4">
                        <Space direction="horizontal" size={8} className="mb-2.5">
                            <Button className="text-blue" key="cancelBtn" onClick={closeHandler}>
                                {formatMessage(genericMessages.cancel)}
                            </Button>
                            <Button
                                type="primary"
                                key="confirmBtn"
                                onClick={() => {
                                    confirmHandler();
                                }}
                            >
                                {formatMessage(genericMessages.validate)}
                            </Button>
                        </Space>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
});

const showTaskGroupPlanificationModal = (onConfirm: () => Promise<void>) => {
    NiceModal.show(TaskGroupPlanificationModal, {
        onConfirm: onConfirm,
    });
};

export default showTaskGroupPlanificationModal;
