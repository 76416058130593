import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Modal, ModalProps, Row } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import taskGroupMessages from '../../../i18n/taskGroupMessages';
import genericMessages from '../../../i18n/genericMessages';

interface TaskGroupPlanificationModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    onConfirm?: () => void;
}

const vehicleUnavailableModal = NiceModal.create<TaskGroupPlanificationModalProps>(({ onConfirm, ...props }) => {
    const customModal = useModal();
    const { formatMessage } = useIntl();
    const confirmHandler = () => {
        if (onConfirm) {
            onConfirm();
        }
        customModal.hide();
    };
    return (
        <Modal
            {...props}
            {...antdModal(customModal)}
            afterClose={customModal.remove}
            closable={false}
            centered
            bodyStyle={{ paddingBottom: '22px' }}
            footer={false}
        >
            <Row>
                <Col span={4} className="flex justify-center text-2xl !text-red">
                    <ExclamationCircleFilled className="text-red" />
                </Col>
                <Col span={20}>
                    <div className="text-lg font-bold mb-4 text-primary">
                        {formatMessage(taskGroupMessages.cantPlan)}
                    </div>
                    <div className="text-base">{formatMessage(taskGroupMessages.vehicleUnavailableError)}</div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="flex justify-end mt-4">
                        <Button
                            key="confirmBtn"
                            onClick={() => {
                                confirmHandler();
                            }}
                        >
                            {formatMessage(genericMessages.edit)}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
});

const showVehicleUnavailableModal = (onConfirm?: () => void) => {
    NiceModal.show(vehicleUnavailableModal, {
        onConfirm: onConfirm,
    });
};

export default showVehicleUnavailableModal;
