import { VFC } from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';

import {
    Issue,
    IssueCommentType,
    IssueStatus,
    Permission,
    PermissionRight,
    Vehicle,
} from '../../../../queries/api/types';
import issueMessages from '../../../../i18n/issueMessages';
import DocumentCheck from '../../../../components/icons/DocumentCheck';
import Repair from '../../../../components/icons/Repair';
import IssueCardComments from './IssueCardComments';
import IssueCardModal, { IssueCardModalProps } from './IssueCardModal';
import { hasPermission } from '../../../../helpers/security';
import { useAuth } from '../../../../context/AuthContext';

interface IssueCardFooterProps {
    issue: Issue;
    vehicleId: Vehicle['id'];
}

const IssueCardFooter: VFC<IssueCardFooterProps> = ({ issue, vehicleId }) => {
    const { user } = useAuth();
    const { formatMessage } = useIntl();
    const isPending = issue.status === IssueStatus.opened;
    const isRepairing = issue.status === IssueStatus.repairing;
    const isClosed = issue.status === IssueStatus.closed;

    const modal = useModal(IssueCardModal);

    const handleDeclareRepairButtonClick = () => {
        const modalParams: IssueCardModalProps = {
            title: formatMessage(issueMessages.declareRepairModalTitle),
            description: formatMessage(issueMessages.declareRepairModalDesc),
            issue,
            newStatus: IssueStatus.repairing,
            onSuccessMessage: formatMessage(issueMessages.declareRepairSuccess),
            vehicleId,
            commentType: IssueCommentType.repairing,
        };

        modal.show(modalParams);
    };

    const handleCloseIssueButtonClick = () => {
        const modalParams: IssueCardModalProps = {
            title: formatMessage(issueMessages.closeIssueModalTitle),
            description: formatMessage(issueMessages.closeIssueModalDesc),
            issue,
            newStatus: IssueStatus.closed,
            onSuccessMessage: formatMessage(issueMessages.closeIssueSuccess),
            vehicleId,
            commentType: IssueCommentType.closing,
        };

        modal.show(modalParams);
    };

    const canCloseIssue = hasPermission(user, Permission.issues, PermissionRight.write);
    return (
        <div className="flex flex-col gap-6">
            <div>
                {(isRepairing || isClosed) && issue.updatedAt && (
                    <IssueCardComments
                        commentType={IssueCommentType.repairing}
                        issue={issue}
                        sectionTitle={`• ${formatMessage(issueMessages.updatedAt, {
                            updatedAt: dayjs(issue.updatedAt).format('DD/MM/YY'),
                        })}`}
                    />
                )}

                {isClosed && issue.closedAt && (
                    <IssueCardComments
                        commentType={IssueCommentType.closing}
                        issue={issue}
                        sectionTitle={`• ${formatMessage(issueMessages.closedAt, {
                            closedAt: dayjs(issue.closedAt).format('DD/MM/YY'),
                        })}`}
                    />
                )}

                <IssueCardComments commentType={IssueCommentType.adding} issue={issue} />
            </div>

            {canCloseIssue && (isPending || !isClosed) && (
                <div className="flex gap-6">
                    {isPending && (
                        <Button
                            className="text-orange border-orange"
                            icon={<Repair />}
                            onClick={handleDeclareRepairButtonClick}
                        >
                            {formatMessage(issueMessages.declareRepair)}
                        </Button>
                    )}

                    {!isClosed && (
                        <Button className="text-blue" icon={<DocumentCheck />} onClick={handleCloseIssueButtonClick}>
                            {formatMessage(issueMessages.closeIssue)}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default IssueCardFooter;
