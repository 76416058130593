/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.976 4.824a.6.6 0 0 1 0-.848l3.6-3.6a.6.6 0 0 1 .848 0l3.6 3.6a.6.6 0 1 1-.848.848L5.6 2.248V9.2a.6.6 0 0 1-1.2 0V2.248L1.824 4.824a.6.6 0 0 1-.848 0Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgArrowUp: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-arrow-up', props.className)} />
));
export default SvgArrowUp;
