import { useCallback, VFC } from 'react';
import { Empty, Input, InputProps, Select, Timeline } from 'antd';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Seo from '../../../components/Seo';
import { IssueStatus, Operator } from '../../../queries/api/types';
import ApiResult from '../../../components/ApiResult';
import vehicleMessages from '../../../i18n/vehicleMessages';
import { useVehicleListIssues } from '../../../queries/vehicles';
import ListTitle from '../../../components/ListTitle';
import genericMessages from '../../../i18n/genericMessages';
import { Search } from '../../../components/icons';
import useQueryParams from '../../../hooks/queryParams';
import DatePicker from '../../../components/DatePicker';
import { issueStatusMessageMap } from '../../../i18n/issueMessages';
import CardSkeleton from '../../../components/CardSkeleton';
import IssueCard from './IssueCard';
import IssueCardIcon from './IssueCard/IssueCardIcon';

let searchTimeout: number;

const ManagementVehiclesDetailsIssues: VFC = () => {
    const { vehicleId } = useParams<{ vehicleId: Operator['id'] }>();
    const { formatMessage } = useIntl();
    const [queryParams, setQueryParams] = useQueryParams('ManagementVehiclesDetailsIssues');
    const search = queryParams.get('search') ?? undefined;
    const status = (queryParams.get('status') as IssueStatus) ?? undefined;
    const dateParam = queryParams.get('date');
    const date = dateParam !== null ? dayjs(dateParam) : undefined;
    const {
        data: issues,
        isLoading,
        isError,
        error,
    } = useVehicleListIssues({ id: vehicleId, date: dateParam ?? undefined, status, search }, { enabled: !!vehicleId });
    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                    sort: undefined,
                    sortOrder: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );

    return (
        <div className="flex flex-col justify-between gap-6">
            <Seo title={formatMessage(vehicleMessages.managementIssues)} />
            <div className="flex justify-between items-center mb-0">
                <ListTitle className="mb-0">
                    {formatMessage({
                        id: 'management.vehicles.issues.list_title',
                        defaultMessage: 'Incidents',
                    })}
                </ListTitle>
                <div className="flex items-center gap-4">
                    <Select
                        allowClear
                        onChange={(value) => setQueryParams({ status: value })}
                        defaultValue={status}
                        placeholder={formatMessage(genericMessages.allStatuses)}
                        style={{ width: 240 }}
                        size="small"
                    >
                        {Object.values(IssueStatus).map((status) => (
                            <Select.Option key={status} value={status}>
                                {formatMessage(issueStatusMessageMap.get(status)!)}
                            </Select.Option>
                        ))}
                    </Select>
                    <DatePicker
                        format="DD/MM/YYYY"
                        onChange={(value) => setQueryParams({ date: value ? value.toISOString() : undefined })}
                        value={date}
                        style={{ width: 240 }}
                        size="small"
                    />
                    <Input
                        placeholder={formatMessage(genericMessages.search)}
                        onChange={onSearch}
                        defaultValue={search}
                        prefix={<Search className="text-primary text-base leading-4" />}
                        size="small"
                        style={{ width: 240 }}
                        allowClear
                    />
                </div>
            </div>
            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : isLoading ? (
                <Timeline>
                    <Timeline.Item dot={<div className="bg-grey-5 rounded-full" style={{ height: 32, width: 32 }} />}>
                        <CardSkeleton rows={3} />
                    </Timeline.Item>
                    <Timeline.Item dot={<div className="bg-grey-5 rounded-full" style={{ height: 32, width: 32 }} />}>
                        <CardSkeleton rows={3} />
                    </Timeline.Item>
                    <Timeline.Item dot={<div className="bg-grey-5 rounded-full" style={{ height: 32, width: 32 }} />}>
                        <CardSkeleton rows={3} />
                    </Timeline.Item>
                    <Timeline.Item dot={<div className="bg-grey-5 rounded-full" style={{ height: 32, width: 32 }} />}>
                        <CardSkeleton rows={3} />
                    </Timeline.Item>
                </Timeline>
            ) : (
                <Timeline>
                    {issues && issues?.items.length > 0 ? (
                        issues?.items.map((issue) => (
                            <Timeline.Item key={issue.id} dot={<IssueCardIcon issue={issue} />}>
                                <IssueCard issue={issue} vehicleId={vehicleId} />
                            </Timeline.Item>
                        ))
                    ) : (
                        <span className="text-xl font-semibold">
                            <Empty />
                        </span>
                    )}
                </Timeline>
            )}
        </div>
    );
};

export default ManagementVehiclesDetailsIssues;
