import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Table, TableProps, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { HiOutlinePencil, HiOutlineTrash, HiPlus } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import { show } from '@ebay/nice-modal-react';

import Seo from '../../components/Seo';
import ListTitle from '../../components/ListTitle';
import ApiResult from '../../components/ApiResult';
import useQueryParams from '../../hooks/queryParams';
import { sortOrderConverter } from '../../helpers';
import genericMessages from '../../i18n/genericMessages';
import { Customer, TaskGroup, TaskGroupService, TaskGroupServiceType, Transporter } from '../../queries/api/types';
import DeletePopConfirm from '../../components/DeletePopConfirm';
import { useTaskGroupServiceRemove } from '../../queries/taskGroupServices';
import { errorMessage, successMessage } from '../../helpers/message';
import { useTaskGroupDetails, useTaskGroupListServices } from '../../queries/taskGroups';
import { getRoute, RoutePathName } from '../../routes';
import TaskGroupServiceFormModal from './TaskGroupServiceFormModal';
import preBillingMessages from '../../i18n/preBillingMessages';

const TaskGroupServicesList: FC = () => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { customerId, transporterId, taskGroupId } =
        useParams<{ taskGroupId: TaskGroup['id']; customerId?: Customer['id']; transporterId?: Transporter['id'] }>();
    const { data: taskGroupDetails } = useTaskGroupDetails(taskGroupId, {
        enabled: !!taskGroupId,
    });
    const isCustomer = !!customerId;
    const isTransporter = !!transporterId;
    const [taskGroupServiceIdBeingDeleted, setTaskGroupServiceIdBeingDeleted] = useState<TaskGroupService['id']>();
    const [queryParams, setQueryParams] = useQueryParams('TaskGroupServicesList');
    const taskGroupReference = queryParams.get('taskGroupReference') ?? undefined;
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const {
        data: taskGroupServices,
        isLoading,
        isFetching,
        isError,
        error,
    } = useTaskGroupListServices({
        id: taskGroupId,
        type: isCustomer ? TaskGroupServiceType.customer : TaskGroupServiceType.transporter,
        page,
        sort,
        sortOrder,
    });
    const backRoute = isCustomer
        ? getRoute(RoutePathName.preBillingCustomersDetails, { customerId })
        : isTransporter
        ? getRoute(RoutePathName.preBillingTransportersDetails, { transporterId })
        : undefined;
    const { mutate: deleteTaskGroupService } = useTaskGroupServiceRemove();
    const onClickDelete = useCallback(
        (id: TaskGroupService['id']) => () => {
            const hasSingleService = taskGroupServices?.totalCount === 1;
            setTaskGroupServiceIdBeingDeleted(id);
            deleteTaskGroupService(id, {
                onSuccess: () => {
                    successMessage({
                        content: formatMessage({
                            id: 'pre_billing.task_group_services.delete_success',
                            defaultMessage: 'Prestation supplémentaire supprimée avec succès',
                        }),
                    });
                    if (hasSingleService && backRoute) {
                        history.push(backRoute);
                    }
                },
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error.response?.status,
                        }),
                    });
                },
                onSettled: () => {
                    setTaskGroupServiceIdBeingDeleted(undefined);
                },
            });
        },
        [backRoute, deleteTaskGroupService, formatMessage, history, taskGroupServices?.totalCount]
    );
    const onTableChange: TableProps<TaskGroupService>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
            });
        },
        [setQueryParams]
    );
    const columns: ColumnsType<TaskGroupService> = useMemo(
        () => [
            {
                key: 'label',
                dataIndex: 'label',
                title: formatMessage(preBillingMessages.taskGroupServiceLabel),
                sorter: true,
            },
            {
                key: 'amount',
                title: formatMessage(preBillingMessages.taskGroupServiceAmount),
                render: (_, item) => (
                    <FormattedNumber
                        style="currency"
                        currency="EUR"
                        maximumFractionDigits={2}
                        minimumFractionDigits={0}
                        value={item.amount}
                    />
                ),
                sorter: true,
            },
            {
                key: 'actions',
                title: formatMessage(genericMessages.actions),
                render: (_, item) => (
                    <div className="flex items-center space-x-2">
                        <Button
                            type="text"
                            icon={<HiOutlinePencil className="text-xl text-faded-blue" />}
                            onClick={() => {
                                show(TaskGroupServiceFormModal, {
                                    taskGroupServiceId: item.id,
                                    taskGroupId: taskGroupId,
                                });
                            }}
                        />
                        <DeletePopConfirm onConfirm={onClickDelete(item.id)}>
                            <Button
                                icon={<HiOutlineTrash className="text-xl text-faded-blue" />}
                                type="text"
                                loading={item.id === taskGroupServiceIdBeingDeleted}
                            />
                        </DeletePopConfirm>
                    </div>
                ),
                width: 136,
            },
        ],
        [formatMessage, onClickDelete, taskGroupId, taskGroupServiceIdBeingDeleted]
    );
    const pageTitle = formatMessage(
        {
            id: 'pre_billing.task_group_services.title',
            defaultMessage: 'Prestations supplémentaires de l’OT n°{reference}',
            description: 'Customer pre billing list title',
        },
        {
            reference: taskGroupReference,
        }
    );
    const taskGroupServiceType = taskGroupDetails?.vehicle?.transporter
        ? TaskGroupServiceType.transporter
        : TaskGroupServiceType.customer;
    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                <ListTitle count={taskGroupServices?.totalCount} className="mb-0 uppercase" backRoute={backRoute}>
                    {pageTitle}
                </ListTitle>
                <Button
                    type="primary"
                    icon={<HiPlus className="text-base" />}
                    onClick={() => {
                        show(TaskGroupServiceFormModal, {
                            taskGroupServiceType: taskGroupServiceType,
                            taskGroupId,
                        });
                    }}
                >
                    <FormattedMessage {...preBillingMessages.addAdditionalServicesTooltip} tagName="span" />
                </Button>
            </div>
            {isError ? (
                <ApiResult status={error?.response?.status} />
            ) : (
                <Table<TaskGroupService>
                    rowKey="id"
                    columns={columns}
                    loading={isLoading || isFetching}
                    dataSource={taskGroupServices?.items}
                    pagination={{
                        total: taskGroupServices?.totalCount,
                        current: page + 1,
                        pageSize: taskGroupServices?.pageSize,
                        hideOnSinglePage: true,
                    }}
                    scroll={{
                        y: 'calc(100vh - 244px)',
                        scrollToFirstRowOnChange: true,
                    }}
                    onChange={onTableChange}
                />
            )}
        </>
    );
};

export default TaskGroupServicesList;
