import { useState, forwardRef } from 'react';
import { Empty, Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectRef } from 'rc-select';
import { useIntl } from 'react-intl';

import { ValueListItem, ValueListSlug } from '../../queries/api/types';
import { useDebounce } from '../../hooks';
import { useValueListItemList } from '../../queries/valueListItems';

export interface ValueListItemSelectProps extends SelectProps {
    onChange?: (value: ValueListItem['id'] | Array<ValueListItem['id']> | undefined) => void;
    initialValue?: ValueListItem[];
    valueListSlug: ValueListSlug;
    itemValueKey?: keyof ValueListItem;
    itemLabelKey?: string;
    filterOptions?: (options: ValueListItem) => boolean;
}

const ValueListItemSelect = forwardRef<BaseSelectRef, ValueListItemSelectProps>(
    ({ onChange, initialValue, valueListSlug, itemValueKey = 'id', itemLabelKey, filterOptions, ...props }, ref) => {
        const { formatMessage } = useIntl();
        const [search, setSearch] = useState<string>();
        const debouncedSearch = useDebounce(search, 300);
        const [value, setValue] = useState<ValueListItem['id'] | Array<ValueListItem['id']> | undefined>(
            initialValue?.map((a) => a.id)
        );
        const { data: list, isLoading } = useValueListItemList({
            valueListSlug,
            search: debouncedSearch || undefined,
            pageSize: 50,
        });

        const onChangeSelect: SelectProps<ValueListItem['id'] | Array<ValueListItem['id']>>['onChange'] = (
            newValue
        ) => {
            setValue(newValue);
            onChange?.(newValue);
        };

        return (
            <Select<ValueListItem['id'] | Array<ValueListItem['id']>>
                ref={ref}
                value={value}
                placeholder={formatMessage({
                    id: 'value_list_item_select.placeholder',
                    defaultMessage: 'Rechercher une valeur',
                })}
                notFoundContent={isLoading ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={setSearch}
                onChange={onChangeSelect}
                style={{ width: '100%' }}
                loading={isLoading}
                showSearch
                allowClear
                {...props}
            >
                {[
                    ...(initialValue || []), // Display initial value
                    ...(list?.items.filter(
                        (a) => !initialValue?.map((b) => b[itemValueKey]).includes(a[itemValueKey])
                    ) ?? []), // Search items, excluding initial value
                ]
                    .filter(filterOptions ?? (() => true))
                    .map((item) => (
                        <Select.Option value={item[itemValueKey]} key={item.id}>
                            {itemLabelKey ? item.fields[itemLabelKey] : item.fields[Object.keys(item.fields)[0]]}
                        </Select.Option>
                    ))}
            </Select>
        );
    }
);

export default ValueListItemSelect;
