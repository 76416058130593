import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { OperatorAbsence, ListResponse, ValueListItem, Operator } from './types';

// Controller Interface
export interface OperatorAbsenceCreatePayload {
    operator: Operator['id'];
    startDate: string;
    endDate: string;
    type: ValueListItem['id'];
}

export interface OperatorAbsenceUpdatePayload extends Omit<OperatorAbsence, 'id'> {
    id?: OperatorAbsence['id'];
}

export type OperatorAbsenceIdPayload = OperatorAbsence['id'];

export type OperatorAbsenceListPayload = SearchPaginationQuery;
export type OperatorAbsenceListResponse = ListResponse<OperatorAbsence>;

// Routes
export const list = async (payload?: OperatorAbsenceListPayload) => {
    return await api
        .get<OperatorAbsenceListResponse>(`/operatorAbsences`, { params: payload })
        .then((response) => response?.data);
};

export const create = async ({ operator, ...payload }: OperatorAbsenceCreatePayload) => {
    if (!operator) {
        throw new Error('missing operator id');
    }
    return await api.post(`/operators/${operator}/absences`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: OperatorAbsenceUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<OperatorAbsence>(`/operatorAbsences/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: OperatorAbsenceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<OperatorAbsence>(`/operatorAbsences/${id}`).then((response) => response?.data);
};

export const remove = async (id?: OperatorAbsenceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api
        .delete<undefined>(`/operatorAbsences`, { params: { commonId: id } })
        .then((response) => response?.data);
};
