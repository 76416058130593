import { LoadingOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import WeekOfYear from 'dayjs/plugin/weekOfYear';
import ReactDOM from 'react-dom';
import { IconContext } from 'react-icons';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';

import './assets/styles/AntOverride.less';

import App from './App';
import IntlProvider from './components/IntlProvider';
import { AuthProvider } from './context/AuthContext';
import { CookiesProvider } from './context/CookiesContext';
import { LocaleProvider } from './context/LocaleContext';
import { LogsProvider } from './context/LogsContext';
import reportWebVitals from './reportWebVitals';

Spin.setDefaultIndicator(<LoadingOutlined spin />);

setDefaultBreakpoints([{ xs: 0 }, { sm: 480 }, { md: 576 }, { lg: 768 }, { xl: 992 }, { xxl: 1200 }, { xxxl: 1600 }]);

dayjs.extend(WeekOfYear);

// API mock data service worker
if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('./mocks/browser');
    worker.start();
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 20 * 1000, // 20s cache
        },
    },
});

const render = (Component: React.ComponentType) => {
    ReactDOM.render(
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <LogsProvider>
                <AuthProvider>
                    <CookiesProvider>
                        <BrowserRouter>
                            <BreakpointProvider>
                                <LocaleProvider>
                                    <IntlProvider>
                                        <NiceModal.Provider>
                                            <IconContext.Provider value={{ className: 'anticon' }}>
                                                <Component />
                                            </IconContext.Provider>
                                        </NiceModal.Provider>
                                    </IntlProvider>
                                </LocaleProvider>
                            </BreakpointProvider>
                        </BrowserRouter>
                    </CookiesProvider>
                </AuthProvider>
            </LogsProvider>
        </QueryClientProvider>,
        document.getElementById('root') as HTMLElement
    );
};

const init = async () => {
    const checkPolyfills = async () => {
        if (!window.IntersectionObserver) {
            await import('intersection-observer');
        }
        if (!window.URLSearchParams) {
            await import('url-search-params-polyfill');
        }
    };

    await checkPolyfills();

    render(App);
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
