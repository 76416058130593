import { useMemo, VFC } from 'react';
import { Select, SelectProps } from 'antd';
import { useIntl } from 'react-intl';

import { InvoicingUnit } from '../../queries/api/types';
import customerMessages, { customerInvoicingUnitMessagesMap } from '../../i18n/customerMessages';

const InvoicingUnitSelect: VFC<SelectProps<InvoicingUnit>> = (props) => {
    const { formatMessage } = useIntl();

    const options: Array<{
        label: string;
        value: InvoicingUnit;
    }> = useMemo(
        () =>
            Object.values(InvoicingUnit).map((key) => ({
                label: formatMessage(customerInvoicingUnitMessagesMap.get(key)!),
                value: InvoicingUnit[key],
            })),
        [formatMessage]
    );

    return (
        <Select<InvoicingUnit>
            placeholder={formatMessage(customerMessages.selectUnit)}
            filterOption={false}
            options={options}
            showSearch={false}
            showArrow
            {...props}
        />
    );
};

export default InvoicingUnitSelect;
