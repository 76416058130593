import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    IssueCreatePayload,
    IssueIdPayload,
    IssueListPayload,
    IssueListResponse,
    IssueUpdatePayload,
    remove,
    update,
} from './api/issues';
import { Issue } from './api/types';
import { issueCommentsKeys } from './issueComments';
import { vehiclesKeys } from './vehicles';

export const issuesKeys = {
    all: ['issues'],
    lists: () => [...issuesKeys.all, 'list'],
    list: (params?: IssueListPayload) => [...issuesKeys.lists(), params],
    details: () => [...issuesKeys.all, 'details'],
    detail: (params?: IssueIdPayload) => [...issuesKeys.details(), params],
};

export const useIssueList = <TData = IssueListResponse>(
    params?: IssueListPayload,
    options?: UseQueryOptions<IssueListResponse, AxiosError, TData>
) => {
    return useQuery<IssueListResponse, AxiosError, TData>(issuesKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useIssueDetails = <TData = Issue>(
    payload: IssueIdPayload,
    options?: UseQueryOptions<Issue, AxiosError, TData>
) => {
    return useQuery<Issue, AxiosError, TData>(issuesKeys.detail(payload), async () => await details(payload), options);
};

export const useIssueCreate = (options?: UseMutationOptions<Issue, AxiosError, IssueCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Issue, AxiosError, IssueCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(issuesKeys.lists());
        },
    });
};

export const useIssueUpdate = (options?: UseMutationOptions<Issue, AxiosError, IssueUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Issue, AxiosError, IssueUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            queryClient.invalidateQueries(vehiclesKeys.listIssuesAll());

            // invalidate detail query so they refetch with the newly added item
            queryClient.invalidateQueries(issueCommentsKeys.detail(variables.issue));
        },
    });
};

export const useIssueRemove = (options?: UseMutationOptions<undefined, AxiosError, IssueIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, IssueIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(issuesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(
                vehiclesKeys.listIssues({
                    id: variables.vehicle,
                })
            );
        },
    });
};
