import { Badge, Button, Input, InputProps, Space, Table, Tabs, Tooltip, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState, VFC } from 'react';
import { FormattedNumber, useIntl, FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { ColumnsType, TableProps } from 'antd/lib/table';
import dayjs from 'dayjs';
import { CalendarOutlined, UploadOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';
import { TbClipboardList } from 'react-icons/tb';
import { HiClipboardCheck } from 'react-icons/hi';

import {
    useManuallycloseTaskGroup,
    useRunAutoPlanification,
    useTaskGroupList,
    useTaskGroupRemoveBatch,
} from '../../../queries/taskGroups';
import {
    Customer,
    Permission,
    PermissionRight,
    RoleSlug,
    TaskGroup,
    TaskGroupStatus,
    TaskGroupTransferStatus,
} from '../../../queries/api/types';
import { getRoute, RoutePathName } from '../../../routes';
import taskGroupMessages from '../../../i18n/taskGroupMessages';
import genericMessages from '../../../i18n/genericMessages';
import { getFullName, sortOrderConverter } from '../../../helpers';
import useQueryParams from '../../../hooks/queryParams';
import CustomerSelect from '../../../components/selects/CustomerSelect';
import DatePicker from '../../../components/DatePicker';
import ListTitle from '../../../components/ListTitle';
import PlaceSelect from '../../../components/selects/PlaceSelect';
import Seo from '../../../components/Seo';
import { Search, Trash } from '../../../components/icons';
import ImportFileModal, { ImportFileModalProps } from './ImportFileModal';
import TaskGroupStatusTag from './TaskGroupStatusTag';
import TaskGroupDetailsDrawer from './TaskGroupDetailsDrawer';
import AutoPlanificationDrawer from './AutoPlanificationDrawer';
import CalendarTaskGroup, { CalendarTaskGroupProps } from './CalendarTaskGroup';
import { ManagementTaskGroupsTabs } from '.';
import { hasPermission, hasRole } from '../../../helpers/security';
import { useAuth } from '../../../context/AuthContext';
import { errorMessage, successMessage } from '../../../helpers/message';
import ConfirmationModal from '../../../components/ConfirmationModal';
import vehicleMessages from '../../../i18n/vehicleMessages';
import OrderNumberModal from '../../../components/OrderNumberModal';

let searchTimeout: number;
const closeSelectedTaskGroupsModal = 'close-selectedTaskGroups-modal';
const confirmRemoveBatchModalId = 'confirm-remove-batch-modal';

enum SelectionType {
    autoPlanification = 'autoPlanification',
    deletion = 'deletion',
    close = 'close',
}

const ManagementTaskGroups: VFC = () => {
    const { user } = useAuth();
    const { formatMessage } = useIntl();
    const detailsDrawer = useModal(TaskGroupDetailsDrawer);
    const autoPlanificationDrawer = useModal(AutoPlanificationDrawer);
    const userCloseSelectedTaskGroupsModal = useModal(closeSelectedTaskGroupsModal);
    const confirmRemoveBatchModal = useModal(confirmRemoveBatchModalId);
    const orderNumberModal = useModal(OrderNumberModal);
    const pageTitle = formatMessage({
        id: 'menu.management.ot',
        defaultMessage: 'Suivi des OT',
        description: 'menu item',
    });
    const [queryParams, setQueryParams] = useQueryParams('management-task-group');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const fromDate = queryParams.get('fromDate') ?? undefined;
    const toDate = queryParams.get('toDate') ?? undefined;
    const customerIds = queryParams.getAll('customerIds') ?? undefined;
    const startingPlaces = queryParams.getAll('startingPlaces') ?? undefined;
    const history = useHistory();
    const { tab } = useParams<{ tab: ManagementTaskGroupsTabs }>();
    const { mutateAsync: runAutoPlanification, isLoading: autoPlanificationInProgress } = useRunAutoPlanification();
    const { mutateAsync: removeBatch, isLoading: isRemoveBatchLoading } = useTaskGroupRemoveBatch();
    const { mutateAsync: manuallyCloseTaskGroup, isLoading: manuallyCloseTaskGroupInProgess } =
        useManuallycloseTaskGroup();
    const userIsAdmin = hasPermission(user, Permission.admin);

    const onTableChange: TableProps<TaskGroup>['onChange'] = (pagination, _, sorter) => {
        const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;
        setQueryParams({
            page: (pagination.current ?? 1) - 1,
            sort: sortObj.column?.key ? `${sortObj.column?.key}` : undefined,
            sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
        });
    };

    const onChangeTab = useCallback(
        (tab) => {
            history.push(
                getRoute(RoutePathName.managementTaskGroups, {
                    tab,
                })
            );
        },
        [history]
    );

    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                    sort: undefined,
                    sortOrder: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );

    const {
        data: taskGroupList,
        isFetching,
        isLoading,
    } = useTaskGroupList({
        ...(tab !== ManagementTaskGroupsTabs.allStatuses &&
        tab !== ManagementTaskGroupsTabs.transferred &&
        tab !== ManagementTaskGroupsTabs.external
            ? { status: TaskGroupStatus[tab] }
            : {}),
        ...(tab === ManagementTaskGroupsTabs.transferred ? { transferStatus: TaskGroupTransferStatus.interGroup } : {}),
        page,
        search,
        sort,
        sortOrder,
        startingPlaces,
        fromDate,
        toDate,
        customer: customerIds,
        isExternal: tab === ManagementTaskGroupsTabs.external ? true : undefined,
    });
    const filterCustomers: any = (customerIds: Array<Customer['id']>) => {
        setQueryParams({
            customerIds,
            page: undefined,
        });
    };

    const onChangeDateRange = (dates: any) => {
        if (!dates) {
            setQueryParams({
                fromDate: undefined,
                toDate: undefined,
            });
        } else {
            const [fromDate, toDate] = dates;

            setQueryParams({
                fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
                toDate: dayjs(toDate).format('YYYY-MM-DD'),
            });
        }
    };

    const onUpdateSuccess = useCallback(() => {
        setSelectedTaskGroupIds([]);
        setExcludedTaskGroupIds([]);
        setSelectAll(false);
    }, []);

    const { data: taskGroupListAllStatuses } = useTaskGroupList({
        pageSize: 1,
    });
    const { data: taskGroupListToPlan } = useTaskGroupList({
        status: TaskGroupStatus.toPlan,
        pageSize: 1,
    });
    const { data: taskGroupListReadyToStart } = useTaskGroupList({
        status: TaskGroupStatus.readyToStart,
        pageSize: 1,
    });
    const { data: taskGroupListInProgress } = useTaskGroupList({
        status: TaskGroupStatus.inProgress,
        pageSize: 1,
    });
    const { data: taskGroupListExternal } = useTaskGroupList({
        pageSize: 1,
        isExternal: true,
    });
    const { data: taskGroupListToClose } = useTaskGroupList({
        status: TaskGroupStatus.toClose,
        pageSize: 1,
    });
    const { data: taskGroupListDone } = useTaskGroupList({
        status: TaskGroupStatus.done,
        pageSize: 1,
    });
    const { data: taskGroupListTransfered } = useTaskGroupList({
        transferStatus: TaskGroupTransferStatus.interGroup,
        pageSize: 1,
    });
    const { data: taskGroupListExternalInProgress } = useTaskGroupList({
        status: TaskGroupStatus.inProgress,
        pageSize: 1,
        isExternal: true,
    });

    const badgeCountPerStatus = {
        [ManagementTaskGroupsTabs.allStatuses]: taskGroupListAllStatuses,
        [ManagementTaskGroupsTabs.toPlan]: taskGroupListToPlan,
        [ManagementTaskGroupsTabs.readyToStart]: taskGroupListReadyToStart,
        [ManagementTaskGroupsTabs.inProgress]: taskGroupListInProgress,
        [ManagementTaskGroupsTabs.external]: taskGroupListExternal,
        [ManagementTaskGroupsTabs.toClose]: taskGroupListToClose,
        [ManagementTaskGroupsTabs.done]: taskGroupListDone,
        [ManagementTaskGroupsTabs.transferred]: taskGroupListTransfered,
    };

    const columns: ColumnsType<TaskGroup> = useMemo(
        () => [
            {
                key: 'reference',
                title: formatMessage({
                    id: 'operators_list.column.ot_number',
                    defaultMessage: 'N° OT',
                }),
                render: (_, item) => (
                    <Button
                        type="text"
                        className="custom-btn-text font-bold blue-link flex items-center"
                        onClick={() => {
                            detailsDrawer.show({ taskGroupId: item.id, onUpdateSuccess });
                        }}
                    >
                        <span className="t-o-ellipsis" style={{ maxWidth: 160 }}>
                            {item.reference}
                        </span>
                    </Button>
                ),
                sorter: true,
                fixed: 'left',
                width: 200,
            },
            {
                dataIndex: 'status',
                key: 'status',
                title: formatMessage(genericMessages.status),
                render: (_, item) => (
                    <Tooltip title={item.vehicle?.transporter?.name}>
                        <TaskGroupStatusTag taskGroup={item} />
                    </Tooltip>
                ),
                width: 170,
            },
            {
                dataIndex: 'date',
                key: 'date',
                title: formatMessage(genericMessages.date),
                render: (_, item) => dayjs(item.date).format('DD/MM/YYYY'),
                sorter: true,
                width: 150,
            },
            {
                key: 'customer',
                title: formatMessage(genericMessages.customer, { count: 1 }),
                render: (_, taskGroup) => taskGroup.customer?.name,
                sorter: true,
                width: 200,
            },
            {
                key: 'places',
                title: formatMessage(genericMessages.place, { count: 0 }),
                render: (_, taskGroup) => taskGroup.startingPlace.name,
                filteredValue: startingPlaces,
                filterDropdown: () => (
                    <div className="p-3">
                        <PlaceSelect
                            defaultValue={startingPlaces}
                            mode="multiple"
                            onChange={(value) => setQueryParams({ startingPlaces: value })}
                            size="small"
                            style={{ width: 260 }}
                        />
                    </div>
                ),
                width: 200,
            },
            {
                key: 'operatorReference',
                title: formatMessage(taskGroupMessages.operatorReference),
                render: (_, taskGroup) => taskGroup.operator?.reference ?? '-',
                width: 150,
            },
            {
                key: 'operator',
                title: formatMessage(genericMessages.name),
                sorter: true,
                render: (_, taskGroup) => {
                    const { firstName, lastName } = taskGroup.operator || {};
                    return firstName && lastName ? getFullName(taskGroup.operator) : '-';
                },
                width: 200,
            },
            {
                key: 'vehicleReference',
                title: formatMessage(vehicleMessages.vehicleReference),
                sorter: true,
                render: (_, taskGroup) => taskGroup.vehicle?.reference ?? '-',
                width: 200,
            },
            {
                key: 'orderNumber',
                title: formatMessage(taskGroupMessages.orderNumber),
                sorter: true,
                render: (_, taskGroup) => {
                    const { id, orderNumber } = taskGroup;
                    return (
                        orderNumber || (
                            <Button
                                type="link"
                                onClick={async () => await orderNumberModal.show({ taskGroupId: id })}
                                className="p-0"
                            >
                                <span className="normal-case text-black font-regular">
                                    {formatMessage(genericMessages.toDefine)}
                                </span>
                            </Button>
                        )
                    );
                },
                width: 200,
            },
            {
                title: formatMessage(taskGroupMessages.desiredVehicle),
                render: (_, taskGroup) =>
                    taskGroup.wantedVehicleType || taskGroup.vehicle?.type?.fields.vehicleType || '-',
                width: 200,
            },
            {
                key: 'startDate',
                title: formatMessage(taskGroupMessages.start),
                render: (_, taskGroup) => (taskGroup.startDate ? dayjs(taskGroup.startDate).format('HH:mm') : '-'),
                sorter: true,
                width: 120,
            },
            {
                key: 'endDate',
                title: formatMessage(taskGroupMessages.end),
                render: (_, taskGroup) => (taskGroup.endDate ? dayjs(taskGroup.endDate).format('HH:mm') : '-'),
                sorter: true,
                width: 120,
            },
            {
                key: 'taskCount',
                title: formatMessage(taskGroupMessages.pdl),
                render: (_, taskGroup) => taskGroup.computedProperties?.taskCount,
                sorter: true,
                width: 120,
            },
            {
                key: 'volume',
                title: formatMessage(taskGroupMessages.volume),
                render: (_, taskGroup) =>
                    taskGroup?.computedProperties?.volume !== undefined ? (
                        <>
                            <FormattedNumber value={taskGroup.computedProperties.volume} style="unit" unit="meter" />
                            <sup>3</sup>
                        </>
                    ) : (
                        '-'
                    ),
                sorter: true,
                width: 120,
            },
            {
                key: 'weight',
                title: formatMessage(taskGroupMessages.weight),
                render: (_, taskGroup) =>
                    taskGroup.computedProperties?.weight !== undefined ? (
                        <FormattedNumber value={taskGroup.computedProperties.weight} style="unit" unit="kilogram" />
                    ) : (
                        '-'
                    ),
                sorter: true,
                width: 120,
            },
            {
                key: 'mileage',
                title: formatMessage(taskGroupMessages.expectedMileage, { count: 0 }),
                render: (_, taskGroup) =>
                    taskGroup.computedProperties?.mileage !== undefined ? (
                        <FormattedNumber value={taskGroup.computedProperties?.mileage} style="unit" unit="kilometer" />
                    ) : (
                        '-'
                    ),
                sorter: true,
                width: 220,
            },
        ],
        [formatMessage, startingPlaces, detailsDrawer, onUpdateSuccess, setQueryParams, orderNumberModal]
    );

    const importFileModal = useModal(ImportFileModal);
    const onImportFileButtonClick = () => {
        const modalProps: ImportFileModalProps = {
            title: formatMessage(taskGroupMessages.importFileButton),
        };

        importFileModal.show(modalProps);
    };

    const CalendarTaskGroupId = 'CalendarTaskGroupId';
    const calendarTaskGroupDrawer = useModal(CalendarTaskGroupId);
    const onCalendarButtonClick = () => {
        const modalProps: CalendarTaskGroupProps = {
            title: (
                <span className="block text-black text-xl font-bold uppercase">
                    {formatMessage(taskGroupMessages.calendarTaskGroups)}
                </span>
            ),
            width: 1200,
        };

        calendarTaskGroupDrawer.show(modalProps);
    };
    const canImportFile = hasPermission(user, Permission.taskGroupTracking, PermissionRight.write);
    const [selectedTaskGroupIds, setSelectedTaskGroupIds] = useState<Array<TaskGroup['id']>>([]);
    const [selectAll, setSelectAll] = useState(false);

    const selectionType = useMemo(() => {
        if ([ManagementTaskGroupsTabs.allStatuses, ManagementTaskGroupsTabs.toPlan].includes(tab)) {
            switch (user?.role?.slug) {
                case RoleSlug.superAdmin:
                    return SelectionType.deletion;
                case RoleSlug.admin:
                    return SelectionType.deletion;
                case RoleSlug.superDispatcher:
                    return SelectionType.autoPlanification;
            }
        }
        if (tab === ManagementTaskGroupsTabs.readyToStart) {
            if (hasRole(user, [RoleSlug.admin, RoleSlug.superAdmin])) {
                return SelectionType.deletion;
            }
        }
        if (tab === ManagementTaskGroupsTabs.external) {
            return SelectionType.close;
        }
        return '';
    }, [tab, user]);

    const canAutoPlan = useMemo(
        () =>
            hasPermission(user, Permission.autoPlanning, PermissionRight.write) &&
            [ManagementTaskGroupsTabs.allStatuses, ManagementTaskGroupsTabs.toPlan].includes(tab),
        [tab, user]
    );
    const canPlan = hasPermission(user, Permission.taskGroups, PermissionRight.write);
    const canDelete = useMemo(
        () =>
            hasRole(user, [RoleSlug.superAdmin, RoleSlug.admin]) &&
            [
                ManagementTaskGroupsTabs.allStatuses,
                ManagementTaskGroupsTabs.toPlan,
                ManagementTaskGroupsTabs.readyToStart,
            ].includes(tab),
        [tab, user]
    );

    const totalSelectableTaskGroups = useMemo(() => {
        if (!selectionType) {
            return 0;
        }
        return (
            {
                [SelectionType.autoPlanification]: taskGroupListToPlan?.totalCount,
                [SelectionType.close]: taskGroupListExternalInProgress?.totalCount,
                [SelectionType.deletion]:
                    (taskGroupListToPlan?.totalCount ?? 0) + (taskGroupListReadyToStart?.totalCount ?? 0),
            }[selectionType] || 0
        );
    }, [
        selectionType,
        taskGroupListExternalInProgress?.totalCount,
        taskGroupListReadyToStart?.totalCount,
        taskGroupListToPlan?.totalCount,
    ]);

    const taskGroupIdsToPlanOnCurrentPage = useMemo(() => {
        return (
            taskGroupList?.items
                .filter((taskGroup) => taskGroup.status === TaskGroupStatus.toPlan)
                .map((taskGroup) => taskGroup.id) || []
        );
    }, [taskGroupList?.items]);

    const taskGroupIdsReadyToStartOnCurrentPage = useMemo(() => {
        return (
            taskGroupList?.items
                .filter((taskGroup) => taskGroup.status === TaskGroupStatus.readyToStart)
                .map((taskGroup) => taskGroup.id) || []
        );
    }, [taskGroupList?.items]);

    const externalTaskGroupIdsInProgressOnCurrentPage = useMemo(() => {
        return (
            taskGroupList?.items
                .filter(
                    (taskGroup) => taskGroup.status === TaskGroupStatus.inProgress && taskGroup.vehicle?.transporter?.id
                )
                .map((taskGroup) => taskGroup.id) || []
        );
    }, [taskGroupList?.items]);

    const selectableIdsOnCurrentPage = useMemo(() => {
        if (!selectionType) {
            return [];
        }
        return (
            {
                [SelectionType.autoPlanification]: taskGroupIdsToPlanOnCurrentPage,
                [SelectionType.close]: externalTaskGroupIdsInProgressOnCurrentPage,
                [SelectionType.deletion]: [
                    ...taskGroupIdsToPlanOnCurrentPage,
                    ...taskGroupIdsReadyToStartOnCurrentPage,
                ],
            }[selectionType] || []
        );
    }, [
        externalTaskGroupIdsInProgressOnCurrentPage,
        selectionType,
        taskGroupIdsReadyToStartOnCurrentPage,
        taskGroupIdsToPlanOnCurrentPage,
    ]);

    const currentPageFullySelected = selectionType
        ? selectableIdsOnCurrentPage?.length > 0 &&
          selectableIdsOnCurrentPage?.every((id) => selectedTaskGroupIds?.includes(id))
        : false;

    const taskGroupAreSelectedOnOtherPage =
        selectedTaskGroupIds.length > (taskGroupList ? taskGroupList.items.length : 0);
    const [excludedTaskGroupIds, setExcludedTaskGroupIds] = useState<Array<TaskGroup['id']>>([]);

    // Compute excluded taskGroup ids if "selectAll" mode is active.
    const onSelect = useCallback(
        (taskGroupId: TaskGroup['id'], select: boolean) => {
            if (selectAll) {
                if (!select) {
                    setExcludedTaskGroupIds([...excludedTaskGroupIds, taskGroupId]);
                } else {
                    setExcludedTaskGroupIds((prevExcludedIds) => prevExcludedIds.filter((id) => id !== taskGroupId));
                }
            }
        },
        [excludedTaskGroupIds, selectAll]
    );

    // Handle table full page selection when "selectAll" mode is active. (Full page selection does not trigger table onSelect event)
    const onFullPageSelection = useCallback(
        (mode: 'select' | 'unselect', newSelection: Array<TaskGroup['id']>) => {
            const pageSelection = newSelection.filter((id) => selectableIdsOnCurrentPage.includes(id));
            const excludedIdsOnOtherPages = excludedTaskGroupIds.filter(
                (id) => !selectableIdsOnCurrentPage.includes(id)
            );
            if (mode === 'unselect') {
                setExcludedTaskGroupIds([...selectableIdsOnCurrentPage, ...pageSelection]);
            } else {
                setExcludedTaskGroupIds([...excludedIdsOnOtherPages]);
            }
        },
        [excludedTaskGroupIds, selectableIdsOnCurrentPage]
    );

    const computeRowDisabledProperty = useCallback(
        (tab: ManagementTaskGroupsTabs, taskGroup: TaskGroup) => {
            if (
                taskGroup?.transferStatus === TaskGroupTransferStatus.interGroup &&
                !user?.places?.some((place) => place.id === taskGroup.startingPlace.id)
            ) {
                return !userIsAdmin;
            }

            if (tab === ManagementTaskGroupsTabs.external && taskGroup.status !== TaskGroupStatus.inProgress) {
                return true;
            }

            if (tab === ManagementTaskGroupsTabs.allStatuses) {
                switch (selectionType) {
                    case SelectionType.autoPlanification:
                        return taskGroup.status !== TaskGroupStatus.toPlan;

                    case SelectionType.deletion:
                        return ![TaskGroupStatus.readyToStart, TaskGroupStatus.toPlan].includes(taskGroup.status);

                    default:
                        break;
                }
            }

            return false;
        },
        [selectionType, user?.places, userIsAdmin]
    );

    // Programatically select all taskGroups on table when page change if "selectAll" mode is enabled to make them look checked. User can then unselect taskgroups to fill excluded taskGroupsIds array.
    useEffect(() => {
        if (selectAll) {
            switch (selectionType) {
                case SelectionType.autoPlanification:
                    setSelectedTaskGroupIds(
                        taskGroupList
                            ? taskGroupList.items
                                  .filter(
                                      (taskGroup) =>
                                          taskGroup.status === TaskGroupStatus.toPlan &&
                                          !excludedTaskGroupIds.includes(taskGroup.id)
                                  )
                                  .map((taskGroup) => taskGroup.id)
                            : []
                    );
                    break;
                case SelectionType.deletion:
                    setSelectedTaskGroupIds(
                        taskGroupList
                            ? taskGroupList.items
                                  .filter(
                                      (taskGroup) =>
                                          [TaskGroupStatus.readyToStart, TaskGroupStatus.toPlan].includes(
                                              taskGroup.status
                                          ) && !excludedTaskGroupIds.includes(taskGroup.id)
                                  )
                                  .map((taskGroup) => taskGroup.id)
                            : []
                    );
                    break;
                case SelectionType.close:
                    setSelectedTaskGroupIds(
                        taskGroupList
                            ? taskGroupList.items
                                  .filter(
                                      (taskGroup) =>
                                          taskGroup.status === TaskGroupStatus.toPlan &&
                                          !excludedTaskGroupIds.includes(taskGroup.id)
                                  )
                                  .map((taskGroup) => taskGroup.id)
                            : []
                    );
                    break;
                default:
                    break;
            }
        }
    }, [computeRowDisabledProperty, excludedTaskGroupIds, selectAll, selectionType, tab, taskGroupList]);

    // Programatically unselect all taskGroups if  tab has changed
    useEffect(() => {
        deleteTaskGroupSelection();
    }, [tab, selectionType]);

    const deleteTaskGroupSelection = () => {
        setSelectedTaskGroupIds([]);
        setExcludedTaskGroupIds([]);
        setSelectAll(false);
    };

    const onRunAutoPlanification = () => {
        runAutoPlanification(
            {
                forAll: selectAll,
                taskGroups: (selectAll ? excludedTaskGroupIds : selectedTaskGroupIds).map((id) => ({ taskGroup: id })),
            },
            {
                onSuccess: (response) => {
                    const proposalIds = response.map((propsal) => propsal.id);
                    autoPlanificationDrawer.show({ proposals: proposalIds });
                    successMessage({ content: formatMessage(taskGroupMessages.autoPlanificationSuccess) });
                    deleteTaskGroupSelection();
                },
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error?.response?.status,
                        }),
                    });
                },
            }
        );
    };

    const onRemoveBatch = useCallback(() => {
        confirmRemoveBatchModal.show().then(() => {
            const statuses = selectAll
                ? {
                      [ManagementTaskGroupsTabs.allStatuses]: [TaskGroupStatus.toPlan, TaskGroupStatus.readyToStart],
                      [ManagementTaskGroupsTabs.toPlan]: [TaskGroupStatus.toPlan],
                      [ManagementTaskGroupsTabs.readyToStart]: [TaskGroupStatus.readyToStart],
                      [ManagementTaskGroupsTabs.external]: [],
                      [ManagementTaskGroupsTabs.transferred]: [],
                      [ManagementTaskGroupsTabs.toClose]: [],
                      [ManagementTaskGroupsTabs.inProgress]: [],
                      [ManagementTaskGroupsTabs.done]: [],
                  }[tab]
                : [];
            removeBatch(
                {
                    forAll: selectAll,
                    taskGroups: (selectAll ? excludedTaskGroupIds : selectedTaskGroupIds).map((id) => id),
                    statuses,
                },
                {
                    onSuccess: () => {
                        successMessage({
                            content: formatMessage(taskGroupMessages.removeBatchSuccess, {
                                count: selectAll
                                    ? totalSelectableTaskGroups - selectedTaskGroupIds.length
                                    : selectedTaskGroupIds.length,
                            }),
                        });
                        deleteTaskGroupSelection();
                    },
                    onError: (error) => {
                        errorMessage({
                            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                status: error?.response?.status,
                            }),
                        });
                    },
                    onSettled: () => {
                        confirmRemoveBatchModal.hide();
                    },
                }
            );
        });
    }, [
        confirmRemoveBatchModal,
        excludedTaskGroupIds,
        formatMessage,
        removeBatch,
        selectAll,
        selectedTaskGroupIds,
        tab,
        totalSelectableTaskGroups,
    ]);

    const onClose = () => {
        userCloseSelectedTaskGroupsModal.show().then(() => {
            manuallyCloseTaskGroup({
                shiftEndedAt: dayjs().toISOString(),
                forAll: selectAll,
                taskGroups: (selectAll ? excludedTaskGroupIds : selectedTaskGroupIds).map((id) => ({ id })),
            }).then(() => {
                successMessage({ content: formatMessage(taskGroupMessages.closeSelectedOTSuccessMessage) });
                deleteTaskGroupSelection();
                userCloseSelectedTaskGroupsModal.hide();
            });
        });
    };

    const totalTaskGroupCountAccordingSelectionType = useMemo(() => {
        switch (selectionType) {
            case SelectionType.autoPlanification:
                return taskGroupListToPlan?.totalCount ?? Infinity;
            case SelectionType.deletion:
                switch (tab) {
                    case ManagementTaskGroupsTabs.allStatuses:
                        return (
                            (taskGroupListToPlan?.totalCount ?? Infinity) +
                            (taskGroupListReadyToStart?.totalCount ?? Infinity)
                        );
                    case ManagementTaskGroupsTabs.toPlan:
                        return taskGroupListToPlan?.totalCount ?? Infinity;
                    case ManagementTaskGroupsTabs.readyToStart:
                        return taskGroupListReadyToStart?.totalCount ?? Infinity;
                    default:
                        return 0;
                }
            case SelectionType.close:
                return taskGroupListExternalInProgress?.totalCount ?? Infinity;
            default:
                return Infinity;
        }
    }, [
        selectionType,
        taskGroupListToPlan?.totalCount,
        tab,
        taskGroupListReadyToStart?.totalCount,
        taskGroupListExternalInProgress?.totalCount,
    ]);

    const rowSelection = useMemo<TableProps<TaskGroup>['rowSelection']>(
        () =>
            canAutoPlan || (canPlan && tab === ManagementTaskGroupsTabs.external) || canDelete
                ? {
                      onSelect: (taskGroup, select) => {
                          onSelect(taskGroup.id, select);
                      },
                      columnWidth: 45,
                      preserveSelectedRowKeys: true,
                      onChange: (selectedRowKeys, _, info) => {
                          if (selectAll && info.type === 'all') {
                              onFullPageSelection(
                                  selectedRowKeys.length < selectedTaskGroupIds.length ? 'unselect' : 'select',
                                  selectedRowKeys as Array<TaskGroup['id']>
                              );
                          }
                          setSelectedTaskGroupIds(selectedRowKeys as Array<TaskGroup['id']>);
                      },
                      selectedRowKeys: selectedTaskGroupIds || [],
                      getCheckboxProps: (taskGroup) => ({
                          disabled: computeRowDisabledProperty(tab, taskGroup),
                      }),
                  }
                : undefined,
        [
            canAutoPlan,
            canDelete,
            canPlan,
            computeRowDisabledProperty,
            onFullPageSelection,
            onSelect,
            selectAll,
            selectedTaskGroupIds,
            tab,
        ]
    );

    return (
        <>
            <Seo title={pageTitle} />

            <CalendarTaskGroup id={CalendarTaskGroupId} />

            <div className="flex justify-between items-center mb-6">
                <ListTitle className="mb-0 uppercase">{pageTitle}</ListTitle>

                <div className="flex gap-2">
                    <Button icon={<CalendarOutlined />} onClick={onCalendarButtonClick} type="primary">
                        {formatMessage(taskGroupMessages.showCalendarButton)}
                    </Button>
                    {canImportFile && (
                        <Button className="text-blue" icon={<UploadOutlined />} onClick={onImportFileButtonClick}>
                            {formatMessage(taskGroupMessages.importFileButton)}
                        </Button>
                    )}
                </div>
            </div>

            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Tabs onChange={onChangeTab} defaultActiveKey={tab}>
                    {Object.values(ManagementTaskGroupsTabs).map((currentTab) => {
                        const badgeColor =
                            badgeCountPerStatus[currentTab]?.totalCount === 0
                                ? '#BFBFBF'
                                : tab === currentTab
                                ? '#e5322c'
                                : '#16a1b8';
                        return (
                            <Tabs.TabPane
                                tab={
                                    <div className="flex justify-between items-center gap-1">
                                        <span className="block">
                                            {formatMessage(taskGroupMessages[currentTab], { unitDisplay: 'long' })}
                                        </span>
                                        <Badge
                                            count={badgeCountPerStatus[currentTab]?.totalCount}
                                            showZero
                                            overflowCount={999999}
                                            style={{ backgroundColor: badgeColor }}
                                        />
                                    </div>
                                }
                                disabled={badgeCountPerStatus[currentTab]?.totalCount === 0}
                                key={currentTab}
                            >
                                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                                    <div className="flex justiy-between items-center gap-2">
                                        <DatePicker.RangePicker
                                            format="DD/MM/YYYY"
                                            onChange={onChangeDateRange}
                                            size="small"
                                            style={{ width: '100%' }}
                                            defaultValue={fromDate && toDate ? [dayjs(fromDate), dayjs(toDate)] : null}
                                        />
                                        <CustomerSelect
                                            maxTagCount="responsive"
                                            mode="multiple"
                                            onChange={filterCustomers}
                                            size="small"
                                        />
                                        <Input
                                            placeholder={formatMessage({
                                                id: 'management_ot.search_field.placeholder',
                                                defaultMessage: 'Rechercher un n° d’OT, un chauffeur…',
                                                description: 'Management OT page search field placeholder',
                                            })}
                                            onChange={onSearch}
                                            defaultValue={search}
                                            prefix={<Search className="text-primary text-base leading-4" />}
                                            size="small"
                                            allowClear
                                        />
                                    </div>

                                    <Table<TaskGroup>
                                        rowKey="id"
                                        columns={columns}
                                        loading={isFetching || isLoading}
                                        dataSource={taskGroupList?.items}
                                        pagination={{
                                            total: taskGroupList?.totalCount,
                                            current: page + 1,
                                            pageSize: taskGroupList?.pageSize,
                                            position: ['bottomCenter'],
                                            showSizeChanger: false,
                                        }}
                                        scroll={{
                                            x: 1440,
                                            y: `calc(100vh - ${(taskGroupList?.pageCount ?? 0) > 0 ? 421 : 357}px)`,
                                            scrollToFirstRowOnChange: true,
                                        }}
                                        size="middle"
                                        onChange={onTableChange}
                                        rowSelection={rowSelection}
                                        footer={
                                            selectAll || selectedTaskGroupIds?.length
                                                ? () => (
                                                      <div className="flex justify-between items-center pl-3 pr-1">
                                                          <Space direction="horizontal" size={24}>
                                                              <Typography.Text className="text-base text-blue font-semibold">
                                                                  <FormattedMessage
                                                                      id="management_ot.task_group_selection_footer.selected_count"
                                                                      defaultMessage="{count, plural, one {{count, number} OT sélectionné} other {{count, number} OT sélectionnés}}"
                                                                      values={{
                                                                          count: selectAll
                                                                              ? totalSelectableTaskGroups -
                                                                                excludedTaskGroupIds.length
                                                                              : selectedTaskGroupIds?.length,
                                                                          currentPageFullySelected,
                                                                      }}
                                                                  />
                                                                  {(selectAll || currentPageFullySelected) && (
                                                                      <FormattedMessage
                                                                          id="management_ot.task_group_selection_footer.on_this_page"
                                                                          defaultMessage="{taskGroupAreSelectedOnOtherPage, select, true { ({taskGroupSelectedOnthisPage,number} sur cette page)} other{ sur cette page}}"
                                                                          values={{
                                                                              taskGroupSelectedOnthisPage:
                                                                                  selectedTaskGroupIds.filter(
                                                                                      (taskGroupId) =>
                                                                                          selectableIdsOnCurrentPage.includes(
                                                                                              taskGroupId
                                                                                          )
                                                                                  ).length,
                                                                              taskGroupAreSelectedOnOtherPage:
                                                                                  taskGroupAreSelectedOnOtherPage ||
                                                                                  selectAll,
                                                                          }}
                                                                      />
                                                                  )}
                                                              </Typography.Text>
                                                              {selectAll ? (
                                                                  <Typography.Text
                                                                      onClick={deleteTaskGroupSelection}
                                                                      className="text-base text-blue font-semibold text-orange cursor-pointer"
                                                                  >
                                                                      <FormattedMessage
                                                                          id="management_ot.task_group_selection_footer.delete_selection"
                                                                          defaultMessage="Effacer la sélection"
                                                                      />
                                                                  </Typography.Text>
                                                              ) : (
                                                                  <>
                                                                      {currentPageFullySelected &&
                                                                          selectedTaskGroupIds.length <
                                                                              totalTaskGroupCountAccordingSelectionType && (
                                                                              <Typography.Text
                                                                                  onClick={() => {
                                                                                      setSelectAll(true);
                                                                                  }}
                                                                                  className="text-base text-blue font-semibold text-orange cursor-pointer"
                                                                              >
                                                                                  {selectionType ===
                                                                                      SelectionType.autoPlanification && (
                                                                                      <FormattedMessage
                                                                                          id="management_ot.task_group_selection_footer.select_remaining_task_group_toPlan"
                                                                                          defaultMessage='Sélectionner les {count, number} OT "A planifier" restants'
                                                                                          values={{
                                                                                              count:
                                                                                                  totalTaskGroupCountAccordingSelectionType -
                                                                                                  selectedTaskGroupIds.length,
                                                                                          }}
                                                                                      />
                                                                                  )}
                                                                                  {selectionType ===
                                                                                      SelectionType.deletion && (
                                                                                      <FormattedMessage
                                                                                          id="management_ot.task_group_selection_footer.select_remaining_task_group"
                                                                                          defaultMessage="Sélectionner les {count, number} OT restants"
                                                                                          values={{
                                                                                              count:
                                                                                                  totalTaskGroupCountAccordingSelectionType -
                                                                                                  selectedTaskGroupIds.length,
                                                                                          }}
                                                                                      />
                                                                                  )}
                                                                                  {selectionType ===
                                                                                      SelectionType.close && (
                                                                                      <FormattedMessage
                                                                                          id="management_ot.task_group_selection_footer.select_remaining_external_task_group_in_progress"
                                                                                          defaultMessage='Sélectionner les {count, number} OT externes "En cours" restants'
                                                                                          values={{
                                                                                              count:
                                                                                                  totalTaskGroupCountAccordingSelectionType -
                                                                                                  selectedTaskGroupIds.length,
                                                                                          }}
                                                                                      />
                                                                                  )}
                                                                              </Typography.Text>
                                                                          )}
                                                                  </>
                                                              )}
                                                          </Space>
                                                          {selectionType === SelectionType.close ? (
                                                              <>
                                                                  <Button
                                                                      type="primary"
                                                                      onClick={onClose}
                                                                      icon={
                                                                          <HiClipboardCheck
                                                                              stroke="#00467f"
                                                                              fill="currentColor"
                                                                              className="text-base"
                                                                          />
                                                                      }
                                                                  >
                                                                      <FormattedMessage
                                                                          id="management_ot.close"
                                                                          defaultMessage="Clôturer"
                                                                          tagName="span"
                                                                      />
                                                                  </Button>
                                                                  <ConfirmationModal
                                                                      maskClosable={!manuallyCloseTaskGroupInProgess}
                                                                      title={formatMessage(
                                                                          taskGroupMessages.closeSelectedOTModalTitle
                                                                      )}
                                                                      text={formatMessage(
                                                                          taskGroupMessages.closeSelectedOTModalContent
                                                                      )}
                                                                      id={closeSelectedTaskGroupsModal}
                                                                      isLoading={manuallyCloseTaskGroupInProgess}
                                                                  />
                                                              </>
                                                          ) : (
                                                              <Space direction="horizontal">
                                                                  {canDelete &&
                                                                      selectionType === SelectionType.deletion && (
                                                                          <Button
                                                                              type="primary"
                                                                              onClick={onRemoveBatch}
                                                                              loading={autoPlanificationInProgress}
                                                                              icon={<Trash className="text-base" />}
                                                                          >
                                                                              {formatMessage(genericMessages.delete)}
                                                                          </Button>
                                                                      )}
                                                                  {canAutoPlan &&
                                                                      selectionType ===
                                                                          SelectionType.autoPlanification && (
                                                                          <Button
                                                                              type="primary"
                                                                              onClick={onRunAutoPlanification}
                                                                              loading={autoPlanificationInProgress}
                                                                              icon={
                                                                                  <TbClipboardList
                                                                                      stroke="#00467f"
                                                                                      fill="currentColor"
                                                                                      className="text-base"
                                                                                  />
                                                                              }
                                                                          >
                                                                              <FormattedMessage
                                                                                  id="management_ot.auto_plan"
                                                                                  defaultMessage="Planifier automatiquement"
                                                                                  tagName="span"
                                                                              />
                                                                          </Button>
                                                                      )}
                                                              </Space>
                                                          )}
                                                      </div>
                                                  )
                                                : undefined
                                        }
                                    />
                                </Space>
                            </Tabs.TabPane>
                        );
                    })}
                </Tabs>
            </Space>
            <ConfirmationModal
                id={confirmRemoveBatchModalId}
                title={formatMessage(taskGroupMessages.removeBatchConfirmationModalTitle)}
                text={formatMessage(taskGroupMessages.removeBatchConfirmationModalText, {
                    count: selectAll
                        ? totalSelectableTaskGroups - selectedTaskGroupIds.length
                        : selectedTaskGroupIds.length,
                })}
                isLoading={isRemoveBatchLoading}
                width={464}
            />
        </>
    );
};

export default ManagementTaskGroups;
