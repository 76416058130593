/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.667 10.667h1-1Zm0-5.333h-1 1Zm2.862-.472.707-.707-.707.707Zm2.276 2.276.707-.707-.707.707ZM2.667 4.334H8v-2H2.667v2Zm5-.334v6.667h2V4h-2ZM3 10.667V4H1v6.667h2Zm.333-.333h-.666v2h.666v-2Zm4.667 0H6v2h2v-2Zm-7 .333c0 .92.746 1.667 1.667 1.667v-2c.184 0 .333.149.333.333H1Zm6.667 0c0-.184.149-.333.333-.333v2c.92 0 1.667-.747 1.667-1.667h-2ZM8 4.334A.333.333 0 0 1 7.667 4h2c0-.92-.747-1.667-1.667-1.667v2Zm-5.333-2C1.747 2.333 1 3.08 1 4h2c0 .184-.15.333-.333.333v-2Zm7 8.333V5.334h-2v5.333h2Zm-.334-5h1.724v-2H9.333v2ZM13 7.61v3.057h2V7.61h-2Zm-2.178-2.04 2.276 2.275 1.414-1.414-2.276-2.276-1.415 1.414ZM10 10.333h-.667v2H10v-2Zm3.333 0h-.666v2h.666v-2ZM15 7.61c0-.442-.176-.866-.488-1.179l-1.414 1.414A.333.333 0 0 1 13 7.61h2Zm-3.943-1.943a.333.333 0 0 1-.236-.098l1.415-1.414a1.667 1.667 0 0 0-1.179-.488v2Zm-3.39 5c0 .92.746 1.667 1.666 1.667v-2c.184 0 .334.149.334.333h-2Zm5.333 0c0-.184.15-.333.333-.333v2c.92 0 1.667-.747 1.667-1.667h-2ZM9.667 5.334c0 .184-.15.333-.334.333v-2c-.92 0-1.666.746-1.666 1.667h2Zm-4.667 6c0 .184-.15.333-.333.333v2A2.333 2.333 0 0 0 7 11.334H5Zm-.333.333a.333.333 0 0 1-.334-.333h-2a2.333 2.333 0 0 0 2.334 2.333v-2Zm-.334-.333c0-.185.15-.334.334-.334V9a2.333 2.333 0 0 0-2.334 2.334h2ZM4.667 11c.184 0 .333.15.333.334h2A2.333 2.333 0 0 0 4.667 9v2Zm7 .334c0 .184-.15.333-.334.333v2a2.333 2.333 0 0 0 2.334-2.333h-2Zm-.334.333a.333.333 0 0 1-.333-.333H9a2.333 2.333 0 0 0 2.333 2.333v-2ZM11 11.334c0-.185.15-.334.333-.334V9A2.333 2.333 0 0 0 9 11.334h2Zm.333-.334c.184 0 .334.15.334.334h2A2.333 2.333 0 0 0 11.333 9v2Z"
            fill="#5C758A"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgTruck: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-truck', props.className)} />
));
export default SvgTruck;
