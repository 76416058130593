/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.03 10.136a.75.75 0 0 0-1.06 1.061l1.06-1.06Zm1.137 2.197-.53.53a.75.75 0 0 0 1.06 0l-.53-.53ZM10.03 9.53a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm2.053-4.697v10h1.5v-10h-1.5Zm-.916 10.917H2.833v1.5h8.334v-1.5Zm-9.25-.917v-10h-1.5v10h1.5Zm.916-10.916H4.5v-1.5H2.833v1.5Zm6.667 0h1.667v-1.5H9.5v1.5ZM2.833 15.75a.917.917 0 0 1-.916-.917h-1.5a2.417 2.417 0 0 0 2.416 2.417v-1.5Zm9.25-.917c0 .507-.41.917-.916.917v1.5a2.417 2.417 0 0 0 2.416-2.417h-1.5Zm1.5-10a2.417 2.417 0 0 0-2.416-2.416v1.5c.506 0 .916.41.916.916h1.5Zm-11.666 0c0-.506.41-.916.916-.916v-1.5A2.417 2.417 0 0 0 .417 4.833h1.5Zm2.053 6.364 1.666 1.667 1.061-1.061-1.667-1.667-1.06 1.061Zm2.727 1.667L10.03 9.53 8.97 8.47l-3.334 3.333 1.061 1.06ZM6.167 2.25h1.666V.75H6.167v1.5Zm1.666 1.833H6.167v1.5h1.666v-1.5Zm-1.666 0a.917.917 0 0 1-.917-.916h-1.5a2.417 2.417 0 0 0 2.417 2.416v-1.5Zm2.583-.916c0 .506-.41.916-.917.916v1.5a2.417 2.417 0 0 0 2.417-2.416h-1.5Zm-.917-.917c.507 0 .917.41.917.917h1.5A2.417 2.417 0 0 0 7.833.75v1.5ZM6.167.75A2.417 2.417 0 0 0 3.75 3.167h1.5c0-.507.41-.917.917-.917V.75Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgDocumentCheckOutlined: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-document-check-outlined', props.className)} />
));
export default SvgDocumentCheckOutlined;
