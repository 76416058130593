import { Button, Divider, Form, Switch } from 'antd';
import { HiOutlineCalendar } from 'react-icons/hi';
import { FormattedDate, FormattedMessage, MessageDescriptor } from 'react-intl';

import { classNames } from '../../helpers';
import DatePicker from '../DatePicker';
import { Trash } from '../icons';
import { sectionCardFormItemLayout } from '../SectionCard';
import TimePicker from '../TimePicker';

interface ClosedDaysFormFieldsProps {
    name: string;
    switchName: string;
    shouldShowHours: (value?: boolean) => boolean;
    hoursMessage: MessageDescriptor;
    datePickerLabel: string;
    unCheckedLabel: string;
    errorLabel: string;
    emptyLabel: string;
}

const ClosedDaysFormFields = ({
    name,
    switchName,
    shouldShowHours,
    hoursMessage,
    datePickerLabel,
    unCheckedLabel,
    errorLabel,
    emptyLabel,
}: ClosedDaysFormFieldsProps) => {
    const form = Form.useFormInstance();

    return (
        <>
            <Form.Item label={datePickerLabel} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <DatePicker
                    onChange={(value) => {
                        if (value) {
                            form.setFieldsValue({
                                [name]: [
                                    ...(form.getFieldValue(name) || []),
                                    {
                                        [switchName]: shouldShowHours(false),
                                        fromDate: value?.startOf('day')?.hour(6),
                                        toDate: value?.startOf('day')?.hour(20),
                                    },
                                ],
                            });
                        }
                    }}
                    suffixIcon={<HiOutlineCalendar />}
                    format="DD/MM/YYYY"
                />
            </Form.Item>
            <Divider />
            <Form.Item shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue([name])?.length > 0 ? (
                        <Form.List name={[name]}>
                            {(fields, { remove }, { errors }) => (
                                <>
                                    {fields.map(({ key, name: subName }, fieldIndex) => (
                                        <Form.Item
                                            key={key}
                                            label={
                                                <FormattedDate
                                                    value={form.getFieldValue([name, subName, 'fromDate'])?.toDate()}
                                                />
                                            }
                                            {...sectionCardFormItemLayout}
                                            wrapperCol={{ span: 16 }}
                                            className={classNames(fields.length === fieldIndex + 1 && 'mb-0')}
                                        >
                                            <div className="flex items-center space-x-3">
                                                <Form.Item
                                                    name={[subName, switchName]}
                                                    valuePropName="checked"
                                                    normalize={shouldShowHours}
                                                    noStyle
                                                >
                                                    <Switch />
                                                </Form.Item>
                                                <Form.Item dependencies={[name, subName, switchName]} noStyle>
                                                    {({ getFieldValue }) =>
                                                        shouldShowHours(getFieldValue([name, subName, switchName])) ? (
                                                            <span>{unCheckedLabel}</span>
                                                        ) : (
                                                            <FormattedMessage
                                                                tagName="span"
                                                                {...hoursMessage}
                                                                values={{
                                                                    from: (
                                                                        <Form.Item
                                                                            name={[subName, 'fromDate']}
                                                                            dependencies={[[name, subName, 'toDate']]}
                                                                            trigger="onSelect"
                                                                            noStyle
                                                                        >
                                                                            <TimePicker
                                                                                format="HH:mm"
                                                                                size="small"
                                                                                minuteStep={5}
                                                                                style={{ margin: '0 4px' }}
                                                                            />
                                                                        </Form.Item>
                                                                    ),
                                                                    to: (
                                                                        <Form.Item
                                                                            name={[subName, 'toDate']}
                                                                            dependencies={[[name, subName, 'fromDate']]}
                                                                            trigger="onSelect"
                                                                            rules={[
                                                                                ({ getFieldValue }) => ({
                                                                                    async validator(_, value) {
                                                                                        if (
                                                                                            !value ||
                                                                                            getFieldValue([
                                                                                                name,
                                                                                                subName,
                                                                                                'fromDate',
                                                                                            ]) < value
                                                                                        ) {
                                                                                            return await Promise.resolve();
                                                                                        }
                                                                                        return await Promise.reject(
                                                                                            new Error(errorLabel)
                                                                                        );
                                                                                    },
                                                                                }),
                                                                            ]}
                                                                            noStyle
                                                                        >
                                                                            <TimePicker
                                                                                format="HH:mm"
                                                                                size="small"
                                                                                minuteStep={5}
                                                                                style={{ margin: '0 4px' }}
                                                                            />
                                                                        </Form.Item>
                                                                    ),
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </Form.Item>
                                                <Button type="text" icon={<Trash />} onClick={() => remove(subName)} />
                                            </div>
                                        </Form.Item>
                                    ))}
                                    <div>
                                        <Form.ErrorList errors={errors} />
                                    </div>
                                </>
                            )}
                        </Form.List>
                    ) : (
                        emptyLabel
                    )
                }
            </Form.Item>
        </>
    );
};

export default ClosedDaysFormFields;
