/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM4.833 5a4.167 4.167 0 1 1 8.334 0 4.167 4.167 0 0 1-8.334 0ZM.667 18.333a8.333 8.333 0 0 1 16.666 0 .833.833 0 1 1-1.666 0 6.667 6.667 0 0 0-13.334 0 .833.833 0 1 1-1.666 0Zm11.422-3.922a.833.833 0 0 1 0 1.178l-3.333 3.334a.833.833 0 0 1-1.179 0l-1.666-1.667a.833.833 0 1 1 1.178-1.179l1.078 1.078 2.744-2.744a.833.833 0 0 1 1.178 0Z"
            fill="currentcolor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgUserCheck: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-user-check', props.className)} />
));
export default SvgUserCheck;
