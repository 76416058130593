import { useState, FC } from 'react';
import Input from 'antd/lib/input';
import { PasswordProps } from 'antd/lib/input/Password';
import { CheckCircleFilled, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import validatePasswordRules, { PasswordIssueReason } from '../helpers/passwords';
import BasicList from './BasicList';
import { classNames } from '../helpers';
import constants from '../config/constants';

interface PasswordInputProps extends PasswordProps {
    value?: string;
}

const PasswordInput: FC<PasswordInputProps> = ({ value, onChange, ...props }) => {
    const [val, setVal] = useState(value ?? '');
    const [errors, setErrors] = useState<ReturnType<typeof validatePasswordRules>>();
    const onChangeInput: PasswordProps['onChange'] = (e) => {
        const inputValue = e.target.value;
        setVal(inputValue);
        setErrors(validatePasswordRules(inputValue));
        if (typeof onChange === 'function') {
            onChange(e);
        }
    };

    return (
        <>
            <Input.Password
                {...props}
                onChange={onChangeInput}
                iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
                className="mb-8"
            />
            <BasicList className="password-input-instructions text-left mb-4">
                <li
                    className={classNames(
                        !!val &&
                            !errors?.issues.find(
                                (issue) =>
                                    issue.reason === PasswordIssueReason.minimumLength ||
                                    issue.reason === PasswordIssueReason.maximumLength
                            ) &&
                            'is-valid'
                    )}
                >
                    <CheckCircleFilled />
                    {constants.PASSWORD_MINIMUM_LENGTH} caractères minimum
                </li>
                <li
                    className={classNames(
                        !!val &&
                            !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireNumber) &&
                            'is-valid'
                    )}
                >
                    <CheckCircleFilled />
                    Au moins un chiffre
                </li>
                <li
                    className={classNames(
                        !!val &&
                            !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireCapital) &&
                            'is-valid'
                    )}
                >
                    <CheckCircleFilled />
                    Au moins une lettre en majuscule
                </li>
                <li
                    className={classNames(
                        !!val &&
                            !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireLower) &&
                            'is-valid'
                    )}
                >
                    <CheckCircleFilled />
                    Au moins une lettre en minuscule
                </li>
                <li
                    className={classNames(
                        !!val &&
                            !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireSpecial) &&
                            'is-valid'
                    )}
                >
                    <CheckCircleFilled />
                    Au moins un caractère spécial
                </li>
            </BasicList>
        </>
    );
};

export default PasswordInput;
