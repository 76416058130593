import { VFC } from 'react';
import dayjs from 'dayjs';
import { Button, Form, InputNumber } from 'antd';
import { useIntl } from 'react-intl';

import { Customer, CustomerForecast } from '../../../queries/api/types';
import formMessages from '../../../i18n/formMessages';
import genericMessages from '../../../i18n/genericMessages';

export interface ForecastsListForm extends Omit<CustomerForecast, 'id'> {}

interface ForecastsListFormModalProps {
    customer: Customer;
    date: string;
    formInitialValues: ForecastsListForm;
    handleCloseModal: (arg: unknown) => void;
    handleSubmit: (arg: ForecastsListForm) => void;
}

const ForecastsListFormModal: VFC<ForecastsListFormModalProps> = ({
    customer,
    date,
    formInitialValues,
    handleCloseModal,
    handleSubmit,
}) => {
    const { formatMessage } = useIntl();

    return (
        <Form initialValues={formInitialValues} onFinish={handleSubmit}>
            <label htmlFor="capacity" className="block text-base text-black font-medium mb-2">
                OT réalisés pour <span className="font-bold">{customer.name}</span> le{' '}
                <span className="font-bold">{dayjs(date).format('DD/MM/YYYY')}</span>
            </label>

            <Form.Item name="actualCount" noStyle>
                <InputNumber
                    addonAfter="OT"
                    min={0}
                    placeholder={formatMessage(genericMessages.numberSelect)}
                    className="w-full"
                />
            </Form.Item>

            <span className="block text-sm font-medium text-faded-blue mt-1">
                Forecast: {formInitialValues.capacity}
                {formInitialValues.tolerance && <span> ({formInitialValues.tolerance}%)</span>}
            </span>
            <div className="flex justify-end gap-2 mt-8">
                <Button ghost onClick={handleCloseModal} type="primary">
                    {formatMessage(formMessages.cancel)}
                </Button>

                <Button htmlType="submit" type="primary">
                    {formatMessage(formMessages.validate)}
                </Button>
            </div>
        </Form>
    );
};

export default ForecastsListFormModal;
