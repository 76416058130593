import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    TransporterCreatePayload,
    TransporterIdPayload,
    TransporterListPayload,
    TransporterListResponse,
    TransporterUpdatePayload,
    remove,
    update,
    exportTransporters,
} from './api/transporters';
import { Transporter } from './api/types';

export const transportersKeys = {
    all: ['transporters'],
    lists: () => [...transportersKeys.all, 'list'],
    list: (params?: TransporterListPayload) => [...transportersKeys.lists(), params],
    details: () => [...transportersKeys.all, 'details'],
    detail: (id?: TransporterIdPayload) => [...transportersKeys.details(), id],
    export: () => [...transportersKeys.all, 'export'],
};

export const useTransporterList = <TData = TransporterListResponse>(
    params?: TransporterListPayload,
    options?: UseQueryOptions<TransporterListResponse, AxiosError, TData>
) => {
    return useQuery<TransporterListResponse, AxiosError, TData>(
        transportersKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useTransporterDetails = <TData = Transporter>(
    id?: TransporterIdPayload,
    options?: UseQueryOptions<Transporter, AxiosError, TData>
) => {
    return useQuery<Transporter, AxiosError, TData>(
        transportersKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useTransporterCreate = (
    options?: UseMutationOptions<Transporter, AxiosError, TransporterCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Transporter, AxiosError, TransporterCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(transportersKeys.lists());
        },
    });
};

export const useTransporterUpdate = (
    options?: UseMutationOptions<Transporter, AxiosError, TransporterUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Transporter, AxiosError, TransporterUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(transportersKeys.detail(variables.id));
        },
    });
};

export const useTransporterRemove = (options?: UseMutationOptions<undefined, AxiosError, TransporterIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, TransporterIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(transportersKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(transportersKeys.lists());
        },
    });
};

export const useExportTransporters = <TData = undefined>(options?: UseQueryOptions<undefined, AxiosError, TData>) => {
    return useQuery<undefined, AxiosError, TData>(
        transportersKeys.export(),
        async () => await exportTransporters(),
        options
    );
};
