/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.267 1.003c.77-1.335 2.695-1.335 3.465 0L13.635 9.5c.77 1.333-.193 3-1.732 3H2.097c-1.54 0-2.502-1.667-1.733-3l4.903-8.498ZM7 4.5a.5.5 0 0 1 .5.5v2.5a.5.5 0 1 1-1 0V5a.5.5 0 0 1 .5-.5ZM7 10a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
            fill="#E5322C"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgWarning: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-warning', props.className)} />
));
export default SvgWarning;
