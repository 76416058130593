import { useMemo, VFC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, FormProps, Input, Space } from 'antd';

import { OperatorUpdatePayload } from '../../../queries/api/operators';
import { useValueListItemList } from '../../../queries/valueListItems';
import { useOperatorCreate, useOperatorDetails, useOperatorUpdate } from '../../../queries/operators';
import genericMessages from '../../../i18n/genericMessages';
import Seo from '../../../components/Seo';
import { Operator, ValueListSlug } from '../../../queries/api/types';
import { getRoute, RoutePathName } from '../../../routes';
import ApiResult from '../../../components/ApiResult';
import DetailsFormCard from '../../../components/form/DetailsFormCard';
import DetailsFormEditableTitle from '../../../components/form/DetailsFormEditableTitle';
import StatusSwitch from '../../../components/form/StatusSwitch';
import {
    formatOperatorCreatePayload,
    formatOperatorInitialValues,
    formatOperatorUpdatePayload,
    getOperatorFormSections,
    OperatorForm,
} from '.';
import operatorMessages from '../../../i18n/operatorMessages';
import { formError } from '../../../helpers/form';
import { successMessage } from '../../../helpers/message';
import ListTitle from '../../../components/ListTitle';
import { Check } from '../../../components/icons';
import formMessages from '../../../i18n/formMessages';

const OperatorDetails: VFC = () => {
    const { operatorId } = useParams<{ operatorId?: Operator['id'] }>();
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const isCreating = !operatorId;
    const { data: operatorDetails, isError, error } = useOperatorDetails(operatorId, { enabled: !!operatorId });
    const { mutate: createOperator, isLoading: isLoadingCreating } = useOperatorCreate();
    const pageTitle = !operatorId
        ? formatMessage(operatorMessages.createPageTitle)
        : operatorDetails?.reference || formatMessage(genericMessages.editing);
    const onCreateSubmit: FormProps['onFinish'] = (values) => {
        const payload = formatOperatorCreatePayload(values);
        createOperator(payload, {
            onSuccess: () => {
                successMessage({
                    content: formatMessage(operatorMessages.createSuccessMessage),
                });
                history.push(getRoute(RoutePathName.operators));
            },
            onError: (error) => formError({ error, form, formatMessage }),
        });
    };

    const { data: valueListItemList } = useValueListItemList(
        { valueListSlug: ValueListSlug.idlEntities },
        {
            onSuccess: (data) => {
                form.setFieldsValue({
                    attachedTo: data?.items?.find((item) => item.fields.entityReference === 'idl')?.id,
                });
            },
        }
    );
    const idlEntityId = valueListItemList?.items?.find((item) => item.fields.entityReference === 'idl')?.id;

    const sections = useMemo(() => getOperatorFormSections(formatMessage, idlEntityId), [formatMessage, idlEntityId]);
    const sectionCards = useMemo(
        () =>
            sections.map((section) => (
                <DetailsFormCard<Operator, OperatorForm, OperatorUpdatePayload>
                    {...section}
                    key={section.title}
                    id={operatorId}
                    detailsQueryHandler={useOperatorDetails}
                    updateQueryHandler={useOperatorUpdate}
                    initialValueFormatter={formatOperatorInitialValues}
                    formatPayload={(values) => formatOperatorUpdatePayload(values, operatorDetails)}
                />
            )),
        [operatorId, operatorDetails, sections]
    );

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                {isCreating ? (
                    <ListTitle className="mb-0 uppercase" backRoute={getRoute(RoutePathName.operators)}>
                        {pageTitle}
                    </ListTitle>
                ) : (
                    <>
                        <DetailsFormEditableTitle
                            backRoute={getRoute(RoutePathName.operators)}
                            detailsQueryHandler={useOperatorDetails}
                            updateQueryHandler={useOperatorUpdate}
                            placeholder={formatMessage(operatorMessages.reference)}
                            id={operatorId}
                            name="reference"
                        />
                        <StatusSwitch
                            detailsQueryHandler={useOperatorDetails}
                            updateQueryHandler={useOperatorUpdate}
                            id={operatorId}
                        />
                    </>
                )}
            </div>

            {isError ? (
                <ApiResult status={error.response?.status} />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    {isCreating ? (
                        <Form
                            form={form}
                            onFinish={onCreateSubmit}
                            initialValues={formatOperatorInitialValues(operatorDetails)}
                            scrollToFirstError
                        >
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                <Form.Item name="reference" className="mb-0">
                                    <Input placeholder={formatMessage(operatorMessages.reference)} />
                                </Form.Item>
                                {sectionCards}
                                <div className="flex justify-center">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        icon={<Check />}
                                        size="large"
                                        loading={isLoadingCreating}
                                    >
                                        <FormattedMessage {...formMessages.saveData} tagName="span" />
                                    </Button>
                                </div>
                            </Space>
                        </Form>
                    ) : (
                        sectionCards
                    )}
                </Space>
            )}
        </>
    );
};

export default OperatorDetails;
