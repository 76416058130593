import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import frLocale from '@fullcalendar/core/locales/fr';
import FullCalendar, {
    CalendarOptions,
    DateRange,
    EventClickArg,
    EventContentArg,
    EventSourceInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import { useLayout } from '../context/LayoutContext';
import CalendarControls from './CalendarControls';

import '../assets/styles/CustomCalendar.less';

interface CustomCalendarProps extends CalendarOptions {
    onDateChange?: (childData: Dayjs) => void;
    onDateSelect?: (date: DateRange) => void;
    events?: EventSourceInput;
    onEventClick?: (arg: EventClickArg) => void;
    renderEventContent?: (arg: EventContentArg) => void;
}

const CustomCalendar: FC<CustomCalendarProps> = ({
    events,
    onDateChange,
    onDateSelect,
    onEventClick,
    renderEventContent,
    ...rest
}) => {
    const { isSidebarCollapsed } = useLayout();
    const [activeDate, setActiveDate] = useState<Dayjs>(dayjs());
    const [calendar, setCalendar] = useState<FullCalendar>();
    const calendarApi = calendar?.getApi();

    useEffect(() => {
        window.setTimeout(() => {
            calendarApi?.updateSize();
        }, 200);
    }, [isSidebarCollapsed, calendarApi, calendar]);

    useEffect(() => {
        if (activeDate) {
            calendarApi?.gotoDate(activeDate.toDate());
        }
    }, [activeDate, calendarApi]);

    const handleChangeDate = (date: Dayjs) => {
        setActiveDate(date);
        if (onDateChange) onDateChange(date);
    };

    return (
        <div className="wrapper-custom-calendar">
            <Row className="mb-6" align="middle">
                <CalendarControls
                    activeDate={activeDate}
                    handleChangeDate={handleChangeDate}
                    mode="month"
                    title={activeDate.format('MMMM YYYY')}
                />
            </Row>
            <Row gutter={16}>
                <Col>
                    <FullCalendar
                        dayMaxEvents
                        editable={false}
                        events={events}
                        eventClick={onEventClick}
                        eventContent={renderEventContent}
                        locale={frLocale}
                        headerToolbar={false}
                        height="auto"
                        navLinks={false}
                        nowIndicator
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        ref={(ref) => setCalendar(ref ?? undefined)}
                        select={onDateSelect}
                        selectable
                        selectMirror
                        slotLabelFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                        }}
                        titleFormat={{
                            month: 'long',
                            year: 'numeric',
                        }}
                        {...rest}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default CustomCalendar;
