import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl';

import genericMessages from '../../i18n/genericMessages';
import { ValueListItem } from '../../queries/api/types';
import BasicList from '../BasicList';

interface WorkingHolidaysDetailsProps<TData> {
    record?: TData;
}

const WorkingHolidaysDetails = <TData extends { workingHolidays: ValueListItem[] }>({
    record,
}: WorkingHolidaysDetailsProps<TData>) =>
    (record?.workingHolidays || []).length > 0 ? (
        <BasicList inline>
            {record?.workingHolidays.map((holiday) => (
                <li key={holiday.id}>
                    <Tag>{holiday.fields.name}</Tag>
                </li>
            ))}
        </BasicList>
    ) : (
        <FormattedMessage {...genericMessages.workingHolidaysEmpty} />
    );

export default WorkingHolidaysDetails;
