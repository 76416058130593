import { VFC } from 'react';
import { Space, Tabs, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import Seo from '../../../components/Seo';
import ListTitle from '../../../components/ListTitle';
import { useValueListList } from '../../../queries/valueLists';
import ApiResult from '../../../components/ApiResult';
import ValueListForm from '../../../components/valueList/ValueListForm';
import genericMessages from '../../../i18n/genericMessages';
import { getRoute, RoutePathName } from '../../../routes';
import VehicleValueListBrandsAndModels from './VehicleValueListBrandsAndModels';
import { ValueListSlug } from '../../../queries/api/types';

const VehiclesValueList: VFC = () => {
    const { formatMessage } = useIntl();
    const { data: valueLists, isLoading, isFetching, isError, error } = useValueListList();
    const items = valueLists?.items?.filter((item) =>
        [ValueListSlug.vehicleTypes, ValueListSlug.vehicleProperties, ValueListSlug.vehicleTransportTypes].includes(
            item.slug as ValueListSlug
        )
    );

    return (
        <>
            <Seo
                title={formatMessage({
                    id: 'vehicles_value_list.title',
                    defaultMessage: 'Listes de valeurs véhicules',
                    description: 'Vehicle value list page title',
                })}
            />
            <div className="flex justify-between items-center mb-6">
                <ListTitle className="mb-0 uppercase" backRoute={getRoute(RoutePathName.vehicles)}>
                    <FormattedMessage {...genericMessages.valuesLists} />
                </ListTitle>
            </div>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Spin spinning={isLoading || isFetching}>
                        <Tabs>
                            {items?.map((valueList) => (
                                <Tabs.TabPane key={valueList.slug} tab={valueList.title}>
                                    <ValueListForm valueListSlug={valueList.slug} queryParamsKey={valueList.slug} />
                                </Tabs.TabPane>
                            ))}
                            <Tabs.TabPane
                                key="brandsAndModels"
                                tab={formatMessage({
                                    id: 'vehicles_value_list.tabs.brands_and_models',
                                    defaultMessage: 'Marques & Modèles',
                                    description: 'Vehicle value list tab title',
                                })}
                            >
                                <VehicleValueListBrandsAndModels />
                            </Tabs.TabPane>
                        </Tabs>
                    </Spin>
                )}
            </Space>
        </>
    );
};

export default VehiclesValueList;
