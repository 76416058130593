import { useState } from 'react';
import { Form, Input, message, Drawer, Select, Button, DrawerProps, Spin } from 'antd';
import { FormProps } from 'antd/lib/form';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';

import { Application } from '../../../queries/api/types';
import { requiredRule } from '../../../helpers';
import { useOrganizationsList } from '../../../queries/organizations';
import { useApplicationCreate, useApplicationDetails, useApplicationUpdate } from '../../../queries/applications';

interface ApplicationFormDrawerProps extends Omit<DrawerProps, 'visible' | 'onClose'>, Record<string, unknown> {
    applicationId?: Application['id'];
}

const ApplicationFormDrawer = NiceModal.create(({ applicationId, ...props }: ApplicationFormDrawerProps) => {
    const modal = useModal();
    const isEditing = applicationId !== undefined;
    const [form] = Form.useForm();
    const [organizationSearch, setOrganizationSearch] = useState<string>();
    const { isLoading: isLoadingDetails } = useApplicationDetails(
        { id: applicationId ?? '' },
        {
            enabled: modal.visible && isEditing,
            onSuccess: (data) => {
                form.setFieldsValue(data);
            },
        }
    );
    const {
        data: organizationsData,
        isLoading,
        isFetching,
    } = useOrganizationsList({
        search: organizationSearch ?? undefined,
    });
    const applicationCreate = useApplicationCreate({
        onError: () => {
            message.error("Une erreur est survenue pendant la création de l'application");
        },
        onSuccess: () => {
            modal.hide();
            message.success("L'application a été créée");
        },
    });
    const applicationUpdate = useApplicationUpdate({
        onError: () => {
            message.error("Une erreur est survenue pendant la mise à jour de l'application");
        },
        onSuccess: () => {
            modal.hide();
            message.success("L'application a été mise à jour");
        },
    });
    const onSubmit: FormProps['onFinish'] = (values) => {
        if (isEditing) {
            applicationUpdate.mutate({ id: applicationId, name: values.name });
        } else {
            applicationCreate.mutate({ organization: values.organization, name: values.name });
        }
    };

    return (
        <>
            <Drawer
                title={`${isEditing ? 'Modification' : 'Création'} d'une application`}
                width={560}
                {...props}
                {...antdDrawer(modal)}
            >
                <Spin spinning={isLoadingDetails}>
                    <Form form={form} onFinish={onSubmit} layout="vertical">
                        <Form.Item label="Nom de l'application" name="name" rules={[requiredRule]}>
                            <Input />
                        </Form.Item>

                        {!isEditing && (
                            <Form.Item label="Organisation" name="organization" rules={[requiredRule]}>
                                <Select
                                    showSearch
                                    placeholder="Sélectionner une organisation"
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    onSearch={setOrganizationSearch}
                                    loading={isLoading || isFetching}
                                >
                                    {organizationsData?.items.map((organization) => (
                                        <Select.Option key={organization.id} value={organization.id}>
                                            {organization.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            loading={isEditing ? applicationUpdate.isLoading : applicationCreate.isLoading}
                            block
                        >
                            {isEditing ? "Modifier l'application" : "Créer l'application"}
                        </Button>
                    </Form>
                </Spin>
            </Drawer>
        </>
    );
});

export default ApplicationFormDrawer;
