import { FC, useCallback, useEffect } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import useQueryParams from '../../hooks/queryParams';
import { AuthorizeType, useAuthorizeMobilic } from '../../queries/mobilic';
import { errorMessage, successMessage } from '../../helpers/message';
import { defaultErrorMessage } from '../../i18n';
import { usePlaceUpdateAdminMobilicToken } from '../../queries/places';
import { getRoute, RoutePathName } from '../../routes';
import usePersistentState from '../../hooks/usePersistentState';
import placeMessages from '../../i18n/placeMessages';

const CodeValidation: FC = () => {
    const { formatMessage } = useIntl();
    const [queryParams] = useQueryParams('code');
    const code = queryParams.get('code');
    const history = useHistory();
    const { mutate: mutateAuthorizeMobilic, isSuccess, isLoading, isError } = useAuthorizeMobilic();
    const { mutate: updatPlaceAdminMobilicToken } = usePlaceUpdateAdminMobilicToken();
    const [updatingPlaceId, setUpdatingPlaceId] = usePersistentState('updatingPlaceId');

    const authorizeMobilic = useCallback(() => {
        if (code && updatingPlaceId) {
            mutateAuthorizeMobilic(
                { code, type: AuthorizeType.place },
                {
                    onSuccess: ({ access_token }) => {
                        updatPlaceAdminMobilicToken(
                            { id: updatingPlaceId, adminMobilicToken: access_token },
                            {
                                onSuccess: () => {
                                    setUpdatingPlaceId();
                                    successMessage({ content: formatMessage(placeMessages.mobilicTokenUpdateSuccess) });
                                    history.push(getRoute(RoutePathName.placeDetails, { placeId: updatingPlaceId }));
                                },
                                onError: () => {
                                    errorMessage({ content: defaultErrorMessage });
                                },
                            }
                        );
                    },
                    onError: () => {
                        setUpdatingPlaceId();
                        errorMessage({ content: defaultErrorMessage });
                        history.push(getRoute(RoutePathName.placeDetails, { placeId: updatingPlaceId }));
                    },
                }
            );
        } else {
            errorMessage({
                content: formatMessage(placeMessages.mobilicTokenUpdateError),
            });
        }
    }, [
        code,
        history,
        mutateAuthorizeMobilic,
        setUpdatingPlaceId,
        updatPlaceAdminMobilicToken,
        updatingPlaceId,
        formatMessage,
    ]);

    useEffect(() => {
        if (!isLoading && !isSuccess && !isError) {
            authorizeMobilic();
        }
    });
    return <Spin spinning={true} />;
};

export default CodeValidation;
