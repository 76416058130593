import React, { VFC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import '../assets/styles/components/ReactPhoneInput2.less';
import { SupportedLanguage } from '../queries/api/types';

type LangImport = { [key in SupportedLanguage]: any };

const availableLangs = ['es', 'de', 'ru', 'fr', 'jp', 'cn', 'pt', 'it', 'ir', 'ar', 'tr', 'id'];

const intlData = availableLangs.reduce<LangImport | Record<string, unknown>>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => await import(`react-phone-input-2/lang/${lang}.json`),
    }),
    {}
);

const PhoneInput: VFC<PhoneInputProps> = (props) => {
    const { locale } = useIntl();
    const [localization, setLocalization] = useState<Record<string, unknown> | undefined>();

    useEffect(() => {
        if (availableLangs.includes(locale)) {
            if (locale === SupportedLanguage.en) {
                setLocalization({});
            } else {
                (async () => {
                    const localeData = await intlData[locale as SupportedLanguage]?.();
                    setLocalization(localeData);
                })();
            }
        }
    }, [locale]);

    return localization ?? locale === SupportedLanguage.en ? (
        <ReactPhoneInput
            {...props}
            countryCodeEditable={false}
            inputClass="ant-input"
            localization={localization}
            preferredCountries={['fr']}
            country={locale === SupportedLanguage.en ? 'gb' : locale}
            specialLabel=""
        />
    ) : null;
};

export default PhoneInput;
