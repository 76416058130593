import { useCallback, useMemo, useState } from 'react';
import { FormattedDate, FormattedTime, useIntl } from 'react-intl';
import NiceModal, { antdDrawer, useModal } from '@ebay/nice-modal-react';
import { Badge, Card, Divider, DrawerProps, Spin, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { EventClickArg, EventContentArg } from '@fullcalendar/react';

import { TaskGroup, TaskGroupStatus } from '../../../queries/api/types';
import { useTaskGroupList } from '../../../queries/taskGroups';
import genericMessages from '../../../i18n/genericMessages';
import taskGroupMessages from '../../../i18n/taskGroupMessages';
import CalendarControls from '../../../components/CalendarControls';
import CustomCalendarTimeline, { FullCalendarTimelineViews } from '../../../components/CustomCalendarTimeline';
import { PencilAlt, Truck, User } from '../../../components/icons';
import CustomDrawer from '../../../components/CustomDrawer';
import PlaceSelect, { PlaceSelectProps } from '../../../components/selects/PlaceSelect';
import TaskGroupDetailsDrawer from './TaskGroupDetailsDrawer';
import CalendarTaskGroupCaption from './CalendarTaskGroupCaption';
import { classNames, getFullName } from '../../../helpers';

const colorsPerStatus = {
    [TaskGroupStatus.readyToStart]: '#202B39', // @dark-blue
    [TaskGroupStatus.toPlan]: '#f97315', // @orange
    [TaskGroupStatus.inProgress]: '#16a1b8', // @duck-blue
    [TaskGroupStatus.done]: '#52c41a', // @green
    [TaskGroupStatus.toClose]: '#e5322c', // @red
};

export interface CalendarTaskGroupProps
    extends Omit<DrawerProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, unknown> {}

const CalendarTaskGroup = NiceModal.create<CalendarTaskGroupProps>(({ ...props }) => {
    const { formatMessage } = useIntl();
    const modal = useModal();
    const [activeDate, setActiveDate] = useState(dayjs());
    const [taskGroups, setTaskGroups] = useState<TaskGroup[]>([]);
    const {
        data: taskGroupsList,
        isFetching,
        isLoading,
    } = useTaskGroupList(
        {
            fromDate: activeDate.startOf('day').format('YYYY-MM-DD'),
            toDate: activeDate.endOf('day').format('YYYY-MM-DD'),
        },
        {
            onSuccess: (data) => setTaskGroups(data.items),
            staleTime: 0,
        }
    );

    const filterPlaces: PlaceSelectProps['onChange'] = (places) => {
        if (!Array.isArray(places)) return;

        if (!taskGroupsList?.items) return;

        const newTaskGroups =
            places?.length > 0
                ? taskGroupsList?.items?.filter((taskGroup) => places.includes(taskGroup.startingPlace.id))
                : taskGroupsList?.items;

        setTaskGroups(newTaskGroups);
    };

    const resourceAreaColumns = [
        {
            group: true,
            field: 'startingPlace',
            headerContent: formatMessage(genericMessages.place, { count: 0 }),
            cellContent: (arg: any) => {
                const findPlace = taskGroups?.find((taskGroup) =>
                    [arg.fieldValue, arg.groupValue].includes(taskGroup.startingPlace.id)
                );
                const placeName = findPlace?.startingPlace.name;
                return <span className="text-base font-semibold text-blue">{placeName}</span>;
            },
        },
        {
            field: 'reference',
            headerContent: formatMessage(genericMessages.taskGroups),
            cellContent: (arg: any) => <span className="text-base font-semibold text-blue">{arg.fieldValue}</span>,
        },
    ];

    const computeEventDate = useCallback((taskGroup: TaskGroup) => {
        switch (taskGroup.status) {
            case TaskGroupStatus.done:
                return { start: taskGroup.shiftStartedAt, end: taskGroup.shiftEndedAt };
            case TaskGroupStatus.inProgress:
                return {
                    start: taskGroup.shiftStartedAt,
                    end: taskGroup.endDate
                        ? dayjs().isAfter(taskGroup.endDate)
                            ? dayjs().toISOString()
                            : taskGroup.endDate
                        : dayjs().toISOString(),
                };
            case TaskGroupStatus.toClose:
                return { start: taskGroup.shiftStartedAt, end: dayjs().toISOString() };
            default:
                return {
                    start: taskGroup.startDate || dayjs(taskGroup.date).startOf('day').toISOString(),
                    end: taskGroup.endDate || dayjs(taskGroup.date).endOf('day').toISOString(),
                };
        }
    }, []);

    const calendarData = useMemo(
        () =>
            taskGroups?.map((taskGroup) => {
                const { start, end } = computeEventDate(taskGroup);
                return {
                    ...taskGroup,
                    id: taskGroup.id,
                    title: taskGroup.startingPlace.name,
                    startingPlace: taskGroup.startingPlace.id,
                    resourceId: taskGroup.id,
                    start,
                    end,
                    backgroundColor: colorsPerStatus[taskGroup.status],
                };
            }),
        [computeEventDate, taskGroups]
    );
    const renderEventContent = ({ event: { extendedProps } }: EventContentArg) => {
        const findPlace = taskGroups?.find((taskGroup) => taskGroup.startingPlace.id === extendedProps.startingPlace);
        const placeName = findPlace?.startingPlace.name;

        const tooltipTitle = [TaskGroupStatus.inProgress, TaskGroupStatus.toClose, TaskGroupStatus.done].includes(
            extendedProps.status
        ) && (
            <Card className="border-0" bodyStyle={{ padding: 0 }}>
                <div className="pt-3 px-4">
                    <span className="block text-black text-sm font-bold">{placeName}</span>
                    <span className="block text-dark-blue text-sm font-medium">Groupe de rattachement</span>
                </div>

                <Divider style={{ marginTop: 12, marginBottom: 0 }} />

                <div className="p-4">
                    {!!extendedProps?.vehicle?.transporter?.id && (
                        <div className="flex justify-start items-center gap-2">
                            <Tag
                                className={classNames(
                                    `inline-flex justify-between items-center gap-2 px-2 py-[1px] mb-2 uppercase tag-#5c758a`,
                                    props.className
                                )}
                            >
                                <div className="tag-body">
                                    {formatMessage(taskGroupMessages.external, { unitDisplay: '' })}
                                </div>
                            </Tag>
                        </div>
                    )}

                    <div className="flex justify-start items-center gap-2">
                        <User className="icon-xs" />
                        {extendedProps?.vehicle?.transporter?.id ? (
                            <span className="block text-black text-sm font-medium">
                                {extendedProps.vehicle.transporter.name}
                            </span>
                        ) : (
                            <span className="block text-black text-sm font-medium">
                                {getFullName(extendedProps.operator)}
                            </span>
                        )}
                    </div>

                    <div className="flex justify-start items-center gap-2">
                        <Truck className="icon-xs" />
                        <span className="block text-black text-sm font-medium">{extendedProps.vehicle.reference}</span>
                    </div>
                </div>
            </Card>
        );

        return (
            <div className="flex justify-between items-center px-2">
                {extendedProps.status === TaskGroupStatus.toPlan && (
                    <div className="flex justify-center items-center gap-2 w-full">
                        <PencilAlt className="icon-sm shrink-0" />
                        <span className="block text-sm text-white font-bold t-o-ellipsis">
                            {formatMessage(taskGroupMessages.toPlan)}
                        </span>
                    </div>
                )}

                {extendedProps.status === TaskGroupStatus.done && (
                    <Tooltip
                        className="w-full"
                        color="#ffffff"
                        overlayInnerStyle={{ borderRadius: 2, padding: 0 }}
                        placement="bottom"
                        title={tooltipTitle}
                    >
                        <div className="flex justify-between">
                            <span className="block text-sm text-white font-bold t-o-ellipsis">
                                <FormattedTime value={extendedProps.shiftStartedAt} hour="numeric" minute="numeric" />
                            </span>
                            <span className="block text-sm text-white font-bold t-o-ellipsis">
                                <FormattedTime value={extendedProps.shiftEndedAt} hour="numeric" minute="numeric" />
                            </span>
                        </div>
                    </Tooltip>
                )}

                {extendedProps.status === TaskGroupStatus.inProgress && (
                    <Tooltip
                        className="w-full"
                        color="#ffffff"
                        overlayInnerStyle={{ borderRadius: 2, padding: 0 }}
                        placement="bottom"
                        title={tooltipTitle}
                    >
                        <span className="block text-sm text-white font-bold t-o-ellipsis">
                            <FormattedTime value={extendedProps.shiftStartedAt} hour="numeric" minute="numeric" />
                        </span>
                    </Tooltip>
                )}

                {extendedProps.status === TaskGroupStatus.toClose && (
                    <Tooltip
                        className="w-full"
                        color="#ffffff"
                        overlayInnerStyle={{ borderRadius: 2, padding: 0 }}
                        placement="bottom"
                        title={tooltipTitle}
                    >
                        <span className="block text-sm text-white font-bold t-o-ellipsis">
                            <FormattedTime value={extendedProps.shiftStartedAt} hour="numeric" minute="numeric" />
                        </span>
                    </Tooltip>
                )}
            </div>
        );
    };

    const detailsDrawer = useModal(TaskGroupDetailsDrawer);
    const handleEventClick = ({ event }: EventClickArg) => {
        detailsDrawer.show({ taskGroupId: event.id });
    };

    const calendarControlsTitle = (
        <>
            <FormattedDate value={activeDate.toDate()} weekday="long" />{' '}
            <FormattedDate value={activeDate.toDate()} day="numeric" month="long" year="numeric" />
        </>
    );

    return (
        <CustomDrawer footer={false} {...props} {...antdDrawer(modal)}>
            <CalendarControls
                activeDate={activeDate}
                additionalControls={
                    <PlaceSelect
                        maxTagCount="responsive"
                        mode="tags"
                        onChange={filterPlaces}
                        size="small"
                        style={{ width: 320 }}
                    />
                }
                handleChangeDate={setActiveDate}
                mode="day"
                navigateBeforeToday
                title={calendarControlsTitle}
            />

            <div className="flex justify-between items-center my-4">
                <div className="flex justify-between items-center gap-2">
                    <span className="block text-lg text-black font-bold">
                        {formatMessage(genericMessages.taskGroups)}
                    </span>
                    <Badge count={taskGroups.length} style={{ backgroundColor: '#16a1b8' }} />
                </div>

                <CalendarTaskGroupCaption />
            </div>

            <Card className="border-0 custom-fullcalendar__taskGroups" bodyStyle={{ padding: 0 }}>
                <Spin spinning={isFetching || isLoading}>
                    <CustomCalendarTimeline
                        activeDate={activeDate}
                        initialView={FullCalendarTimelineViews.resourceTimelineDay}
                        events={calendarData}
                        eventSources={taskGroups}
                        eventContent={renderEventContent}
                        eventClick={handleEventClick}
                        nowIndicator
                        resources={calendarData}
                        resourceAreaColumns={resourceAreaColumns}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: 'numeric',
                        }}
                    />
                </Spin>
            </Card>
        </CustomDrawer>
    );
});

export default CalendarTaskGroup;
