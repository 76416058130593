import { useModal } from '@ebay/nice-modal-react';
import { Button, Descriptions } from 'antd';
import { useCallback, VFC } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import genericMessages from '../../../i18n/genericMessages';
import taskGroupMessages from '../../../i18n/taskGroupMessages';
import { TaskGroup, TaskGroupPreBillingStatus } from '../../../queries/api/types';
import { useTaskGroupDetails, useTaskGroupUpdate } from '../../../queries/taskGroups';
import TaskGroupTransporterServiceModal from './TaskGroupTransporterServiceModal';
import OrderNumberModal from '../../../components/OrderNumberModal';

interface TaskGroupDetailsDescriptionsProps {
    taskGroupId?: TaskGroup['id'];
}

const transporterServiceModalId = 'transporter-service-modal';

const TaskGroupDetailsDescriptions: VFC<TaskGroupDetailsDescriptionsProps> = ({ taskGroupId }) => {
    const { formatMessage } = useIntl();
    const { data: taskGroup } = useTaskGroupDetails(taskGroupId, { enabled: !!taskGroupId });
    const { mutate: updateTaskGroup, isLoading } = useTaskGroupUpdate();
    const transporterServiceModal = useModal(transporterServiceModalId);
    const orderNumberModal = useModal(OrderNumberModal);
    const onClickEditService = useCallback(() => {
        transporterServiceModal.show();
    }, [transporterServiceModal]);
    return (
        <>
            <Descriptions column={1} size="small" labelStyle={{ width: 180 }} bordered>
                <Descriptions.Item label={formatMessage(taskGroupMessages.orderNumber)}>
                    <div className="flex justify-between items-center">
                        <span>{taskGroup?.orderNumber ?? '-'}</span>
                        <span>
                            <Button
                                size="small"
                                className="text-blue"
                                onClick={() => {
                                    orderNumberModal.show({ taskGroupId });
                                }}
                            >
                                {formatMessage(
                                    taskGroup?.orderNumber ? genericMessages.modify : taskGroupMessages.addOrderNumber
                                )}
                            </Button>
                        </span>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage(genericMessages.customer, { count: 1 })}>
                    {taskGroup?.customer.name ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage(genericMessages.place, { count: 1 })}>
                    {taskGroup?.startingPlace.name ?? '-'}
                </Descriptions.Item>
                {taskGroup?.wantedVehicleType && (
                    <Descriptions.Item
                        label={formatMessage({
                            id: 'task_group.details_drawer.wanted_vehicle',
                            defaultMessage: 'Véhicule souhaité',
                        })}
                    >
                        {taskGroup?.wantedVehicleType}
                    </Descriptions.Item>
                )}
            </Descriptions>
            {taskGroup?.vehicle?.transporter && (
                <Descriptions column={1} size="small" labelStyle={{ width: 180 }} bordered>
                    <Descriptions.Item label={formatMessage(genericMessages.transporters, { count: 1 })}>
                        {taskGroup?.vehicle?.transporter?.name ?? '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label={formatMessage(genericMessages.services, { count: 1 })}>
                        <div className="flex justify-between items-center">
                            {taskGroup?.transporterService?.label ?? '-'}
                            {taskGroup?.vehicle.transporter &&
                                taskGroup.preBillingStatus !== TaskGroupPreBillingStatus.preBilled && (
                                    <Button type="primary" size="small" onClick={onClickEditService} ghost>
                                        <FormattedMessage {...genericMessages.edit} />
                                    </Button>
                                )}
                        </div>
                    </Descriptions.Item>
                </Descriptions>
            )}
            <Descriptions column={2} size="small" labelStyle={{ width: 180 }} bordered>
                <Descriptions.Item label={formatMessage(taskGroupMessages.start)}>
                    {taskGroup?.shiftStartedAt ? <FormattedDate value={taskGroup?.shiftStartedAt} /> : '-'}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage(taskGroupMessages.end)}>
                    {taskGroup?.shiftEndedAt ? <FormattedDate value={taskGroup?.shiftEndedAt} /> : '-'}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage(taskGroupMessages.taskCount)}>
                    {taskGroup?.computedProperties?.taskCount !== undefined ? (
                        <FormattedNumber value={taskGroup?.computedProperties.taskCount} />
                    ) : (
                        '-'
                    )}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage(taskGroupMessages.expectedMileage, { count: 1 })}>
                    {taskGroup?.computedProperties?.mileage !== undefined ? (
                        <FormattedNumber value={taskGroup?.computedProperties.mileage} style="unit" unit="kilometer" />
                    ) : (
                        '-'
                    )}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage(taskGroupMessages.weight)}>
                    {taskGroup?.computedProperties?.weight !== undefined ? (
                        <FormattedNumber value={taskGroup?.computedProperties.weight} style="unit" unit="kilogram" />
                    ) : (
                        '-'
                    )}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage(taskGroupMessages.volume)}>
                    {taskGroup?.computedProperties?.volume !== undefined ? (
                        <>
                            <FormattedNumber value={taskGroup?.computedProperties.volume} style="unit" unit="meter" />
                            <sup>3</sup>
                        </>
                    ) : (
                        '-'
                    )}
                </Descriptions.Item>
            </Descriptions>
            <TaskGroupTransporterServiceModal
                id={transporterServiceModalId}
                taskGroupId={taskGroup?.id}
                transporterId={taskGroup?.vehicle?.transporter?.id}
                vehicle={taskGroup?.vehicle}
                onConfirm={(transporterServiceId?: string) => {
                    updateTaskGroup(
                        { id: taskGroupId, transporterService: transporterServiceId },
                        { onSuccess: async () => await transporterServiceModal.hide() }
                    );
                }}
                loading={isLoading}
            />
        </>
    );
};

export default TaskGroupDetailsDescriptions;
