/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 11h.007m-2.674 2h5.334C8.403 13 9 12.403 9 11.667V2.333C9 1.597 8.403 1 7.667 1H2.333C1.597 1 1 1.597 1 2.333v9.334C1 12.403 1.597 13 2.333 13Z"
            stroke="#5C758A"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgSmartPhone: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-smart-phone', props.className)} />
));
export default SvgSmartPhone;
