import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { TaskGroupService, ListResponse, TaskGroupServiceType } from './types';

// Controller Interface
export interface TaskGroupServiceCreatePayload {
    label: string;
    amount: number;
}

export interface TaskGroupServiceUpdatePayload extends Omit<TaskGroupService, 'id'> {
    id?: TaskGroupService['id'];
}

export type TaskGroupServiceIdPayload = TaskGroupService['id'];

export type TaskGroupServiceListPayload = SearchPaginationQuery & {
    type?: TaskGroupServiceType;
};
export type TaskGroupServiceListResponse = ListResponse<TaskGroupService>;

// Routes
export const list = async (payload?: TaskGroupServiceListPayload) => {
    return await api
        .get<TaskGroupServiceListResponse>(`/taskGroupServices`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: TaskGroupServiceCreatePayload) => {
    return await api.post(`/taskGroupServices`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: TaskGroupServiceUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<TaskGroupService>(`/taskGroupServices/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: TaskGroupServiceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<TaskGroupService>(`/taskGroupServices/${id}`).then((response) => response?.data);
};

export const remove = async (id?: TaskGroupServiceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/taskGroupServices/${id}`).then((response) => response?.data);
};
