import { useCallback, useMemo, useState, VFC } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Table } from 'antd';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { ColumnsType, TableProps } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useModal } from '@ebay/nice-modal-react';
import { HiOutlineTrash } from 'react-icons/hi';

import Seo from '../../../components/Seo';
import { Operator, OperatorAbsence, Permission, PermissionRight } from '../../../queries/api/types';
import { useOperatorListAbscences } from '../../../queries/operators';
import ApiResult from '../../../components/ApiResult';
import { addDefaultColumnSorting, sortOrderConverter } from '../../../helpers';
import ListTitle from '../../../components/ListTitle';
import useQueryParams from '../../../hooks/queryParams';
import { Plus } from '../../../components/icons';
import operatorMessages from '../../../i18n/operatorMessages';
import genericMessages from '../../../i18n/genericMessages';
import unitMessages from '../../../i18n/unitMessages';
import { OperatorListAbsencesPayload } from '../../../queries/api/operators';
import OperatorAbsenceAddModal from '../../../components/modals/OperatorAbsenceAddModal';
import { hasPermission } from '../../../helpers/security';
import { useAuth } from '../../../context/AuthContext';
import { useOperatorAbsenceRemove } from '../../../queries/operatorAbsences';
import { errorMessage, successMessage } from '../../../helpers/message';
import DeletePopConfirm from '../../../components/DeletePopConfirm';

const ManagementOperatorsDetailsUnavailabilities: VFC = () => {
    const { user } = useAuth();
    const [absenceIdBeingDeleted, setAbsenceIdBeingDeleted] = useState<OperatorAbsence['id']>();
    const canEdit = hasPermission(user, Permission.operatorAbsences, PermissionRight.write);
    const { formatMessage } = useIntl();
    const { operatorId } = useParams<{ operatorId: Operator['id'] }>();
    const addModal = useModal(OperatorAbsenceAddModal);
    const [queryParams, setQueryParams] = useQueryParams('ManagementOperatorsDetailsUnavailabilities');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const status = queryParams.getAll('status') ?? undefined;
    const {
        data: absences,
        isLoading,
        error,
        isError,
    } = useOperatorListAbscences({
        id: operatorId,
        sort,
        sortOrder,
        page,
        status,
    });
    const { mutate: deleteAbsence } = useOperatorAbsenceRemove();
    const onClickDelete = useCallback(
        (id: OperatorAbsence['id']) => () => {
            setAbsenceIdBeingDeleted(id);
            deleteAbsence(id, {
                onSuccess: () => {
                    successMessage({
                        content: formatMessage({
                            id: 'management.operators.unavailabilites.delete_success',
                            defaultMessage: 'Indisponibilité supprimée avec succès',
                        }),
                    });
                },
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error.response?.status,
                        }),
                    });
                },
                onSettled: () => {
                    setAbsenceIdBeingDeleted(undefined);
                },
            });
        },
        [deleteAbsence, formatMessage]
    );
    const pageTitle = formatMessage(operatorMessages.unavailabilities);
    const onTableChange: TableProps<OperatorAbsence>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;
            const statusFilter = (filters.status as string[]) || [];

            const query: Partial<OperatorListAbsencesPayload> = {
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
                status: statusFilter,
            };

            if (statusFilter.length === 1) {
                if (statusFilter[0] === 'past') {
                    query.toDate = dayjs().subtract(1, 'day').endOf('day').toISOString();
                } else if (statusFilter[0] === 'future') {
                    query.fromDate = dayjs().add(1, 'day').startOf('day').toISOString();
                } else if (statusFilter[0] === 'today') {
                    query.fromDate = dayjs().startOf('day').toISOString();
                    query.toDate = dayjs().endOf('day').toISOString();
                }
            } else if (statusFilter.length === 2) {
                if (statusFilter.includes('today')) {
                    if (statusFilter.includes('future')) {
                        query.fromDate = dayjs().startOf('day').toISOString();
                    } else if (statusFilter.includes('past')) {
                        query.toDate = dayjs().endOf('day').toISOString();
                    }
                }
            }

            setQueryParams(query);
        },
        [setQueryParams]
    );
    const columns = useMemo<ColumnsType<OperatorAbsence>>(
        () =>
            addDefaultColumnSorting<OperatorAbsence>(
                sort,
                sortOrder,
                (
                    [
                        {
                            key: 'startDate',
                            title: formatMessage(genericMessages.startDate),
                            render: (value, record) => <FormattedDate value={record.startDate} />,
                            sorter: true,
                        },
                        {
                            key: 'endDate',
                            title: formatMessage(genericMessages.endDate),
                            render: (value, record) => <FormattedDate value={record.endDate} />,
                            sorter: true,
                        },
                        {
                            key: 'duration',
                            title: formatMessage(genericMessages.duration),
                            render: (value, record) => (
                                <>
                                    {record.duration}
                                    {formatMessage(unitMessages.dayShort)}
                                    {record.slot && ` (${record.slot.fields.preferedSlot})`}
                                </>
                            ),
                            sorter: true,
                        },
                        {
                            key: 'status',
                            title: formatMessage(genericMessages.status),
                            render: (value, record) => {
                                if (dayjs(record.startDate).isSame(dayjs(), 'day')) {
                                    return formatMessage(genericMessages.today);
                                }

                                if (dayjs(record.startDate).isBefore(dayjs(), 'day')) {
                                    return formatMessage(genericMessages.past);
                                }

                                if (dayjs(record.startDate).isAfter(dayjs(), 'day')) {
                                    return formatMessage(genericMessages.forthcoming);
                                }

                                return null;
                            },
                            filters: [
                                { text: formatMessage(genericMessages.past), value: 'past' },
                                { text: formatMessage(genericMessages.inProgress), value: 'inProgress' },
                                { text: formatMessage(genericMessages.forthcoming), value: 'coming' },
                            ],
                        },
                        {
                            key: 'type',
                            title: formatMessage(operatorMessages.absenceReason),
                            render: (value, record) => record.type?.fields.operatorAbsenceReason,
                        },
                        canEdit && {
                            key: 'delete',
                            render: (_, record) => (
                                <DeletePopConfirm onConfirm={onClickDelete(record.computedProperties.commonId)}>
                                    <Button
                                        icon={<HiOutlineTrash />}
                                        type="text"
                                        loading={record.id === absenceIdBeingDeleted}
                                    />
                                </DeletePopConfirm>
                            ),
                            width: 100,
                        },
                    ] as ColumnsType<OperatorAbsence>
                ).filter(Boolean)
            ),
        [canEdit, formatMessage, sort, sortOrder, absenceIdBeingDeleted, onClickDelete]
    );

    return (
        <>
            <Seo title={pageTitle} />
            <Card>
                <div className="flex justify-between items-center mb-6">
                    <ListTitle className="mb-0" count={absences?.totalCount}>
                        {pageTitle}
                    </ListTitle>
                    {canEdit && (
                        <Button
                            icon={<Plus />}
                            type="primary"
                            onClick={() => {
                                addModal.show({ operatorId });
                            }}
                            ghost
                        >
                            <FormattedMessage
                                id="management.operators.unavailabilities.add_button"
                                defaultMessage="Ajouter une indisponibilité"
                                tagName="span"
                            />
                        </Button>
                    )}
                </div>
                {isError ? (
                    <ApiResult status={error.response?.status} />
                ) : (
                    <Table<OperatorAbsence>
                        columns={columns}
                        rowKey="id"
                        dataSource={absences?.items}
                        onChange={onTableChange}
                        pagination={{
                            total: absences?.totalCount,
                            current: page + 1,
                            pageSize: absences?.pageSize,
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            showSizeChanger: false,
                            className: 'mb-0',
                        }}
                        scroll={{
                            scrollToFirstRowOnChange: true,
                            y: `calc(100vh - ${(absences?.pageCount ?? 0) > 1 ? 403 : 364}px)`,
                        }}
                        loading={isLoading}
                        size="small"
                        bordered
                    />
                )}
            </Card>
        </>
    );
};

export default ManagementOperatorsDetailsUnavailabilities;
