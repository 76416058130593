/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13 4a1 1 0 1 0-2 0h2Zm-2 1a1 1 0 1 0 2 0h-2Zm7 10a1 1 0 1 0 0 2v-2Zm2 2a1 1 0 1 0 0-2v2Zm-8-1v-1a1 1 0 0 0-1 1h1Zm2 1a1 1 0 1 0 0-2v2Zm-3 3a1 1 0 1 0 2 0h-2Zm2-11a1 1 0 1 0-2 0h2Zm-1 3h-1a1 1 0 0 0 1 1v-1Zm4 7a1 1 0 1 0 0 2v-2Zm4 2a1 1 0 1 0 0-2v2ZM4 11a1 1 0 1 0 0 2v-2Zm4 2a1 1 0 1 0 0-2v2Zm4.01 0a1 1 0 1 0 0-2v2Zm4 0a1 1 0 1 0 0-2v2ZM20 11a1 1 0 1 0 0 2v-2Zm.01 2a1 1 0 1 0 0-2v2ZM5 5h2V3H5v2Zm2 0v2h2V5H7Zm0 2H5v2h2V7ZM5 7V5H3v2h2Zm0 0H3a2 2 0 0 0 2 2V7Zm2 0v2a2 2 0 0 0 2-2H7Zm0-2h2a2 2 0 0 0-2-2v2ZM5 3a2 2 0 0 0-2 2h2V3Zm12 2h2V3h-2v2Zm2 0v2h2V5h-2Zm0 2h-2v2h2V7Zm-2 0V5h-2v2h2Zm0 0h-2a2 2 0 0 0 2 2V7Zm2 0v2a2 2 0 0 0 2-2h-2Zm0-2h2a2 2 0 0 0-2-2v2Zm-2-2a2 2 0 0 0-2 2h2V3ZM5 17h2v-2H5v2Zm2 0v2h2v-2H7Zm0 2H5v2h2v-2Zm-2 0v-2H3v2h2Zm0 0H3a2 2 0 0 0 2 2v-2Zm2 0v2a2 2 0 0 0 2-2H7Zm0-2h2a2 2 0 0 0-2-2v2Zm-2-2a2 2 0 0 0-2 2h2v-2Zm6-11v1h2V4h-2Zm7 13h2v-2h-2v2Zm-6 0h2v-2h-2v2Zm-1-1v4h2v-4h-2Zm0-7v3h2V9h-2Zm5 12h4v-2h-4v2ZM4 13h4v-2H4v2Zm8 0h.01v-2H12v2Zm8 0h.01v-2H20v2Zm-8 0h4.01v-2H12v2Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgQrcode: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-qrcode', props.className)} />
));
export default SvgQrcode;
