import { useState, forwardRef } from 'react';
import { Empty, Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectRef } from 'rc-select';
import { useIntl } from 'react-intl';

import { useTransporterList } from '../../queries/transporters';
import { Transporter, ValueListSlug } from '../../queries/api/types';
import { useDebounce } from '../../hooks';
import { useValueListItemList } from '../../queries/valueListItems';
import genericMessages from '../../i18n/genericMessages';

type ValueType = Transporter['id'] | 'inHouse' | undefined;

interface TransporterSelectProps extends SelectProps {
    onChange?: (value: ValueType | ValueType[]) => void;
    initialValue?: Transporter[];
    hasInHouseOption?: boolean;
}

const TransporterSelect = forwardRef<BaseSelectRef, TransporterSelectProps>(
    ({ onChange, initialValue, hasInHouseOption = false, ...props }, ref) => {
        const { formatMessage } = useIntl();
        const [search, setSearch] = useState<string>();
        const debouncedSearch = useDebounce(search, 300);
        const [value, setValue] = useState<Transporter['id'] | Array<Transporter['id']> | undefined>(
            initialValue?.map((a) => a.id)
        );
        const { data: list, isLoading } = useTransporterList({
            search: debouncedSearch || undefined,
            pageSize: 50,
        });

        const onChangeSelect: SelectProps<Transporter['id'] | Array<Transporter['id']>>['onChange'] = (newValue) => {
            setValue(newValue);
            onChange?.(newValue);
        };

        const { data: vehicleOwnershipTypes } = useValueListItemList(
            {
                valueListSlug: ValueListSlug.vehicleOwnershipTypes,
            },
            { enabled: hasInHouseOption }
        );
        const rentalOwnershipTypeLabel = vehicleOwnershipTypes?.items?.find(
            (item) => item.fields.vehicleOwnershipTypeReference === 'inHouse'
        )?.fields.vehicleOwnershipTypeName;

        const selectOptions = (
            <>
                {[
                    ...(initialValue || []), // Display initial value
                    ...(list?.items.filter((a) => !initialValue?.map((b) => b.id).includes(a.id)) ?? []), // Search items, excluding initial value
                ].map((item) => (
                    <Select.Option value={item.id} key={item.id}>
                        {item.name}
                    </Select.Option>
                ))}
            </>
        );

        return (
            <Select<Transporter['id'] | Array<Transporter['id']>>
                ref={ref}
                placeholder={formatMessage({
                    id: 'transporter_select.placeholder',
                    defaultMessage: 'Rechercher un partenaire de transport',
                })}
                notFoundContent={isLoading ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={setSearch}
                onChange={onChangeSelect}
                style={{ width: '100%' }}
                loading={isLoading}
                showSearch
                allowClear
                {...props}
                value={value}
            >
                {hasInHouseOption && !search && (
                    <Select.Option value={'inHouse'}>
                        <span className="pl-3">
                            {rentalOwnershipTypeLabel} ({formatMessage(genericMessages.idl)})
                        </span>
                    </Select.Option>
                )}
                {hasInHouseOption ? (
                    <Select.OptGroup key={'Partenaires transport'}>{selectOptions}</Select.OptGroup>
                ) : (
                    selectOptions
                )}
            </Select>
        );
    }
);

export default TransporterSelect;
