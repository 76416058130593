import { VFC } from 'react';
import { Card, Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { Dayjs } from 'dayjs';
import { FormattedDate, useIntl } from 'react-intl';

import genericMessages from '../../../i18n/genericMessages';
import { formatDateOverlap } from '../../../helpers/date';
import CalendarControls, { CalendarControlsMode } from '../../../components/CalendarControls';
import { FullCalendarTimelineViews } from '../../../components/CustomCalendarTimeline';

export interface PlanningsHeaderProps {
    activeDate: Dayjs;
    calendarControlsMode: CalendarControlsMode;
    handleSegmentedChange: (arg: SegmentedValue) => void;
    setActiveDate: (arg: Dayjs) => void;
    activeView?: FullCalendarTimelineViews;
}

const PlanningsHeader: VFC<PlanningsHeaderProps> = ({
    activeDate,
    calendarControlsMode,
    handleSegmentedChange,
    setActiveDate,
    activeView,
}) => {
    const { formatMessage } = useIntl();
    const monthModeTitle = <FormattedDate value={activeDate.toDate()} month="long" year="numeric" />;
    const weekModeTitle = (
        <>
            {formatDateOverlap(activeDate)} ({formatMessage(genericMessages.week)} {activeDate.week()})
        </>
    );

    const headerTitle = calendarControlsMode === 'week' ? weekModeTitle : monthModeTitle;

    return (
        <Card className="border-b-0" key={activeView}>
            <div className="flex justify-between items-center">
                <CalendarControls
                    activeDate={activeDate}
                    additionalControls={
                        <Segmented
                            block
                            options={[
                                {
                                    label: formatMessage(genericMessages.week),
                                    value: FullCalendarTimelineViews.resourceTimelineWeek,
                                },
                                {
                                    label: formatMessage(genericMessages.month),
                                    value: FullCalendarTimelineViews.resourceTimelineMonth,
                                },
                            ]}
                            style={{ width: 225 }}
                            size="small"
                            onChange={handleSegmentedChange}
                            value={activeView}
                        />
                    }
                    handleChangeDate={setActiveDate}
                    mode={calendarControlsMode}
                    navigateBeforeToday={true}
                    title={headerTitle}
                />
            </div>
        </Card>
    );
};

export default PlanningsHeader;
