import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { Application, ListResponse, Organization } from './types';

// Controller Interface
export interface ApplicationCreatePayload {
    name: Application['name'];
    organization: Organization['id'];
}

export type ApplicationUpdatePayload = Application;

export interface ApplicationIdPayload {
    id: Application['id'];
}

export type ApplicationListPayload = SearchPaginationQuery;
export type ApplicationListResponse = ListResponse<Application>;

// Routes
export const list = async (payload: ApplicationListPayload = {}) => {
    return await api
        .get<ApplicationListResponse>(`/applications`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: ApplicationCreatePayload) => {
    return await api.post<Application>(`/applications`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: ApplicationUpdatePayload) => {
    return await api.put<Application>(`/applications/${id}`, payload).then((response) => response?.data);
};

export const details = async (payload: ApplicationIdPayload) => {
    return await api.get<Application>(`/applications/${payload.id}`).then((response) => response?.data);
};

export const remove = async (payload: ApplicationIdPayload) => {
    return await api.delete<undefined>(`/applications/${payload.id}`).then((response) => response?.data);
};
