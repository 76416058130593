import { useIntl } from 'react-intl';
import { FileAddOutlined } from '@ant-design/icons';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Form, FormProps, ModalProps, Spin, Upload, UploadProps } from 'antd';

import { ImportFileErrorsCodes, useTaskGroupImportFile } from '../../../queries/taskGroups';
import { Customer, Place } from '../../../queries/api/types';
import { getRequiredRule } from '../../../i18n';
import formMessages from '../../../i18n/formMessages';
import genericMessages from '../../../i18n/genericMessages';
import taskGroupMessages from '../../../i18n/taskGroupMessages';
import { errorMessage, successMessage } from '../../../helpers/message';
import CustomerSelect from '../../../components/selects/CustomerSelect';
import PlaceSelect from '../../../components/selects/PlaceSelect';
import CustomModal from '../../../components/CustomModal';
import CantImportFileModal from './CantImportFileModal';

export interface ImportFileModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    title: string;
}

interface ImportModalFormProps {
    customerId: Customer['id'];
    placeId: Place['id'];
    file: {
        file: File;
    };
}

const ImportFileModal = NiceModal.create<ImportFileModalProps>(({ title }) => {
    const { formatMessage } = useIntl();
    const modal = useModal();
    const cantImportFileModal = useModal(CantImportFileModal);

    const { mutate: importFile, isLoading } = useTaskGroupImportFile();

    const onSubmit: FormProps['onFinish'] = (values: ImportModalFormProps) => {
        importFile(
            {
                ...values,
                file: values.file.file,
            },
            {
                onSuccess: () => {
                    successMessage({
                        content: formatMessage(taskGroupMessages.importFileSuccess),
                    });
                    modal.hide();
                },
                onError: (error) => {
                    const reason = error.response?.data?.code;
                    if (reason && Object.values(ImportFileErrorsCodes).includes(reason)) {
                        cantImportFileModal.show({
                            reason,
                        });
                    } else {
                        errorMessage({
                            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                status: error.response?.status ?? 0,
                            }),
                        });
                    }
                },
            }
        );
    };

    const handleBeforeUpload: UploadProps['beforeUpload'] = (file) => {
        const xlsMimeType = 'application/vnd.ms-excel';
        const xlsxMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        const authorizedMimeTypes = [xlsMimeType, xlsxMimeType];

        if (!authorizedMimeTypes.includes(file.type)) {
            errorMessage({
                content: formatMessage(taskGroupMessages.importFileError),
            });

            return Upload.LIST_IGNORE;
        }

        return false;
    };
    return (
        <CustomModal {...antdModal(modal)} footer={false} title={title}>
            <Spin spinning={isLoading}>
                <Form onFinish={onSubmit}>
                    <Form.Item
                        required
                        rules={[getRequiredRule(formatMessage)]}
                        name="customerId"
                        label={formatMessage(genericMessages.customer, { count: 0 })}
                        labelCol={{ sm: { span: 6 }, lg: { span: 4 } }}
                        wrapperCol={{ sm: { span: 18 }, lg: { span: 24 } }}
                    >
                        <CustomerSelect placeholder={formatMessage(genericMessages.select)} validForcastOnly />
                    </Form.Item>

                    <Form.Item dependencies={['customerId']} noStyle>
                        {({ getFieldValue }) => {
                            const isCustomerSelected = getFieldValue(['customerId']);

                            return (
                                <Form.Item
                                    required
                                    rules={[getRequiredRule(formatMessage)]}
                                    name="placeId"
                                    label={formatMessage(genericMessages.warehouse, { count: 0 })}
                                    labelCol={{ sm: { span: 6 }, lg: { span: 4 } }}
                                    wrapperCol={{ sm: { span: 18 }, lg: { span: 24 } }}
                                >
                                    <PlaceSelect
                                        disabled={!isCustomerSelected}
                                        placeholder={formatMessage(genericMessages.select)}
                                    />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>

                    <Form.Item
                        required
                        rules={[getRequiredRule(formatMessage)]}
                        name="file"
                        label={formatMessage(genericMessages.file)}
                        labelCol={{ sm: { span: 6 }, lg: { span: 4 } }}
                        wrapperCol={{ sm: { span: 18 }, lg: { span: 24 } }}
                    >
                        <Upload beforeUpload={handleBeforeUpload} maxCount={1} accept={'.xls, .xlsx'}>
                            <Button className="text-blue" icon={<FileAddOutlined />}>
                                {formatMessage(genericMessages.select)}
                            </Button>
                        </Upload>
                    </Form.Item>

                    <div className="flex justify-end gap-4">
                        <Button
                            type="primary"
                            onClick={() => {
                                modal.hide();
                            }}
                            ghost
                        >
                            {formatMessage(formMessages.cancel)}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {formatMessage(formMessages.validate)}
                        </Button>
                    </div>
                </Form>
            </Spin>
        </CustomModal>
    );
});

export default ImportFileModal;
