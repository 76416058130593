/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16 11v1h1v-1h-1Zm-8 0H7v1h1v-1Zm5 4a1 1 0 1 0-2 0h2Zm-2 2a1 1 0 1 0 2 0h-2Zm-5-5h12v-2H6v2Zm13 1v6h2v-6h-2Zm-1 7H6v2h12v-2ZM5 19v-6H3v6h2Zm1 1a1 1 0 0 1-1-1H3a3 3 0 0 0 3 3v-2Zm13-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2Zm-1-7a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2ZM6 10a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1v-2Zm9-3v4h2V7h-2Zm1 3H8v2h8v-2Zm-7 1V7H7v4h2Zm3-7a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2Zm0-2a5 5 0 0 0-5 5h2a3 3 0 0 1 3-3V2Zm-1 13v2h2v-2h-2Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgLockClosed: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-lock-closed', props.className)} />
));
export default SvgLockClosed;
