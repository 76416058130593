import { forwardRef, useMemo } from 'react';
import { Segmented, SegmentedProps } from 'antd';

import { ValueListItem, ValueListSlug } from '../../queries/api/types';
import { useValueListItemList } from '../../queries/valueListItems';

export interface ValueListItemSegmentedProps extends Omit<SegmentedProps, 'options' | 'ref'> {
    valueListSlug: ValueListSlug;
    itemValueKey?: Extract<keyof ValueListItem, string>;
    itemLabelKey?: Extract<keyof ValueListItem['fields'], string>;
    filterOptions?: (options: ValueListItem) => boolean;
}

const ValueListItemSegmented = forwardRef<HTMLDivElement, ValueListItemSegmentedProps>(
    ({ valueListSlug, filterOptions, itemLabelKey, itemValueKey = 'id', ...props }, ref) => {
        const { data: list } = useValueListItemList({
            valueListSlug,
        });

        const options = useMemo(
            () =>
                list?.items?.filter(filterOptions ?? (() => true))?.map((item: ValueListItem) => ({
                    label: itemLabelKey ? item.fields[itemLabelKey] : item.fields[Object.keys(item.fields)[0]],
                    value: item[itemValueKey] as string | number,
                })),
            [filterOptions, itemLabelKey, itemValueKey, list?.items]
        );

        return <Segmented {...props} ref={ref} options={options || []} />;
    }
);

export default ValueListItemSegmented;
