import { useEffect, useState, VFC } from 'react';
import dayjs from 'dayjs';

import { isBeforeToday } from '../helpers';
import { ArrowDown, ArrowUp, Check, Pencil, SwitchVertical } from './icons';

interface CustomCalendarEventProps {
    actualCount?: number;
    capacity: number;
    date: string;
    tolerance?: number;
}

const CustomCalendarEvent: VFC<CustomCalendarEventProps> = ({ actualCount, capacity, date, tolerance, ...props }) => {
    const [eventHovered, setEventHovered] = useState<string>('');
    const [bgColor, setBgColor] = useState<string>('');

    const isTolerancePositive = tolerance ? tolerance >= 0 : true;
    const percentageDifference = actualCount && Math.round(((actualCount - capacity) / 100) * 100);

    useEffect(() => {
        if (actualCount) {
            const thresholdExceedingTolerance = 10; // over threshold + 10% = red
            const defaultTolerance = 10; // 10%
            const maxThreshold = capacity + ((tolerance || defaultTolerance) / 100) * 100;

            if (isTolerancePositive) {
                const thresholdExceed = maxThreshold + thresholdExceedingTolerance;

                if (actualCount < capacity || actualCount > thresholdExceed) {
                    return setBgColor('red');
                }

                if (actualCount > maxThreshold) {
                    return setBgColor('orange');
                }

                if (actualCount <= maxThreshold) {
                    return setBgColor('green');
                }
            } else {
                const thresholdExceed = maxThreshold - thresholdExceedingTolerance;

                if (actualCount > capacity || actualCount < thresholdExceed) {
                    return setBgColor('red');
                }

                if (actualCount < maxThreshold) {
                    return setBgColor('orange');
                }

                if (actualCount >= maxThreshold) {
                    return setBgColor('green');
                }
            }
        }
    }, [actualCount, capacity, isTolerancePositive, tolerance]);

    return (
        <div
            className="h-full w-full cursor-pointer"
            onMouseEnter={isBeforeToday(dayjs(date).toDate()) ? undefined : () => setEventHovered(date)}
            onMouseLeave={() => setEventHovered('')}
            {...props}
        >
            <div className="relative">
                <div className={`flex flex-col justify-center gap-1 fc-event-content fc-event-bg-${bgColor}`}>
                    {actualCount ? (
                        <>
                            <div className="flex justify-start items-center gap-1">
                                {bgColor === 'green' ? (
                                    <Check className="icon-xs" />
                                ) : (
                                    <>
                                        {actualCount > capacity && <ArrowUp className="icon-xs" />}
                                        {actualCount < capacity && <ArrowDown className="icon-xs" />}
                                    </>
                                )}
                                <p className="font-bold text-base mb-0">{actualCount} OT</p>

                                {tolerance && percentageDifference ? (
                                    <>
                                        {percentageDifference > 0 && (
                                            <p className="font-medium text-xs mb-0">+ {percentageDifference}%</p>
                                        )}

                                        {percentageDifference < 0 && (
                                            <p className="font-medium text-xs mb-0">{percentageDifference}%</p>
                                        )}
                                    </>
                                ) : null}
                            </div>

                            <div className="flex items-center gap-2">
                                <p className="font-medium text-xs mb-0 opacity-7">{capacity} OT</p>
                                {tolerance && (
                                    <div className="flex items-center gap-1 font-medium text-xs leading-5">
                                        (<SwitchVertical className="icon-xs" />
                                        {tolerance > 0 ? <>+{tolerance} %</> : <>{tolerance} %</>})
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="font-bold text-base mb-0">{capacity} OT</p>
                            {tolerance && eventHovered !== date && (
                                <div className="flex items-center gap-1">
                                    <SwitchVertical className="icon-xs" />
                                    <span className="font-medium text-xs leading-5">{tolerance} %</span>
                                </div>
                            )}
                        </>
                    )}
                </div>

                {eventHovered === date && (
                    <div
                        style={{
                            display: 'grid',
                            placeItems: 'center',
                            backgroundColor: 'rgba(32, 43, 57, 0.8)',
                            borderRadius: 2,
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        }}
                    >
                        <Pencil className="icon-sm text-white" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomCalendarEvent;
