import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { Issue, IssueComment, ListResponse, User, Vehicle } from './types';

// Controller Interface
export interface IssueCreatePayload {
    description?: string;
}

export interface IssueUpdatePayload extends Omit<Issue, 'id' | 'comments'> {
    issue: Issue['id'];
    vehicle: Vehicle['id'];
    comments?: Array<
        Omit<IssueComment, 'id' | 'createdAt' | 'user'> & {
            user?: User;
        }
    >;
}

export type IssueIdPayload = {
    issue: Issue['id'];
    vehicle: Vehicle['id'];
};

export type IssueListPayload = SearchPaginationQuery;
export type IssueListResponse = ListResponse<Issue>;

// Routes
export const list = async (payload?: IssueListPayload) => {
    return await api.get<IssueListResponse>(`/issues`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: IssueCreatePayload) => {
    return await api.post(`/issues`, payload).then((response) => response?.data);
};

export const update = async ({ issue, ...payload }: IssueUpdatePayload) => {
    if (!issue) {
        throw new Error('missing issue');
    }
    return await api.put<Issue>(`/issues/${issue}`, payload).then((response) => response?.data);
};

export const details = async ({ issue }: IssueIdPayload) => {
    if (!issue) {
        throw new Error('missing issue');
    }
    return await api.get<Issue>(`/issues/${issue}`).then((response) => response?.data);
};

export const remove = async ({ issue }: IssueIdPayload) => {
    if (!issue) {
        throw new Error('missing issue');
    }
    return await api.delete<undefined>(`/issues/${issue}`).then((response) => response?.data);
};
