import { NumberFormatOptions } from 'intl';
import { VFC } from 'react';
import { useIntl } from 'react-intl';

import { convertMinutesIntoDuration } from '../helpers/convertMinutesIntoDuration';
import genericMessages from '../i18n/genericMessages';

interface FormattedDurationProps {
    value: number;
    unitDisplay?: { hours?: NumberFormatOptions['unitDisplay']; minutes?: NumberFormatOptions['unitDisplay'] };
}

const FormattedDuration: VFC<FormattedDurationProps> = ({
    value,
    unitDisplay = { hours: 'narrow', minutes: 'short' },
}) => {
    const { formatMessage } = useIntl();
    const { hours, minutes } = convertMinutesIntoDuration(value);
    const fractionUnits = [
        hours > 0
            ? formatMessage(genericMessages.durationInHour, { duration: hours, unitDisplay: unitDisplay.hours })
            : undefined,
        formatMessage(genericMessages.durationInMinute, { duration: minutes, unitDisplay: unitDisplay.minutes }),
    ];
    return <>{fractionUnits.filter(Boolean).join(' ')}</>;
};

export default FormattedDuration;
