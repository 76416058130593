import { FC, ReactNode, useCallback, useMemo } from 'react';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { Table, Space, Input, InputProps, Tag, Tooltip, Button, Dropdown, Menu } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, LinkProps } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import dayjs, { Dayjs } from 'dayjs';

import { useOperatorList } from '../../queries/operators';
import { Operator, ValueListSlug } from '../../queries/api/types';
import { DotsHorizontal, Eye, LockClosed, Qrcode, Search } from '../../components/icons';
import useQueryParams from '../../hooks/queryParams';
import { sortOrderConverter } from '../../helpers';
import { OperatorListPayload } from '../../queries/api/operators';
import ListTitle from '../ListTitle';
import genericMessages from '../../i18n/genericMessages';
import BasicList from '../BasicList';
import ValueListItemSelect from '../selects/ValueListItemSelect';
import operatorMessages from '../../i18n/operatorMessages';
import { formatDate } from '../../i18n';
import DatePicker from '../DatePicker';
import ApiResult from '../ApiResult';
import OperatorInformationsModal from '../modals/OperatorInformationsModal';
import { downloadQrCodeAsImage } from '../../helpers/qrCode';

let searchTimeout: number;

interface OperatorsListProps {
    queryParamsKey: string;
    queryPayload?: Partial<OperatorListPayload>;
    title: ReactNode;
    backRoute?: LinkProps['to'];
    listItemLink: (item: Operator) => LinkProps['to'];
    scrollOffset?: number;
}

const OperatorsList: FC<OperatorsListProps> = ({
    queryParamsKey,
    queryPayload,
    title,
    backRoute,
    listItemLink,
    children,
    scrollOffset,
}) => {
    const { formatMessage } = useIntl();
    const infoModal = useModal(OperatorInformationsModal);
    const [queryParams, setQueryParams] = useQueryParams(queryParamsKey);
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const profiles = queryParams.getAll('profiles') ?? undefined;
    const placeIds = queryParams.getAll('placeIds') ?? undefined;
    const contractTypes = queryParams.getAll('contractTypes') ?? undefined;
    const contractDates = queryParams.getAll('contractDates') ?? undefined;
    const preferedSlots = queryParams.getAll('preferedSlots') ?? undefined;
    const groupIds = queryParams.getAll('groupIds') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const {
        data: operatorsList,
        isLoading,
        isFetching,
        isError,
        error,
    } = useOperatorList({
        ...queryPayload,
        page,
        search,
        profiles,
        placeIds,
        contractTypes,
        'contractStartedAt.fromDate': contractDates?.[0],
        'contractStartedAt.toDate': contractDates?.[1],
        preferedSlots,
        groupIds,
        sort,
        sortOrder,
    });
    const onTableChange: TableProps<Operator>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
            });
        },
        [setQueryParams]
    );
    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                    sort: undefined,
                    sortOrder: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );
    const columns: ColumnsType<Operator> = useMemo(
        () => [
            {
                key: 'reference',
                dataIndex: 'reference',
                title: formatMessage({
                    id: 'operators_list.column.reference',
                    defaultMessage: 'Matricule',
                }),
                render: (_, item) => (
                    <Link to={listItemLink(item)} className="blue-link font-bold">
                        {item.reference}
                    </Link>
                ),
                sorter: true,
                fixed: 'left',
            },
            {
                key: 'lastName',
                title: formatMessage(genericMessages.lastName),
                width: 175,
                render: (_, item) => item.lastName,
                sorter: true,
            },
            {
                key: 'firstName',
                title: formatMessage(genericMessages.firstName),
                render: (_, item) => item.firstName,
                sorter: true,
            },
            {
                key: 'profile',
                title: formatMessage(operatorMessages.profile),
                render: (_, item) => item.profile?.fields.operatorProfileName,
                filteredValue: profiles,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={profiles}
                            valueListSlug={ValueListSlug.operatorProfiles}
                            onChange={(value) => setQueryParams({ profiles: value })}
                            style={{ width: 260 }}
                            size="small"
                            allowClear
                        />
                    </div>
                ),
            },
            {
                key: 'groups',
                title: formatMessage(genericMessages.group, { count: 2 }),
                width: 250,
                render: (_, item) => (
                    <BasicList inline>
                        {item.groups.map((group) => (
                            <li key={group.id}>
                                <Tooltip title={group.fields.name}>
                                    <Tag>{group.fields.reference}</Tag>
                                </Tooltip>
                            </li>
                        ))}
                    </BasicList>
                ),
                filteredValue: groupIds,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={groupIds}
                            valueListSlug={ValueListSlug.groups}
                            onChange={(value) => setQueryParams({ groupIds: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                key: 'contractType',
                title: formatMessage(genericMessages.contractType),
                render: (_, item) => item.contractType?.fields.operatorContract,
                filteredValue: contractTypes,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={contractTypes}
                            valueListSlug={ValueListSlug.operatorContracts}
                            onChange={(value) => setQueryParams({ contractTypes: value })}
                            style={{ width: 260 }}
                            size="small"
                            allowClear
                        />
                    </div>
                ),
            },
            {
                key: 'contractStartedAt',
                title: formatMessage({
                    id: 'operator_list.column.contract_started_at',
                    defaultMessage: 'Début de contrat',
                }),
                width: 150,
                render: (_, item) => formatDate(item.contractStartedAt),
                filteredValue: contractDates,
                filterDropdown: () => (
                    <div className="p-3">
                        <DatePicker.RangePicker
                            defaultValue={contractDates.map((date) => dayjs(date)) as [Dayjs, Dayjs]}
                            onChange={(values) =>
                                setQueryParams({ contractDates: values?.map((value) => value?.toISOString()) })
                            }
                            style={{ width: 260 }}
                            size="small"
                            allowClear
                        />
                    </div>
                ),
            },
            {
                key: 'preferedSlots',
                title: formatMessage({
                    id: 'operator_list.column.prefered_slots',
                    defaultMessage: 'Créneaux',
                }),
                render: (_, item) => (
                    <BasicList inline>
                        {item.preferedSlots.map((slot) => (
                            <li key={slot.id}>
                                <Tag>{slot.fields[Object.keys(slot.fields)[0]]}</Tag>
                            </li>
                        ))}
                    </BasicList>
                ),
                filteredValue: preferedSlots,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={preferedSlots}
                            valueListSlug={ValueListSlug.operatorSlots}
                            onChange={(value) => setQueryParams({ preferedSlots: value })}
                            style={{ width: 260 }}
                            size="small"
                            allowClear
                        />
                    </div>
                ),
            },
            {
                key: 'actions',
                title: formatMessage(genericMessages.actions),
                render: (_, item) => (
                    <>
                        <Link to={listItemLink(item)}>
                            <Button type="text" icon={<Eye className="text-xl text-faded-blue" />} />
                        </Link>
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu
                                    items={[
                                        {
                                            key: 'loginInfos',
                                            icon: <LockClosed />,
                                            disabled: !item.account,
                                            onClick: () => {
                                                infoModal.show({ operator: item });
                                            },
                                            label: (
                                                <FormattedMessage
                                                    id="operator_list.column.actions.menu.login_infos"
                                                    defaultMessage="Informations de connexion"
                                                />
                                            ),
                                        },
                                        {
                                            key: 'qrCode',
                                            icon: <Qrcode />,
                                            disabled: !item.account,
                                            onClick: () => {
                                                if (item.account) {
                                                    downloadQrCodeAsImage(
                                                        item.account.id,
                                                        `qrcode-chauffeur-${item.reference}`
                                                    );
                                                }
                                            },
                                            label: (
                                                <FormattedMessage
                                                    id="operator_list.column.actions.menu.qr_code"
                                                    defaultMessage="Télécharger le QR Code"
                                                />
                                            ),
                                        },
                                    ]}
                                />
                            }
                            placement="topRight"
                        >
                            <Button type="text" icon={<DotsHorizontal className="text-xl text-faded-blue" />} />
                        </Dropdown>
                    </>
                ),
                width: 136,
                fixed: 'right',
            },
        ],
        [
            formatMessage,
            profiles,
            groupIds,
            contractTypes,
            contractDates,
            preferedSlots,
            listItemLink,
            setQueryParams,
            infoModal,
        ]
    );

    return (
        <>
            <div className="flex justify-between items-center mb-6">
                <ListTitle count={operatorsList?.totalCount} className="mb-0 uppercase" backRoute={backRoute}>
                    {title}
                </ListTitle>
                {children}
            </div>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Input
                    placeholder={formatMessage({
                        id: 'operators_list.search_field.placeholder',
                        defaultMessage: 'Rechercher un nom, un matricule…',
                        description: 'Operators page search field placeholder',
                    })}
                    onChange={onSearch}
                    defaultValue={search}
                    prefix={<Search className="text-primary text-base leading-4" />}
                    size="small"
                    allowClear
                />
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Table<Operator>
                        rowKey="id"
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={operatorsList?.items}
                        pagination={{
                            total: operatorsList?.totalCount,
                            current: page + 1,
                            pageSize: operatorsList?.pageSize,
                            position: ['bottomCenter'],
                            hideOnSinglePage: true,
                        }}
                        scroll={{
                            x: 1440,
                            y: scrollOffset
                                ? `calc(100vh - ${
                                      (operatorsList?.pageCount ?? 0) > 1 ? scrollOffset + 64 : scrollOffset
                                  }px)`
                                : undefined,
                            scrollToFirstRowOnChange: true,
                        }}
                        onChange={onTableChange}
                    />
                )}
            </Space>
        </>
    );
};

export default OperatorsList;
