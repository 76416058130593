import { ReactNode, VFC } from 'react';
import { Button, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useIntl } from 'react-intl';

import genericMessages from '../i18n/genericMessages';
import { ChevronLeft, ChevronRight } from './icons';

export interface CalendarControlsProps {
    activeDate: Dayjs;
    additionalControls?: ReactNode;
    handleChangeDate: (arg: Dayjs) => void;
    mode: CalendarControlsMode;
    navigateBeforeToday?: boolean;
    title: string | ReactNode;
}

export type CalendarControlsMode = 'week' | 'month' | 'day';

const CalendarControls: VFC<CalendarControlsProps> = ({
    activeDate,
    additionalControls,
    handleChangeDate,
    mode,
    navigateBeforeToday,
    title,
}) => {
    const { formatMessage } = useIntl();

    return (
        <div className="flex justify-between items-center w-full here">
            <Typography.Title className="mb-0 font-24 uppercase">{title}</Typography.Title>

            <div className="flex justify-between items-center gap-12">
                <div>{additionalControls}</div>

                <div className="flex justify-between items-center w-full">
                    <Button
                        disabled={
                            navigateBeforeToday
                                ? !navigateBeforeToday
                                : activeDate.subtract(1, mode).isBefore(dayjs(), mode)
                        }
                        icon={<ChevronLeft className="icon-xs" />}
                        onClick={() => handleChangeDate(activeDate.subtract(1, mode))}
                        type="primary"
                    />
                    <Button
                        className="ml-1"
                        icon={<ChevronRight className="icon-xs" />}
                        onClick={() => handleChangeDate(activeDate.add(1, mode))}
                        type="primary"
                    />
                    <Button
                        className="ml-4"
                        disabled={dayjs().isSame(activeDate, mode)}
                        onClick={() => handleChangeDate(dayjs())}
                        type="primary"
                    >
                        {formatMessage(genericMessages.today)}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CalendarControls;
