import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';

import '../assets/styles/Header.less';

import { getRoute, RoutePathName } from '../routes';
import Logo from './Logo';
import UserDropdown from './UserDropdown';
import useEnv from '../hooks/useEnv';
import constants from '../config/constants';

const Header: FC = () => {
    const env = useEnv();

    return (
        <Layout.Header id="main-header">
            <Link to={getRoute(RoutePathName.home)} className="flex items-center gap-4">
                <Logo color="red" style={{ width: 61, height: 40 }} />
                <span className="bg-white rounded-xl py-1 px-3 text-sm uppercase font-bold text-primary space-x-2">
                    {constants.HEADER_PRODUCT_NAME}
                    {env && ['development', 'preprod'].includes(env) && (
                        <>
                            {constants.HEADER_PRODUCT_NAME && ' - '}
                            <span className="text-xs">{env === 'development' ? 'LOCAL' : 'PREPROD'}</span>
                        </>
                    )}
                </span>
            </Link>
            <UserDropdown />
        </Layout.Header>
    );
};

export default Header;
