import { FormattedDateTimeRange } from 'react-intl';

import { getTimeAsDayjs } from '../../helpers';
import { getDataFromFieldName } from './DetailsFormCard';
import { FormFieldProps } from './FormField';

interface OpenHoursDetailsProps<TData> {
    field?: FormFieldProps<TData>;
    record?: TData;
    dataGetter: typeof getDataFromFieldName;
    disabledMessage: string;
}

const OpenHoursDetails = <TData extends Record<string, any>>({
    field,
    record,
    dataGetter,
    disabledMessage,
}: OpenHoursDetailsProps<TData>) =>
    dataGetter(record, [...(field?.name ?? []), 'isEnabled']) &&
    dataGetter(record, [...(field?.name ?? []), 'fromHour']) &&
    dataGetter(record, [...(field?.name ?? []), 'toHour']) ? (
        <FormattedDateTimeRange
            from={getTimeAsDayjs(dataGetter(record, [...(field?.name ?? []), 'fromHour']) as number).toDate()}
            to={getTimeAsDayjs(dataGetter(record, [...(field?.name ?? []), 'toHour']) as number).toDate()}
            hour="2-digit"
            minute="2-digit"
        />
    ) : (
        <>{disabledMessage}</>
    );

export default OpenHoursDetails;
