import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Form, ModalProps, Row, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';

import CustomModal from '../../../components/CustomModal';
import { TaskGroup, Transporter, Vehicle } from '../../../queries/api/types';
import { useTaskGroupDetails } from '../../../queries/taskGroups';
import formMessages from '../../../i18n/formMessages';
import { useTransporterDetails } from '../../../queries/transporters';

export interface TaskGroupTransporterServiceModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, unknown> {
    taskGroupId?: TaskGroup['id'];
    transporterId?: Transporter['id'];
    vehicle?: Vehicle;
    loading?: boolean;
    onConfirm?: (transporterServiceId?: string) => void;
    onCancel?: () => void;
}

const TaskGroupTransporterServiceModal = NiceModal.create<TaskGroupTransporterServiceModalProps>(
    ({ taskGroupId, transporterId, vehicle, loading, onConfirm, onCancel, ...props }) => {
        const { formatMessage } = useIntl();
        const [transporterServiceId, setTransporterServiceId] = useState<string>();
        const modal = useModal();
        const { data: transporter } = useTransporterDetails(transporterId, { enabled: !!transporterId });
        const { data: taskGroup } = useTaskGroupDetails(taskGroupId, { enabled: !!taskGroupId });
        const selectOptions = useMemo(() => {
            return transporter?.services
                ?.filter(
                    (service) =>
                        service.vehicleType?.id === vehicle?.type?.id &&
                        dayjs(taskGroup?.date).isAfter(service.referencePeriod.fromDate, 'day') &&
                        dayjs(taskGroup?.date).isBefore(service.referencePeriod.toDate, 'day')
                )
                .map((service) => ({
                    label: service.label,
                    value: service.id,
                }));
        }, [taskGroup?.date, transporter?.services, vehicle?.type?.id]);

        const onFormSubmit = () => {
            try {
                onConfirm?.(transporterServiceId);
                modal.resolveHide();
            } catch (e) {
                modal.reject();
            }
        };

        return (
            <CustomModal
                footer={false}
                width={464}
                closable={false}
                maskClosable={false}
                title={formatMessage({
                    id: 'task_group.transporter_service_modal.title',
                    defaultMessage: 'Prestation partenaire',
                })}
                {...props}
                {...antdModal(modal)}
            >
                <Form name="transporterServiceForm" onFinish={onFormSubmit}>
                    <Form.Item
                        label={formatMessage({
                            id: 'task_group.transporter_service_modal.select_label',
                            defaultMessage: 'Prestation du partenaire associée à cet OT',
                        })}
                        labelCol={{ span: 24 }}
                        name={'transporterServiceId'}
                        preserve={false}
                    >
                        <Select
                            placeholder={formatMessage(formMessages.selectPlaceholder)}
                            onChange={setTransporterServiceId}
                            options={selectOptions}
                            className="w-full"
                        />
                    </Form.Item>
                    <Row justify="end" gutter={8}>
                        <Col>
                            <Button
                                type="primary"
                                ghost
                                onClick={() => {
                                    onCancel?.();
                                    modal.hide();
                                }}
                            >
                                <FormattedMessage {...formMessages.cancel} />
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" htmlType="submit" disabled={!transporterServiceId} loading={loading}>
                                <FormattedMessage {...formMessages.validate} />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </CustomModal>
        );
    }
);

export default TaskGroupTransporterServiceModal;
