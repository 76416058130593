import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Form, Input, Modal, ModalProps, Row } from 'antd';
import { useIntl } from 'react-intl';
import { useForm } from 'antd/lib/form/Form';
import { useCallback } from 'react';

import genericMessages from '../i18n/genericMessages';
import taskGroupMessages from '../i18n/taskGroupMessages';
import { useTaskGroupUpdate } from '../queries/taskGroups';
import { successMessage } from '../helpers/message';

interface OrderNumberModalProps extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>, Record<string, any> {
    taskGroupId: string;
}

interface orderNumberFormShape {
    orderNumber: string;
}

const OrderNumberModal = NiceModal.create<OrderNumberModalProps>(({ taskGroupId, ...props }) => {
    const { formatMessage } = useIntl();
    const orderNumberModal = useModal();
    const [form] = useForm();
    const { mutate: updateTaskGroup, isLoading: isUpdateTaskGroupLoading } = useTaskGroupUpdate();

    const onFinish = useCallback(
        (values: orderNumberFormShape) => {
            updateTaskGroup(
                { id: taskGroupId, ...values },
                {
                    onSuccess: () => {
                        successMessage({ content: formatMessage(taskGroupMessages.editOrderNumberSuccess) });
                        orderNumberModal.hide();
                    },
                }
            );
        },
        [formatMessage, orderNumberModal, taskGroupId, updateTaskGroup]
    );

    return (
        <Modal
            {...props}
            {...antdModal(orderNumberModal)}
            afterClose={orderNumberModal.remove}
            closable={false}
            footer={false}
            centered
            title={<span className="font-bold">{formatMessage(taskGroupMessages.orderNumberModalTitle)}</span>}
        >
            <Row>
                <Col span={24}>
                    <Form<orderNumberFormShape> form={form} onFinish={onFinish}>
                        <Form.Item name="orderNumber">
                            <Input placeholder={formatMessage(taskGroupMessages.orderNumberModalPlaceholder)} />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col span={24} className="flex justify-end gap-2">
                    <Button
                        key="cancelBtn"
                        onClick={orderNumberModal.hide}
                        className="text-blue"
                        disabled={isUpdateTaskGroupLoading}
                    >
                        {formatMessage(genericMessages.cancel)}
                    </Button>
                    <Button key="yesBtn" onClick={form.submit} type="primary" loading={isUpdateTaskGroupLoading}>
                        {formatMessage(genericMessages.validate)}
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
});
export default OrderNumberModal;
