import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Input, Table, Space, TableProps, InputProps, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { HiOutlineCalendar } from 'react-icons/hi';
import dayjs from 'dayjs';

import Seo from '../../components/Seo';
import ListTitle from '../../components/ListTitle';
import ApiResult from '../../components/ApiResult';
import useQueryParams from '../../hooks/queryParams';
import { sortOrderConverter } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import { Download, Eye, Search } from '../../components/icons';
import genericMessages from '../../i18n/genericMessages';
import preBillingMessages from '../../i18n/preBillingMessages';
import DatePicker from '../../components/DatePicker';
import TransporterSelect from '../../components/selects/TransporterSelect';
import {
    usePreBillingTransporterDetailsExport,
    usePreBillingTransporterExport,
    usePreBillingTransportersList,
} from '../../queries/preBilling';
import { PreBillingTransporter, Transporter } from '../../queries/api/types';
import { errorMessage } from '../../helpers/message';

let searchTimeout: number;

const PreBillingTransportersList: FC = () => {
    const { formatMessage, formatNumber } = useIntl();
    const [isExportAllEnabled, setIsExportAllEnabled] = useState(false);
    const [currentTransporterExporting, setCurrentTransporterExporting] = useState<Transporter['id']>();
    const [queryParams, setQueryParams] = useQueryParams('PreBillingTransportersList');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const transporterIds = queryParams.getAll('transporterIds') ?? undefined;
    const fromDate = queryParams.get('fromDate') ?? undefined;
    const toDate = queryParams.get('toDate') ?? undefined;

    const {
        data: transporters,
        isLoading,
        isFetching,
        isError,
        error,
    } = usePreBillingTransportersList({
        page,
        search,
        sort,
        sortOrder,
        transporter: transporterIds,
        fromDate,
        toDate,
    });
    const { isLoading: isExportingAll } = usePreBillingTransporterExport(
        { fromDate, toDate },
        {
            enabled: isExportAllEnabled,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
                });
            },
            onSettled: () => {
                setIsExportAllEnabled(false);
            },
        }
    );
    usePreBillingTransporterDetailsExport(
        {
            transporterId: currentTransporterExporting,
        },
        {
            enabled: Boolean(currentTransporterExporting),
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
                });
            },
            onSettled: () => {
                setCurrentTransporterExporting(undefined);
            },
        }
    );
    const onTableChange: TableProps<PreBillingTransporter>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
            });
        },
        [setQueryParams]
    );
    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                    sort: undefined,
                    sortOrder: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );
    const getDetailsLink = useCallback(
        (id: string) => {
            const searchParams = new URLSearchParams();
            if (fromDate && toDate) {
                searchParams.append('fromDate', fromDate);
                searchParams.append('toDate', toDate);
            }
            if (search) {
                searchParams.append('search', search);
            }
            transporterIds.forEach((id) => searchParams.append('transporterIds', id));
            return {
                pathname: getRoute(RoutePathName.preBillingTransportersDetails, {
                    transporterId: id,
                }),
                search: searchParams.toString(),
            };
        },
        [fromDate, toDate, search, transporterIds]
    );
    const columns: ColumnsType<PreBillingTransporter> = useMemo(
        () => [
            {
                key: 'transporterName',
                title: formatMessage(genericMessages.partner),
                render: (_, item) => (
                    <Link to={getDetailsLink(item.id)} className="blue-link font-bold">
                        {item.transporterName}
                    </Link>
                ),
                sorter: true,
                width: 230,
            },
            {
                key: 'taskGroupCount',
                title: formatMessage(genericMessages.taskGroup, { count: 2 }),
                render: (_, item) => (item.taskGroupCount ? formatNumber(item.taskGroupCount) : '-'),
            },
            {
                key: 'handlingUnit',
                title: formatMessage(preBillingMessages.handlingUnitShort),
                render: (_, item) => (item.handlingUnit ? formatNumber(item.handlingUnit) : '-'),
            },
            {
                key: 'taskCount',
                title: formatMessage(preBillingMessages.taskCountShort),
                render: (_, item) => (item.taskCount ? formatNumber(item.taskCount) : '-'),
            },
            {
                key: 'mileage',
                title: formatMessage(preBillingMessages.mileage),
                render: (_, item) =>
                    item.mileage ? <FormattedNumber value={item.mileage} style="unit" unit="kilometer" /> : '—',
            },
            {
                key: 'totalAmount.purchased',
                title: formatMessage(genericMessages.total),
                render: (_, item) =>
                    item.purchased ? (
                        <FormattedNumber
                            style="currency"
                            currency="EUR"
                            maximumFractionDigits={2}
                            minimumFractionDigits={0}
                            value={item.purchased}
                        />
                    ) : (
                        '—'
                    ),
                sorter: true,
            },
            {
                key: 'actions',
                title: formatMessage(genericMessages.actions),
                render: (_, item) => (
                    <div className="flex items-center space-x-2">
                        <Link to={getDetailsLink(item.id)}>
                            <Button type="text" icon={<Eye className="text-xl text-faded-blue" />} />
                        </Link>
                        <Button
                            type="text"
                            icon={<Download className="text-xl text-faded-blue" />}
                            onClick={() => setCurrentTransporterExporting(item.id)}
                            loading={currentTransporterExporting === item.id}
                        />
                    </div>
                ),
                width: 136,
                fixed: 'right',
            },
        ],
        [currentTransporterExporting, formatMessage, formatNumber, getDetailsLink]
    );
    const pageTitle = formatMessage({
        id: 'pre_billing.transporters.title',
        defaultMessage: 'Pré-facturation partenaires transport',
        description: 'Transporter pre billing list title',
    });

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                <ListTitle count={transporters?.totalCount} className="mb-0 uppercase">
                    {pageTitle}
                </ListTitle>
                <Button
                    type="primary"
                    icon={<Download className="text-base" />}
                    onClick={() => setIsExportAllEnabled(true)}
                    loading={isExportingAll}
                >
                    <FormattedMessage {...genericMessages.export} tagName="span" />
                </Button>
            </div>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <div className="flex items-center gap-4">
                    <DatePicker.RangePicker
                        defaultValue={fromDate && toDate ? [dayjs(fromDate), dayjs(toDate)] : null}
                        onChange={(values) => {
                            setQueryParams({
                                fromDate: values?.[0]?.toISOString(),
                                toDate: values?.[1]?.toISOString(),
                            });
                        }}
                        style={{ width: 284 }}
                        format="DD/MM/YYYY"
                        suffixIcon={<HiOutlineCalendar />}
                        size="small"
                        allowClear
                    />
                    <TransporterSelect
                        onChange={(value) => setQueryParams({ transporterIds: value })}
                        mode="multiple"
                        style={{ width: 280 }}
                        size="small"
                    />
                    <Input
                        placeholder={formatMessage(preBillingMessages.searchInputPlaceholder)}
                        onChange={onSearch}
                        defaultValue={search}
                        prefix={<Search className="text-primary text-base leading-4" />}
                        size="small"
                        className="flex-1"
                        allowClear
                    />
                </div>
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Table<PreBillingTransporter>
                        rowKey="id"
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={transporters?.items}
                        pagination={{
                            total: transporters?.totalCount,
                            current: page + 1,
                            pageSize: transporters?.pageSize,
                            position: ['bottomCenter'],
                            hideOnSinglePage: true,
                        }}
                        scroll={{
                            x: 1440,
                            y: 'calc(100vh - 244px)',
                            scrollToFirstRowOnChange: true,
                        }}
                        onChange={onTableChange}
                    />
                )}
            </Space>
        </>
    );
};

export default PreBillingTransportersList;
