import { FC, ReactNode, useCallback, useMemo } from 'react';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { Table, Space, Input, InputProps, Tag, Tooltip, Button, Dropdown, Menu } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, LinkProps } from 'react-router-dom';

import { useVehicleList } from '../../queries/vehicles';
import { Vehicle, ValueListSlug } from '../../queries/api/types';
import { DotsHorizontal, Eye, Qrcode, Search } from '../../components/icons';
import useQueryParams from '../../hooks/queryParams';
import { sortOrderConverter } from '../../helpers';
import { VehicleListPayload } from '../../queries/api/vehicles';
import ListTitle from '../ListTitle';
import genericMessages from '../../i18n/genericMessages';
import BasicList from '../BasicList';
import TransporterSelect from '../selects/TransporterSelect';
import ValueListItemSelect from '../selects/ValueListItemSelect';
import vehicleMessages from '../../i18n/vehicleMessages';
import { useValueListItemList } from '../../queries/valueListItems';
import ApiResult from '../ApiResult';
import { downloadQrCodeAsImage } from '../../helpers/qrCode';

let searchTimeout: number;

interface VehiclesListProps {
    queryParamsKey: string;
    queryPayload?: VehicleListPayload;
    title: ReactNode;
    backRoute?: LinkProps['to'];
    listItemLink: (item: Vehicle) => LinkProps['to'];
    scrollOffset?: number;
}

const VehiclesList: FC<VehiclesListProps> = ({
    queryParamsKey,
    queryPayload,
    title,
    backRoute,
    listItemLink,
    children,
    scrollOffset,
}) => {
    const { formatMessage } = useIntl();
    const [queryParams, setQueryParams] = useQueryParams(queryParamsKey);
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const ownerIds = queryParams.getAll('ownerIds') ?? undefined;
    const brandIds = queryParams.getAll('brandIds') ?? undefined;
    const modelIds = queryParams.getAll('modelIds') ?? undefined;
    const typeIds = queryParams.getAll('typeIds') ?? undefined;
    const groupIds = queryParams.getAll('groupIds') ?? undefined;
    const inHouse = queryParams.get('inHouse') ? true : undefined;
    const {
        data: vehiclesList,
        isLoading,
        isFetching,
        isError,
        error,
    } = useVehicleList({
        ...queryPayload,
        page,
        search,
        sort,
        sortOrder,
        ownerIds,
        brandIds,
        modelIds,
        typeIds,
        groupIds,
        inHouse,
    });
    const { data: vehicleOwnershipTypes } = useValueListItemList({
        valueListSlug: ValueListSlug.vehicleOwnershipTypes,
    });
    const rentalOwnershipTypeLabel = vehicleOwnershipTypes?.items?.find(
        (item) => item.fields.vehicleOwnershipTypeReference === 'inHouse'
    )?.fields.vehicleOwnershipTypeName;
    const onTableChange: TableProps<Vehicle>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
            });
        },
        [setQueryParams]
    );
    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                    sort: undefined,
                    sortOrder: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );
    const columns: ColumnsType<Vehicle> = useMemo(
        () => [
            {
                key: 'reference',
                title: formatMessage({
                    id: 'vehicle_list.column.reference',
                    defaultMessage: 'ID / Référence',
                }),
                render: (_, item) => (
                    <Link to={listItemLink(item)} className="blue-link font-bold">
                        {item.reference}
                    </Link>
                ),
                sorter: true,
            },
            {
                key: 'owner',
                title: formatMessage(vehicleMessages.ownership),
                render: (_, item) => (
                    <>
                        {!item.transporter && rentalOwnershipTypeLabel}
                        {item.transporter?.name}
                    </>
                ),
                filteredValue: [...ownerIds, ...(inHouse ? ['inHouse'] : [])],
                filterDropdown: () => (
                    <div className="p-3">
                        <TransporterSelect
                            hasInHouseOption
                            defaultValue={[...ownerIds, ...(inHouse ? ['inHouse'] : [])]}
                            onChange={(value) =>
                                setQueryParams({
                                    inHouse: value?.includes('inHouse') || undefined,
                                    ownerIds: (value as string[]).filter((id) => id !== 'inHouse'),
                                })
                            }
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                key: 'groups',
                title: formatMessage(genericMessages.group, { count: 2 }),
                render: (_, item) => (
                    <BasicList inline>
                        {item.groups.map((group) => (
                            <li key={group.id}>
                                <Tooltip title={group.fields.name}>
                                    <Tag>{group.fields.reference}</Tag>
                                </Tooltip>
                            </li>
                        ))}
                    </BasicList>
                ),
                filteredValue: groupIds,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={groupIds}
                            valueListSlug={ValueListSlug.groups}
                            onChange={(value) => setQueryParams({ groupIds: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                dataIndex: 'plateNumber',
                title: formatMessage(vehicleMessages.plateNumber),
            },
            {
                key: 'brand',
                title: formatMessage(genericMessages.brand, { count: 1 }),
                render: (_, item) => item.brand?.fields[Object.keys(item.brand?.fields)[0]],
                filteredValue: brandIds,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={brandIds}
                            valueListSlug={ValueListSlug.vehicleBrands}
                            onChange={(value) => setQueryParams({ brandIds: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                key: 'model',
                title: formatMessage(genericMessages.model, { count: 1 }),
                render: (_, item) => item.model?.fields[Object.keys(item.model?.fields)[0]],
                filteredValue: modelIds,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={modelIds}
                            valueListSlug={ValueListSlug.vehicleModels}
                            onChange={(value) => setQueryParams({ modelIds: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                key: 'type',
                title: formatMessage(genericMessages.type, { count: 1 }),
                render: (_, item) => item.type?.fields[Object.keys(item.type?.fields)[0]],
                filteredValue: typeIds,
                filterDropdown: () => (
                    <div className="p-3">
                        <ValueListItemSelect
                            defaultValue={typeIds}
                            valueListSlug={ValueListSlug.vehicleTypes}
                            onChange={(value) => setQueryParams({ typeIds: value })}
                            mode="multiple"
                            style={{ width: 260 }}
                            size="small"
                        />
                    </div>
                ),
            },
            {
                key: 'actions',
                title: formatMessage(genericMessages.actions),
                render: (_, item) => (
                    <>
                        <Link to={listItemLink(item)}>
                            <Button type="text" icon={<Eye className="text-xl text-faded-blue" />} />
                        </Link>
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu
                                    items={[
                                        {
                                            key: 'qrCode',
                                            icon: <Qrcode />,
                                            onClick: () => {
                                                downloadQrCodeAsImage(item.id, `qrcode-vehicule-${item.reference}`);
                                            },
                                            label: (
                                                <FormattedMessage
                                                    id="operator_list.column.actions.menu.qr_code"
                                                    defaultMessage="Télécharger le QR Code"
                                                />
                                            ),
                                        },
                                    ]}
                                />
                            }
                            placement="topRight"
                        >
                            <Button type="text" icon={<DotsHorizontal className="text-xl text-faded-blue" />} />
                        </Dropdown>
                    </>
                ),
                width: 136,
                fixed: 'right',
            },
        ],
        [
            brandIds,
            formatMessage,
            groupIds,
            inHouse,
            listItemLink,
            modelIds,
            ownerIds,
            rentalOwnershipTypeLabel,
            setQueryParams,
            typeIds,
        ]
    );

    return (
        <>
            <div className="flex justify-between items-center mb-6">
                <ListTitle count={vehiclesList?.totalCount} className="mb-0 uppercase" backRoute={backRoute}>
                    {title}
                </ListTitle>
                {children}
            </div>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Input
                    placeholder={formatMessage({
                        id: 'vehicles_list.search_field.placeholder',
                        defaultMessage: 'Rechercher un ID, une immatriculation…',
                        description: 'Vehicle page search field placeholder',
                    })}
                    onChange={onSearch}
                    defaultValue={search}
                    prefix={<Search className="text-primary text-base leading-4" />}
                    size="small"
                    allowClear
                />
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Table<Vehicle>
                        rowKey="id"
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={vehiclesList?.items}
                        pagination={{
                            total: vehiclesList?.totalCount,
                            current: page + 1,
                            pageSize: vehiclesList?.pageSize,
                            position: ['bottomCenter'],
                            hideOnSinglePage: true,
                        }}
                        scroll={{
                            x: 1440,
                            y: scrollOffset
                                ? `calc(100vh - ${
                                      (vehiclesList?.pageCount ?? 0) > 1 ? scrollOffset + 64 : scrollOffset
                                  }px)`
                                : undefined,
                            scrollToFirstRowOnChange: true,
                        }}
                        onChange={onTableChange}
                    />
                )}
            </Space>
        </>
    );
};

export default VehiclesList;
