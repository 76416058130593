import { Card, Spin, Table, TableProps, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState, VFC } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';

import ApiResult from '../../../components/ApiResult';
import FormattedDuration from '../../../components/FormattedDuration';
import LabelWithTooltipIcon from '../../../components/LabelWithTooltipIcon';
import { sortOrderConverter } from '../../../helpers';
import genericMessages from '../../../i18n/genericMessages';
import { Operator, OperatorShift } from '../../../queries/api/types';
import { useOperatorListShifts } from '../../../queries/operators';

interface OperatorWorkedDaysListCardProps {
    operatorId: Operator['id'];
}

const OperatorWorkedDaysListCard: VFC<OperatorWorkedDaysListCardProps> = ({ operatorId }) => {
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState<string>('startDate');
    const [sortOrder, setSortOrder] = useState<string>('desc');
    const { formatMessage } = useIntl();
    const {
        data: shifts,
        isLoading,
        error,
        isError,
    } = useOperatorListShifts({
        id: operatorId,
        wholeDay: true,
        page,
        sort: sort || undefined,
        sortOrder: sort ? sortOrder : undefined,
        pageSize: 8,
    });
    const onTableChange: TableProps<OperatorShift>['onChange'] = (pagination, _, sorter) => {
        const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

        setPage((pagination.current ?? 1) - 1);
        setSort(`${sortObj.column?.key ?? ''}`);
        setSortOrder(sortOrderConverter(sortObj.order ?? ''));
    };
    const columns = useMemo<ColumnsType<OperatorShift>>(
        () => [
            {
                key: 'startDate',
                title: formatMessage(genericMessages.date),
                render: (value, record) => <FormattedDate value={record.startDate} />,
                sorter: true,
                defaultSortOrder: 'descend',
            },
            {
                key: 'startTime',
                title: formatMessage({
                    id: 'management.operators.history.check_in',
                    defaultMessage: 'Prise de poste',
                    description: 'table header',
                }),
                render: (value, record) => <FormattedTime value={record.startDate} />,
                sorter: true,
            },
            {
                key: 'endDate',
                title: formatMessage({
                    id: 'management.operators.history.check_out',
                    defaultMessage: 'Fin de poste',
                    description: 'table header',
                }),
                render: (value, record) => (record.endDate ? <FormattedTime value={record.endDate} /> : '-'),
                sorter: true,
            },
            {
                key: 'workedTime',
                title: formatMessage({
                    id: 'management.operators.history.effective_work_time',
                    defaultMessage: 'Temps de travail effectif',
                    description: 'table header',
                }),
                render: (value, record) =>
                    record.endDate ? (
                        <FormattedDuration value={record.workedTime ?? 0} />
                    ) : (
                        formatMessage(genericMessages.inProgress)
                    ),
                sorter: true,
            },
        ],
        [formatMessage]
    );

    return (
        <Spin spinning={isLoading}>
            <Card>
                <Typography.Title level={2} className="text-base font-medium text-faded-blue mb-6">
                    <LabelWithTooltipIcon tooltip="à définir">
                        <FormattedMessage
                            id="management.operators.history.worked_days"
                            defaultMessage="Historique des prises de poste et des fins de poste"
                            description="Card title"
                        />
                    </LabelWithTooltipIcon>
                </Typography.Title>
                {isError ? (
                    <ApiResult status={error.response?.status} />
                ) : (
                    <Table<OperatorShift>
                        columns={columns}
                        rowKey="id"
                        dataSource={shifts?.items}
                        onChange={onTableChange}
                        pagination={{
                            total: shifts?.totalCount,
                            current: page + 1,
                            pageSize: shifts?.pageSize,
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            showSizeChanger: false,
                            className: 'mb-0',
                        }}
                        size="small"
                        bordered
                    />
                )}
            </Card>
        </Spin>
    );
};

export default OperatorWorkedDaysListCard;
