import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, FormProps, Input, Segmented, Select } from 'antd';
import { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessage, successMessage } from '../../../helpers/message';
import { getRequiredRule } from '../../../i18n';
import formMessages from '../../../i18n/formMessages';
import genericMessages from '../../../i18n/genericMessages';
import { useValueListItemCreate, useValueListItemList } from '../../../queries/valueListItems';
import { useValueListList } from '../../../queries/valueLists';
import ApiResult from '../../../components/ApiResult';
import { ValueListSlug } from '../../../queries/api/types';

const VehicleBrandsAndModelsValueListAddCard: VFC = () => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [type, setType] = useState<string | number>('brand');
    const {
        data: valueLists,
        isLoading,
        isError,
        error,
    } = useValueListList({
        pageSize: 200,
    });
    const brandsValueList = valueLists?.items?.find((item) => item.slug === ValueListSlug.vehicleBrands);
    const modelsValueList = valueLists?.items?.find((item) => item.slug === ValueListSlug.vehicleModels);
    const {
        data: brandsValueListItem,
        isLoading: brandsValueListItemIsLoading,
        isFetching: brandsValueListItemIsFetching,
    } = useValueListItemList(
        { valueListSlug: brandsValueList?.slug, pageSize: 200 },
        { enabled: type === 'model' && !!brandsValueList?.slug }
    );
    const field = type === 'brand' ? brandsValueList?.fields?.[0] : modelsValueList?.fields?.[0];
    const { mutate: createValueListItem, isLoading: isCreating } = useValueListItemCreate();
    const onSubmit: FormProps['onFinish'] = ({ brandValueListItemId, ...values }) => {
        createValueListItem(
            {
                valueListSlug: type === 'brand' ? brandsValueList?.slug : modelsValueList?.slug,
                title: 'dummy',
                meta: {},
                ...(brandValueListItemId ? { meta: { brandValueListItemId } } : {}),
                ...values,
            },
            {
                onSuccess: () => {
                    form.resetFields();
                    successMessage({
                        content: formatMessage({
                            id: 'value_list_add_card.success_message',
                            defaultMessage: 'Valeur ajoutée avec succès',
                        }),
                    });
                },
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error.response?.status,
                        }),
                    });
                },
            }
        );
    };

    return (
        <Card
            title={formatMessage({
                id: 'value_list_brands_models_add_card.title',
                defaultMessage: 'Ajouter une marque / un modèle',
            })}
            loading={isLoading}
        >
            {isError ? (
                <ApiResult status={error?.response?.status} />
            ) : (
                <Form form={form} onFinish={onSubmit} layout="vertical" requiredMark>
                    <Form.Item
                        label={formatMessage({
                            id: 'value_list_brands_models_add_card.choice.label',
                            defaultMessage: 'Type à ajouter',
                            description: 'form item label',
                        })}
                    >
                        <Segmented
                            options={[
                                { label: formatMessage(genericMessages.brand, { count: 1 }), value: 'brand' },
                                { label: formatMessage(genericMessages.model, { count: 1 }), value: 'model' },
                            ]}
                            value={type}
                            onChange={setType}
                            block
                        />
                    </Form.Item>
                    {type === 'model' && (
                        <Form.Item
                            name="brandValueListItemId"
                            label={brandsValueList?.fields?.[0]?.title}
                            rules={
                                brandsValueList?.fields?.[0]?.isRequired ? [getRequiredRule(formatMessage)] : undefined
                            }
                            required={brandsValueList?.fields?.[0]?.isRequired}
                        >
                            <Select
                                placeholder={formatMessage({
                                    id: 'value_list_brands_models_add_card.brand_select.placeholder',
                                    defaultMessage: 'Sélectionner une marque',
                                })}
                                loading={brandsValueListItemIsLoading || brandsValueListItemIsFetching}
                                options={brandsValueListItem?.items?.map((item) => ({
                                    label: item.fields[brandsValueList?.fields?.[0]?.name ?? ''],
                                    value: item.id,
                                }))}
                                filterOption={(input, option) =>
                                    (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                showSearch
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        name={['fields', field?.name ?? '']}
                        label={field?.title}
                        rules={field?.isRequired ? [getRequiredRule(formatMessage)] : undefined}
                        required={field?.isRequired}
                    >
                        <Input placeholder={formatMessage(formMessages.valueListInputPlaceholder)} />
                    </Form.Item>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        htmlType="submit"
                        loading={isCreating}
                        block
                    >
                        <FormattedMessage {...formMessages.add} />
                    </Button>
                </Form>
            )}
        </Card>
    );
};

export default VehicleBrandsAndModelsValueListAddCard;
