import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Modal, ModalProps } from 'antd';

const ModalOverlap = NiceModal.create<Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>>(({ ...props }) => {
    const modalOverlay = useModal();

    return (
        <Modal {...props} {...antdModal(modalOverlay)}>
            {props.children}
        </Modal>
    );
});

export default ModalOverlap;
