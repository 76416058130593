import { Form, Input, InputNumber, Select } from 'antd';
import { RuleRender } from 'antd/lib/form';
import dayjs, { Dayjs } from 'dayjs';
import { FormattedDate, FormattedNumber, IntlShape } from 'react-intl';

import ClosedDaysFormFields from '../../../components/form/ClosedDaysFormFields';
import { DetailsFormCardProps } from '../../../components/form/DetailsFormCard';
import OpenHoursDetails from '../../../components/form/OpenHoursDetails';
import WorkingHolidaysDetails from '../../../components/form/WorkingHolidaysDetails';
import { sectionCardFormItemLayout } from '../../../components/SectionCard';
import { getTimeAsDayjs, getTimeAsNumber } from '../../../helpers';
import { getRequiredRule } from '../../../i18n';
import formMessages from '../../../i18n/formMessages';
import genericMessages from '../../../i18n/genericMessages';
import transporterMessages from '../../../i18n/transporterMessages';
import { TransporterUpdatePayload } from '../../../queries/api/transporters';
import {
    Transporter,
    TransporterService,
    ValueListItem,
    ValueListSlug,
    WeekDay,
    WeekDaysRecord,
} from '../../../queries/api/types';
import TransporterBillingAddressFields from './TransporterBillingAddressFields';
import TransporterExceptionnalDaysDetails from './TransporterExceptionnalDaysDetails';

export { default as TransportersList } from './TransportersList';
export { default as TransportersValueList } from './TransportersValueList';
export { default as TransporterCreate } from './TransporterCreate';
export { default as TransporterDetails } from './TransporterDetails';

export enum TransporterFormSection {
    companyName = 'companyName',
    groups = 'groups',
    addresses = 'addresses',
    contacts = 'contacts',
    services = 'services',
    openHours = 'openHours',
    workingHolidays = 'workingHolidays',
    exceptionnalDays = 'exceptionnalDays',
}

export interface TransporterForm
    extends Omit<
        Transporter,
        'id' | 'openHours' | 'exceptionnalDays' | 'services' | 'passkey' | 'workingHolidays' | 'groups'
    > {
    openHours: WeekDaysRecord<{
        isEnabled: boolean;
        fromHour: Dayjs;
        toHour: Dayjs;
    }>;
    exceptionnalDays: Array<{
        isOpened: boolean;
        fromDate: Dayjs;
        toDate: Dayjs;
    }>;
    services?: Array<
        Omit<TransporterService, 'referencePeriod' | 'type' | 'vehicleType'> & {
            referencePeriod: [Dayjs, Dayjs];
            type: ValueListItem['id'];
            vehicleType: ValueListItem['id'];
        }
    >;
    workingHolidays: Array<ValueListItem['id']>;
    groups: Array<ValueListItem['id']>;
}

const initialOpenHourDay = {
    isEnabled: true,
    fromHour: dayjs().hour(6).minute(0),
    toHour: dayjs().hour(20).minute(0),
};

const getTransporterServicesRule: (formatMessage: IntlShape['formatMessage']) => RuleRender =
    (formatMessage) =>
    ({ getFieldsValue, validateFields, getFieldsError }) => ({
        async validator(ruleObj) {
            const servicesFieldsWithErrors = getFieldsError().filter(
                (field) => field.name.includes('services') && !!field.errors.length
            );
            const values = getFieldsValue() as TransporterForm;
            const rule = ruleObj as any; // types don't contain rule.fields, so we do what we must
            const currentServiceIndex = parseInt((rule.field as string).split('.')[1], 10);
            const revalidateFieldsWithErrors = () => {
                if (servicesFieldsWithErrors.length) {
                    validateFields(servicesFieldsWithErrors.map((f) => f.name));
                }
            };

            // don't check if no data (unlikely, but prevents us from making all sorts of checks down below)
            if (isNaN(currentServiceIndex) || !values.services) {
                revalidateFieldsWithErrors();
                return await Promise.resolve();
            }

            const currentService = values.services[currentServiceIndex];

            // don't check if all three fields aren't filled.
            if (
                !currentService.type ||
                !currentService.vehicleType ||
                !currentService.referencePeriod ||
                !currentService.referencePeriod.some((val) => !!val)
            ) {
                revalidateFieldsWithErrors();
                return await Promise.resolve();
            }

            // exclude current service from services to compare with
            const servicesToCompare = values.services.filter((_, index) => index !== currentServiceIndex);

            // if we don't find any other service with the same three fields value combo
            if (
                !servicesToCompare?.some(
                    (service) =>
                        service.vehicleType === currentService.vehicleType &&
                        service.type === currentService.type &&
                        service.referencePeriod?.[0]?.isSame(currentService.referencePeriod[0], 'day') &&
                        service.referencePeriod?.[1]?.isSame(currentService.referencePeriod[1], 'day')
                )
            ) {
                revalidateFieldsWithErrors();
                return await Promise.resolve();
            }

            return await Promise.reject(
                new Error(
                    formatMessage({
                        id: 'transporter.services.rule.error',
                        defaultMessage:
                            'La combinaison des champs "Type de prestation", "Type de véhicule" et "Période de référence" de cette prestation existe déjà dans une autre prestation.',
                    })
                )
            );
        },
    });

export const formatTransporterInitialValue = (details?: Transporter) =>
    ({
        ...details,
        reference: '',
        groups: details?.groups.map((group) => group.id) || [],
        openHours: {
            ...Object.values(WeekDay).reduce(
                (acc, day) => ({
                    ...acc,
                    [day]: {
                        ...initialOpenHourDay,
                        ...(day === WeekDay.sunday ? { isEnabled: false } : {}),
                        ...(details?.openHours[day]
                            ? {
                                  fromHour: getTimeAsDayjs(details?.openHours[day]?.fromHour, '0600'),
                                  toHour: getTimeAsDayjs(details?.openHours[day]?.toHour, '2000'),
                              }
                            : {}),
                    },
                }),
                {}
            ),
        } as TransporterForm['openHours'],
        exceptionnalDays:
            details?.exceptionnalDays?.map((day) => ({
                ...day,
                fromDate: dayjs(day.fromDate),
                toDate: dayjs(day.toDate),
            })) || [],
        services:
            details?.services?.map((service) => ({
                ...service,
                referencePeriod: [dayjs(service.referencePeriod.fromDate), dayjs(service.referencePeriod.toDate)],
                type: service.type?.id,
                vehicleType: service.vehicleType?.id,
            })) || [],
        meta: {
            isSameAddressForBilling: true,
            ...details?.meta,
        },
        workingHolidays: details?.workingHolidays?.map((valueListItem) => valueListItem.id) || [],
    } as TransporterForm); // cast to avoid required props (which are not required as initial values. form validation ensures we cannot submit empty required fields)

export const formatTransporterUpdatePayload =
    (step?: number) =>
    (values: TransporterForm, transporter?: Transporter): TransporterUpdatePayload => {
        const { services, exceptionnalDays, openHours, ...restValues } = values;

        if (!transporter?.id) {
            throw new Error('missing id');
        }
        return {
            ...transporter,
            ...restValues,
            openHours: Object.values(WeekDay).reduce(
                (acc, day) => ({
                    ...acc,
                    [day]: {
                        fromHour:
                            getTimeAsNumber(openHours?.[day]?.fromHour) ?? transporter?.openHours?.[day]?.fromHour ?? 0,
                        toHour: getTimeAsNumber(openHours?.[day]?.toHour) ?? transporter?.openHours?.[day]?.toHour ?? 0,
                        isEnabled: openHours?.[day]?.isEnabled ?? transporter?.openHours?.[day]?.isEnabled ?? false,
                    },
                }),
                // reduce type is fucked up so we have to do this :
                // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
                {} as Transporter['openHours']
            ),
            services:
                services?.map((service) => ({
                    ...service,
                    referencePeriod: {
                        fromDate: service.referencePeriod[0].startOf('day').toISOString(),
                        toDate: service.referencePeriod[1].endOf('day').toISOString(),
                    },
                })) || transporter.services,
            ...(exceptionnalDays
                ? {
                      exceptionnalDays: exceptionnalDays.map((day) => ({
                          ...day,
                          isOpened: !!day.isOpened,
                          fromDate: day.fromDate.toISOString(),
                          toDate: day.toDate.toISOString(),
                      })),
                  }
                : {
                      exceptionnalDays: [],
                  }),
            meta: {
                ...transporter?.meta,
                ...values.meta,
                creationStep:
                    (step ?? 0) > (transporter?.meta?.creationStep ?? 0) ? step : transporter?.meta?.creationStep,
            },
            ...(values.meta?.isSameAddressForBilling !== undefined
                ? {
                      billingAddress: {
                          ...(values.meta?.isSameAddressForBilling
                              ? {
                                    ...values.address,
                                }
                              : values.billingAddress ?? {}),
                      },
                  }
                : {}),
        };
    };

export const getFormSections = (formatMessage: IntlShape['formatMessage'], select?: TransporterFormSection[]) => {
    const sections: {
        [value in TransporterFormSection]: Pick<
            DetailsFormCardProps<Transporter, TransporterForm, TransporterUpdatePayload>,
            'title' | 'editButtonText' | 'fields' | 'renderEdit' | 'renderDetails'
        >;
    } = {
        [TransporterFormSection.companyName]: {
            title: formatMessage({
                id: 'transporter.company_name',
                defaultMessage: 'Raison sociale',
            }),
            editButtonText: formatMessage({
                id: 'transporter.company_name.edit_button',
                defaultMessage: 'Modifier la raison',
            }),
            fields: [
                {
                    name: 'companyName',
                    type: 'string',
                    label: formatMessage({
                        id: 'transporter.company_name',
                        defaultMessage: 'Raison sociale',
                    }),
                    fieldComponentProps: {
                        placeholder: formatMessage({
                            id: 'transporter.company_name.placeholder',
                            defaultMessage: 'Saisir une raison',
                        }),
                    },
                    required: true,
                },
            ],
        },
        [TransporterFormSection.groups]: {
            title: formatMessage(genericMessages.groupLink),
            editButtonText: formatMessage(genericMessages.groupLinkEdit),
            fields: [
                {
                    name: 'groups',
                    type: 'ValueListItemSelect',
                    fieldComponentProps: {
                        mode: 'multiple',
                        valueListSlug: ValueListSlug.groups,
                        itemLabelKey: 'reference',
                    },
                    label: formatMessage(genericMessages.groupLinkLong),
                    required: true,
                },
            ],
        },
        [TransporterFormSection.addresses]: {
            title: formatMessage(genericMessages.address, { count: 2 }),
            editButtonText: formatMessage({
                id: 'transporter.addresses.edit_button',
                defaultMessage: 'Modifier les adresses',
            }),
            fields: [
                {
                    label: formatMessage({
                        id: 'transporter.addresses.production.label',
                        defaultMessage: 'Production',
                    }),
                    fields: [
                        {
                            name: ['address', 'street'],
                            type: 'string',
                            label: formatMessage(genericMessages.address, { count: 1 }),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.addressPlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['address', 'zipCode'],
                            type: 'string',
                            label: formatMessage(genericMessages.zipCode),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.zipCodePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['address', 'city'],
                            type: 'string',
                            label: formatMessage(genericMessages.city),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.cityPlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['address', 'country'],
                            type: 'CountrySelect',
                            label: formatMessage(genericMessages.country),
                            required: true,
                        },
                    ],
                },
                {
                    label: formatMessage(genericMessages.billing),
                    renderEdit: TransporterBillingAddressFields,
                    fields: [
                        {
                            name: ['billingAddress', 'street'],
                            type: 'string',
                            label: formatMessage(genericMessages.address, { count: 1 }),

                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.addressPlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['billingAddress', 'zipCode'],
                            type: 'string',
                            label: formatMessage(genericMessages.zipCode),

                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.zipCodePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['billingAddress', 'city'],
                            type: 'string',
                            label: formatMessage(genericMessages.city),

                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.cityPlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['billingAddress', 'country'],
                            type: 'CountrySelect',
                            label: formatMessage(genericMessages.country),
                            required: true,
                        },
                    ],
                },
            ],
        },
        [TransporterFormSection.contacts]: {
            title: formatMessage(genericMessages.contact, { count: 2 }),
            editButtonText: formatMessage(genericMessages.contactEditButton),
            fields: [
                {
                    label: formatMessage(genericMessages.primaryLabel),
                    fields: [
                        {
                            name: ['primaryContact', 'lastName'],
                            type: 'string',
                            label: formatMessage(genericMessages.lastName),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.lastNamePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['primaryContact', 'firstName'],
                            type: 'string',
                            label: formatMessage(genericMessages.firstName),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.firstNamePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['primaryContact', 'email'],
                            type: 'email',
                            label: formatMessage(genericMessages.email),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.emailPlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['primaryContact', 'phone'],
                            type: 'PhoneInput',
                            label: formatMessage(genericMessages.phone),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.phonePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['primaryContact', 'jobTitle'],
                            type: 'string',
                            label: formatMessage(genericMessages.jobTitle),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.jobTitlePlaceholder),
                            },
                            required: true,
                        },
                    ],
                },
                {
                    label: formatMessage(genericMessages.administrativeLabel),
                    fields: [
                        {
                            name: ['administrativeContact', 'lastName'],
                            type: 'string',
                            label: formatMessage(genericMessages.lastName),

                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.lastNamePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['administrativeContact', 'firstName'],
                            type: 'string',
                            label: formatMessage(genericMessages.firstName),

                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.firstNamePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['administrativeContact', 'email'],
                            type: 'email',
                            label: formatMessage(genericMessages.email),

                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.emailPlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['administrativeContact', 'phone'],
                            type: 'PhoneInput',
                            label: formatMessage(genericMessages.phone),

                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.phonePlaceholder),
                            },
                            required: true,
                        },
                        {
                            name: ['administrativeContact', 'jobTitle'],
                            type: 'string',
                            label: formatMessage(genericMessages.jobTitle),

                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.jobTitlePlaceholder),
                            },
                            required: true,
                        },
                    ],
                    isLast: true,
                },
            ],
        },
        [TransporterFormSection.services]: {
            title: formatMessage(genericMessages.services, { count: 2 }),
            editButtonText: formatMessage({
                id: 'transporter.services.edit_button',
                defaultMessage: 'Modifier les prestations',
            }),
            fields: [
                {
                    name: 'services',
                    type: 'list',
                    label: formatMessage({
                        id: 'transporter.services.label',
                        defaultMessage: 'Ajouter ci-dessous les différentes prestations contractées avec ce partenaire',
                    }),
                    listItemLabel: transporterMessages.serviceIndex,
                    listAddButtonLabel: formatMessage({
                        id: 'transporter.services.add_button',
                        defaultMessage: 'Ajouter une prestation',
                    }),
                    required: true,
                    listAddValues: {
                        toleranceUnit: 'number',
                    },
                    listFields: [
                        {
                            name: 'label',
                            type: 'string',
                            label: formatMessage(genericMessages.label),
                            required: true,
                        },
                        {
                            name: 'type',
                            type: 'ValueListItemSelect',
                            fieldComponentProps: {
                                valueListSlug: ValueListSlug.transporterServices,
                            },
                            formItemProps: {
                                shouldUpdate: true,
                                rules: [getRequiredRule(formatMessage), getTransporterServicesRule(formatMessage)],
                            },
                            label: formatMessage(transporterMessages.serviceTypeLabel),
                            required: true,
                        },
                        {
                            name: 'vehicleType',
                            type: 'ValueListItemSelect',
                            fieldComponentProps: {
                                valueListSlug: ValueListSlug.vehicleTypes,
                            },
                            formItemProps: {
                                shouldUpdate: true,
                                rules: [getRequiredRule(formatMessage), getTransporterServicesRule(formatMessage)],
                            },
                            label: formatMessage(genericMessages.vehicleType),
                            required: true,
                        },
                        {
                            label: formatMessage(transporterMessages.serviceDailyCountLabel),
                            renderEdit: (field, record, listItemName) =>
                                listItemName !== undefined ? (
                                    <Form.Item
                                        label={formatMessage(transporterMessages.serviceDailyCountLabel)}
                                        {...sectionCardFormItemLayout}
                                        key={`dailyCount-${listItemName}`}
                                        required
                                    >
                                        <div className="flex space-x-6">
                                            <div className="flex items-center space-x-4">
                                                <label
                                                    className="block text-base font-medium"
                                                    htmlFor="dailyCountMinimum"
                                                >
                                                    {formatMessage(genericMessages.minimumShort)}
                                                </label>
                                                <Form.Item
                                                    name={[listItemName, 'dailyCount', 'minimum']}
                                                    rules={[getRequiredRule(formatMessage)]}
                                                    id="dailyCountMinimum"
                                                    noStyle
                                                >
                                                    <InputNumber min={0} placeholder="0" />
                                                </Form.Item>
                                            </div>
                                            <div className="flex items-center space-x-4">
                                                <label
                                                    className="block text-base font-medium"
                                                    htmlFor="dailyCountMaximum"
                                                >
                                                    {formatMessage(genericMessages.maximumShort)}
                                                </label>
                                                <Form.Item
                                                    name={[listItemName, 'dailyCount', 'maximum']}
                                                    rules={[getRequiredRule(formatMessage)]}
                                                    id="dailyCountMaximum"
                                                    noStyle
                                                >
                                                    <InputNumber min={0} placeholder="0" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form.Item>
                                ) : (
                                    <></>
                                ),
                            renderDetails: (field, record) => (
                                <>
                                    {formatMessage(genericMessages.minimumShort)}{' '}
                                    {<FormattedNumber value={record?.dailyCount.minimum} />}
                                    {' / '}
                                    {formatMessage(genericMessages.maximumShort)}{' '}
                                    {<FormattedNumber value={record?.dailyCount.maximum} />}
                                </>
                            ),
                        },
                        {
                            name: 'referencePeriod',
                            type: 'DateRangePicker',
                            label: formatMessage(transporterMessages.serviceReferencePeriodLabel),
                            formItemProps: {
                                shouldUpdate: true,
                                rules: [getRequiredRule(formatMessage), getTransporterServicesRule(formatMessage)],
                            },
                            renderDetails: (field, record) => (
                                <>
                                    <FormattedDate value={record?.referencePeriod.fromDate} />
                                    {' - '}
                                    <FormattedDate value={record?.referencePeriod.toDate} />
                                </>
                            ),
                            required: true,
                        },
                        {
                            name: 'dailyCost',
                            type: 'number',
                            label: formatMessage(genericMessages.price),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.pricePlaceholder),
                                addonAfter: '€',
                                min: 0,
                                style: { width: '100%' },
                            },
                            renderDetails: (field, record) => (
                                <FormattedNumber
                                    style="currency"
                                    currency="EUR"
                                    maximumFractionDigits={2}
                                    minimumFractionDigits={0}
                                    value={record?.dailyCost ?? 0}
                                />
                            ),
                            required: true,
                        },
                        {
                            name: 'penaltyCost',
                            type: 'number',
                            label: formatMessage(transporterMessages.servicePenaltyCostLabel),
                            fieldComponentProps: {
                                placeholder: formatMessage(genericMessages.amountSelect),
                                addonAfter: '€',
                                min: 0,
                                style: { width: '100%' },
                            },
                            renderDetails: (field, record) => (
                                <FormattedNumber
                                    style="currency"
                                    currency="EUR"
                                    maximumFractionDigits={2}
                                    minimumFractionDigits={0}
                                    value={record?.penaltyCost}
                                />
                            ),
                            required: true,
                        },
                        {
                            label: formatMessage(transporterMessages.serviceToleranceLabel),
                            renderEdit: (field, record, listItemName) =>
                                listItemName !== undefined ? (
                                    <Form.Item
                                        label={formatMessage(transporterMessages.serviceToleranceLabel)}
                                        {...sectionCardFormItemLayout}
                                    >
                                        <Input.Group className="flex w-full" compact>
                                            <Form.Item
                                                name={[listItemName, 'tolerance']}
                                                rules={[{ required: true, message: 'Street is required' }]}
                                                noStyle
                                            >
                                                <InputNumber
                                                    placeholder={formatMessage(formMessages.inputPlaceholder)}
                                                    style={{ flex: '1' }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name={[listItemName, 'toleranceUnit']}
                                                rules={[getRequiredRule(formatMessage)]}
                                                noStyle
                                            >
                                                <Select>
                                                    <Select.Option value="number">
                                                        <span className="lowercase">
                                                            {formatMessage(genericMessages.vehicles, { count: 2 })}
                                                        </span>
                                                    </Select.Option>
                                                    <Select.Option value="percentage">
                                                        <span className="lowercase">
                                                            {formatMessage(genericMessages.percentage)}
                                                        </span>
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Input.Group>
                                    </Form.Item>
                                ) : (
                                    <></>
                                ),
                            renderDetails: (field, record) => {
                                const tolerance: TransporterService['tolerance'] | undefined = record?.tolerance;
                                const toleranceUnit: TransporterService['toleranceUnit'] | undefined =
                                    record?.toleranceUnit;
                                return tolerance ? (
                                    toleranceUnit === 'percentage' ? (
                                        <FormattedNumber value={tolerance / 100} style="percent" />
                                    ) : (
                                        <>
                                            <FormattedNumber value={tolerance} />{' '}
                                            {toleranceUnit === 'number' &&
                                                formatMessage(genericMessages.vehicles, {
                                                    count: tolerance ?? 1,
                                                })}
                                        </>
                                    )
                                ) : (
                                    '-'
                                );
                            },
                        },
                    ],
                },
            ],
        },
        [TransporterFormSection.openHours]: {
            title: formatMessage(transporterMessages.openHoursLabel),
            editButtonText: formatMessage({
                id: 'transporter.open_hours.edit_button',
                defaultMessage: 'Modifier les jours et horaires',
            }),
            fields: Object.values(WeekDay).map((weekDay) => ({
                name: ['openHours', weekDay],
                type: 'OpenHoursFormFields',
                fieldComponentProps: {
                    enabledMessage: transporterMessages.availableFromTo,
                    disabledMessage: transporterMessages.unavailable,
                    errorMessage: transporterMessages.openHoursErrorFromMustBeBeforeTo,
                },
                renderDetails: (field, record, dataGetter) => (
                    <OpenHoursDetails<Transporter>
                        field={field}
                        record={record}
                        dataGetter={dataGetter}
                        disabledMessage={formatMessage(transporterMessages.unavailable)}
                    />
                ),
                label: formatMessage(genericMessages[weekDay]),
            })),
        },
        [TransporterFormSection.workingHolidays]: {
            title: formatMessage(genericMessages.workingHolidays),
            editButtonText: formatMessage(genericMessages.workingHolidaysEditButton),
            fields: [
                {
                    name: 'workingHolidays',
                    type: 'ValueListItemButtons',
                    fieldComponentProps: {
                        valueListSlug: ValueListSlug.holidays,
                    },
                    label: formatMessage(genericMessages.workingHolidaysLabel),
                    detailsLabel: formatMessage(genericMessages.workingHolidaysDetailLabel),
                    renderDetails: (field, record) => <WorkingHolidaysDetails record={record} />,
                    formItemProps: {
                        labelCol: { span: 24 },
                        wrapperCol: { span: 24 },
                    },
                },
            ],
        },
        [TransporterFormSection.exceptionnalDays]: {
            title: formatMessage(transporterMessages.exceptionnalDaysLabel),
            editButtonText: formatMessage(transporterMessages.exceptionnalDaysEditButton),
            renderEdit: () => (
                <ClosedDaysFormFields
                    name="exceptionnalDays"
                    switchName="isOpened"
                    shouldShowHours={(value) => !value}
                    hoursMessage={genericMessages.unavailableFromTo}
                    datePickerLabel={formatMessage(transporterMessages.exceptionnalDaysDateLabel)}
                    unCheckedLabel={formatMessage(genericMessages.closed)}
                    errorLabel={formatMessage(transporterMessages.openHoursErrorFromMustBeBeforeTo)}
                    emptyLabel={formatMessage(transporterMessages.exceptionnalDaysEmpty)}
                />
            ),
            renderDetails: (fields, record) => <TransporterExceptionnalDaysDetails record={record} />,
        },
    };

    return select ? select.map((sectionName) => sections[sectionName]) : Object.values(sections);
};
