import { VFC, ReactNode } from 'react';
import { Button, Popconfirm } from 'antd';
import { FormattedMessage } from 'react-intl';

import formMessages from '../../i18n/formMessages';
import { CheckCircle, Pencil, Trash, XCircle } from '../icons';

interface EditableCellActionsProps {
    isEditing: boolean;
    onEdit: () => void;
    onRemove?: () => void;
    onSave?: () => void;
    onCancel: () => void;
    loading?: boolean;
    removeTitle?: ReactNode;
}

const EditableCellActions: VFC<EditableCellActionsProps> = ({
    isEditing,
    onEdit,
    onRemove,
    onCancel,
    loading,
    removeTitle,
}) => {
    return (
        <div className="space-x-1">
            {!isEditing ? (
                <>
                    <Button
                        key="edit"
                        icon={<Pencil className="text-xl" />}
                        type="text"
                        size="small"
                        className="btn-faded-blue"
                        onClick={onEdit}
                    />
                    {onRemove && (
                        <Popconfirm
                            title={removeTitle || <FormattedMessage {...formMessages.valueListDeleteConfirm} />}
                            okText={<FormattedMessage {...formMessages.delete} />}
                            cancelText={<FormattedMessage {...formMessages.cancel} />}
                            okButtonProps={{
                                danger: true,
                                shape: 'round',
                                size: 'small',
                            }}
                            cancelButtonProps={{
                                shape: 'round',
                                size: 'small',
                            }}
                            onConfirm={onRemove}
                            placement="topRight"
                            icon={false}
                        >
                            <Button
                                icon={<Trash className="text-xl" />}
                                className="btn-faded-blue"
                                type="text"
                                size="small"
                            />
                        </Popconfirm>
                    )}
                </>
            ) : (
                <>
                    <Button
                        icon={<XCircle className="text-3.5xl" />}
                        type="text"
                        size="small"
                        onClick={onCancel}
                        disabled={loading}
                        danger
                    />
                    <Button
                        key="save"
                        icon={<CheckCircle className="text-3.5xl" />}
                        type="text"
                        size="small"
                        className="btn-text-success"
                        htmlType="submit"
                        loading={loading}
                    />
                </>
            )}
        </div>
    );
};

export default EditableCellActions;
