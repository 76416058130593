import { VFC } from 'react';
import { useParams } from 'react-router-dom';
import { Result } from 'antd';
import { ResultProps } from 'antd/lib/result';
import { FormattedMessage, useIntl } from 'react-intl';

import Seo from '../../components/Seo';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';
import genericMessages from '../../i18n/genericMessages';

interface ErrorPageProps {
    status?: string | number;
}

const ErrorPage: VFC<ErrorPageProps> = ({ status: propsStatus }) => {
    const { formatMessage } = useIntl();
    const { 0: param } = useParams<{ 0?: string }>();
    let statusCode = parseInt(param?.substring(1) ?? `${propsStatus ?? ''}`, 10);
    let status: ResultProps['status'] = 'error';
    let title: ResultProps['title'] = 'Erreur';
    let subTitle: ResultProps['subTitle'] = formatMessage(genericMessages.defaultError);

    if (isNaN(statusCode)) {
        statusCode = 404;
    }

    if (`${statusCode}`.length === 3 && `${statusCode}`.startsWith('5')) {
        status = '500';
        title = `${statusCode}`;
    } else if (statusCode === 403 || statusCode === 401) {
        status = '403';
        title = statusCode;
        subTitle =
            statusCode === 403
                ? formatMessage(genericMessages.forbiddenError)
                : formatMessage(genericMessages.unauthorizedError);
    } else if (statusCode === 400) {
        title = statusCode;
        subTitle = formatMessage(genericMessages.invalidDataSentError);
    } else {
        status = '404';
        title = 404;
        subTitle = formatMessage(genericMessages.notFoundError);
    }

    return (
        <>
            <Seo title={`${statusCode}`} />
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
                extra={
                    <ButtonLink to={getRoute(RoutePathName.home)} type="primary">
                        <FormattedMessage
                            id="error_page.back_to_homepage_link"
                            defaultMessage="Retourner à l’accueil"
                            description="Back to homepage link"
                        />
                    </ButtonLink>
                }
            />
        </>
    );
};

export default ErrorPage;
