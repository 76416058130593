import { Form, Input, PopoverProps, Select, Space, Typography } from 'antd';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { getFullName } from '../../../helpers';
import taskGroupMessages from '../../../i18n/taskGroupMessages';
import { TaskGroupListAvailableOperatorsResponse } from '../../../queries/api/taskGroups';
import { Operator } from '../../../queries/api/types';

interface TaskGroupOperatorSelectorProps extends PopoverProps {
    operatorId: Operator['id'] | undefined;
    externalOperator: boolean;
    operators?: TaskGroupListAvailableOperatorsResponse;
}

const TaskGroupOperatorSelector: FC<TaskGroupOperatorSelectorProps> = ({ operatorId, externalOperator, operators }) => {
    const { formatMessage } = useIntl();
    const selectOptions = operators?.items.map((operator) => ({
        label: getFullName(operator),
        value: operator.id,
    }));
    const form = Form.useFormInstance();
    const vehicleId = Form.useWatch('vehicleId', form);
    return (
        <Space direction="vertical" size={4} className="w-full">
            <Typography.Text>{formatMessage(taskGroupMessages.operatorSelectorLabel)}</Typography.Text>
            {externalOperator ? (
                <Input placeholder="large size" value={'Chauffeur externe'} readOnly />
            ) : (
                <Select<Operator['id']>
                    options={selectOptions}
                    className="w-full mb-4"
                    placeholder={formatMessage(taskGroupMessages.operatorSelectorPlaceholder)}
                    onChange={(id: string) => {
                        form.setFieldsValue({ operatorId: id });
                    }}
                    allowClear
                    value={operatorId}
                    disabled={!vehicleId}
                ></Select>
            )}
        </Space>
    );
};

export default TaskGroupOperatorSelector;
