import { FC, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useCookies as useReactCookies } from 'react-cookie';
import { Button } from 'antd';

import '../assets/styles/CookieBanner.less';

import { getRoute, RoutePathName } from '../routes';
import { acceptAllCookies, refuseAllCookies, analytics, addGoogleTagManager } from '../helpers/cookies';
import { useCookies } from '../context/CookiesContext';

const CookieBanner: FC = () => {
    const { acceptAll, refuse, gtag, hasMadeChoice } = useCookies();
    const [_, setCookie, removeCookie] = useReactCookies(); // eslint-disable-line @typescript-eslint/no-unused-vars
    const location = useLocation();
    const onClickAccept = () => {
        acceptAllCookies(setCookie);
        acceptAll();
    };
    const onClickRefuse = () => {
        refuseAllCookies(setCookie, removeCookie);
        refuse();
    };

    useEffect(() => {
        if (gtag) {
            if (window.dataLayer) {
                analytics.pageView(location.pathname);
            } else {
                addGoogleTagManager();
            }
        }
    }, [location.pathname, gtag]);

    return !hasMadeChoice ? (
        <div id="cookie-banner">
            <p>
                {
                    'En poursuivant votre navigation ou en cliquant sur "Accepter" vous acceptez l\'utilisation de cookies destinés à réaliser des statistiques de navigation et de fréquentation. Pour en savoir plus ou modifier les paramètres des cookies vous pouvez consulter à tout moment notre '
                }
                <Link to={getRoute(RoutePathName.privacy)}>
                    Politique de confidentialité et Protection de la vie privée
                </Link>
                {'.'}
            </p>
            <div>
                <Button type="ghost" onClick={onClickRefuse}>
                    Refuser
                </Button>
                <Button type="primary" onClick={onClickAccept}>
                    Accepter
                </Button>
            </div>
        </div>
    ) : null;
};

export default CookieBanner;
