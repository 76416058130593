import { serialize } from 'object-to-formdata';

import api from './apiClient';
import { PaginationQuery, SearchPaginationQuery } from '.';
import {
    TaskGroup,
    ListResponse,
    TaskGroupService,
    VehicleAvailability,
    TaskGroupStatus,
    TaskGroupTransferStatus,
    ValueListItem,
    Customer,
    Place,
    Operator,
    Vehicle,
    TransporterService,
    TaskGroupServiceType,
    VehiclePlanning,
    GeoJson,
    TaskGroupAssignmentProposal,
} from './types';
import { TaskGroupServiceCreatePayload } from './taskGroupServices';

// Controller Interface
export interface TaskGroupCreatePayload {
    reference: string;
}

export interface TaskGroupUpdatePayload
    extends Partial<Omit<TaskGroup, 'id' | 'operator' | 'vehicle' | 'transporterService'>> {
    id?: TaskGroup['id'];
    ids?: Array<TaskGroup['id']>;
    operator?: Operator['id'];
    vehicle?: Vehicle['id'];
    transporterService?: TransporterService['id'];
}

export enum TaskGroupError {
    taskGroupInProgress = 'taskGroup_In_progress',
}

export type TaskGroupUpdateErrorPayload = {
    error: TaskGroupError;
    fields: any;
};

export type TaskGroupIdPayload = TaskGroup['id'];

export type TaskGroupListPayload = SearchPaginationQuery & {
    status?: TaskGroupStatus;
    isExternal?: boolean;
    transferStatus?: TaskGroupTransferStatus;
    fromDate?: string;
    toDate?: string;
    startingPlaces?: Array<ValueListItem['id']>;
    customer?: Customer['id'] | Array<Customer['id']>;
};

export type VehiclePlanningAvailabilitiesPayload = SearchPaginationQuery & {
    fromDate?: string;
    toDate?: string;
    places?: Array<Place['id']>;
};

export type VehiclePlanningAvailabilitiesResponse = VehicleAvailability[];

export type TaskGroupListResponse = ListResponse<TaskGroup>;
export type TaskGroupListPointsResponse = GeoJson[];

export type TaskGroupListServicesPayload = SearchPaginationQuery & {
    id: TaskGroup['id'];
    type: TaskGroupServiceType;
};
export type TaskGroupListServicesResponse = ListResponse<TaskGroupService>;

export type VehiclePlanningsResponse = ListResponse<VehiclePlanning>;

export interface TaskGroupCreateServicePayload extends TaskGroupServiceCreatePayload {
    taskGroupId: TaskGroup['id'];
    type: TaskGroupServiceType;
}
export type TaskGroupImportFilePayload = {
    customerId: Customer['id'];
    placeId: Place['id'];
    file: File;
};

export type TaskGroupListAvailableVehiclesPayload = SearchPaginationQuery & {
    id?: TaskGroup['id'];
};

export type TaskGroupListAvailableOperatorsPayload = SearchPaginationQuery & {
    id?: TaskGroup['id'];
};

export type TaskGroupAvailableVehiclePayload = {
    id: Vehicle['id'];
    taskGroupId: TaskGroup['id'];
};

export type TaskGroupAvailableOperatorPayload = SearchPaginationQuery & {
    id: Operator['id'];
    taskGroupId: TaskGroup['id'];
};

export type TaskGroupManuallyClosePayload = {
    shiftEndedAt: string;
    forAll: boolean;
    taskGroups: Array<{ id: TaskGroup['id'] }>;
};

export type TaskGroupTransferPayload = {
    taskGroupId: TaskGroup['id'];
    startingPlace: Place['id'];
};

export type TaskGroupRunAutoPlanificationPayload = {
    forAll: boolean;
    taskGroups?: Array<{ taskGroup: TaskGroup['id'] }>;
};

export type TaskGroupRemoveBatchPayload = {
    forAll: boolean;
    taskGroups?: Array<TaskGroup['id']>;
    statuses: TaskGroupStatus[];
};

export type TaskgroupListAutoPlanificationProposalsPayload = PaginationQuery & {
    proposals: Array<TaskGroupAssignmentProposal['id']>;
    successful: boolean;
};
export type TaskgroupApplyAutoPlanificationProposalsPayload = {
    payload: {
        forAll: boolean;
        proposals: Array<{ id: TaskGroupAssignmentProposal['id'] }>;
    };
};

export type TaskGroupListAvailableOperatorsResponse = ListResponse<Operator>;

export type TaskGroupListAvailableVehiclesResponse = ListResponse<Vehicle>;

export type TaskGroupAvailableOperatorResponse = { available: boolean };
export type TaskGroupAvailableVehicleResponse = { available: boolean };
export type TaskGroupRunAutoPlanificationResponse = Array<{ id: TaskGroupAssignmentProposal['id'] }>;
export type TaskgroupListAutoPlanificationProposalsResponse = ListResponse<TaskGroupAssignmentProposal> & {
    missingVehicles: number;
    missingOperators: number;
};
export type TaskgroupApplyAutoPlanificationProposalsResponse = {
    successful: TaskGroup[];
    error: TaskGroup[];
};

export type TaskgroupCheckTaskGroupMobilicMissionResponse = {
    id: number;
    type: string;
    startTime: number;
    endTime: number;
    missionId: number;
};

// Routes
export const list = async (payload?: TaskGroupListPayload) => {
    return await api.get<TaskGroupListResponse>(`/taskGroups`, { params: payload }).then((response) => response?.data);
};

export const listToPlan = async (payload?: TaskGroupListPayload) => {
    return await api.get<TaskGroupListResponse>(`/taskGroups`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: TaskGroupCreatePayload) => {
    return await api.post(`/taskGroups`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: TaskGroupUpdatePayload) => {
    if (!payload.ids && !id) {
        throw new Error('missing ids or id');
    }
    return await api.put<TaskGroup>(`/taskGroups/${id || ''}`, payload).then((response) => response?.data);
};

export const details = async (id?: TaskGroupIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<TaskGroup>(`/taskGroups/${id}`).then((response) => response?.data);
};

export const remove = async (id?: TaskGroupIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/taskGroups/${id}`).then((response) => response?.data);
};

export const removeBatch = async (payload: TaskGroupRemoveBatchPayload) => {
    return await api.delete<undefined>(`/taskGroups`, { params: payload }).then((response) => response?.data);
};

export const listPoints = async (id?: TaskGroupIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<TaskGroupListPointsResponse>(`/taskGroups/${id}/points`).then((response) => response?.data);
};

export const importFile = async (payload: TaskGroupImportFilePayload) => {
    const { customerId, placeId } = payload;

    if (!customerId || !placeId) {
        throw new Error('missing id');
    }

    const formData = serialize(payload, { indices: true });

    return await api
        .post<TaskGroup>(`/customers/${customerId}/places/${placeId}/taskGroups`, formData)
        .then((response) => response?.data);
};

export const vehiclePlannings = async (payload?: TaskGroupListPayload) => {
    return await api
        .get<VehiclePlanningsResponse>(`/planning/vehicles`, { params: payload })
        .then((response) => response?.data);
};

export const vehicleAvailabilities = async (payload: VehiclePlanningAvailabilitiesPayload) => {
    return await api
        .get<VehiclePlanningAvailabilitiesResponse>(`/planning/vehicles/availabilities`, { params: payload })
        .then((response) => response?.data);
};

export const listServices = async ({ id, ...payload }: TaskGroupListServicesPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api
        .get<TaskGroupListServicesResponse>(`/taskGroups/${id}/taskGroupServices`, { params: payload })
        .then((response) => response?.data);
};

export const createService = async ({ taskGroupId: id, ...payload }: TaskGroupCreateServicePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.post(`/taskGroups/${id}/taskGroupServices`, payload).then((response) => response?.data);
};

export const listAvailableOperators = async ({ id, ...payload }: TaskGroupListAvailableOperatorsPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api
        .get<TaskGroupListAvailableOperatorsResponse>(`/taskGroups/${id}/availableOperators`, { params: payload })
        .then((response) => response?.data);
};

export const listAvailableVehicles = async ({ id, ...payload }: TaskGroupListAvailableVehiclesPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api
        .get<TaskGroupListAvailableVehiclesResponse>(`/taskGroups/${id}/availableVehicles`, { params: payload })
        .then((response) => response?.data);
};

export const availableOperator = async ({ id, taskGroupId }: TaskGroupAvailableOperatorPayload) => {
    if (!id) {
        throw new Error('missing operator id');
    }
    if (!taskGroupId) {
        throw new Error('missing taskGroup id');
    }
    return await api
        .get<TaskGroupAvailableOperatorResponse>(`/taskGroups/${taskGroupId}/operators/${id}/available`)
        .then((response) => response?.data);
};

export const availableVehicle = async ({ id, taskGroupId }: TaskGroupAvailableVehiclePayload) => {
    if (!id) {
        throw new Error('missing vehicle id');
    }
    if (!taskGroupId) {
        throw new Error('missing taskGroup id');
    }
    return await api
        .get<TaskGroupAvailableVehicleResponse>(`/taskGroups/${taskGroupId}/vehicles/${id}/available`)
        .then((response) => response?.data);
};

export const unplan = async (id?: TaskGroupIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.post<TaskGroup>(`/taskGroups/${id}/unplan`).then((response) => response?.data);
};

export const manuallyClose = async (payload: TaskGroupManuallyClosePayload) => {
    return await api.post<undefined>(`/taskgroups/closeExternal`, payload).then((response) => response?.data);
};

export const runAutoPlanification = async ({ forAll, taskGroups }: TaskGroupRunAutoPlanificationPayload) => {
    const payload = {
        forAll,
        taskGroups,
    };
    return await api
        .post<TaskGroupRunAutoPlanificationResponse>(`/taskgroupAssignmentProposal`, payload)
        .then((response) => response?.data);
};

export const listAutoPlanificationProposals = async ({
    proposals,
    successful,
    page,
    sort,
    sortOrder,
}: TaskgroupListAutoPlanificationProposalsPayload) => {
    return await api
        .get<TaskgroupListAutoPlanificationProposalsResponse>(`/taskgroupAssignmentProposal`, {
            params: {
                proposals,
                successful,
                page,
                sort,
                sortOrder,
            },
        })
        .then((response) => response?.data);
};

export const applyAutoPlanificationProposals = async ({ payload }: TaskgroupApplyAutoPlanificationProposalsPayload) => {
    return await api
        .put<TaskgroupApplyAutoPlanificationProposalsResponse>(`/taskgroupAssignmentProposal/apply`, payload)
        .then((response) => response?.data);
};

export const transfer = async ({ taskGroupId, startingPlace }: TaskGroupTransferPayload) => {
    return await api
        .put<TaskGroup>(`/taskGroups/${taskGroupId}/transfer`, { startingPlace })
        .then((response) => response?.data);
};

export const checkMobilicMission = async (id?: TaskGroupIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api
        .get<TaskgroupCheckTaskGroupMobilicMissionResponse>(`/taskGroups/${id}/checkTaskGroupMobilicMission`)
        .then((response) => response?.data);
};
