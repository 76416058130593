import { Badge, Typography } from 'antd';
import { FC } from 'react';
import { TitleProps } from 'antd/lib/typography/Title';
import { Link, LinkProps } from 'react-router-dom';
import { useIntl } from 'react-intl';

import '../assets/styles/ListTitle.less';

import { ArrowLeft } from './icons';
import { classNames } from '../helpers';

interface ListTitleProps extends TitleProps {
    count?: number;
    backRoute?: LinkProps['to'];
    alt?: boolean;
}

const ListTitle: FC<ListTitleProps> = ({ children, count, level = 1, backRoute, alt, ...props }) => {
    const { formatNumber } = useIntl();
    return (
        <div className="flex items-center list-title">
            {backRoute && (
                <Link to={backRoute} className="mr-3">
                    <ArrowLeft className="text-xl leading-5" />
                </Link>
            )}
            <Typography.Title
                level={level}
                {...props}
                className={classNames(props.className, 'flex items-center font-bold', alt && 'text-blue')}
            >
                {children}
                <Badge count={formatNumber(count ?? 0)} overflowCount={999999} />
            </Typography.Title>
        </div>
    );
};

export default ListTitle;
