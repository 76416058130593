import { useCallback, useMemo, VFC } from 'react';
import { Button, Form, FormProps, Input, Space, Steps } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useCustomerCreate, useCustomerDetails, useCustomerUpdate } from '../../../queries/customers';
import { CustomerUpdatePayload } from '../../../queries/api/customers';
import { Customer } from '../../../queries/api/types';
import { getRoute, RoutePathName } from '../../../routes';
import { errorMessage, successMessage } from '../../../helpers/message';
import useQueryParams from '../../../hooks/queryParams';
import { getRequiredRule } from '../../../i18n';
import customerMessages from '../../../i18n/customerMessages';
import formMessages from '../../../i18n/formMessages';
import genericMessages from '../../../i18n/genericMessages';
import Seo from '../../../components/Seo';
import DetailsFormCard, { DetailsFormCardProps } from '../../../components/form/DetailsFormCard';
import StatusSwitch from '../../../components/form/StatusSwitch';
import { Check } from '../../../components/icons';
import ListTitle from '../../../components/ListTitle';
import {
    CustomerForm,
    CustomerFormSection,
    formatCustomerCreatePayload,
    formatCustomerInitialValues,
    formatCustomerUpdatePayload,
    getFormSections,
} from '.';

export enum CustomerCreateStep {
    generalInformations = 'generalInformations',
    contract = 'contract',
    billing = 'billing',
    forecast = 'forecast',
}

const CustomerCreate: VFC = () => {
    const history = useHistory();
    const params = useParams<{ step?: CustomerCreateStep }>();
    const [queryParams] = useQueryParams('CustomerCreate');
    const customerId = queryParams.get('customerId') ?? undefined;

    const { formatMessage } = useIntl();
    const pageTitle = formatMessage(customerMessages.createTitle);

    const [form] = Form.useForm();

    const step = parseInt(params.step || '0', 10);
    const steps = useMemo<
        Array<{
            title: string;
            sections: Array<
                Pick<
                    DetailsFormCardProps<Customer, CustomerForm, CustomerUpdatePayload>,
                    'title' | 'editButtonText' | 'fields' | 'renderEdit'
                >
            >;
        }>
    >(
        () => [
            {
                title: formatMessage(genericMessages.generalInformations),
                sections: getFormSections(formatMessage, [CustomerFormSection.address, CustomerFormSection.contacts]),
            },
            {
                title: formatMessage(customerMessages.contractAndBreakTime),
                sections: getFormSections(formatMessage, [CustomerFormSection.contract, CustomerFormSection.pauses]),
            },
            {
                title: formatMessage(genericMessages.billingUnit),
                sections: getFormSections(formatMessage, [CustomerFormSection.billing]),
            },
            {
                title: formatMessage(customerMessages.forecast),
                sections: getFormSections(formatMessage, [CustomerFormSection.forecast]),
            },
        ],
        [formatMessage]
    );
    const nextStep = step < steps.length - 1 ? step + 1 : undefined;

    const { data: customerDetails } = useCustomerDetails(customerId, {
        enabled: !!customerId,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            form.setFieldsValue(formatCustomerInitialValues(data));
        },
    });
    const { mutate: createCustomer, isLoading: isCreating } = useCustomerCreate();
    const { mutate: updateCustomer, isLoading: isUpdating } = useCustomerUpdate();

    const onSubmit: FormProps<CustomerForm>['onFinish'] = useCallback(
        (values) => {
            if (customerId) {
                updateCustomer(formatCustomerUpdatePayload(step)(values, customerDetails), {
                    onSuccess: () => {
                        if (nextStep) {
                            history.push({
                                pathname: getRoute(RoutePathName.customerCreate, { step: nextStep }),
                                search: queryParams.toString(),
                            });
                        } else {
                            successMessage({
                                content: formatMessage({
                                    id: 'customer.update.success_message',
                                    defaultMessage: 'Client créé avec succès',
                                }),
                            });
                            history.push(getRoute(RoutePathName.customers));
                        }
                    },
                    onError: (error) => {
                        errorMessage({
                            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                status: error.response?.status,
                            }),
                        });
                    },
                });
            } else {
                createCustomer(formatCustomerCreatePayload(values), {
                    onSuccess: (data) => {
                        history.push({
                            pathname: getRoute(RoutePathName.customerCreate, { step: 1 }),
                            search: new URLSearchParams({ customerId: data.id }).toString(),
                        });
                    },
                    onError: (error) => {
                        errorMessage({
                            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                status: error.response?.status,
                            }),
                        });
                    },
                });
            }
        },
        [
            createCustomer,
            formatMessage,
            history,
            customerId,
            customerDetails,
            nextStep,
            queryParams,
            step,
            updateCustomer,
        ]
    );

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center">
                <ListTitle className="uppercase mb-0" backRoute={getRoute(RoutePathName.customers)}>
                    {pageTitle}
                </ListTitle>
                {customerId && (
                    <StatusSwitch
                        detailsQueryHandler={useCustomerDetails}
                        updateQueryHandler={useCustomerUpdate}
                        id={customerId}
                    />
                )}
            </div>
            <div className="flex justify-center items-center p-6 mx-auto mb-4" style={{ width: '90%' }}>
                <Steps current={step}>
                    {steps.map((step) => (
                        <Steps.Step key={step.title} title={step.title} />
                    ))}
                </Steps>
            </div>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    initialValues={formatCustomerInitialValues(customerDetails)}
                    scrollToFirstError
                >
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        {step === 0 && (
                            <Form.Item name="name" className="mb-0" rules={[getRequiredRule(formatMessage)]}>
                                <Input size="large" placeholder={formatMessage(genericMessages.lastNamePlaceholder)} />
                            </Form.Item>
                        )}
                        {steps[step].sections.map((section) => (
                            <DetailsFormCard<Customer, CustomerForm, CustomerUpdatePayload>
                                {...section}
                                key={section.title}
                                detailsQueryHandler={useCustomerDetails}
                                updateQueryHandler={useCustomerUpdate}
                                initialValueFormatter={formatCustomerInitialValues}
                                formatPayload={formatCustomerUpdatePayload(step)}
                                id={customerId}
                                isCreating
                            />
                        ))}
                        <div className="flex justify-center gap-2">
                            {step > 0 && (
                                <Link
                                    to={{
                                        pathname: getRoute(RoutePathName.customerCreate, { step: step - 1 }),
                                        search: queryParams.toString(),
                                    }}
                                >
                                    <Button type="primary" size="large" ghost>
                                        <FormattedMessage {...formMessages.previousStepButton} tagName="span" />
                                    </Button>
                                </Link>
                            )}
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<Check />}
                                loading={isCreating || isUpdating}
                                size="large"
                            >
                                {step === steps.length - 1 ? (
                                    <FormattedMessage {...formMessages.saveAndCloseButton} tagName="span" />
                                ) : (
                                    <FormattedMessage {...formMessages.saveAndContinueButton} tagName="span" />
                                )}
                            </Button>
                        </div>
                    </Space>
                </Form>
            </Space>
        </>
    );
};

export default CustomerCreate;
