import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { list, PublicHolidaysListPayload, PublicHolidaysListResponse } from './api/publicHolidays';

export const publicHolidays = {
    all: ['publicHolidays'],
    lists: () => [...publicHolidays.all, 'list'],
    list: (params?: PublicHolidaysListPayload) => [...publicHolidays.lists(), params],
};

export const usePublicHolidaysList = (
    params: PublicHolidaysListPayload,
    options?: UseQueryOptions<PublicHolidaysListResponse, AxiosError>
) => {
    return useQuery<PublicHolidaysListResponse, AxiosError>(
        publicHolidays.list(params),
        async () => await list(params),
        {
            keepPreviousData: true,
            ...options,
        }
    );
};
