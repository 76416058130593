import { useState } from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, ModalProps, Row, Typography } from 'antd';
import { HiCheckCircle, HiExclamationCircle } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';

import CustomModal from '../../components/CustomModal';
import { TaskGroup, TaskGroupPreBillingStatus, TaskGroupServiceType } from '../../queries/api/types';
import { useTaskGroupUpdate } from '../../queries/taskGroups';
import genericMessages from '../../i18n/genericMessages';
import formMessages from '../../i18n/formMessages';
import { usePreBillingTaskGroupExport } from '../../queries/preBilling';
import { errorMessage } from '../../helpers/message';

export interface SetPreBillingStatusModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, unknown> {
    taskGroupIds: Array<TaskGroup['id']>;
    serviceType: TaskGroupServiceType;
}

const SetPreBillingStatusModal = NiceModal.create<SetPreBillingStatusModalProps>(
    ({ taskGroupIds, serviceType, ...props }) => {
        const { formatMessage } = useIntl();
        const modal = useModal();
        const [hasUpdated, setHasUpdated] = useState(false);
        const isBulk = taskGroupIds.length > 1;
        const { mutate: updateTaskGroups, isLoading } = useTaskGroupUpdate();
        const onSubmit = () => {
            updateTaskGroups(
                { ids: taskGroupIds, preBillingStatus: TaskGroupPreBillingStatus.preBilled },
                {
                    onSuccess: () => {
                        setHasUpdated(true);
                        modal.resolve();
                    },
                }
            );
        };
        const { refetch: triggerPreBillingTaskGroupExport, isRefetching } = usePreBillingTaskGroupExport(
            {
                taskGroupId: taskGroupIds[0],
                serviceType,
            },
            {
                enabled: false,
                refetchOnMount: false,
                refetchOnReconnect: false,
                refetchOnWindowFocus: false,
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error?.response?.status,
                        }),
                    });
                },
                onSuccess: () => {
                    modal.hide();
                },
            }
        );

        const preBillingTaskGroupExport = () => {
            triggerPreBillingTaskGroupExport();
        };

        return (
            <CustomModal
                footer={false}
                width={464}
                closable={false}
                maskClosable={false}
                {...props}
                {...antdModal(modal)}
            >
                {hasUpdated ? (
                    <>
                        <Row gutter={16} wrap={false} className="mb-6">
                            <Col className="flex">
                                <HiCheckCircle className="text-green text-2xl" />
                            </Col>
                            <Col className="grow">
                                <Typography.Paragraph className="text-base">
                                    <FormattedMessage
                                        id="pre_billing_status_modal.success.content"
                                        defaultMessage="{count, plural, one {La tournée a été pré-facturée avec succès !} other {Les tournées ont été pré-facturées avec succès !}}"
                                        values={{ count: isBulk ? 2 : 1 }}
                                    />
                                </Typography.Paragraph>
                            </Col>
                        </Row>
                        <Row justify="end" gutter={8}>
                            <Col>
                                <Button type="primary" ghost onClick={preBillingTaskGroupExport} loading={isRefetching}>
                                    <FormattedMessage
                                        id="pre_billing_status_modal.success.download_file"
                                        defaultMessage="Télécharger la synthèse"
                                    />
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        modal.hide();
                                    }}
                                >
                                    <FormattedMessage {...genericMessages.close} />
                                </Button>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row gutter={16} wrap={false}>
                            <Col className="flex">
                                <HiExclamationCircle className="text-orange text-2xl" />
                            </Col>
                            <Col className="grow">
                                <Typography.Title className="text-blue font-bold text-lg">
                                    <FormattedMessage
                                        id="pre_billing_status_modal.title"
                                        defaultMessage="Modification du statut"
                                    />
                                </Typography.Title>
                                <Typography.Paragraph className="text-base">
                                    <FormattedMessage
                                        id="pre_billing_status_modal.content"
                                        defaultMessage="{count, plural, one {Êtes-vous sur de vouloir marquer cette tournée comme pré-facturée ?} other {Êtes-vous sur de vouloir marquer ces tournées comme pré-facturées ?}}"
                                        values={{ count: isBulk ? 2 : 1 }}
                                    />
                                </Typography.Paragraph>
                            </Col>
                        </Row>
                        <Row justify="end" gutter={8}>
                            <Col>
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={() => {
                                        modal.hide();
                                    }}
                                >
                                    <FormattedMessage {...formMessages.cancel} />
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" onClick={onSubmit} loading={isLoading}>
                                    <FormattedMessage {...formMessages.validate} />
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </CustomModal>
        );
    }
);

export default SetPreBillingStatusModal;
