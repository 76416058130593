import { FC, useCallback } from 'react';
import { TableProps } from 'antd/lib/table';
import { Table, Tag, Space, Result, Input, InputProps, Button, Menu, Dropdown, message } from 'antd';
import { EditOutlined, EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import { show, useModal } from '@ebay/nice-modal-react';

import { useUserRemove, useUserResetPassword, useUsersList } from '../../../queries/users';
import { User } from '../../../queries/api/types';
import Seo from '../../../components/Seo';
import LayoutHeader from '../../../components/LayoutHeader';
import ListTitle from '../../../components/ListTitle';
import useQueryParams from '../../../hooks/queryParams';
import { addDefaultColumnSorting, getFullName, sortOrderConverter } from '../../../helpers';
import ConfirmationModal from '../../../components/ConfirmationModal';
import UserFormDrawer from './UserFormDrawer';
import { formatDate } from '../../../i18n';
import { errorMessage, successMessage } from '../../../helpers/message';

let searchTimeout: number;
const userRemoveConfirmationModalId = 'remove-confirmation-modal';
const userResetPasswordConfirmationModalId = 'reset-password-confirmation-modal';

const UsersList: FC = () => {
    const [queryParams, setQueryParams] = useQueryParams('users-list');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const { data: usersData, isLoading, isFetching, isError, error } = useUsersList({ page, search, sort, sortOrder });

    const userRemoveModal = useModal(userRemoveConfirmationModalId);
    const userResetPasswordModal = useModal(userResetPasswordConfirmationModalId);

    const userRemove = useUserRemove({
        onError: () => {
            errorMessage({ content: "Une erreur est survenu lors de la suppression de l'utilisateur" });
        },
        onSuccess: () => {
            successMessage({ content: "L'utilisateur à été supprimé avec succès" });
            userRemoveModal.hide();
        },
    });
    const userResetPassword = useUserResetPassword({
        onMutate: () => {
            message.loading({
                key: 'resetPasswordMessage',
                content: 'Envoi du mail de réinitialisation du mot de passe en cours',
                duration: 0,
            });
        },
        onError: () => {
            errorMessage({
                key: 'resetPasswordMessage',
                content: 'Une erreur est survenue pendant la réinitialisation du mot de passe',
            });
        },
        onSuccess: () => {
            successMessage({
                key: 'resetPasswordMessage',
                content: 'Email de réinitialisation envoyé avec succès',
            });
            userResetPasswordModal.hide();
        },
    });
    const onTableChange: TableProps<User>['onChange'] = (pagination, filters, sorter) => {
        const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

        setQueryParams({
            page: (pagination.current ?? 1) - 1,
            sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
            sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
        });
    };

    const columns = addDefaultColumnSorting<User>(sort, sortOrder, [
        {
            key: 'name',
            title: 'Full name',
            render: (_, item) => getFullName(item),
        },
        {
            dataIndex: 'email',
            title: 'Email',
        },
        {
            dataIndex: ['role', 'name'],
            title: 'Role',
            render: (value) => {
                return <Tag>{value}</Tag>;
            },
        },
        {
            key: 'createdAt',
            title: 'Created At',
            render: (_, item) => formatDate(item.createdAt),
            sorter: true,
        },
        {
            key: 'updatedAt',
            title: 'Updated At',
            render: (_, item) => formatDate(item.updatedAt),
            sorter: true,
        },
        {
            title: 'Actions',
            key: 'action',
            width: 128,
            render: (_, item) => (
                <Space size="middle">
                    <Button
                        onClick={() => {
                            show(UserFormDrawer, { userId: item.id });
                        }}
                        icon={<EditOutlined />}
                        shape={'circle'}
                        className="actions-buttons"
                    />
                    <Dropdown overlay={getMenuDropdown(item.id)} trigger={['click']} placement={'bottomRight'}>
                        <Button onClick={(e) => e.preventDefault()} shape={'circle'} icon={<EllipsisOutlined />} />
                    </Dropdown>
                </Space>
            ),
        },
    ]);

    const logAsUser = (id: string) => () => {
        return window.location.replace(`/api/auth/logAs/${id}`);
    };

    const getMenuDropdown = (id: string) => {
        const menuDropdown = (
            <Menu
                items={[
                    {
                        label: 'Se connecter en tant que',
                        key: '0',
                        onClick: logAsUser(id),
                    },
                    {
                        label: 'Réinitialiser le mot de passe',
                        key: '1',
                        onClick: () => {
                            userResetPasswordModal.show().then(() => userResetPassword.mutate(id));
                        },
                    },
                    {
                        label: "Supprimer l'utilisateur",
                        key: '2',
                        onClick: () => {
                            userRemoveModal.show().then(() => userRemove.mutate(id));
                        },
                    },
                ]}
            />
        );
        return menuDropdown;
    };
    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );

    return (
        <>
            <Seo title="Utilisateurs" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={usersData?.totalCount}>Utilisateurs</ListTitle>
                    <Button
                        className="header-button"
                        size="large"
                        type="primary"
                        onClick={() => {
                            show(UserFormDrawer);
                        }}
                    >
                        Ajouter un utilisateur
                    </Button>
                </div>
            </LayoutHeader>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Input
                    placeholder="Rechercher un nom..."
                    onChange={onSearch}
                    defaultValue={search}
                    prefix={<SearchOutlined className="text-primary text-base leading-4" />}
                    size="large"
                    allowClear
                />
                {isError ? (
                    <Result status={error?.request?.status} />
                ) : (
                    <Table<User>
                        rowKey="id"
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={usersData?.items}
                        pagination={{
                            total: usersData?.totalCount,
                            current: page + 1,
                            pageSize: usersData?.pageSize,
                        }}
                        onChange={onTableChange}
                    />
                )}
                <ConfirmationModal
                    title="Confirmation de suppression"
                    text={'Êtes-vous sûr de vouloir supprimer cet utilisateur ?'}
                    id={userRemoveConfirmationModalId}
                    isLoading={userRemove.isLoading}
                />
                <ConfirmationModal
                    title="Confirmation de réinitialisation"
                    text={
                        <>
                            <p>Êtes-vous sur de vouloir réinitialiser le mot de passe de cet utilisateur ?</p>
                            <p className="text-orange text-bold">
                                Un lien de modification du mot de passe sera envoyé sur la boite e-mail de
                                l’utilisateur.
                            </p>
                        </>
                    }
                    id={userResetPasswordConfirmationModalId}
                    isLoading={userResetPassword.isLoading}
                />
            </Space>
        </>
    );
};

export default UsersList;
