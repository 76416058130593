import { Table } from 'antd';
import { VFC } from 'react';
import { FormattedDate, FormattedDateTimeRange, FormattedMessage } from 'react-intl';

import genericMessages from '../../../i18n/genericMessages';
import placeMessages from '../../../i18n/placeMessages';
import { Place } from '../../../queries/api/types';

interface PlaceClosedDaysDetailsProps {
    record?: Place;
}

const PlaceClosedDaysDetails: VFC<PlaceClosedDaysDetailsProps> = ({ record }) =>
    (record?.closedDays || []).length > 0 ? (
        <Table<Place['closedDays'][0]>
            columns={[
                {
                    key: 'day',
                    title: <FormattedMessage {...genericMessages.day} />,
                    render: (value, record) => (
                        <FormattedDate
                            value={record.fromDate}
                            weekday="long"
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                        />
                    ),
                },
                {
                    key: 'hours',
                    title: <FormattedMessage {...genericMessages.hours} />,
                    render: (value, record) =>
                        record.isFullDay ? (
                            <FormattedMessage {...genericMessages.fullDay} tagName="span" />
                        ) : (
                            <FormattedDateTimeRange
                                from={new Date(record.fromDate)}
                                to={new Date(record.toDate)}
                                hour="2-digit"
                                minute="2-digit"
                            />
                        ),
                },
            ]}
            rowKey="fromDate"
            dataSource={record?.closedDays}
            pagination={false}
        />
    ) : (
        <FormattedMessage {...placeMessages.closedDaysEmpty} />
    );

export default PlaceClosedDaysDetails;
