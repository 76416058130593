import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { ValueList, ListResponse, ValueListField } from './types';

// Controller Interface
export interface ValueListCreatePayload {
    title: string;
    slug: string;
    fields: Array<Omit<ValueListField, 'id'>>;
    meta: {
        [key: string]: any;
    };
}

export interface ValueListUpdatePayload extends Omit<ValueList, 'slug'> {
    routeSlug: ValueList['slug'];
    slug?: ValueList['slug'];
}

export type ValueListSlugPayload = ValueList['slug'];

export type ValueListListPayload = SearchPaginationQuery;
export type ValueListListResponse = ListResponse<ValueList>;

// Routes
export const list = async (payload?: ValueListListPayload) => {
    return await api.get<ValueListListResponse>(`/valueLists`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: ValueListCreatePayload) => {
    return await api.post(`/valueLists`, payload).then((response) => response?.data);
};

export const update = async ({ routeSlug, ...payload }: ValueListUpdatePayload) => {
    if (!routeSlug) {
        throw new Error('missing routeSlug');
    }
    return await api.put<ValueList>(`/valueLists/${routeSlug}`, payload).then((response) => response?.data);
};

export const details = async (slug?: ValueListSlugPayload) => {
    if (!slug) {
        throw new Error('missing slug');
    }
    return await api.get<ValueList>(`/valueLists/${slug}`).then((response) => response?.data);
};

export const remove = async (slug?: ValueListSlugPayload) => {
    if (!slug) {
        throw new Error('missing slug');
    }
    return await api.delete<undefined>(`/valueLists/${slug}`).then((response) => response?.data);
};
