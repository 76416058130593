import { FC } from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import Seo from '../../../components/Seo';
import VehiclesListComponent from '../../../components/lists/VehiclesList';
import { Ban, DotsHorizontal, Download, PencilAlt, Plus } from '../../../components/icons';
import genericMessages from '../../../i18n/genericMessages';
import { getRoute, RoutePathName } from '../../../routes';
import { useExportVehicles } from '../../../queries/vehicles';
import { errorMessage } from '../../../helpers/message';

const VehiclesList: FC = () => {
    const { formatMessage } = useIntl();
    const isDisabledVehiclesRoute = !!useRouteMatch({
        path: getRoute(RoutePathName.vehiclesDisabled),
        exact: true,
    });
    const { refetch: exportVehicles } = useExportVehicles({
        enabled: false,
        onError: () => {
            errorMessage({ content: formatMessage(genericMessages.defaultExportError) });
        },
    });
    const onClickExport = () => {
        exportVehicles();
    };

    return (
        <>
            <Seo
                title={formatMessage({
                    id: 'vehicles_list.title',
                    defaultMessage: 'Véhicules',
                    description: 'Vehicle page title',
                })}
            />
            <VehiclesListComponent
                queryParamsKey="vehicles-list"
                queryPayload={{
                    isActive: !isDisabledVehiclesRoute,
                }}
                title={
                    <>
                        <Route path={getRoute(RoutePathName.vehicles)} exact>
                            <FormattedMessage
                                id="vehicles_list.header.title"
                                defaultMessage="Véhicules"
                                description="Header list title"
                            />
                        </Route>
                        <Route path={getRoute(RoutePathName.vehiclesDisabled)} exact>
                            <FormattedMessage
                                id="vehicles_list_disabled.header.title"
                                defaultMessage="Véhicules inactifs"
                                description="Header list title"
                            />
                        </Route>
                    </>
                }
                backRoute={isDisabledVehiclesRoute ? getRoute(RoutePathName.vehicles) : undefined}
                listItemLink={(item) => getRoute(RoutePathName.vehicleDetails, { vehicleId: item.id })}
                scrollOffset={291}
            >
                <Route path={getRoute(RoutePathName.vehicles)} exact>
                    <div className="flex items-center space-x-2">
                        <Link to={getRoute(RoutePathName.vehicleDetails)}>
                            <Button icon={<Plus className="text-base" />} type="primary">
                                <FormattedMessage {...genericMessages.add} tagName="span" />
                            </Button>
                        </Link>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key="disabled" icon={<Ban className="text-faded-blue text-base" />}>
                                        <Link to={getRoute(RoutePathName.vehiclesDisabled)}>
                                            <FormattedMessage
                                                id="vehicles_list.header.menu.disabled_vehicles"
                                                defaultMessage="Véhicules inactifs"
                                                description="Header list menu item"
                                            />
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        key="export"
                                        icon={<Download className="text-faded-blue text-base" />}
                                        onClick={onClickExport}
                                    >
                                        <FormattedMessage {...genericMessages.exportData} />
                                    </Menu.Item>
                                    <Menu.Item
                                        key="valuesLists"
                                        icon={<PencilAlt className="text-faded-blue text-base" />}
                                    >
                                        <Link to={getRoute(RoutePathName.vehiclesValueLists)}>
                                            <FormattedMessage {...genericMessages.valuesLists} />
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                            placement="bottomRight"
                        >
                            <Button icon={<DotsHorizontal className="text-lg" />} />
                        </Dropdown>
                    </div>
                </Route>
            </VehiclesListComponent>
        </>
    );
};

export default VehiclesList;
