import { Form, Switch, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import FormField, { FormFieldProps } from '../../../components/form/FormField';
import { Transporter } from '../../../queries/api/types';

const TransporterBillingAddressFields: FormFieldProps<Transporter>['renderEdit'] = (field) => {
    const { label, fields } = field || {};

    return (
        <div className="p-6 border">
            <div className="flex gap-8 justify-between items-center">
                <Typography.Paragraph className="text-dark-blue font-bold text-base mb-0">{label}</Typography.Paragraph>
                <div className="flex items-center gap-4">
                    <Form.Item
                        name={['meta', 'isSameAddressForBilling']}
                        valuePropName="checked"
                        id="isSameAddressForBilling"
                        noStyle
                    >
                        <Switch />
                    </Form.Item>
                    <label htmlFor="isSameAddressForBilling" className="text-dark-blue font-bold text-base">
                        <FormattedMessage
                            id="transport.billing_address.toggle_use_same_as_production"
                            defaultMessage="Utiliser l’adresse de production"
                        />
                    </label>
                </div>
            </div>
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    !getFieldValue(['meta', 'isSameAddressForBilling']) && (
                        <div className="mt-2">
                            {fields?.map((field, fieldIndex) => (
                                <FormField
                                    {...field}
                                    key={Array.isArray(field.name) ? field.name.join('') : field.name}
                                    isLast={fields.length === fieldIndex + 1}
                                />
                            ))}
                        </div>
                    )
                }
            </Form.Item>
        </div>
    );
};

export default TransporterBillingAddressFields;
