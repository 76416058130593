import { FC, useCallback, useMemo } from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { Table, Space, Button, Dropdown, Menu, Input, InputProps, Badge, Tooltip, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { useCustomersList, useExportCustomers } from '../../../queries/customers';
import { Customer, CustomerType, GenericStatus, ValueListSlug } from '../../../queries/api/types';
import ListTitle from '../../../components/ListTitle';
import Seo from '../../../components/Seo';
import { Ban, CurrencyEuro, DotsHorizontal, Download, Plus, Search } from '../../../components/icons';
import useQueryParams from '../../../hooks/queryParams';
import genericMessages from '../../../i18n/genericMessages';
import { addDefaultColumnSorting, filterDuplicates, sortOrderConverter } from '../../../helpers';
import { getRoute, RoutePathName } from '../../../routes';
import { customerTypeMessagesMap } from '../../../i18n/customerMessages';
import CustomerTypeTagSelect from '../../../components/selects/CustomerTypeSelect';
import ContactTag from '../../../components/ContactTag';
import ValueListItemSelect from '../../../components/selects/ValueListItemSelect';
import BasicList from '../../../components/BasicList';
import { CustomerCreateStep } from './CustomerCreate';
import { CustomerDetailsTabs } from '.';
import NoBudgetHeader from '../../../components/NoBudgetHeader';
import ApiResult from '../../../components/ApiResult';
import { errorMessage } from '../../../helpers/message';

const getDetailsLink = (record: Customer) => {
    const stepsLength = Object.keys(CustomerCreateStep).length;
    const areCreationStepsNotDone = (record.meta?.creationStep ?? 0) < stepsLength - 2;

    return areCreationStepsNotDone
        ? {
              pathname: getRoute(RoutePathName.customerCreate, {
                  step: (record.meta?.creationStep ?? 0) + 1,
              }),
              search: new URLSearchParams({ customerId: record.id }).toString(),
          }
        : getRoute(RoutePathName.customerDetails, { customerId: record.id, tab: CustomerDetailsTabs.informations });
};

let searchTimeout: number;

const CustomersList: FC = () => {
    const { formatMessage } = useIntl();
    const isDisabledCustomersRoute = !!useRouteMatch({
        path: getRoute(RoutePathName.customersDisabled),
        exact: true,
    });
    const [queryParams, setQueryParams] = useQueryParams('customers-list');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const search = queryParams.get('search') ?? undefined;
    const groupIds = queryParams.getAll('groupIds') ?? undefined;
    const type = (queryParams.get('type') as CustomerType) ?? undefined;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const {
        data: customersList,
        isLoading,
        isFetching,
        isError,
        error,
    } = useCustomersList({ isActive: !isDisabledCustomersRoute, page, search, groupIds, type, sort, sortOrder });
    const onTableChange: TableProps<Customer>['onChange'] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
            });
        },
        [setQueryParams]
    );
    const { refetch: exportCustomers } = useExportCustomers({
        enabled: false,
        onError: () => {
            errorMessage({ content: formatMessage(genericMessages.defaultExportError) });
        },
    });
    const onSearch: InputProps['onChange'] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );
    const onClickExport = () => {
        exportCustomers();
    };
    const columns: ColumnsType<Customer> = useMemo(
        () =>
            addDefaultColumnSorting<Customer>(sort, sortOrder, [
                {
                    key: 'forecast',
                    title: <CurrencyEuro className="text-2xl" />,
                    render: (_, item) => (
                        <Badge
                            className="big-dot"
                            color={
                                item.forecastStatus === GenericStatus.success
                                    ? 'green'
                                    : item.forecastStatus === GenericStatus.warning
                                    ? 'orange'
                                    : 'red'
                            }
                        />
                    ),
                    width: 64,
                    filteredValue: [],
                },
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: formatMessage(genericMessages.name),
                    render: (_, item) => (
                        <Link to={getDetailsLink(item)} className="blue-link font-bold">
                            {item.name}
                        </Link>
                    ),
                    sorter: true,
                    filteredValue: [],
                },
                {
                    key: 'type',
                    title: formatMessage({
                        id: 'vehicle_list.column.type',
                        defaultMessage: 'Type',
                    }),
                    render: (_, item) => (item.type ? formatMessage(customerTypeMessagesMap.get(item.type)!) : <></>),
                    filteredValue: type ? [type] : [],
                    filterDropdown: () => (
                        <div className="p-3">
                            <CustomerTypeTagSelect
                                onChange={(value) => setQueryParams({ type: value })}
                                style={{ width: 260 }}
                                size="small"
                                allowClear
                            />
                        </div>
                    ),
                },
                {
                    key: 'group',
                    title: formatMessage(genericMessages.group, { count: 2 }),
                    render: (_, item) => {
                        const filteredPlaces = filterDuplicates(item.places, 'group.id');

                        if (!filteredPlaces) return <></>;

                        return (
                            <BasicList inline>
                                {filteredPlaces.map((place, index) => (
                                    <li key={place.group?.id ?? index}>
                                        <Tooltip title={place.group?.fields.name}>
                                            <Tag>{place.group?.fields.reference}</Tag>
                                        </Tooltip>
                                    </li>
                                ))}
                            </BasicList>
                        );
                    },
                    filteredValue: groupIds,
                    filterDropdown: () => (
                        <div className="p-3">
                            <ValueListItemSelect
                                valueListSlug={ValueListSlug.groups}
                                onChange={(value) => setQueryParams({ groupIds: value })}
                                mode="multiple"
                                style={{ width: 260 }}
                                size="small"
                            />
                        </div>
                    ),
                },
                {
                    key: 'address.street',
                    title: formatMessage(genericMessages.address, { count: 1 }),
                    render: (_, item) => item.address.street,
                    sorter: true,
                    filteredValue: [],
                },
                {
                    key: 'address.zipCode',
                    title: formatMessage(genericMessages.zipCode),
                    render: (_, item) => item.address.zipCode,
                    sorter: true,
                    filteredValue: [],
                },
                {
                    key: 'address.city',
                    title: formatMessage(genericMessages.city),
                    render: (_, item) => item.address.city,
                    sorter: true,
                    filteredValue: [],
                },
                {
                    key: 'primaryContact',
                    title: formatMessage(genericMessages.primaryContact),
                    render: (_, item) => (item.primaryContact ? <ContactTag contact={item.primaryContact} /> : '-'),
                    sorter: true,
                    filteredValue: [],
                },
                {
                    key: 'administrativeContact',
                    title: formatMessage(genericMessages.administrativeContact),
                    render: (_, item) => <ContactTag contact={item.administrativeContact} />,
                    sorter: true,
                    filteredValue: [],
                },
            ]),
        [formatMessage, groupIds, setQueryParams, sort, sortOrder, type]
    );

    return (
        <>
            <Seo
                title={formatMessage({
                    id: 'customers_list.title',
                    defaultMessage: 'Clients',
                    description: 'Customer page title',
                })}
            />
            <div className="flex justify-between items-center mb-6">
                <ListTitle
                    count={customersList?.totalCount}
                    className="mb-0 uppercase"
                    backRoute={isDisabledCustomersRoute ? getRoute(RoutePathName.customers) : undefined}
                >
                    <Route path={getRoute(RoutePathName.customers)} exact>
                        <FormattedMessage
                            id="customers_list.header.title"
                            defaultMessage="Clients"
                            description="Header list title"
                        />
                    </Route>
                    <Route path={getRoute(RoutePathName.customersDisabled)} exact>
                        <FormattedMessage
                            id="customers_list_disabled.header.title"
                            defaultMessage="Clients inactifs"
                            description="Header list title"
                        />
                    </Route>
                </ListTitle>
                <Route path={getRoute(RoutePathName.customers)} exact>
                    <div className="flex items-center space-x-2">
                        <Link to={getRoute(RoutePathName.customerCreate)}>
                            <Button icon={<Plus className="text-base" />} type="primary">
                                <FormattedMessage {...genericMessages.add} tagName="span" />
                            </Button>
                        </Link>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key="disabled" icon={<Ban className="text-faded-blue text-base" />}>
                                        <Link to={getRoute(RoutePathName.customersDisabled)}>
                                            <FormattedMessage
                                                id="customers_list.header.menu.disabled_customers"
                                                defaultMessage="Clients inactifs"
                                                description="Header list menu item"
                                            />
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        key="export"
                                        icon={<Download className="text-faded-blue text-base" />}
                                        onClick={onClickExport}
                                    >
                                        <FormattedMessage {...genericMessages.exportData} />
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                            placement="bottomRight"
                        >
                            <Button icon={<DotsHorizontal className="text-lg" />} />
                        </Dropdown>
                    </div>
                </Route>
            </div>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Input
                    placeholder={formatMessage({
                        id: 'customers_list.search_field.placeholder',
                        defaultMessage: 'Rechercher un nom, une adresse, un contact…',
                        description: 'Customer page search field placeholder',
                    })}
                    onChange={onSearch}
                    defaultValue={search}
                    prefix={<Search className="text-primary text-base leading-4" />}
                    size="small"
                    allowClear
                />
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <>
                        <NoBudgetHeader />
                        <Table<Customer>
                            rowKey="id"
                            columns={columns}
                            loading={isLoading || isFetching}
                            dataSource={customersList?.items}
                            pagination={{
                                total: customersList?.totalCount,
                                current: page + 1,
                                pageSize: customersList?.pageSize,
                                position: ['bottomCenter'],
                                hideOnSinglePage: true,
                            }}
                            expandable={{
                                childrenColumnName: 'notChildren',
                            }}
                            scroll={{
                                x: 1440,
                                y: `calc(100vh - ${(customersList?.pageCount ?? 0) > 1 ? 385 + 64 : 385}px)`,
                                scrollToFirstRowOnChange: true,
                            }}
                            onChange={onTableChange}
                        />
                    </>
                )}
            </Space>
        </>
    );
};

export default CustomersList;
