import { VFC } from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { HiOutlineCalendar } from 'react-icons/hi';
import dayjs from 'dayjs';

import { Customer, InvoicingPrice, ValueListSlug } from '../../../queries/api/types';
import { getRequiredRule } from '../../../i18n';
import customerMessages from '../../../i18n/customerMessages';
import DatePicker from '../../../components/DatePicker';
import FormField from '../../../components/form/FormField';
import { sectionCardFormItemLayout } from '../../../components/SectionCard';
import ValueListItemSelect, { ValueListItemSelectProps } from '../../../components/selects/ValueListItemSelect';
import { useValueListItemList } from '../../../queries/valueListItems';
import CustomerTable from './CustomerTable';
import genericMessages from '../../../i18n/genericMessages';

interface CustomerInvoicingUnitVehicleProps {
    isEditMode?: boolean; // default true
    record?: Customer;
}

const CustomerInvoicingUnitVehicle: VFC<CustomerInvoicingUnitVehicleProps> = ({ isEditMode = true, record }) => {
    const form = Form.useFormInstance();
    const { formatMessage } = useIntl();

    const { data: vehiclesList } = useValueListItemList({
        valueListSlug: ValueListSlug.vehicleTypes,
        pageSize: 50,
    });

    const onVehicleSelectChange: ValueListItemSelectProps['onChange'] = (vehicleId) => {
        if (!vehicleId || Array.isArray(vehicleId)) return;

        const { dates, invoicingPricesVehicle, invoicingUnit } = form.getFieldsValue([
            'dates',
            'invoicingPricesVehicle',
            'invoicingUnit',
        ]);

        const vehiclesIds = invoicingPricesVehicle.map(
            (invoicingPrice: InvoicingPrice) => invoicingPrice.vehicleType?.id
        );
        if (vehiclesIds.includes(vehicleId)) return; // prevents from duplicates

        const newVehicleType = vehiclesList?.items.find((vehicle) => vehicle.id === vehicleId);

        const newInvoicingPrice: Omit<InvoicingPrice, 'weekend' | 'holiday' | 'reuse'> = {
            invoicingUnit,
            vehicleType: newVehicleType,
            standard: 0,
            fromDate: dayjs(dates[0]).format(),
            toDate: dayjs(dates[1]).format(),
        };

        const newInvoicingPrices: Customer['invoicingPrices'] = [...invoicingPricesVehicle, newInvoicingPrice];
        form.setFieldsValue({
            invoicingPricesVehicle: newInvoicingPrices,
        });
    };

    return (
        <>
            {isEditMode && (
                <Form.Item
                    {...sectionCardFormItemLayout}
                    name="dates"
                    label={formatMessage(customerMessages.invoicingUnitPrices)}
                    rules={[getRequiredRule(formatMessage)]}
                    required
                >
                    <DatePicker.RangePicker format="DD/MM/YYYY" suffixIcon={<HiOutlineCalendar />} />
                </Form.Item>
            )}
            <FormField
                label={formatMessage(genericMessages.vehiclesGroup)}
                fields={[
                    {
                        renderEdit: () => (
                            <>
                                <label className="block text-base font-medium mb-4">
                                    {formatMessage(customerMessages.invoicingUnitVehiclePrices)}
                                </label>

                                {isEditMode && (
                                    <ValueListItemSelect
                                        allowClear={false}
                                        onChange={onVehicleSelectChange}
                                        style={{ width: '35%' }}
                                        placeholder={formatMessage(
                                            customerMessages.invoicingUnitVehiclePricesPlaceholder
                                        )}
                                        valueListSlug={ValueListSlug.vehicleTypes}
                                    />
                                )}

                                <div className="mt-6">
                                    <Form.Item shouldUpdate>
                                        {({ getFieldValue }) => {
                                            const invoicingPricesField: InvoicingPrice[] = getFieldValue([
                                                'invoicingPricesVehicle',
                                            ]);

                                            return (
                                                <CustomerTable
                                                    isEditMode={isEditMode}
                                                    invoicingPricesField={invoicingPricesField}
                                                    record={record}
                                                />
                                            );
                                        }}
                                    </Form.Item>
                                </div>
                            </>
                        ),
                    },
                ]}
            />
        </>
    );
};

export default CustomerInvoicingUnitVehicle;
