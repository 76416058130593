import { Button, Card, Spin, Table, TableProps, Typography } from 'antd';
import { RangePickerBaseProps } from 'antd/lib/date-picker/generatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { lazy, Suspense, useState, VFC } from 'react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';

import ApiResult from '../../../components/ApiResult';
import DatePicker from '../../../components/DatePicker';
import ErrorBoundary from '../../../components/ErrorBoundary';
import LabelWithTooltipIcon from '../../../components/LabelWithTooltipIcon';
import { classNames } from '../../../helpers';
import useQueryParams from '../../../hooks/queryParams';
import genericMessages from '../../../i18n/genericMessages';
import { TaskGroup, TaskGroupStatus, Vehicle } from '../../../queries/api/types';
import { useVehicleListTaskGroups } from '../../../queries/vehicles';

const TaskGroupMap = lazy(async () => await import('./TaskGroupMap'));

interface TaskGroupHistoryCardProps {
    vehicleId: Vehicle['id'];
}

const TaskGroupHistoryCard: VFC<TaskGroupHistoryCardProps> = ({ vehicleId }) => {
    const [queryParams, setQueryParams] = useQueryParams('vehicle-details');
    const fromDate = queryParams.get('fromDate') ?? undefined;
    const toDate = queryParams.get('toDate') ?? undefined;
    const [page, setPage] = useState(0);
    const taskGroupId = queryParams.get('taskGroupId') ?? undefined;
    const { formatMessage, formatDate } = useIntl();
    const {
        data: taskGroups,
        isLoading,
        error,
        isError,
    } = useVehicleListTaskGroups({
        id: vehicleId,
        page,
        fromDate,
        toDate,
        status: [TaskGroupStatus.done],
        sort: 'shiftEndedAt',
        sortOrder: 'desc',
    });
    const onTableChange: TableProps<TaskGroup>['onChange'] = (pagination) => {
        setPage((pagination.current ?? 1) - 1);
    };
    const dateChangeHandler: RangePickerBaseProps<Dayjs>['onChange'] = (value) => {
        if (value) {
            const [from, to] = value;
            setQueryParams({
                fromDate: from?.toISOString(),
                toDate: to?.toISOString(),
            });
        } else {
            setQueryParams({
                fromDate: undefined,
                toDate: undefined,
            });
        }
    };

    return (
        <Spin spinning={isLoading}>
            <Card>
                <div className="flex justify-between items-center mb-6">
                    <Typography.Title level={2} className="text-base font-medium text-faded-blue mb-0">
                        <LabelWithTooltipIcon tooltip="à définir">
                            <FormattedMessage
                                id="management.vehicles.history.task_groups"
                                defaultMessage="Historique des parcours réalisés"
                                description="Card title"
                            />
                        </LabelWithTooltipIcon>
                    </Typography.Title>
                    <div className="flex items-center">
                        <Button
                            type="text"
                            icon={
                                <span className="anticon">
                                    <HiOutlineChevronLeft />
                                </span>
                            }
                        />
                        <DatePicker.RangePicker
                            format="DD/MM/YYYY"
                            defaultValue={fromDate && toDate ? [dayjs(fromDate), dayjs(toDate)] : undefined}
                            onChange={dateChangeHandler}
                        />
                        <Button
                            type="text"
                            icon={
                                <span className="anticon">
                                    <HiOutlineChevronRight />
                                </span>
                            }
                        />
                    </div>
                </div>
                {isError ? (
                    <ApiResult status={error.response?.status} />
                ) : (
                    <div className="flex" style={{ height: 460, margin: '0 -24px -24px -24px' }}>
                        <div className="h-full" style={{ width: 260 }}>
                            <Table<TaskGroup>
                                columns={[
                                    {
                                        dataIndex: 'reference',
                                        title: formatMessage(genericMessages.taskGroup, { count: 1 }),
                                        width: 110,
                                    },
                                    {
                                        key: 'date',
                                        title: formatMessage(genericMessages.dateAndTime),
                                        render: (value, record) =>
                                            formatDate(record.shiftEndedAt || record.endDate || record.date, {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: '2-digit',
                                                hour:
                                                    ((record.shiftEndedAt || record.endDate) && '2-digit') || undefined,
                                                minute:
                                                    ((record.shiftEndedAt || record.endDate) && '2-digit') || undefined,
                                            }),
                                    },
                                ]}
                                rowKey="id"
                                dataSource={taskGroups?.items}
                                rowClassName={(record) =>
                                    classNames('cursor-pointer', record.id === taskGroupId && 'table-row-highlighted')
                                }
                                onRow={(record) => ({
                                    onClick: () => setQueryParams({ taskGroupId: record.id }),
                                })}
                                scroll={{ y: 420 }}
                                pagination={{
                                    total: taskGroups?.totalCount,
                                    current: page + 1,
                                    pageSize: taskGroups?.pageSize,
                                    hideOnSinglePage: true,
                                    position: ['bottomCenter'],
                                }}
                                onChange={onTableChange}
                                size="small"
                            />
                        </div>
                        <div className="flex-1 h-full relative">
                            <ErrorBoundary>
                                <Suspense
                                    fallback={
                                        <div className="w-full h-full flex items-center justify-center">
                                            <Spin />
                                        </div>
                                    }
                                >
                                    <TaskGroupMap taskGroupId={taskGroupId} />
                                </Suspense>
                            </ErrorBoundary>
                        </div>
                    </div>
                )}
            </Card>
        </Spin>
    );
};

export default TaskGroupHistoryCard;
