import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    IssueCommentCreatePayload,
    IssueCommentIdPayload,
    IssueCommentListPayload,
    IssueCommentListResponse,
    IssueCommentUpdatePayload,
    remove,
    update,
} from './api/issueComments';
import { IssueComment } from './api/types';

export const issueCommentsKeys = {
    all: ['issueComments'],
    lists: () => [...issueCommentsKeys.all, 'list'],
    list: (params?: IssueCommentListPayload) => [...issueCommentsKeys.lists(), params],
    details: () => [...issueCommentsKeys.all, 'details'],
    detail: (id?: IssueCommentIdPayload) => [...issueCommentsKeys.details(), id],
};

export const useIssueCommentList = <TData = IssueCommentListResponse>(
    params?: IssueCommentListPayload,
    options?: UseQueryOptions<IssueCommentListResponse, AxiosError, TData>
) => {
    return useQuery<IssueCommentListResponse, AxiosError, TData>(
        issueCommentsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useIssueCommentDetails = <TData = IssueComment>(
    id?: IssueCommentIdPayload,
    options?: UseQueryOptions<IssueComment, AxiosError, TData>
) => {
    return useQuery<IssueComment, AxiosError, TData>(
        issueCommentsKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useIssueCommentCreate = (
    options?: UseMutationOptions<IssueComment, AxiosError, IssueCommentCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<IssueComment, AxiosError, IssueCommentCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(issueCommentsKeys.detail(variables.id));
        },
    });
};

export const useIssueCommentUpdate = (
    options?: UseMutationOptions<IssueComment, AxiosError, IssueCommentUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<IssueComment, AxiosError, IssueCommentUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(issueCommentsKeys.detail(variables.id));
        },
    });
};

export const useIssueCommentRemove = (options?: UseMutationOptions<undefined, AxiosError, IssueCommentIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, IssueCommentIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(issueCommentsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(issueCommentsKeys.lists());
        },
    });
};
