import { Badge, Button, Dropdown, Menu, SegmentedProps, Spin, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { OperatorPlanning, Place, VehiclePlanning } from '../../../queries/api/types';
import { useOperatorShiftList } from '../../../queries/operatorShifts';
import {
    useExportOperatorsPlanning,
    useOperatorList,
    useOperatorPlanningAvailabilities,
} from '../../../queries/operators';
import { useVehiclePlanningAvailabilities, useVehiclePlannings } from '../../../queries/taskGroups';
import { getRoute, RoutePathName } from '../../../routes';
import genericMessages from '../../../i18n/genericMessages';
import ListTitle from '../../../components/ListTitle';
import Seo from '../../../components/Seo';
import { FullCalendarTimelineViews } from '../../../components/CustomCalendarTimeline';
import { PlaceSelectProps } from '../../../components/selects/PlaceSelect';
import PlanningsHeader from './PlanningsHeader';
import PlanningsAvailabilities from './PlanningsAvailabilities';
import PlanningsOperators from './PlanningsOperators';
import PlanningsSubHeader from './PlanningsSubHeader';
import PlanningsVehicles from './PlanningsVehicles';
import { useExportVehiclesPlanning, useVehicleList } from '../../../queries/vehicles';
import { Download } from '../../../components/icons';
import planningsMessages from '../../../i18n/planningsMessages';
import SvgTruck from '../../../components/icons/Truck';
import { errorMessage } from '../../../helpers/message';
import SvgUser from '../../../components/icons/User';

export enum PlanningsTabs {
    operators = 'operators',
    vehicles = 'vehicles',
}

const ManagementPlannings: VFC = () => {
    const { formatMessage } = useIntl();

    const pageTitle = formatMessage({
        id: 'menu.management.plannings',
        defaultMessage: 'Suivi des Plannings',
        description: 'menu item',
    });

    const history = useHistory();
    const { tab } = useParams<{ tab: PlanningsTabs }>();
    const onChangeTab = useCallback(
        (tab) => {
            setActivePlaces([]);
            history.push(
                getRoute(RoutePathName.managementPlannings, {
                    tab,
                })
            );
        },
        [history]
    );
    const [activeView, setActiveView] = useState<FullCalendarTimelineViews>(
        FullCalendarTimelineViews.resourceTimelineWeek
    );
    const [activeDate, setActiveDate] = useState(dayjs());
    const [activePlaces, setActivePlaces] = useState<Array<Place['id']>>();

    const calendarControlsMode = activeView === FullCalendarTimelineViews.resourceTimelineWeek ? 'week' : 'month';

    const [operatorsPlanning, setOperatorsPlanning] = useState<OperatorPlanning[] | undefined>([]);
    const [vehicleShifts, setVehicleShifts] = useState<VehiclePlanning[] | undefined>([]);
    const reqParams = {
        fromDate: (activeDate.isAfter(dayjs().endOf(calendarControlsMode))
            ? dayjs().startOf('day')
            : activeDate.startOf(calendarControlsMode)
        ).format('YYYY-MM-DD'),
        toDate: activeDate.endOf(calendarControlsMode).format('YYYY-MM-DD'),
        places: activePlaces,
    };
    const [isExportDriversEnabled, setIsExportDriversEnabled] = useState(false);
    useExportOperatorsPlanning(reqParams, {
        enabled: isExportDriversEnabled,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onError: (error) => {
            errorMessage({
                content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
            });
        },
        onSettled: () => {
            setIsExportDriversEnabled(false);
        },
    });

    const [isExportVehiclesEnabled, setIsExportVehiclesEnabled] = useState(false);
    useExportVehiclesPlanning(reqParams, {
        enabled: isExportVehiclesEnabled,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onError: (error) => {
            errorMessage({
                content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
            });
        },
        onSettled: () => {
            setIsExportVehiclesEnabled(false);
        },
    });

    const { data: operatorList } = useOperatorList({ pageSize: 1 });
    const { data: vehicleList } = useVehicleList({ pageSize: 1 });

    const { data: operatorAvailabilities } = useOperatorPlanningAvailabilities(reqParams);
    const { data: vehicleAvailabilities } = useVehiclePlanningAvailabilities(reqParams);
    const {
        data: allOperatorShifts,
        isFetching: isOperatorShiftsFetching,
        isLoading: isOperatorShiftsLoading,
    } = useOperatorShiftList(
        { ...reqParams, pageSize: 999 },
        {
            onSuccess: (data) => {
                setOperatorsPlanning(data.items);
            },
            staleTime: 0,
        }
    );
    const {
        data: allVehicleShifts,
        isFetching: isVehicleShiftsFetching,
        isLoading: isVehicleShiftsLoading,
    } = useVehiclePlannings(
        { ...reqParams, pageSize: 999 },
        {
            onSuccess: (data) => {
                setVehicleShifts(data.items);
            },
            staleTime: 0,
        }
    );

    const operatorShiftsCount = allOperatorShifts?.totalCount;
    const vehicleShiftsCount = allVehicleShifts?.totalCount;

    const handleSegmentedChange: SegmentedProps['onChange'] = (view) => {
        setActiveView(view as FullCalendarTimelineViews);
    };

    const handlePlaceSelectChange: PlaceSelectProps['onChange'] = (places) => {
        setActivePlaces(places as string[] | undefined);
        setOperatorsPlanning(allOperatorShifts?.items);
        setVehicleShifts(allVehicleShifts?.items);
    };

    useEffect(() => {
        let planning: Element | null | undefined;
        let onScroll: (e: Event) => void;

        const timeout = window.setTimeout(() => {
            const wrapper = document.querySelector('.planning-scroll-wrapper');
            const availabilities = wrapper?.querySelector(
                '.custom-fullcalendar__availabilities thead th:last-child .fc-scroller'
            );
            planning = wrapper?.querySelector('.custom-fullcalendar__planning tbody td:last-child .fc-scroller');

            onScroll = (e) => {
                if (availabilities) {
                    availabilities.scrollLeft = (e.target as HTMLDivElement | null)?.scrollLeft ?? 0;
                }
            };

            planning?.addEventListener('scroll', onScroll);
        }, 500);

        return () => {
            window.clearTimeout(timeout);
            planning?.removeEventListener('scroll', onScroll);
        };
    }, []);

    const dropdownOverlay = (
        <Menu
            items={[
                {
                    key: 'driversExport',
                    icon: <SvgUser />,
                    onClick: () => {
                        setIsExportDriversEnabled(true);
                    },
                    label: <FormattedMessage {...planningsMessages.exportDriversPlanning} />,
                },
                {
                    key: 'vehiclesExport',
                    icon: <SvgTruck />,
                    onClick: () => {
                        setIsExportVehiclesEnabled(true);
                    },
                    label: <FormattedMessage {...planningsMessages.exportVehiclesPlanning} />,
                },
            ]}
        />
    );

    return (
        <>
            <Seo title={pageTitle} />

            <div className="flex justify-between items-center mb-6">
                <ListTitle className="mb-0 uppercase">{pageTitle}</ListTitle>
                <Dropdown placement="bottomRight" arrow overlay={dropdownOverlay}>
                    <Button type="primary" icon={<Download className="text-base" />}>
                        <FormattedMessage {...genericMessages.export} tagName="span" />
                    </Button>
                </Dropdown>
            </div>

            <Tabs onChange={onChangeTab} defaultActiveKey={tab}>
                <Tabs.TabPane
                    tab={
                        <div className="flex justify-between items-center gap-1">
                            <span className="block">{formatMessage(genericMessages.operators)}</span>
                            <Badge
                                count={operatorList?.totalCount}
                                overflowCount={999999}
                                style={{ backgroundColor: '#e5322c' }}
                            />
                        </div>
                    }
                    key={PlanningsTabs.operators}
                >
                    <PlanningsHeader
                        activeView={activeView}
                        activeDate={activeDate}
                        calendarControlsMode={calendarControlsMode}
                        handleSegmentedChange={handleSegmentedChange}
                        setActiveDate={setActiveDate}
                    />
                    <PlanningsSubHeader
                        badgeCount={operatorShiftsCount}
                        handlePlaceSelectChange={handlePlaceSelectChange}
                        label={formatMessage(genericMessages.operators)}
                        type="operator"
                    />
                    <div className="planning-scroll-wrapper">
                        <Spin spinning={isOperatorShiftsFetching || isOperatorShiftsLoading}>
                            <PlanningsAvailabilities
                                activeDate={activeDate.day(1)}
                                activeView={activeView}
                                operatorAvailabilities={operatorAvailabilities}
                            />
                            <PlanningsOperators
                                activeDate={activeDate}
                                activeView={activeView}
                                operatorsPlanning={operatorsPlanning}
                            />
                        </Spin>
                    </div>
                </Tabs.TabPane>

                <Tabs.TabPane
                    tab={
                        <div className="flex justify-between items-center gap-1">
                            <span className="block">{formatMessage(genericMessages.vehicles, { count: 2 })}</span>
                            <Badge
                                count={vehicleList?.totalCount}
                                overflowCount={999999}
                                style={{ backgroundColor: '#e5322c' }}
                            />
                        </div>
                    }
                    key={PlanningsTabs.vehicles}
                >
                    <PlanningsHeader
                        activeView={activeView}
                        activeDate={activeDate}
                        calendarControlsMode={calendarControlsMode}
                        handleSegmentedChange={handleSegmentedChange}
                        setActiveDate={setActiveDate}
                    />
                    <PlanningsSubHeader
                        badgeCount={vehicleShiftsCount}
                        handlePlaceSelectChange={handlePlaceSelectChange}
                        label={formatMessage(genericMessages.vehicles, { count: 2 })}
                        type="vehicle"
                    />
                    <div className="planning-scroll-wrapper">
                        <Spin spinning={isVehicleShiftsFetching || isVehicleShiftsLoading}>
                            <PlanningsAvailabilities
                                activeDate={activeDate.day(1)}
                                activeView={activeView}
                                vehicleAvailabilities={vehicleAvailabilities}
                            />
                            <PlanningsVehicles
                                activeDate={activeDate}
                                activeView={activeView}
                                vehicleShifts={vehicleShifts}
                            />
                        </Spin>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </>
    );
};

export default ManagementPlannings;
