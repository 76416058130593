export { default as ManagementTaskGroups } from './ManagementTaskGroups';

export enum ManagementTaskGroupsTabs {
    allStatuses = 'allStatuses',
    toPlan = 'toPlan',
    readyToStart = 'readyToStart',
    inProgress = 'inProgress',
    toClose = 'toClose',
    done = 'done',
    external = 'external',
    transferred = 'transferred',
}

export enum TaskGroupsAutoPlanificationTabs {
    completeProposals = 'completeProposals',
    incompleteProposals = 'incompleteProposals',
}
