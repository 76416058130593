import { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import genericMessages from '../i18n/genericMessages';

const NoData: VFC = () => (
    <span className="text-secondary">
        <FormattedMessage {...genericMessages.noData} />
    </span>
);

export default NoData;
