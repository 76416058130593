import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { TaskGroupListServicesPayload } from './api/taskGroups';
import {
    create,
    details,
    list,
    TaskGroupServiceCreatePayload,
    TaskGroupServiceIdPayload,
    TaskGroupServiceListPayload,
    TaskGroupServiceListResponse,
    TaskGroupServiceUpdatePayload,
    remove,
    update,
} from './api/taskGroupServices';
import { TaskGroupService } from './api/types';
import { preBillingKeys } from './preBilling';
import { taskGroupsKeys } from './taskGroups';

export const taskGroupServicesKeys = {
    all: ['taskGroupServices'],
    lists: () => [...taskGroupServicesKeys.all, 'list'],
    list: (params?: TaskGroupServiceListPayload) => [...taskGroupServicesKeys.lists(), params],
    listAllServices: () => [...taskGroupsKeys.all, 'services'],
    listServices: (params?: TaskGroupListServicesPayload) => [...taskGroupsKeys.listAllServices(), params],
    details: () => [...taskGroupServicesKeys.all, 'details'],
    detail: (id?: TaskGroupServiceIdPayload) => [...taskGroupServicesKeys.details(), id],
};

export const useTaskGroupServiceList = <TData = TaskGroupServiceListResponse>(
    params?: TaskGroupServiceListPayload,
    options?: UseQueryOptions<TaskGroupServiceListResponse, AxiosError, TData>
) => {
    return useQuery<TaskGroupServiceListResponse, AxiosError, TData>(
        taskGroupServicesKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useTaskGroupServiceDetails = <TData = TaskGroupService>(
    id?: TaskGroupServiceIdPayload,
    options?: UseQueryOptions<TaskGroupService, AxiosError, TData>
) => {
    return useQuery<TaskGroupService, AxiosError, TData>(
        taskGroupServicesKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useTaskGroupServiceCreate = (
    options?: UseMutationOptions<TaskGroupService, AxiosError, TaskGroupServiceCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<TaskGroupService, AxiosError, TaskGroupServiceCreatePayload>(
        async (params) => await create(params),
        {
            ...options,
            onSuccess: (...args) => {
                options?.onSuccess?.(...args);

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(taskGroupServicesKeys.lists());

                queryClient.invalidateQueries(preBillingKeys.listsTaskGroups());
                queryClient.invalidateQueries(preBillingKeys.details());
            },
        }
    );
};

export const useTaskGroupServiceUpdate = (
    options?: UseMutationOptions<TaskGroupService, AxiosError, TaskGroupServiceUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<TaskGroupService, AxiosError, TaskGroupServiceUpdatePayload>(
        async (params) => await update(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(taskGroupServicesKeys.detail(variables.id));
                queryClient.invalidateQueries(taskGroupsKeys.listAllServices());
                queryClient.invalidateQueries(taskGroupsKeys.lists());
            },
        }
    );
};

export const useTaskGroupServiceRemove = (
    options?: UseMutationOptions<undefined, AxiosError, TaskGroupServiceIdPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, TaskGroupServiceIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(taskGroupServicesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(taskGroupServicesKeys.lists());
            queryClient.invalidateQueries(taskGroupsKeys.listAllServices());
            queryClient.invalidateQueries(taskGroupsKeys.lists());
        },
    });
};
