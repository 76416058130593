import { defineMessages } from 'react-intl';

import { TaskGroupPreBillingStatus } from '../queries/api/types';

const preBillingMessages = defineMessages({
    handlingUnitShort: {
        id: 'pre_billing.customers.handling_unit_short',
        defaultMessage: 'UM',
        description: 'column title',
    },
    taskCountShort: {
        id: 'pre_billing.customers.task_count_short',
        defaultMessage: 'PDL',
        description: 'column title',
    },
    taskCount: {
        id: 'pre_billing.customers.task_count',
        defaultMessage: 'Points de livraison',
        description: 'column title',
    },
    mileage: {
        id: 'pre_billing.customers.mileage',
        defaultMessage: 'Kilométrage',
        description: 'column title',
    },
    soldAmount: {
        id: 'pre_billing.customers.sold_amount',
        defaultMessage: 'Vente',
        description: 'column title',
    },
    boughtAmount: {
        id: 'pre_billing.customers.bought_amount',
        defaultMessage: 'Achat',
        description: 'column title',
    },
    marginAmount: {
        id: 'pre_billing.customers.margin_amount',
        defaultMessage: 'Marge',
        description: 'column title',
    },
    extraServices: {
        id: 'pre_billing.customers.extra_services',
        defaultMessage: 'Prestations annexes',
        description: 'pre-billing extra services',
    },
    searchInputPlaceholder: {
        id: 'pre_billing.search_input.placeholder',
        defaultMessage: 'Rechercher un OT',
        description: 'pre-billing pages search input placeholder',
    },
    preBillingStatusToPreBill: {
        id: 'pre_billing.status.to_pre_bill',
        defaultMessage: 'A pré-facturer',
        description: 'pre-billing status',
    },
    preBillingStatusPreBilled: {
        id: 'pre_billing.status.pre_billed',
        defaultMessage: 'Pré-facturée',
        description: 'pre-billing status',
    },
    preBillingReference: {
        id: 'pre_billing.reference',
        defaultMessage: 'N° pré-facturation',
        description: 'pre-billing reference',
    },
    startingMileage: {
        id: 'pre_billing.starting_mileage',
        defaultMessage: 'KM (départ)',
        description: 'column title',
    },
    endingMileage: {
        id: 'pre_billing.ending_mileage',
        defaultMessage: 'KM (arrivée)',
        description: 'column title',
    },
    taskGroupServiceLabel: {
        id: 'pre_billing.task_group_services.label',
        defaultMessage: 'Libellé',
        description: 'form label',
    },
    taskGroupServiceAmount: {
        id: 'pre_billing.task_group_services.amount',
        defaultMessage: 'Montant',
        description: 'form label',
    },
    taskGroupServiceLabelPlaceholder: {
        id: 'pre_billing.task_group_services.label.placeholder',
        defaultMessage: 'Saisir un libellé',
        description: 'input placeholder',
    },
    taskGroupServiceAmountPlaceholder: {
        id: 'pre_billing.task_group_services.amount.placeholder',
        defaultMessage: 'Saisir un montant',
        description: 'input placeholder',
    },
    penaltyAmount: {
        id: 'pre_billing.penalty_amount',
        defaultMessage: 'Surcoût hors MG',
        description: 'column title',
    },
    interGroup: {
        id: 'pre_billing.inter_group',
        defaultMessage: 'Intergroupe',
        description: 'column title',
    },
    markAsPreBilledTooltip: {
        id: 'pre_billing.tooltip.mark_as_pre_billed',
        defaultMessage: 'Marquer comme pré-facturée',
        description: 'tooltip',
    },
    seeAdditionalServicesTooltip: {
        id: 'pre_billing.tooltip.see_additional_services',
        defaultMessage: 'Voir les prestations supplémentaires',
        description: 'tooltip',
    },
    addAdditionalServicesTooltip: {
        id: 'pre_billing.add_additional_services',
        defaultMessage: 'Ajouter une prestation supplémentaire',
        description: 'Modal title, tooltip, button',
    },
    exportIDL: {
        id: 'pre_billing.export_idl',
        defaultMessage: 'Export IDL',
        description: 'Download button',
    },
    exportCustomer: {
        id: 'pre_billing.export_customer',
        defaultMessage: 'Export client',
        description: 'Download button',
    },
    exportAll: {
        id: 'pre_billing.export_all',
        defaultMessage: 'Exporter tout',
        description: 'Download button',
    },
    exportInterGroup: {
        id: 'pre_billing.export_inter_group',
        defaultMessage: 'Exporter les <b>intergroupe</b>',
        description: 'Download button',
    },
});

export default preBillingMessages;

export const preBillingStatusMessageMap = new Map([
    [TaskGroupPreBillingStatus.toPreBill, preBillingMessages.preBillingStatusToPreBill],
    [TaskGroupPreBillingStatus.preBilled, preBillingMessages.preBillingStatusPreBilled],
]);
