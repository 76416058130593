import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { SearchPaginationQuery } from './api';
import {
    list,
    create,
    details,
    ApplicationCreatePayload,
    ApplicationIdPayload,
    remove,
    update,
    ApplicationUpdatePayload,
    ApplicationListResponse,
} from './api/applications';
import { Application } from './api/types';

export const applicationKeys = {
    all: ['applications'],
    lists: () => [...applicationKeys.all, 'list'],
    list: (params?: SearchPaginationQuery) => [...applicationKeys.lists(), params],
    details: () => [...applicationKeys.all, 'details'],
    detail: (params: ApplicationIdPayload) => [...applicationKeys.details(), params],
};

// LIST
export const useApplicationsList = (params?: SearchPaginationQuery) => {
    return useQuery<ApplicationListResponse, AxiosError>(applicationKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
    });
};

// DETAILS
export const useApplicationDetails = <TData = Application>(
    params: ApplicationIdPayload,
    options: UseQueryOptions<Application, AxiosError, TData>
) => {
    return useQuery<Application, AxiosError, TData>(
        applicationKeys.detail(params),
        async () => await details(params),
        options
    );
};

// CREATE
export const useApplicationCreate = (
    options: UseMutationOptions<Application, AxiosError, ApplicationCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Application, AxiosError, ApplicationCreatePayload>(async (params) => await create(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options.onSettled?.(data, error, variables, context);

            // invalidate list query to refetch with the newly added item
            queryClient.invalidateQueries(applicationKeys.lists());

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(applicationKeys.detail({ id: data?.id ?? '' }));
        },
    });
};

// UPDATE
export const useApplicationUpdate = (
    options: UseMutationOptions<Application, AxiosError, ApplicationUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Application, AxiosError, ApplicationUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options.onSettled?.(data, error, variables, context);

            // invalidate list query to refetch with the newly updated item
            queryClient.invalidateQueries(applicationKeys.lists());

            // invalidate detail query to refetch with the newly updated item
            queryClient.invalidateQueries(applicationKeys.detail(variables));
        },
    });
};

// DELETE
export const useApplicationRemove = (options: UseMutationOptions<undefined, AxiosError, ApplicationIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, ApplicationIdPayload>(async (params) => await remove(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options.onSettled?.(data, error, variables, context);

            // invalidate list query since we deleted the item
            queryClient.invalidateQueries(applicationKeys.lists());

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(applicationKeys.detail(variables));
        },
    });
};
