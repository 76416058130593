import { FC, ReactNode, useCallback, useMemo } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import type { Location } from 'history';
import { SiderProps } from 'antd/lib/layout';
import { Layout, Menu, Typography } from 'antd';
import { useIntl } from 'react-intl';
import {
    ApartmentOutlined,
    AppstoreOutlined,
    ProfileOutlined,
    SkinOutlined,
    StarOutlined,
    UserOutlined,
} from '@ant-design/icons';

import genericMessages from '../i18n/genericMessages';
import constants from '../config/constants';
import { useLayout } from '../context/LayoutContext';
import { hasPermission } from '../helpers/security';
import { Permission, PermissionRight } from '../queries/api/types';
import { ManagementTaskGroupsTabs } from '../pages/management/taskGroups';
import { useAuth } from '../context/AuthContext';
import { PlanningsTabs } from '../pages/management/plannings/ManagementPlannings';
import { getRoute, RoutePathName } from '../routes';
import { Order, SettingTwo, Transaction, UserCheck } from './icons';
import { CustomerDetailsTabs } from '../pages/settings/customers';

import '../assets/styles/MainMenu.less';

const getSelectedKey = (location: Location) => {
    if (location.pathname.startsWith(getRoute(RoutePathName.vehicles))) {
        return getRoute(RoutePathName.vehicles);
    } else if (location.pathname.startsWith(getRoute(RoutePathName.operators))) {
        return getRoute(RoutePathName.operators);
    } else if (location.pathname.startsWith(getRoute(RoutePathName.customers))) {
        return getRoute(RoutePathName.customers);
    } else if (location.pathname.startsWith(getRoute(RoutePathName.places))) {
        return getRoute(RoutePathName.places);
    } else if (location.pathname.startsWith(getRoute(RoutePathName.transporters))) {
        return getRoute(RoutePathName.transporters);
    } else if (location.pathname.startsWith('/pre-billing/customers')) {
        return getRoute(RoutePathName.preBillingCustomersList);
    } else if (location.pathname.startsWith('/pre-billing/transporters')) {
        return getRoute(RoutePathName.preBillingTransportersList);
    }

    return location.pathname;
};

interface MenuItem {
    key: string;
    to?: LinkProps['to'];
    href?: string; // used for outgoing links
    target?: string; // used for outgoing links
    onClick?: () => void;
    label: ReactNode;
    icon?: any;
    children?: MenuItem[];
    childKeys?: string[];
    disabled?: boolean;
    permissions?: Array<{ permission: Permission; permissionRight?: PermissionRight }>;
}

const MainMenu: FC = () => {
    const { formatMessage } = useIntl();
    const { user } = useAuth();
    const { setIsSidebarCollapsed, isSidebarCollapsed } = useLayout();
    const location = useLocation();
    const onCollapse: SiderProps['onCollapse'] = (value) => {
        setIsSidebarCollapsed(value);
    };
    const menuItems: MenuItem[] = useMemo(
        () => [
            {
                key: 'management',
                label: formatMessage({
                    id: 'menu.management',
                    defaultMessage: 'Pilotage',
                    description: 'menu item',
                }),
                icon: <Order />,
                permissions: [
                    { permission: Permission.operators },
                    { permission: Permission.vehicles },
                    { permission: Permission.customerForecasts },
                ],
                children: [
                    {
                        key: getRoute(RoutePathName.managementTaskGroups, {
                            tab: ManagementTaskGroupsTabs.allStatuses,
                        }),
                        label: formatMessage({
                            id: 'menu.management.ot',
                            defaultMessage: 'Suivi des OT',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.managementTaskGroups, { tab: ManagementTaskGroupsTabs.allStatuses }),
                        permissions: [{ permission: Permission.taskGroupTracking }],
                    },
                    {
                        key: getRoute(RoutePathName.managementPlannings, { tab: PlanningsTabs.operators }),
                        label: formatMessage({
                            id: 'menu.management.plannings',
                            defaultMessage: 'Suivi des Plannings',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.managementPlannings, { tab: PlanningsTabs.operators }),
                        permissions: [{ permission: Permission.operatorTracking }],
                    },
                    {
                        key: getRoute(RoutePathName.managementForecasts),
                        label: formatMessage({
                            id: 'menu.management.forecasts',
                            defaultMessage: 'Suivi du Forecast',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.managementForecasts),
                        permissions: [{ permission: Permission.customerForecasts }],
                    },
                    {
                        key: getRoute(RoutePathName.managementOperators),
                        label: formatMessage({
                            id: 'menu.management.operators',
                            defaultMessage: 'Suivi des Chauffeurs',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.managementOperators),
                        permissions: [{ permission: Permission.operatorTracking }],
                    },
                    {
                        key: getRoute(RoutePathName.managementVehicles),
                        label: formatMessage({
                            id: 'menu.management.vehicles',
                            defaultMessage: 'Suivi du Parc',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.managementVehicles),
                        permissions: [{ permission: Permission.vehicleTracking }],
                    },
                ],
            },
            {
                key: 'preBilling',
                label: formatMessage({
                    id: 'menu.pre_billing',
                    defaultMessage: 'Pré-facturation',
                    description: 'menu item',
                }),
                icon: <Transaction />,
                permissions: [{ permission: Permission.prebilling }],
                children: [
                    {
                        key: getRoute(RoutePathName.preBillingCustomersList),
                        childKeys: [
                            getRoute(RoutePathName.preBillingCustomersDetails, { customerId: '0' }),
                            getRoute(RoutePathName.preBillingCustomersTaskGroupServices, {
                                customerId: '0',
                                taskGroupId: '0',
                            }),
                        ],
                        label: formatMessage({
                            id: 'menu.pre_billing.customers',
                            defaultMessage: 'Clients',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.preBillingCustomersList),
                        permissions: [{ permission: Permission.customerPrebilling }],
                    },
                    {
                        key: getRoute(RoutePathName.preBillingTransportersList),
                        childKeys: [
                            getRoute(RoutePathName.preBillingTransportersDetails, { transporterId: '0' }),
                            getRoute(RoutePathName.preBillingTransportersTaskGroupServices, {
                                transporterId: '0',
                                taskGroupId: '0',
                            }),
                        ],
                        label: formatMessage({
                            id: 'menu.pre_billing.transporters',
                            defaultMessage: 'Partenaires transport',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.preBillingTransportersList),
                        permissions: [{ permission: Permission.transporterPrebilling }],
                    },
                ],
            },
            {
                key: 'settings',
                label: formatMessage({
                    id: 'menu.settings',
                    defaultMessage: 'Configuration',
                    description: 'menu item',
                }),
                icon: <SettingTwo />,
                permissions: [{ permission: Permission.settings }],
                children: [
                    {
                        key: getRoute(RoutePathName.places),
                        childKeys: [
                            getRoute(RoutePathName.placesDisabled),
                            getRoute(RoutePathName.placesValueLists),
                            getRoute(RoutePathName.placeDetails, { placeId: '0' }),
                        ],
                        label: formatMessage({
                            id: 'menu.places',
                            defaultMessage: 'Entrepôts / Magasins',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.places),
                        permissions: [{ permission: Permission.placeConfiguration }],
                    },
                    {
                        key: getRoute(RoutePathName.customers),
                        childKeys: [
                            getRoute(RoutePathName.customersDisabled),
                            getRoute(RoutePathName.customerDetails, {
                                customerId: '0',
                                tab: CustomerDetailsTabs.informations,
                            }),
                        ],
                        label: formatMessage({
                            id: 'menu.customers',
                            defaultMessage: 'Clients',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.customers),
                        permissions: [{ permission: Permission.customerConfiguration }],
                    },
                    {
                        key: getRoute(RoutePathName.transporters),
                        childKeys: [
                            getRoute(RoutePathName.transportersDisabled),
                            getRoute(RoutePathName.transporterDetails, { transporterId: '0' }),
                            getRoute(RoutePathName.transportersValueLists),
                        ],
                        label: formatMessage({
                            id: 'menu.transporters',
                            defaultMessage: 'Partenaires transport',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.transporters),
                        permissions: [{ permission: Permission.transporterConfiguration }],
                    },
                    {
                        key: getRoute(RoutePathName.operators),
                        childKeys: [
                            getRoute(RoutePathName.operatorsDisabled, { operatorId: '0' }),
                            getRoute(RoutePathName.operatorsProfileForm),
                            getRoute(RoutePathName.operatorsValueLists),
                        ],
                        label: formatMessage(genericMessages.operators),
                        to: getRoute(RoutePathName.operators),
                        permissions: [{ permission: Permission.operatorConfiguration }],
                    },
                    {
                        key: getRoute(RoutePathName.vehicles),
                        childKeys: [
                            getRoute(RoutePathName.vehicleDetails, { vehicleId: '0' }),
                            getRoute(RoutePathName.vehiclesDisabled),
                            getRoute(RoutePathName.vehiclesValueLists),
                        ],
                        label: formatMessage({
                            id: 'menu.vehicles',
                            defaultMessage: 'Véhicules',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.vehicles),
                        permissions: [{ permission: Permission.vehicleConfiguration }],
                    },
                ],
            },
            {
                key: 'administration',
                label: formatMessage({
                    id: 'menu.administration',
                    defaultMessage: 'Administration',
                    description: 'menu item',
                }),
                icon: <UserCheck />,
                permissions: [{ permission: Permission.admin }],
                children: [
                    {
                        key: getRoute(RoutePathName.usersList),
                        childKeys: [getRoute(RoutePathName.usersList), getRoute(RoutePathName.usersDetails)],
                        label: formatMessage({
                            id: 'menu.users',
                            defaultMessage: 'Utilisateurs',
                            description: 'menu item',
                        }),
                        to: getRoute(RoutePathName.usersList),
                        permissions: [{ permission: Permission.admin }],
                    },
                ],
            },
            {
                key: 'superAdmin',
                label: 'Super Admin',
                icon: <StarOutlined />,
                permissions: [{ permission: Permission.superAdmin }],
                children: [
                    {
                        key: getRoute(RoutePathName.superAdminApplications),
                        to: getRoute(RoutePathName.superAdminApplications),
                        label: 'Applications',
                        icon: <AppstoreOutlined />,
                        permissions: [{ permission: Permission.superAdmin }],
                    },
                    {
                        key: getRoute(RoutePathName.superAdminOrganizations),
                        to: getRoute(RoutePathName.superAdminOrganizations),
                        label: 'Organisations',
                        icon: <ApartmentOutlined />,
                        permissions: [{ permission: Permission.superAdmin }],
                    },
                    {
                        key: getRoute(RoutePathName.superAdminValueList),
                        to: getRoute(RoutePathName.superAdminValueList),
                        label: 'Listes de valeurs',
                        icon: <ProfileOutlined />,
                        permissions: [{ permission: Permission.superAdmin }],
                    },
                    {
                        key: getRoute(RoutePathName.superAdminRoleList),
                        to: getRoute(RoutePathName.superAdminRoleList),
                        label: 'Rôles',
                        icon: <SkinOutlined />,
                        permissions: [{ permission: Permission.superAdmin }],
                    },
                    // {
                    //     key: getRoute(RoutePathName.superAdminJobList),
                    //     to: getRoute(RoutePathName.superAdminJobList),
                    //     label: 'Jobs',
                    //     icon: <ToolOutlined />,
                    //     permissions: [{ permission: Permission.superAdmin }],
                    // },
                    {
                        key: getRoute(RoutePathName.superAdminUserList),
                        to: {
                            pathname: getRoute(RoutePathName.superAdminUserList),
                            search: new URLSearchParams({ sort: 'updatedAt', sortOrder: 'desc' }).toString(),
                        },
                        label: 'Utilisateurs',
                        icon: <UserOutlined />,
                        permissions: [{ permission: Permission.superAdmin }],
                    },
                ],
            },
        ],
        [formatMessage]
    );
    const getDefaultOpenKeys = useCallback(
        (pathname: string) => {
            const topLevelItem = menuItems.find((menuItem) =>
                menuItem.children?.some(
                    (child) =>
                        child.key === pathname ||
                        child.childKeys?.includes(pathname) ||
                        pathname.startsWith(child.key) ||
                        child.childKeys?.some((childKey) => pathname.startsWith(childKey.substring(0, -1)))
                )
            );

            return topLevelItem ? [topLevelItem.key] : undefined;
        },
        [menuItems]
    );
    return (
        <Layout.Sider
            collapsible
            collapsed={isSidebarCollapsed}
            onCollapse={onCollapse}
            trigger={null}
            breakpoint="lg"
            theme="light"
            id="sidebar"
            width={254}
        >
            <div id="sidebar-wrapper">
                <Menu
                    mode="inline"
                    selectedKeys={[getSelectedKey(location)]}
                    defaultOpenKeys={getDefaultOpenKeys(location.pathname)}
                >
                    {menuItems
                        .filter((item) =>
                            item.permissions
                                ? item.permissions?.some(({ permission, permissionRight }) =>
                                      hasPermission(user, permission, permissionRight ?? PermissionRight.read)
                                  )
                                : true
                        )
                        .map((item) => {
                            if (!item.children) {
                                return (
                                    <Menu.Item key={item.key} icon={item.icon} disabled={item.disabled}>
                                        {item.href ? (
                                            <a href={item.href} target={item.target}>
                                                {item.label}
                                            </a>
                                        ) : (
                                            <Link to={item.to!}>{item.label}</Link>
                                        )}
                                    </Menu.Item>
                                );
                            } else {
                                return (
                                    <Menu.SubMenu
                                        key={item.key}
                                        disabled={item.disabled}
                                        title={item.label}
                                        icon={item.icon}
                                    >
                                        {item.children
                                            .filter((subItem) =>
                                                subItem.permissions
                                                    ? subItem.permissions.some(({ permission, permissionRight }) =>
                                                          hasPermission(
                                                              user,
                                                              permission,
                                                              permissionRight ?? PermissionRight.read
                                                          )
                                                      )
                                                    : true
                                            )
                                            .map((subItem) => (
                                                <Menu.Item key={subItem.key} disabled={subItem.disabled}>
                                                    {subItem.href ? (
                                                        <a href={subItem.href} target={subItem.target}>
                                                            {subItem.icon}
                                                            <span>{subItem.label}</span>
                                                        </a>
                                                    ) : (
                                                        <Link to={subItem.to!}>{subItem.label}</Link>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                    </Menu.SubMenu>
                                );
                            }
                        })}
                </Menu>
                <Typography.Text type="secondary">v{constants.VERSION}</Typography.Text>
            </div>
        </Layout.Sider>
    );
};

export default MainMenu;
