import { defineMessages } from 'react-intl';

import { PlaceType } from '../queries/api/types';

const placeMessages = defineMessages({
    type: {
        id: 'place.type',
        defaultMessage: 'Type',
        description: 'Place type',
    },
    warehouse: {
        id: 'place.type.warehouse',
        defaultMessage: 'Entrepôt',
        description: 'Place type',
    },
    shop: {
        id: 'place.type.shop',
        defaultMessage: 'Magasin',
        description: 'Place type',
    },
    managers: {
        id: 'place.managers',
        defaultMessage: `{count, plural, =0 {Gestionnaire} one {Gestionnaire} other {Gestionnaires}}`,
        description: 'Place managers',
    },
    managerIndex: {
        id: 'place.manager_with_index',
        defaultMessage: 'Gestionnaire n°{index}',
        description: 'Place manager with index',
    },
    managersAddButton: {
        id: 'place.managers.add_button',
        defaultMessage: 'Ajouter un gestionnaire',
        description: 'Place managers add button',
    },
    managersEditButton: {
        id: 'place.managers.edit_button',
        defaultMessage: 'Modifier les gestionnaires',
        description: 'Place managers edit button',
    },
    generalInformationsEdit: {
        id: 'place_details.section.general_informations.edit_button',
        defaultMessage: 'Modifier les informations',
        description: 'Place details section edit button',
    },
    openHoursSection: {
        id: 'place_details.section.open_hours',
        defaultMessage: 'Jours et horaires d’ouverture et de fermeture standards',
        description: 'Place details section title',
    },
    openHoursEditButton: {
        id: 'place_details.section.open_hours.edit_button',
        defaultMessage: 'Modifier les jours et horaires',
        description: 'Place details section edit button',
    },
    closedDays: {
        id: 'place.closed_days',
        defaultMessage: 'Fermetures exceptionnelles',
        description: 'Place closed days',
    },
    closedDaysEditButton: {
        id: 'place.closed_days.edit_button',
        defaultMessage: 'Modifier les jours de fermeture',
        description: 'Place closed days',
    },
    closedDaysLabel: {
        id: 'place.closed_days.label',
        defaultMessage: 'Ajouter ci-dessous les jours et horaires de fermetures exceptionnelles :',
        description: 'Place closed days label',
    },
    closedDaysEmpty: {
        id: 'place.closed_days.empty',
        defaultMessage: 'Aucun jour de fermeture exceptionnelle',
        description: 'Place closed days empty text',
    },
    docks: {
        id: 'place.docks',
        defaultMessage: 'Quais',
        description: 'Place docks',
    },
    dockTypesEditButton: {
        id: 'place.dock_types.edit_button',
        defaultMessage: 'Modifier les quais',
        description: 'Place dock types edit button',
    },
    dockTypeIndex: {
        id: 'place.dock_types_with_index',
        defaultMessage: 'Type n°{index}',
        description: 'Place dock type with index',
    },
    dockTypesLabel: {
        id: 'place.dock_types.label',
        defaultMessage: 'Indiquez ci-dessous le nombre de quais disponibles par type :',
        description: 'Place dock types label',
    },
    dockTypesAddButton: {
        id: 'place.dock_types.add_button',
        defaultMessage: 'Ajouter un type de quai',
        description: 'Place dock types add button',
    },
    dockTypeLabel: {
        id: 'place.dock_types.type.label',
        defaultMessage: 'Type de quai',
        description: 'Place dock type label',
    },
    dockTypeCountLabel: {
        id: 'place.dock_types.count.label',
        defaultMessage: 'Nombre',
        description: 'Place dock type count label',
    },
    vehiclesByDockSection: {
        id: 'place_details.section.vehicles_by_dock',
        defaultMessage: 'Véhicules acceptés par quai',
        description: 'Place vehicles by docks',
    },
    vehiclesByDockLabel: {
        id: 'place_details.vehicles_by_dock.label',
        defaultMessage: 'Ajouter ci-dessous les types de véhicules acceptés par quai :',
        description: 'Place vehicles by docks',
    },
    vehiclesByDockEditButton: {
        id: 'place_details.vehicles_by_dock.edit_button',
        defaultMessage: 'Modifier les véhicules',
        description: 'Place vehicles by docks',
    },
    vehiclesByDockAddButton: {
        id: 'place_details.vehicles_by_dock.add_button',
        defaultMessage: 'Ajouter un quai',
        description: 'Place vehicles by dock add button',
    },
    vehiclesByDockIndex: {
        id: 'place_details.vehicles_by_dock.index',
        defaultMessage: 'Quai n°{index}',
        description: 'Place vehicles by dock add button',
    },
    vehiclesByDockDockLabel: {
        id: 'place_details.vehicles_by_dock.dock.label',
        defaultMessage: 'Quai',
        description: 'Place vehicles by dock dock label',
    },
    vehiclesByDockDockPlaceholder: {
        id: 'place_details.vehicles_by_dock.dock.placeholder',
        defaultMessage: 'Nom du quai',
        description: 'Place vehicles by dock dock placeholder',
    },
    namePlaceholder: {
        id: 'place_details.create.input.name.placeholder',
        defaultMessage: 'Saisir un nom',
    },
    groupLong: {
        id: 'place_details.create.input.group',
        defaultMessage: 'Groupe de rattachement',
    },
    activity: {
        id: 'place.activity',
        defaultMessage: 'Activité',
    },
    customers: {
        id: 'place.customers',
        defaultMessage: 'Clients associés',
    },
    secondaryPlaces: {
        id: 'place.secondary_places',
        defaultMessage: 'Entrepôts / magasins de débord',
    },
    firstLoadingHour: {
        id: 'place.first_loading_hour',
        defaultMessage: 'Première heure de chargement',
    },
    openHoursErrorFromMustBeBeforeTo: {
        id: 'place.open_hours.error.from_higher_than_to',
        defaultMessage: "L'horaire de fermeture doit être supérieure à l'horaire d'ouverture",
    },
    closedFromTo: {
        id: 'place.closed_days.closed_from_to',
        defaultMessage: 'Fermé de {from} à {to}',
    },
    mobilicToken: {
        id: 'place.mobilic_token.mobilic_token',
        defaultMessage: 'Token Mobilic',
    },
    mobilicTokenGenerate: {
        id: 'place.mobilic_token.generate',
        defaultMessage: 'générer',
    },
    mobilicTokenUpdate: {
        id: 'place.mobilic_token.update',
        defaultMessage: 'mettre à jour',
    },
    mobilicTokenDelete: {
        id: 'place.mobilic_token.delete',
        defaultMessage: 'supprimer',
    },
    mobilicTokenActivated: {
        id: 'place.mobilic_token.activated',
        defaultMessage: 'Actif',
    },
    mobilicTokenDisabled: {
        id: 'place.mobilic_token.disabled',
        defaultMessage: 'Aucun',
    },
    mobilicPlaceIdName: {
        id: 'place.mobilic_place_id.name',
        defaultMessage: 'Identifiant de société Mobilic',
    },
    mobilicPlaceIdPlaceHolder: {
        id: 'place.mobilic_place_id.placeholder',
        defaultMessage: 'Saisir un numéro',
    },
    mobilicTokenDeleteConfirmation: {
        id: 'place.mobilic_place_id.token_delete_confirmation',
        defaultMessage: 'Suppression du token Mobilic',
    },
    mobilicTokenDeleteContent: {
        id: 'place.mobilic_place_id.token_delete_content',
        defaultMessage: 'Etes-vous sûr de vouloir supprimer le token ?',
    },
    mobilicTokenUpdateConfirmation: {
        id: 'place.mobilic_place_id.mobilic_token_update_confirmation',
        defaultMessage: 'Mise à jour du token Mobilic',
    },
    mobilicTokenUpdateContent: {
        id: 'place.mobilic_place_id.mobilic_token_update_content',
        defaultMessage: 'Etes-vous sûr de vouloir mettre à jour le token ?',
    },
    mobilicTokenUpdateSuccess: {
        id: 'place.mobilic_place_id.mobilic_token_update_success',
        defaultMessage: 'Token Mobilic généré avec succès',
    },
    mobilicTokenUpdateError: {
        id: 'place.mobilic_place_id.mobilic_token_update_error',
        defaultMessage: 'Pas de magasin à mettre à jour avec le token mobilic',
    },
});

export default placeMessages;

export const placeTypeMessagesMap = new Map([
    [PlaceType.warehouse, placeMessages.warehouse],
    [PlaceType.shop, placeMessages.shop],
]);
