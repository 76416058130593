/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.024 5.176a.6.6 0 0 1 0 .848l-3.6 3.6a.6.6 0 0 1-.848 0l-3.6-3.6a.6.6 0 0 1 .848-.848L4.4 7.75V.8a.6.6 0 0 1 1.2 0v6.951l2.576-2.575a.6.6 0 0 1 .848 0Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgArrowDown: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-arrow-down', props.className)} />
));
export default SvgArrowDown;
