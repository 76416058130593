import { Card, CardProps, Spin, Table, TableProps, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, useState, VFC } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import ApiResult from '../../../components/ApiResult';
import LabelWithTooltipIcon from '../../../components/LabelWithTooltipIcon';
import NoData from '../../../components/NoData';
import { sortOrderConverter } from '../../../helpers';
import genericMessages from '../../../i18n/genericMessages';
import { Operator, TaskGroup, TaskGroupStatus } from '../../../queries/api/types';
import { useOperatorListTaskGroups } from '../../../queries/operators';
import { getRoute, RoutePathName } from '../../../routes';

interface OperatorTaskGroupListCardProps extends CardProps {
    operatorId: Operator['id'];
}

const OperatorTaskGroupListCard: VFC<OperatorTaskGroupListCardProps> = ({ operatorId, ...cardProps }) => {
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState<string>('date');
    const [sortOrder, setSortOrder] = useState<string>('desc');
    const { formatMessage } = useIntl();
    const {
        data: taskGroups,
        isLoading,
        error,
        isError,
    } = useOperatorListTaskGroups({
        id: operatorId,
        sort: sort || undefined,
        sortOrder: sort ? sortOrder : undefined,
        page,
        pageSize: 8,
        status: TaskGroupStatus.done,
    });
    const onTableChange: TableProps<TaskGroup>['onChange'] = (pagination, _, sorter) => {
        const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

        setPage((pagination.current ?? 1) - 1);
        setSort(`${sortObj.column?.key ?? ''}`);
        setSortOrder(sortOrderConverter(sortObj.order ?? ''));
    };
    const columns = useMemo<ColumnsType<TaskGroup>>(
        () => [
            {
                key: 'date',
                title: formatMessage(genericMessages.date),
                render: (value, record) => <FormattedDate value={record.date} />,
                sorter: true,
                defaultSortOrder: 'descend',
            },
            {
                dataIndex: 'reference',
                title: formatMessage(genericMessages.taskGroup, { count: 1 }),
            },
            {
                dataIndex: 'vehicle',
                title: formatMessage(genericMessages.vehicles, { count: 1 }),
                render: (value, record) =>
                    record.vehicle ? (
                        <Link
                            to={getRoute(RoutePathName.vehicleDetails, { vehicleId: record.vehicle.id })}
                            className="blue-link font-semibold"
                        >
                            {record.vehicle.reference}
                        </Link>
                    ) : (
                        <NoData />
                    ),
            },
        ],
        [formatMessage]
    );

    return (
        <Spin spinning={isLoading} wrapperClassName={cardProps.className}>
            <Card {...cardProps}>
                <Typography.Title level={2} className="text-base font-medium text-faded-blue mb-6">
                    <LabelWithTooltipIcon tooltip="à définir">
                        <FormattedMessage
                            id="management.operators.history.task_groups"
                            defaultMessage="Historique des tournées"
                            description="Card title"
                        />
                    </LabelWithTooltipIcon>
                </Typography.Title>
                {isError ? (
                    <ApiResult status={error.response?.status} />
                ) : (
                    <Table<TaskGroup>
                        columns={columns}
                        rowKey="id"
                        dataSource={taskGroups?.items}
                        onChange={onTableChange}
                        pagination={{
                            total: taskGroups?.totalCount,
                            current: page + 1,
                            pageSize: taskGroups?.pageSize,
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            showSizeChanger: false,
                            className: 'mb-0',
                        }}
                        size="small"
                        bordered
                    />
                )}
            </Card>
        </Spin>
    );
};

export default OperatorTaskGroupListCard;
